<template>
  <v-main>
    <!--loading overlay show component -->
    <loading v-if="loading" />
    <!--loading overlay show component -->
    <v-container>
      <v-row>
        <v-col cols="12" xs="12" sm="12" md="4"> </v-col>
        <v-col cols="12" xs="12" sm="12" md="4">
          <v-card class="login-card" max-width="450" outlined>
            <v-alert text prominent type="error" v-if="errorResponse">
              {{ authShowerror }}
            </v-alert>
            <img class="logo" alt="Vue logo" src="../assets/logo.png" />
            <v-divider class="mx-4"></v-divider>
            <v-card-text>
              <form>
                <v-text-field v-model="username" :error-messages="usernameErrors" label="USERNAME" required
                  @input="$v.username.$touch()" @blur="$v.username.$touch()" class="login-text"></v-text-field>
                <v-text-field v-model="password" :error-messages="passwordErrors"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" :type="showPassword ? 'text' : 'password'"
                  @click:append="showPassword = !showPassword" label="PASSWORD" required @input="$v.password.$touch()"
                  @blur="$v.password.$touch()" class="login-text"></v-text-field>

                <div class="mx-auto text-center">
                  <v-btn class="mr-4 text-center" color="secondary" dark @click="submit" block>
                    Login
                  </v-btn>
                </div>
              </form>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="4"> </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import { required } from "vuelidate/lib/validators";

import { mapActions, mapGetters } from "vuex";


export default {
  name: "Login",
  data: () => ({
    showPassword: false,
    password: "",
    username: ""
  }),
  validations: {
    username: { required },
    password: { required }
  },
  computed: {
    usernameErrors() {
      const errors = [];
      if (!this.$v.username.$dirty) return errors;
      !this.$v.username.required && errors.push("Username is required.");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required && errors.push("Password is required");
      return errors;
    },
    ...mapGetters(["authStatus", "authStatusError"]),
    loading: function () {
      return this.authStatus === "loading";
    },
    errorResponse: function () {
      return this.authStatus === "error";
    },
    authShowerror: function () {
      return this.authStatusError;
    }
  },
  created() {

  },
  methods: {
    ...mapActions(["loginAction"]),
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("ERROR");
      } else {
        // do your submit logic here
        setTimeout(() => {
          const loginData = {
            username: this.username.toUpperCase(),
            password: this.password.toUpperCase()
          };

          this.loginAction(loginData);
        }, 500);
      }
    }
  }
};
</script>

<style scoped>
.logo {
  margin: 0 auto;
  display: block;
  padding: 20px;
}

.login-card {
  margin: 150px auto;
  display: block;
}

.login-text {
  text-transform: none !important;
}
</style>
