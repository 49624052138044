<template>
  <div>
    <!--container-->
    <v-container>
      <!--tob bar-->
      <bar />
      <!--tob bar end-->

      <!--template-->
      <template>
        <!--card -->
        <v-card class="form-card mx-auto my-12">
          <!--alert box-->
          <v-alert text prominent type="error" v-if="errorResponse">
            <div v-for="(error, i) in productError" :key="i">
              {{ error }}
            </div>
          </v-alert>
          <!--alert box end-->
          <v-list-item class="headline">
            <v-list-item-content>
              <v-list-item-title> PRODUCT ADD </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <!--form-->
          <form enctype="multipart/form-data">
            <!--input field-->
            <v-card-text>
              <v-row justify="space-between">
                <v-col md="6" cols="12">
                  <v-select
                    v-model="productgroup"
                    :error-messages="productgroupErrors"
                    :items="productgroups"
                    item-text="product_group_name"
                    item-value="id"
                    label="PRODUCT GROUP*"
                    required
                    @input="$v.productgroup.$touch()"
                    @blur="$v.productgroup.$touch()"
                  ></v-select>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="tplno"
                    :error-messages="tplnoErrors"
                    label="TPL NO*"
                    required
                    @input="$v.tplno.$touch()"
                    @blur="$v.tplno.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row justify="space-between">
                <v-col cols="12" xs="12" sm="12" md="6">
                  <v-text-field
                    v-model="equipmentname"
                    :error-messages="equipmentnameErrors"
                    label="EQUIPMENT NAME *"
                    required
                    @input="$v.equipmentname.$touch()"
                    @blur="$v.equipmentname.$touch()"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6">
                  <v-file-input
                    v-model="productimage"
                    accept="image/*"
                    label="PRODUCT IMAGE*"
                    :error-messages="productimageErrors"
                    required
                    @input="$v.productimage.$touch()"
                    @blur="$v.productimage.$touch()"
                    @change="onFileSelected"
                  ></v-file-input>
                </v-col>
              </v-row>

              <v-row justify="space-between">
                <v-col cols="12" xs="12" sm="12" md="6">
                  <v-text-field
                    v-model="warranty_in_years"
                    :error-messages="warranty_in_yearsErrors"
                    label="WARRANTY IN YEARS *"
                    required
                    @input="$v.warranty_in_years.$touch()"
                    @blur="$v.warranty_in_years.$touch()"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6">
                  <v-text-field
                    v-model="warranty_exclusions"
                    :error-messages="warranty_exclusionsErrors"
                    label="WARRANTY EXCLUSIONS *"
                    required
                    @input="$v.warranty_exclusions.$touch()"
                    @blur="$v.warranty_exclusions.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row justify="space-between">
                <v-col cols="12" xs="12" sm="12" md="6">
                  <v-text-field
                    v-model="model"
                    :error-messages="modelErrors"
                    label="MODEL *"
                    required
                    @input="$v.model.$touch()"
                    @blur="$v.model.$touch()"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6">
                  <v-text-field
                    v-model="scope_of_supply"
                    :error-messages="scope_of_supplyErrors"
                    label="SCOPE OF SUPPLY *"
                    required
                    @input="$v.scope_of_supply.$touch()"
                    @blur="$v.scope_of_supply.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row justify="space-between">
                <v-col cols="12" xs="12" sm="12" md="6">
                  <v-text-field
                    v-model="product_specification"
                    :error-messages="product_specificationErrors"
                    label="PRODUCT SPECIFICATION *"
                    required
                    @input="$v.product_specification.$touch()"
                    @blur="$v.product_specification.$touch()"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6">
                  <v-text-field
                    v-model="special_features"
                    :error-messages="special_featuresErrors"
                    label="SPECIAL FEATURES *"
                    required
                    @input="$v.special_features.$touch()"
                    @blur="$v.special_features.$touch()"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6">
                  <v-file-input
                    v-model="attachment1"
                    label="PRODUCT CATALOGUE"
                    @change="attachment1Selected"
                  ></v-file-input>

                  <!--<input type="file"  @change="attachment1Selected">-->
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6">
                  <v-file-input
                    v-model="attachment2"
                    label="PRODUCT PPT"
                    @change="attachment2Selected"
                  ></v-file-input>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6">
                  <v-file-input
                    v-model="attachment3"
                    label="PRODUCT BPV"
                    @change="attachment3Selected"
                  ></v-file-input>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6">
                  <v-file-input
                    v-model="attachment4"
                    label="PRODUCT VIDEO"
                    @change="attachment4Selected"
                  ></v-file-input>
                </v-col>
              </v-row>
            </v-card-text>
            <!--input field end-->
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="black" dark @click="submit"> SUBMIT </v-btn>
              <v-btn color="warning" dark to="/product"> CANCEL </v-btn>
            </v-card-actions>
          </form>
          <!--form end-->
        </v-card>
        <!--card end-->
      </template>
      <!--template end-->
    </v-container>
    <!--container end-->
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";

import { mapActions, mapGetters } from "vuex";
/*
import axios from "axios";
import store from "../../store";
import { VUE_APP_BASEURL } from "../../utils/api";*/

export default {
  name: "ProductAdd",
  data: () => ({
    productgroup: null,
    tplno: "",
    equipmentname: "",
    productimage: [],
    selectedFile: null,
    warranty_in_years: "",
    warranty_exclusions: "",
    model: "",
    scope_of_supply: "",
    product_specification: "",
    special_features: "",
    attachment1: [],
    attachment1File: null,
    attachment1name: "",
    attachment2: [],
    attachment2File: null,
    attachment2name: "",
    attachment3: [],
    attachment3File: null,
    attachment3name: "",
    attachment4: [],
    attachment4File: null,
    attachment4name: ""
  }),
  validations: {
    productgroup: { required },
    tplno: { required },
    productimage: { required },
    equipmentname: { required },
    warranty_in_years: { required },
    warranty_exclusions: { required },
    model: { required },
    scope_of_supply: { required },
    product_specification: { required },
    special_features: { required }
  },
  computed: {
    ...mapGetters(["productGroup", "productStatus", "productStatusResponse"]),
    productgroups: function() {
      return this.productGroup;
    },
    errorResponse: function() {
      return this.productStatus === "error";
    },
    productError: function() {
      return this.productStatusResponse;
    },

    productgroupErrors() {
      const errors = [];
      if (!this.$v.productgroup.$dirty) return errors;
      !this.$v.productgroup.required &&
        errors.push("Product Group is required.");
      return errors;
    },
    tplnoErrors() {
      const errors = [];
      if (!this.$v.tplno.$dirty) return errors;
      !this.$v.tplno.required && errors.push("TPL NO is required");
      return errors;
    },
    equipmentnameErrors() {
      const errors = [];
      if (!this.$v.equipmentname.$dirty) return errors;
      !this.$v.equipmentname.required &&
        errors.push("Equipment Name is required");
      return errors;
    },
    productimageErrors() {
      const errors = [];
      if (!this.$v.productimage.$dirty) return errors;
      !this.$v.productimage.required &&
        errors.push("Product Image is required.");
      return errors;
    },
    warranty_in_yearsErrors() {
      const errors = [];
      if (!this.$v.warranty_in_years.$dirty) return errors;
      !this.$v.warranty_in_years.required &&
        errors.push("Warranty Years is required");
      return errors;
    },
    warranty_exclusionsErrors() {
      const errors = [];
      if (!this.$v.warranty_exclusions.$dirty) return errors;
      !this.$v.warranty_exclusions.required &&
        errors.push("Warrenty Exclusion is required.");
      return errors;
    },
    modelErrors() {
      const errors = [];
      if (!this.$v.model.$dirty) return errors;
      !this.$v.model.required && errors.push("Model is required");
      return errors;
    },
    scope_of_supplyErrors() {
      const errors = [];
      if (!this.$v.scope_of_supply.$dirty) return errors;
      !this.$v.scope_of_supply.required &&
        errors.push("Scope Of Supply is required.");
      return errors;
    },
    product_specificationErrors() {
      const errors = [];
      if (!this.$v.product_specification.$dirty) return errors;
      !this.$v.product_specification.required &&
        errors.push("Product Specification is required");
      return errors;
    },
    special_featuresErrors() {
      const errors = [];
      if (!this.$v.special_features.$dirty) return errors;
      !this.$v.special_features.required &&
        errors.push("Special Features is required");
      return errors;
    }
  },
  created() {
    this.fetchProductgroup();
  },
  methods: {
    ...mapActions(["fetchProductgroup", "productAdd"]),
    onFileSelected(event) {
      let image = event;
      let reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = event => {
        this.selectedFile = event.target.result;
      };
    },
    attachment1Selected(event) {
      let attach1 = event;
      this.attachment1name = event.name;
      let reader = new FileReader();
      reader.readAsDataURL(attach1);
      reader.onload = event => {
        this.attachment1File = event.target.result;
      };

      // this.attachment1File = event;

      // this.attachment1File = event.target.files[0];
    },
    attachment2Selected(event) {
      let attach2 = event;
      this.attachment2name = event.name;
      let reader = new FileReader();
      reader.readAsDataURL(attach2);
      reader.onload = event => {
        this.attachment2File = event.target.result;
      };

      //this.attachment2File = event;
    },
    attachment3Selected(event) {
      let attach3 = event;
      this.attachment3name = event.name;
      let reader = new FileReader();
      reader.readAsDataURL(attach3);
      reader.onload = event => {
        this.attachment3File = event.target.result;
      };
    },
    attachment4Selected(event) {
      let attach4 = event;
      this.attachment4name = event.name;
      let reader = new FileReader();
      reader.readAsDataURL(attach4);
      reader.onload = event => {
        this.attachment4File = event.target.result;
      };
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("ERROR");
      } else {
        // do your submit logic here
        setTimeout(() => {
          const productData = {
            productGroup: this.productgroup,
            tplNo: this.tplno.toUpperCase(),
            equipmentName: this.equipmentname.toUpperCase(),
            productImage: this.selectedFile,
            warrantyinYears: this.warranty_in_years.toUpperCase(),
            warrantyExclusions: this.warranty_exclusions.toUpperCase(),
            model: this.model.toUpperCase(),
            scopeofSupply: this.scope_of_supply.toUpperCase(),
            productSpecification: this.product_specification.toUpperCase(),
            specialFeatures: this.special_features.toUpperCase(),
            attachment1name: this.attachment1name,
            attachment1: this.attachment1File,
            attachment2name: this.attachment2name,
            attachment2: this.attachment2File,
            attachment3name: this.attachment3name,
            attachment3: this.attachment3File,
            attachment4name: this.attachment4name,
            attachment4: this.attachment4File
          };

          //console.log(productData);

          this.productAdd(productData);

          /*
          let  productData = new FormData();

          productData.append("productGroup", this.productgroup);
          productData.append("tplNo", this.tplno.toUpperCase());
          productData.append("equipmentName", this.equipmentname.toUpperCase());
          productData.append("productImage", this.selectedFile);
          productData.append("warrantyinYears", this.warranty_in_years.toUpperCase());
          productData.append("warrantyExclusions", this.warranty_exclusions.toUpperCase());
          productData.append("model", this.model.toUpperCase());
          productData.append("scopeofSupply", this.scope_of_supply.toUpperCase());
          productData.append("productSpecification", this.product_specification.toUpperCase());
          productData.append("specialFeatures", this.special_features);
          productData.append("attachment1", this.attachment1File);
          productData.append("attachment2", this.attachment2File);
          productData.append("attachment3", this.attachment3File);
          productData.append("attachment4", this.attachment4File);

          //console.log(productData);

          //this.productAdd(productData);*/
        }, 500);
      }
    }
  }
};
</script>
