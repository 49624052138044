import { VUE_APP_BASEURL } from "../../utils/api";
import router from "../../router";
import axios from "axios";
import store from "../../store";

const state = {
  roleResponse: [],
  status: "",
  roles: [],
  roleName: "",
  permissions: [],
  permissionsSelected: [],
  menuSelected: []
};

const getters = {
  roleList: state => state.roles,
  getPermission: state => state.permissions,
  roleStatusResponse: state => state.roleResponse,
  roleStatus: state => state.status,
  getRolename: state => state.roleName,
  getpermissionsSelected: state => state.permissionsSelected,
  getmenuSelected: state => state.menuSelected
};

const actions = {
  //role fetch
  async fetchRoles({ commit }) {
    commit("authRequest");
    axios({
      method: "GET",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "roles"
    })
      .then(function(response) {
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("setroleList", response.data.response);
        }
      })
      .catch(function(error) {
        console.log(error);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("userError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  },

  //new country add action
  async roleAdd({ commit }, roleData) {
    commit("authRequest");
    axios({
      method: "POST",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "role",
      data: {
        roleName: roleData.roleName,
        permission: roleData.permission
      }
    })
      .then(function(response) {
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("roleaddMethod", response.data.response);
          router.push("/role");
        }
      })
      .catch(function(error) {
        console.log(error);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("roleError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  },
  //new country add action end

  //fetch Country details
  async fetchRoledetails({ commit }, roleId) {
    commit("authRequest");

    axios({
      method: "GET",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "role/" + roleId
    })
      .then(function(response) {
        //   console.log(response);
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("roleDetails", response.data.response);
        }
      })
      .catch(function(error) {
        console.log(error);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("roleError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  },

  //permission fetch
  async fetchPermissions({ commit }) {
    commit("authRequest");
    axios({
      method: "GET",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "permission"
    })
      .then(function(response) {
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("setpermissionList", response.data.response);
        }
      })
      .catch(function(error) {
        console.log(error);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("userError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  },

  //new country add action
  async roleUpdate({ commit }, roleData) {
    commit("authRequest");
    axios({
      method: "PUT",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "role/" + roleData.roleId,
      data: {
        permission: roleData.permission,
        menus: roleData.menus
      }
    })
      .then(function(response) {
        console.log(response);
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("roleupdateMethod", response.data.response);
          router.push("/role");
        }
      })
      .catch(function(error) {
        console.log(error);
        if (error.response) {
          // client received an error response (5xx, 4xx)
          console.log(error.response);
          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("roleError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  }
  //new country add action end
};

const mutations = {
  setroleList: (state, resp) => {
    state.roles = resp;
  },
  roleaddMethod: (state, resp) => {
    state.status = "success";
    state.roleResponse = resp;
  },
  roleupdateMethod: (state, resp) => {
    state.status = "success";
    state.roleResponse = resp;
  },
  setpermissionList: (state, resp) => {
    state.permissions = resp;
  },
  roleError: (state, resp) => {
    state.status = "error";
    state.roleResponse = resp;
    console.log(resp);
  },
  roleDetails: (state, resp) => {
    state.status = "";
    state.roleName = resp.name;
    state.permissionsSelected = resp.permission;
    state.menuSelected = resp.roleMenus;
  },
  updateRolename: (state, rolename) => {
    state.roleName = rolename;
  },
  updatePermission: (state, permission) => {
    state.permissionsSelected = permission;
  },
  updateMenu: (state, menu) => {
    state.menuSelected = menu;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
