<template>
  <div>
    <!--container-->
    <v-container>
      <!--tob bar-->
      <bar />
      <!--tob bar end-->
      <!--template-->
      <template>
        <!--card -->
        <v-card class="form-card mx-auto my-12">
          <!--alert box-->
          <v-alert text prominent type="error" v-if="errorResponse">
            <div v-for="(error, i) in proformaError" :key="i">
              {{ error }}
            </div>
          </v-alert>
          <!--alert box end-->

          <v-list-item class="headline">
            <v-list-item-content>
              <v-list-item-title>
                PROFORMA EDIT
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <!--form-->
          <form enctype="multipart/form-data">
            <v-card-text>

              <v-row justify="space-between">
                <v-col xs="12" sm="12" md="3">
                  <v-text-field v-model="workshopName" label="WORK SHOPNAME" readonly></v-text-field>
                </v-col>

                <v-col cols="12" md="3">
                  <v-text-field v-model="gstin" label="GSTIN"></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field v-model="pan" label="PAN"></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-select v-model="reverseCharge" :items="reverseChargeList" label="REVERSE CHARGE"></v-select>
                </v-col>

              </v-row>

              <v-row justify="space-between">

                <v-col cols="12" md="4">
                  <v-text-field v-model="poRef" label="PO REF" required></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field v-model="placeofsupply" label="PLACE OF SUPPLY" required></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                    offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="proformaInvoiceDate" :error-messages="proformaInvoiceDateErrors"
                        @input="$v.proformaInvoiceDate.$touch()" @blur="$v.proformaInvoiceDate.$touch()"
                        label="PROFORM INVOICE DATE*" prepend-icon="mdi-calendar" readonly v-bind="attrs"
                        v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="proformaInvoiceDate" @input="menu2 = false" :max="new Date(
                      Date.now() - new Date().getTimezoneOffset() * 60000
                    )
                      .toISOString()
                      .substr(0, 10)
                      "></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row justify="space-between">
                <v-col cols="12" md="6">
                  <v-textarea v-model="billto" rows="3" label="BILL TO"></v-textarea>
                </v-col>
                <v-col cols="12" md="6">
                  <v-textarea v-model="shipto" rows="3" label="SHIP TO"></v-textarea>
                </v-col>
              </v-row>


              <v-row>
                <v-col>
                  <v-text-field v-model="ewaybill" label="E WAY BILL"></v-text-field>
                </v-col>

                <v-col>
                  <v-text-field v-model="transportname" label="TRANSPORT NAME"></v-text-field>
                </v-col>

                <v-col>
                  <v-text-field v-model="lrnumber" label="LR NUMBER"></v-text-field>
                </v-col>

              </v-row>


              <v-row>
                <v-col>

                  <v-menu v-model="transportmenu" :close-on-content-click="false" :nudge-right="40"
                    transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="transportdate" label="TRANSPORT DATE*" prepend-icon="mdi-calendar" readonly
                        v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="transportdate" @input="transportmenu = false"></v-date-picker>
                  </v-menu>

                </v-col>

                <v-col>
                  <v-text-field v-model="vehiclenumber" label="VEHICLE NUMBER"></v-text-field>
                </v-col>

                <v-col>
                  <v-text-field v-model="dispatchmode" label="DISPATCH MODE"></v-text-field>
                </v-col>

              </v-row>


              <v-row justify="space-between" v-for="(pro, k) in productDetails" :key="k">
                <v-col xs="12" sm="12" md="2">
                  <!-- <v-select
                    v-model="pro.equipment"
                    :items="products"
                    item-text="product_name"
                    item-value="id"
                    label="EQUIPMENT"
                    @change="getProductdetails(pro)" ></v-select>-->

                  <v-autocomplete v-model="pro.equipment" :items="products" item-text="product_name_withtpl"
                    item-value="id" label="EQUIPMENT" @change="getProductdetails(pro)">
                  </v-autocomplete>
                </v-col>


                <v-col xs="12" sm="12" md="2">
                  <v-text-field v-model="pro.hsncode" label="HSNCODE"></v-text-field>
                </v-col>

                <v-col xs="12" sm="12" md="1">
                  <v-text-field v-model="pro.quantity" label="QTY" @change="getProductqtyprice(pro)"></v-text-field>
                </v-col>

                <v-col xs="12" sm="12" md="2">
                  <v-text-field v-model="pro.price" label="PRICE" @change="getProductqtyprice(pro)"></v-text-field>
                </v-col>

                <v-col xs="12" sm="12" md="2">
                  <v-text-field v-model="pro.totalPrice" label="TOTAL COST" readonly></v-text-field>
                </v-col>

                <v-col xs="12" sm="12" md="2">
                  <v-text-field v-model="pro.totalPriceWithTax" label="TOTAL WITH TAX"></v-text-field>
                </v-col>


                <v-col xs="12" sm="12" md="1">
                  <v-btn color="warning" fab dark x-small v-show="productDetails.length > 1"
                    @click="deleteProductdetails(k)">
                    <v-icon dark> mdi-close </v-icon>
                  </v-btn>
                </v-col>
              </v-row>

              <v-row>
                <v-col xs="12" sm="12" md="11"> </v-col>
                <v-col xs="12" sm="12" md="1">
                  <v-btn depressed color="warning" @click="addProductdetails">
                    +ADD
                  </v-btn>
                </v-col>
              </v-row>

              <v-row>
                <v-col xs="12" sm="12" md="6">
                  <v-textarea v-model="deliveryterms" rows="3" label="DELIVERY TERMS"></v-textarea>
                </v-col>

                <v-col xs="12" sm="12" md="3">
                </v-col>
                <v-col xs="12" sm="12" md="3">
                  <v-checkbox v-model="igst" label="IGST" color="orange" value="1" hide-details></v-checkbox>
                </v-col>
              </v-row>

              <v-row>
                <v-col xs="12" sm="12" md="6">
                  <v-textarea v-model="paymentterms" rows="3" label="PAYMENT TERMS"></v-textarea>
                </v-col>
                <v-col xs="12" sm="12" md="3">
                </v-col>
                <v-col xs="12" sm="12" md="3">
                  <v-text-field v-model="tax" label="TAX" @change="calculateTax()"></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col xs="12" sm="12" md="9"> </v-col>
                <v-col xs="12" sm="12" md="3">
                  <v-text-field v-model="freightCharges" label="FREIGHT CHARGES"
                    @change="calculateTotal()"></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col xs="12" sm="12" md="9"> </v-col>
                <v-col xs="12" sm="12" md="3">
                  <v-text-field v-model="taxAmount" label="TAX AMOUNT" readonly></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col xs="12" sm="12" md="9"> </v-col>
                <v-col xs="12" sm="12" md="3">
                  <v-text-field v-model="totalPrice" label="TOTAL PRICE" readonly></v-text-field>
                </v-col>
              </v-row>



              <v-row>
                <v-col xs="12" sm="12" md="9"> </v-col>
                <v-col xs="12" sm="12" md="3">
                  <v-text-field v-model="totalPricewithtax" label="TOTAL PRICE (WITH TAX)" readonly></v-text-field>
                </v-col>
              </v-row>
              <!--input field end-->
              <v-divider></v-divider>
              <h3 class="mt-5 mb-5 orange--text text--darken-2">
                TERMS & CONDITIONS
              </h3>
              <!--input field end-->
              <v-divider></v-divider>

              <div v-for="(ter, t) in terms" :key="'A' + t">
                <v-text-field v-model="terms[t]"></v-text-field>
              </div>
            </v-card-text>

            <!--input field end-->
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="black" dark @click="submit"> SUBMIT </v-btn>
              <v-btn color="warning" dark to="/proforma"> CANCEL </v-btn>
              <v-btn color="blue-grey darken-3" dark @click="invoiceOpen">
                PDF
              </v-btn>
            </v-card-actions>
          </form>
        </v-card>
        <!--card end-->
      </template>
      <!--template end-->
    </v-container>
    <!--container end-->




    <v-row justify="center">
      <v-dialog v-model="deleteDialog" persistent max-width="290">
        <v-card>
          <v-card-title class="text-h5">
            Are You sure Want to delete!
          </v-card-title>
          <v-card-text>Current Data is in database.If it's deleted can't be
            retrive.</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="deleteDialog = false">
              No
            </v-btn>
            <v-btn color="red darken-1" text @click="deleteProformaDetails(deleteId, proformaId)">
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

  </div>
</template>

<script>

import { required } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
import { VUE_APP_BASEURL, VUE_WEB_BASEURL } from "../../utils/api";
import axios from "axios";
import store from "../../store";

export default {
  name: "ProformaAdd",
  data: () => ({
    modal: false,
    menu2: false,
    transportmodal: false,
    transportmenu: false,
    reverseChargeList: ["YES", "NO"],
    deleteDialog: false
  }),
  validations: {
    proformaInvoiceDate: { required },
  },
  computed: {
    ...mapGetters([
      "proformaStatus",
      "proformaStatusResponse",
      "productList",
      "productImageDetails",
      "getTax",
      "getProformaDetails",
      "workshopNameDetails",
    ]),
    proformaId: {
      get() {
        return this.getProformaDetails.id;
      }
    },
    gstin: {
      get() {
        return this.getProformaDetails.gstin;
      },
      set(value) {
        this.$store.commit("updateGstin", value);
      }
    },
    pan: {
      get() {
        return this.getProformaDetails.pan;
      },
      set(value) {
        this.$store.commit("updatePan", value);
      }
    },

    reverseCharge: {
      get() {
        return this.getProformaDetails.reverseCharge;
      },
      set(value) {
        this.$store.commit("updateReverseCharge", value);
      }
    },
    poRef: {
      get() {
        return this.getProformaDetails.poRef;
      },
      set(value) {
        this.$store.commit("updatePoRef", value);
      }
    },
    placeofsupply: {
      get() {
        return this.getProformaDetails.placeofSupply;
      },
      set(value) {
        this.$store.commit("updatePlaceOfSupply", value);
      }
    },
    billto: {
      get() {
        return this.getProformaDetails.billTo;
      },
      set(value) {
        this.$store.commit("updateBillTo", value);
      }
    },
    shipto: {
      get() {
        return this.getProformaDetails.shipTo;
      },
      set(value) {
        this.$store.commit("updateShipTo", value);
      }
    },
    proformaInvoiceDate: {
      get() {
        return this.getProformaDetails.proformaInvoiceDate;
      },
      set(value) {
        this.$store.commit("updateProformaInvoiceDate", value);
      }
    },
    productDetails: function () {
      return this.getProformaDetails.productList;
    },
    tax: {
      get() {
        return this.getProformaDetails.tax;
      },
      set(value) {
        this.$store.commit("updatePerformaTax", value);
      }
    },
    igst: {
      get() {
        return this.getProformaDetails.isIgst;
      },
      set(value) {
        this.$store.commit("updatePerformaIgst", value);
      }
    },
    taxAmount: {
      get() {
        return this.getProformaDetails.taxAmount;
      },
      set(value) {
        this.$store.commit("updatePerformaTaxAmount", value);
      }
    },
    totalPrice: {
      get() {
        return this.getProformaDetails.totalAmount;
      },
      set(value) {
        this.$store.commit("updatePerformaTotalPrice", value);
      }
    },
    freightCharges: {
      get() {
        return this.getProformaDetails.freightCharges;
      },
      set(value) {
        this.$store.commit("updateFreightCharges", value);
      }
    },
    totalPricewithtax: {
      get() {
        return this.getProformaDetails.totalAmountWithTax;
      },
      set(value) {
        this.$store.commit("updatePerformaTotalPriceWithTax", value);
      }
    },
    ewaybill: {
      get() {
        return this.getProformaDetails.ewaybill;
      },
      set(value) {
        this.$store.commit("updatePerformaEwaybill", value);
      }
    },
    transportname: {
      get() {
        return this.getProformaDetails.transportname;
      },
      set(value) {
        this.$store.commit("updatePerformaTransportname", value);
      }
    },
    lrnumber: {
      get() {
        return this.getProformaDetails.lrnumber;
      },
      set(value) {
        this.$store.commit("updatePerformaLrnumber", value);
      }
    },
    transportdate: {
      get() {
        return this.getProformaDetails.transportdate;
      },
      set(value) {
        this.$store.commit("updatePerformaTransportdate", value);
      }
    },
    vehiclenumber: {
      get() {
        return this.getProformaDetails.vehiclenumber;
      },
      set(value) {
        this.$store.commit("updatePerformaVehiclenumber", value);
      }
    },
    dispatchmode: {
      get() {
        return this.getProformaDetails.dispatchmode;
      },
      set(value) {
        this.$store.commit("updatePerformaDispatchmode", value);
      }
    },
    deliveryterms: {
      get() {
        return this.getProformaDetails.deliveryterms;
      },
      set(value) {
        this.$store.commit("updatePerformaDeliveryterms", value);
      }
    },

    paymentterms: {
      get() {
        return this.getProformaDetails.paymentterms;
      },
      set(value) {
        this.$store.commit("updatePerformaPaymenttermss", value);
      }
    },
    terms: {
      get() {
        return this.getProformaDetails.terms;
      },
      set(value) {
        console.log(value);
      }
    },
    products: function () {
      return this.productList;
    },
    workshopName: {
      get() {
        return this.workshopNameDetails;
      }
    },
    errorResponse: function () {
      //  console.log(this.enquiryStatus);
      return this.proformaStatus === "error";
    },
    proformaError: function () {
      return this.proformaStatusResponse;
    },
    proformaInvoiceDateErrors() {
      const errors = [];
      if (!this.$v.proformaInvoiceDate.$dirty) return errors;
      !this.$v.proformaInvoiceDate.required && errors.push("Proforma Invoice Date is required.");
      return errors;
    },

  },
  created() {
    this.fetchProducts();
    this.fetchDefinedvalues();
    this.fetchProformaDetails(this.$route.params.id);

    // do your submit logic here
    setTimeout(() => {
      console.log(this.getProformaDetails.customerId);
      this.fetchCustomerdetails(this.getProformaDetails.customerId);
    }, 5000);

  },
  methods: {
    ...mapActions([
      "fetchProducts",
      "fetchDefinedvalues",
      "fetchProductdetails",
      "fetchProformaDetails",
      "proformaUpdate",
      "proformadetailsDelete",
      "fetchCustomerdetails",

    ]),
    //add product details

    addProductdetails() {
      this.productDetails.push({
        equipment: "",
        productImageshow: "",
        hsncode: "",
        quantity: "",
        price: "",
        totalPrice: "",
        taxAmount: "",
        totalPriceWithTax: "",
        proformaDetailid: "",

      });
    },
    //delete product details
    deleteProductdetails(counter) {

      let proformadetail = this.productDetails[counter];
      //console.log(enquirydetail.enquiryDetailid);
      //check database existing else delete the record option.
      if (proformadetail.proformaDetailid != "") {
        this.deleteId = proformadetail.proformaDetailid;
        this.deleteDialog = true;
      } else {
        //remove record
        this.productDetails.splice(counter, 1);
      }

      this.calculateTotal();
    },
    //delete enquiry details
    deleteProformaDetails(deleteId, proformaId) {
      const deleteData = {
        proformaId: proformaId,
        deleteId: deleteId
      };

      console.log(deleteData);
      this.proformadetailsDelete(deleteData);
      this.deleteDialog = false;
    },
    //get product details onchange
    getProductdetails(pro) {
      //console.log(this.$route.params.id);

      axios({
        method: "GET",
        headers: {
          Authorization: "Bearer " + store.getters.UserToken
        },
        url: VUE_APP_BASEURL + "product/" + pro.equipment + "?customerId=0"
      })
        .then(function (response) {
          //   console.log(response);

          pro.productImageshow = response.data.response.product_image;
          pro.model = response.data.response.model;
          pro.quantity = 1;
          pro.price = parseFloat(response.data.response.brandPrice);
          pro.special_price = parseFloat(response.data.response.brandPrice);
          let cost = parseFloat(pro.quantity) * parseFloat(pro.price);

          var gst = this.tax;
          var pricetaxamount = (parseFloat(cost) * parseFloat(gst)) / parseFloat(100);
          pro.taxAmount = pricetaxamount;

          var totpricewithtax = parseFloat(cost) + parseFloat(pricetaxamount);
          pro.totalPrice = cost;
          pro.totalPriceWithTax = totpricewithtax;

          this.calculateTotal();
        })
        .catch(function (error) {
          console.log(error);
        });

    },
    //qty based product price onchange
    getProductqtyprice(pro) {
      //console.log(pro);
      let cost = parseFloat(pro.quantity) * parseFloat(pro.price);

      console.log(this.tax);
      var gst = this.tax;

      var pricetaxamount =
        (parseFloat(cost) * parseFloat(gst)) / parseFloat(100);

      pro.taxAmount = pricetaxamount;

      var totpricewithtax = parseFloat(cost) + parseFloat(pricetaxamount);
      pro.totalPrice = cost;
      pro.totalPriceWithTax = totpricewithtax;
      this.calculateTotal();
    },
    calculateTax() {

      //tax
      var gst = this.tax;
      //product detail tax update
      this.productDetails = this.productDetails.filter((pro) => {
        var cost = parseFloat(pro.quantity) * parseFloat(pro.price);
        var pricetaxamount = (parseFloat(cost) * parseFloat(gst)) / parseFloat(100);
        pro.taxAmount = pricetaxamount;
        var totpricewithtax = parseFloat(cost) + parseFloat(pricetaxamount);
        pro.totalPrice = cost;
        pro.totalPriceWithTax = totpricewithtax;
        return pro;
      });

      console.log(this.productDetails);
      this.calculateTotal();

    },
    calculateTotal() {
      var gst = this.tax;
      var freightCharges = this.freightCharges;

      var subtotal;
      subtotal = this.productDetails.reduce(function (sum, product) {
        var lineTotal = parseFloat(product.totalPrice);
        if (!isNaN(lineTotal)) {
          return sum + lineTotal;
        }
      }, 0);

      var billtotal = parseFloat(subtotal) + parseFloat(freightCharges);
      var taxamount =
        (parseFloat(billtotal) * parseFloat(gst)) / parseFloat(100);
      var billwithtax = parseFloat(billtotal) + parseFloat(taxamount);

      this.taxAmount = taxamount;
      this.totalPrice = subtotal;
      this.totalPricewithtax = billwithtax.toFixed(2);
    },

    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("ERROR");
      } else {
        // do your submit logic here
        setTimeout(() => {
          const proformaData = {
            proformaId: this.$route.params.id,
            gstin: !this.gstin ? this.gstin : this.gstin.toUpperCase(),
            pan: !this.pan ? this.pan : this.pan.toUpperCase(),
            reverseCharge: this.reverseCharge,
            poRef: !this.poRef ? this.poRef : this.poRef.toUpperCase(),
            placeofSupply: !this.placeofsupply ? this.placeofsupply : this.placeofsupply.toUpperCase(),
            proformaInvoiceDate: this.proformaInvoiceDate,
            billTo: !this.billto ? this.billto : this.billto.toUpperCase(),
            shipTo: !this.shipto ? this.shipto : this.shipto.toUpperCase(),
            isIgst: this.igst,
            tax: this.tax,
            taxAmount: this.taxAmount,
            totalAmount: this.totalPrice,
            freightCharges: this.freightCharges,
            totalAmountWithTax: this.totalPricewithtax,
            deliveryterms: !this.deliveryterms ? this.deliveryterms : this.deliveryterms.toUpperCase(),
            paymentterms: !this.paymentterms ? this.paymentterms : this.paymentterms.toUpperCase(),
            ewaybill: !this.ewaybill ? this.ewaybill : this.ewaybill.toUpperCase(),
            transportname: !this.transportname ? this.transportname : this.transportname.toUpperCase(),
            lrnumber: !this.lrnumber ? this.lrnumber : this.lrnumber.toUpperCase(),
            transportdate: this.transportdate,
            vehiclenumber: !this.vehiclenumber ? this.vehiclenumber : this.vehiclenumber.toUpperCase(),
            dispatchmode: !this.dispatchmode ? this.dispatchmode : this.dispatchmode.toUpperCase(),
            terms: this.terms,
            proformaDetails: this.productDetails
          };

          // console.log(proformaData);
          this.proformaUpdate(proformaData);
        }, 500);
      }
    },
    invoiceOpen() {
      window.open(
        VUE_WEB_BASEURL + "proforma/invoice/" + this.$route.params.id,
        "_blank"
      );
      //console.log(VUE_WEB_BASEURL);
    }

  }
};
</script>
