<template>
  <div>
    <!--container-->
    <v-container>
      <!--tob bar-->
      <bar />
      <!--tob bar end-->

      <!--template-->
      <template>
        <!--alert box-->
        <v-alert text dense color="teal" icon="mdi-clock-fast" border="left" v-if="menuStatusmsg">
          <div v-for="(resp, i) in menuResponse" :key="i">
            {{ resp }}
          </div>
        </v-alert>
        <!--alert box end-->

        <!---datatable -->
        <v-data-table :headers="headers" :items="menus" :search="search" sort-by="name" class="elevation-1" primary>
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>MENU</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line
                hide-details></v-text-field>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-btn color="primary" dark class="mb-2" to="/menu/add">
                New Menu
              </v-btn>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editMenu(item)">
              mdi-pencil
            </v-icon>
          </template>
        </v-data-table>
        <!---datatable end-->
      </template>
      <!--template end-->
    </v-container>
    <!--container end-->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import router from "../../router";
export default {
  name: "Menu",
  data: () => ({
    search: "",
    headers: [
      {
        text: "Menu",
        align: "start",
        sortable: true,
        value: "name"
      },
      {
        text: "Url",
        align: "start",
        sortable: true,
        value: "url"
      },
      {
        text: "Active",
        align: "start",
        sortable: true,
        value: "active"
      },
      {
        text: "Is Main Menu",
        align: "start",
        sortable: true,
        value: "isMainmenu"
      },
      {
        text: "Menu Order",
        align: "start",
        sortable: true,
        value: "order"
      },
      {
        text: "Main Menu Name",
        align: "start",
        sortable: true,
        value: "maniMenuname"
      },
      { text: "Actions", value: "actions", sortable: false }
    ]
  }),

  computed: {
    ...mapGetters(["menuList", "menuStatus", "menuStatusResponse"]),
    menus: function () {
      return this.menuList;
    },
    menuStatusmsg: function () {
      return this.menuStatus === "success";
    },
    menuResponse: function () {
      return this.menuStatusResponse;
    }
  },

  watch: {},

  created() {
    this.fetchMenus();
  },

  methods: {
    ...mapActions(["fetchMenus"]),

    editMenu(item) {
      const id = item.id;
      router.push({ path: `/menu/edit/${id}` });
    }
  }
};
</script>
