<template>
  <div class="form-card">
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <v-card class="mx-auto" outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="text-h5 mb-1">
                TOTAL ENQUIRY
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ customerEnquiryDetails.enquiryValue }}
                MN</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>

      <v-col cols="12" sm="3" md="3" lg="3">
        <v-card class="mx-auto" outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="text-h5 mb-1">
                ENQUIRY
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ customerEnquiryDetails.totalEnquirystatusvalue }}
                MN</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col cols="12" sm="3" md="3" lg="3">
        <v-card class="mx-auto" outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="text-h5 mb-1">
                FOLLOWUP
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ customerEnquiryDetails.enquiryFollowupvalue }}
                MN</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col cols="12" sm="3" md="3" lg="3">
        <v-card class="mx-auto" outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="text-h5 mb-1">
                NEGOTIATION
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ customerEnquiryDetails.enquiryNegotiationvalue }}
                MN</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col cols="12" sm="3" md="3" lg="3">
        <v-card class="mx-auto" outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="text-h5 mb-1">
                CONFIRM
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ customerEnquiryDetails.enquiryConfirmvalue }}
                MN</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col cols="12" sm="3" md="3" lg="3">
        <v-card class="mx-auto" outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="text-h5 mb-1">
                FOLLOWUP CLOSED
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ customerEnquiryDetails.enquiryFoloupclosedvalue }}
                MN</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col cols="12" sm="3" md="3" lg="3">
        <v-card class="mx-auto" outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="text-h5 mb-1">
                LOST
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ customerEnquiryDetails.enquiryLostvalue }}
                MN</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col cols="12" sm="3" md="3" lg="3">
        <v-card class="mx-auto" outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="text-h5 mb-1">
                BILLED
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ customerEnquiryDetails.enquiryBilledvalue }}
                MN</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col cols="12" sm="3" md="3" lg="3">
        <v-card class="mx-auto" outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="text-h5 mb-1">
                RESCHEDULED
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ customerEnquiryDetails.enquiryReschduledvalue }}
                MN</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// @ is an alias to /src

import { mapActions, mapGetters } from "vuex";
export default {
  name: "CustomerEnquiryValue",
  props: ["customerId"],
  data: () => ({}),
  components: {},
  computed: {
    ...mapGetters(["getCustomerDetails"]),
    customerEnquiryDetails: function() {
      return this.getCustomerDetails;
    }
  },
  mounted() {},
  created() {
    setTimeout(() => {
      console.log(this.customerId);
      this.fetchCustomerdetails(this.customerId);
    }, 2000);
  },
  methods: {
    ...mapActions(["fetchCustomerdetails"])
  }
};
</script>
