<template>
  <div>
    <!--container-->
    <v-container>
      <!--tob bar-->
      <bar />
      <!--tob bar end-->

      <!--template-->
      <template>
        <!--alert box-->
        <v-alert text dense color="teal" icon="mdi-clock-fast" border="left" v-if="customerStatusmsg">
          <div v-for="(resp, i) in customerResponse" :key="i">
            {{ resp }}
          </div>
        </v-alert>
        <!--alert box end-->

        <v-card class="mx-auto mb-5" outlined>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="3">
                <v-autocomplete v-model="brand" :items="brands" item-text="name" item-value="id" label="LOB*" class="mt-4"
                  chips multiple clearable></v-autocomplete>
              </v-col>
              <v-col cols="12" md="3">
                <v-select v-model="status" :items="status_list" label="STATUS" class="mt-6" clearable></v-select>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="workshopName" label="WORKSHOP NAME " class="mt-6"></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" dark @click="filterCustomer"> FIND </v-btn>
          </v-card-actions>
        </v-card>

        <!--Simple table start-->
        <v-card class="mx-auto" outlined>
          <v-card-title>
            CUSTOMERS
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-btn color="primary" dark class="mb-2" to="/customer/add">
              New CUSTOMER
            </v-btn>
          </v-card-title>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">TEMPORARY NUMBER</th>
                  <th class="text-left">CUSTOMER CODE</th>
                  <th class="text-left">LOB</th>
                  <th class="text-left">WORKSHOP NAME</th>
                  <th class="text-left">ADDRESS 1</th>
                  <th class="text-left">VISIT DATE</th>
                  <th class="text-left">DATA STATUS</th>
                  <th class="text-left">UPDATED BY</th>
                  <th class="text-left">LAST UPDATED</th>
                  <th class="text-left">ACTIONS</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="customer in customers" :key="customer.id">
                  <td>{{ customer.temporary_no }}</td>
                  <td>{{ customer.customer_code }}</td>
                  <td>{{ customer.brand_name }}</td>
                  <td>{{ customer.workshop_name }}</td>
                  <td>{{ customer.address1 }}</td>
                  <td>{{ customer.visit_date }}</td>
                  <td>{{ customer.status }}</td>
                  <td>{{ customer.updated_by }}</td>
                  <td>{{ customer.updated_date }}</td>
                  <td>
                    <v-icon small class="mr-2" @click="editCustomer(customer)">
                      mdi-pencil
                    </v-icon>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
        <!--Simple Table end-->
        <!--pagination-->
        <div class="text-center mt-5">
          <v-pagination v-model="page" :length="customersPagination" :total-visible="7"
            @input="paginationNextPage"></v-pagination>
        </div>
        <!--pagination end-->
      </template>
      <!--template end-->
    </v-container>
    <!--container end-->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import router from "../../router";

export default {
  name: "Customer",
  data: () => ({
    search: "",

    headers: [
      { text: "Actions", value: "actions", sortable: false },
      {
        text: "TEMPORARY NUMBER",
        align: "start",
        sortable: true,
        value: "temporary_no"
      },
      { text: "CUSTOMER CODE", value: "customer_code" },
      { text: "LOB", value: "brand_name" },
      { text: "WORKSHOP NAME", value: "workshop_name" },
      { text: "VISIT DATE", value: "visit_date" },
      { text: "DATA STATUS", value: "status" },
      { text: "UPDATED BY", value: "updated_by" },
      { text: "LAST UPDATED", value: "updated_date" },
      { text: "Actions", value: "actions", sortable: false }
    ],
    page: 1,
    selectedPage: 1,
    brand: "",
    status: "",
    workshopName: ""
  }),

  computed: {
    ...mapGetters([
      "customerList",
      "customerStatus",
      "customerStatusResponse",
      "customerListPaginate",
      "brandList",
      "getStatuslist"
    ]),
    brands: function () {
      return this.brandList;
    },
    status_list: function () {
      return this.getStatuslist;
    },
    customers: function () {
      //console.log(this.customerList);
      return this.customerList;
    },
    customersPagination: function () {
      return this.customerListPaginate;
    },

    customerStatusmsg: function () {
      return this.customerStatus === "success";
    },
    customerResponse: function () {
      return this.customerStatusResponse;
    }
  },

  watch: {},

  created() {
    this.fetchCustomers();
    this.fetchBrand();
    this.fetchDefinedvalues();
  },

  methods: {
    ...mapActions([
      "fetchCustomers",
      "fetchBrand",
      "customerFilter",
      "fetchDefinedvalues"
    ]),

    editCustomer(item) {
      const id = item.id;
      //  router.push({ name: 'ProductGroupEdit', params: { productgroupId: 123 }})
      router.push({ path: `/customer/edit/${id}` });
    },
    paginationNextPage(page) {
      this.selectedPage = page;
      this.filterCustomer();
    },
    filterCustomer() {
      const filterData = {
        page: this.selectedPage,
        brand: !this.brand ? "" : this.brand,
        status: !this.status ? "" : this.status,
        workshopName: !this.workshopName
          ? this.workshopName
          : this.workshopName.toUpperCase()
      };
      console.log(filterData);
      this.customerFilter(filterData);
    }
  }
};
</script>
