import { VUE_APP_BASEURL } from "../../utils/api";
import router from "../../router";
import axios from "axios";
import store from "../../store";

const state = {
  segments: [],
  segmentResponse: [],
  segmentName: "",
  status: ""
};

const getters = {
  segmentList: state => state.segments,
  segmentStatusResponse: state => state.segmentResponse,
  segmentStatus: state => state.status,
  segmentDetailsname: state => state.segmentName
};

const actions = {
  //segment fetch
  async fetchSegment({ commit }) {
    commit("authRequest");
    axios({
      method: "GET",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "segment"
    })
      .then(function(response) {
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("setsegmentList", response.data.response);
        }
      })
      .catch(function(error) {
        console.log(error);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("segmentError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  },

  //new Segment add action
  async segmentAdd({ commit }, segmentData) {
    commit("authRequest");
    axios({
      method: "POST",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "segment",
      data: {
        segmentName: segmentData.segmentName
      }
    })
      .then(function(response) {
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("segmentaddMethod", response.data.response);
          router.push("/segment");
        }
      })
      .catch(function(error) {
        console.log(error);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("segmentError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  },
  //new Segment add action end

  //fetch segment details
  async fetchSegmentdetails({ commit }, segmentId) {
    commit("authRequest");

    axios({
      method: "GET",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "segment/" + segmentId
    })
      .then(function(response) {
        if (response.data.result == 1) {
          commit("authSuccess");
          console.log(response.data.response.name);
          commit("segmentDetails", response.data.response.name);
        }
      })
      .catch(function(error) {
        console.log(error);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("segmentError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  },

  //new segment update action
  async segmentUpdate({ commit }, segmentData) {
    commit("authRequest");
    axios({
      method: "PUT",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "segment/" + segmentData.segmentId,
      data: {
        segmentName: segmentData.segmentName
      }
    })
      .then(function(response) {
        console.log(response);
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("segmentupdateMethod", response.data.response);
          router.push("/segment");
        }
      })
      .catch(function(error) {
        console.log(error);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("segmentError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  }
  //new country update action end
};

const mutations = {
  setsegmentList: (state, resp) => {
    state.segments = resp;
  },
  segmentaddMethod: (state, resp) => {
    state.status = "success";
    state.segmentResponse = resp;
  },
  segmentError: (state, resp) => {
    state.status = "error";
    state.segmentResponse = resp;
  },
  segmentDetails: (state, resp) => {
    state.status = "";
    state.segmentName = resp;
  },
  segmentupdateMethod: (state, resp) => {
    state.status = "success";
    state.segmentResponse = resp;
  },
  updateSegmentname: (state, segmentname) => {
    state.segmentName = segmentname;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
