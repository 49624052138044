<template>
  <div>
    <!--container-->
    <v-container>
      <!--tob bar-->
      <bar />
      <!--tob bar end-->

      <!--template-->
      <template>
        <!--alert box-->
        <v-alert
          text
          dense
          color="teal"
          icon="mdi-clock-fast"
          border="left"
          v-if="tspplanStatusmsg"
        >
          <div v-for="(resp, i) in tspplanResponse" :key="i">
            {{ resp }}
          </div>
        </v-alert>
        <!--alert box end-->

        <!---datatable -->
        <v-data-table
          :headers="headers"
          :items="tspplandetailsList"
          :search="search"
          sort-by="temporary_no"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>TSP DETAILS</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
              <v-divider class="mx-4" inset vertical></v-divider>

              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              color="warning"
              class="mr-2"
              outlined
              @click="tspConfirm(item)"
              v-if="item.status === 'CONFIRM'"
            >
              MSP
            </v-btn>
          </template>
        </v-data-table>
        <!---datatable end-->
      </template>
      <!--template end-->

      <v-btn
        color="warning"
        class="mt-10"
        right
        dark
        to="/enquiry-tsp-plan-list"
      >
        BACK
      </v-btn>
    </v-container>
    <!--container end-->

    <!--dialog-->

    <v-row justify="center">
      <v-dialog v-model="confirmDialog" persistent max-width="800px">
        <v-card>
          <v-card-title>
            <span class="text-h5">MONTHLY SALES PLAN</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    label="EQUIPMENT"
                    v-model="EquipmentName"
                    required
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="ENQUIRY QTY*"
                    required
                    readonly
                    v-model="enquiryQuantity"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="PRICE*"
                    v-model="enquiryPrice"
                    required
                    readonly
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="SALES QTY *"
                    required
                    :readonly="parf_status_updated"
                    v-model="enquiryMspsalesqty"
                    :error-messages="enquiryMspsalesqtyErrors"
                    @input="$v.enquiryMspsalesqty.$touch()"
                    @blur="$v.enquiryMspsalesqty.$touch()"
                    @change="getProductqtyprice()"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="TOTAL PRICE *"
                    v-model="enquiryMsptotalprice"
                    :error-messages="enquiryMsptotalpriceErrors"
                    @input="$v.enquiryMsptotalprice.$touch()"
                    @blur="$v.enquiryMsptotalprice.$touch()"
                    required
                    readonly
                  ></v-text-field>
                </v-col>

                <v-col md="6">
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="pfmdate"
                        label="PFM DATE*"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :error-messages="pfmdateErrors"
                        @input="$v.pfmdate.$touch()"
                        @blur="$v.pfmdate.$touch()"
                      ></v-text-field>
                    </template>
                    <!-- <v-date-picker
                                v-model="pfmdate"
                                @input="menu2 = false"
                                :min="(new Date(pfmMinDate - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                                :max="(new Date(pfmMaxDate- (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                              ></v-date-picker>-->

                    <v-date-picker
                      v-model="pfmdate"
                      @input="menu2 = false"
                      :min="
                        new Date(
                          pfmMinDate - new Date().getTimezoneOffset() * 60000
                        )
                          .toISOString()
                          .substr(0, 10)
                      "
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-select
                    :items="pfmstatusList"
                    label="PFM STATUS*"
                    v-model="pfmstatus"
                    :error-messages="pfmstatusErrors"
                    @input="$v.pfmstatus.$touch()"
                    @blur="$v.pfmstatus.$touch()"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="warning"
              dark
              @click="
                close();
                confirmDialog = false;
              "
            >
              CLOSE
            </v-btn>
            <v-btn
              color="black"
              dark
              @click="submit"
              v-if="userRole == 'sales-engineer'"
            >
              SUBMIT
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <!--dialog-->
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "EnquiryTspPlanForm",
  data: () => ({
    search: "",
    confirmDialog: false,
    headers: [
      { text: "Actions", value: "actions", sortable: false },
      {
        text: "EQUIPMENT",
        align: "start",
        sortable: true,
        value: "equipment.product_name"
      },
      { text: "QTY", value: "quantity" },
      { text: "PRICE", value: "price" },
      { text: "TOTAL PRICE", value: "totalPrice" },
      { text: "TSP QTY", value: "tsp_qty" },
      { text: "TSP TOTAL PRICE", value: "tsp_total_price" },
      { text: "TSPDATE", value: "tsp_date" },
      { text: "STATUS", value: "status" },
      
    ],
    EquipmentName: "",
    enquiryQuantity: "",
    enquiryPrice: "",
    enquiryUnitprice: "",
    enquiryMspsalesqty: "",
    enquiryMsptotalprice: "",
    parf_status_updated: false,
    pfmstatus: "",
    pfmstatusList: ["CONFIRM", "TO BE CONFIRM"],
    pfmdate: "",
    menu2: false,
    pfmMinDate: Date.now(),
    pfmMaxDate: Date.now(),
    pfmDetails: []
  }),
  validations: {
    enquiryMspsalesqty: { required },
    enquiryMsptotalprice: { required },
    pfmdate: { required },
    pfmstatus: { required }
  },
  computed: {
    ...mapGetters([
      "getTspplandetailList",
      "followupStatus",
      "followupStatusResponse",
      "authRole"
    ]),
    enquiryMspsalesqtyErrors() {
      const errors = [];
      if (!this.$v.enquiryMspsalesqty.$dirty) return errors;
      !this.$v.enquiryMspsalesqty.required &&
        errors.push("SalesQty is required.");
      return errors;
    },
    enquiryMsptotalpriceErrors() {
      const errors = [];
      if (!this.$v.enquiryMsptotalprice.$dirty) return errors;
      !this.$v.enquiryMsptotalprice.required &&
        errors.push("Totalprice is required.");
      return errors;
    },
    pfmstatusErrors() {
      const errors = [];
      if (!this.$v.pfmstatus.$dirty) return errors;
      !this.$v.pfmstatus.required && errors.push("PFM status is required.");
      return errors;
    },
    pfmdateErrors() {
      const errors = [];
      if (!this.$v.pfmdate.$dirty) return errors;
      !this.$v.pfmdate.required && errors.push("PFM Date is required.");
      return errors;
    },
    userRole: function() {
      return this.authRole;
    },
    tspplandetailsList: function() {
      return this.getTspplandetailList.productList;
    },
    tspplanStatusmsg: function() {
      return this.followupStatus === "success";
    },
    tspplanResponse: function() {
      return this.followupStatusResponse;
    }
  },

  watch: {},

  created() {
    this.fetchTspPlandetails(this.$route.params.id);
  },

  methods: {
    ...mapActions(["fetchTspPlandetails", "enquiryPfmstatusupdate"]),
    tspConfirm(item) {
      this.confirmDialog = true;
      this.EquipmentName = item.equipment.product_name;
      this.enquiryQuantity = item.quantity;

      console.log(item.parf_status);
      if (item.parf_status == "CONFIRM" || item.parf_status == "REJECT") {
        this.enquiryMspsalesqty = item.quantity;
        this.enquiryMsptotalprice = item.final_total_sales_price;
        this.parf_status_updated = true;
      }
      this.enquiryPrice = item.final_total_sales_price;
      this.enquiryUnitprice = item.final_unit_price;
      this.pfmMinDate = new Date(item.followup_date);
      this.pfmMaxDate = new Date(item.tsp_date);
      this.pfmDetails = item;
    },
    getProductqtyprice() {
      let cost =
        parseFloat(this.enquiryMspsalesqty) * parseFloat(this.enquiryUnitprice);
      this.enquiryMsptotalprice = cost;
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("ERROR");
      } else {
        const enquiryData = {
          enquiryDetailid: this.pfmDetails.enquiryDetailid,
          enquiryTspstatus: "CONFIRM",
          enquiryPfmqty: this.enquiryMspsalesqty,
          enquiryPfmprice: this.enquiryMsptotalprice,
          enquiryPfmdate: this.pfmdate,
          enquiryPfmstatus: this.pfmstatus
        };
        console.log(enquiryData);
        this.enquiryPfmstatusupdate(enquiryData);
      }
    },
    close() {
      this.enquiryMspsalesqty = "";
      this.enquiryMsptotalprice = "";
      this.pfmdate = "";
      this.pfmstatus = "";
      this.parf_status_updated = false;
    }
  }
};
</script>
