<template>
  <div>
    <!--container-->
    <v-container>
      <!--tob bar-->
      <bar />
      <!--tob bar end-->

      <!--template-->
      <template>
        <!--card -->
        <v-card class="form-card mx-auto my-12" outlined>
          <!--alert box-->
          <v-alert text prominent type="error" v-if="errorResponse">
            <div v-for="(error, i) in countryError" :key="i">
              {{ error }}
            </div>
          </v-alert>
          <!--alert box end-->
          <v-list-item class="headline">
            <v-list-item-content>
              <v-list-item-title class="orange--text text--darken-2">
                COUNTRY ADD
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>

          <!--form-->
          <form>
            <!--input field-->
            <v-card-text>
              <v-row justify="space-between">
                <v-col cols md="6">
                  <v-text-field
                    v-model="countryname"
                    :error-messages="countrynameErrors"
                    label="COUNTRY NAME*"
                    required
                    @input="$v.countryname.$touch()"
                    @blur="$v.countryname.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <!--input field end-->
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="black" dark @click="submit"> SUBMIT </v-btn>
              <v-btn color="warning" dark to="/country"> CANCEL </v-btn>
            </v-card-actions>
          </form>
          <!--form end-->
        </v-card>
        <!--card end-->
      </template>
      <!--template end-->
    </v-container>
    <!--container end-->
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "CountryAdd",
  data: () => ({
    countryname: ""
  }),
  validations: {
    countryname: { required }
  },
  computed: {
    ...mapGetters(["countryStatus", "countryStatusResponse"]),
    countrynameErrors() {
      const errors = [];
      if (!this.$v.countryname.$dirty) return errors;
      !this.$v.countryname.required && errors.push("Country Name is required.");
      return errors;
    },
    errorResponse: function() {
      return this.countryStatus === "error";
    },
    countryError: function() {
      return this.countryStatusResponse;
    }
  },
  methods: {
    ...mapActions(["countryAdd"]),
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("ERROR");
      } else {
        // do your submit logic here
        setTimeout(() => {
          const countryData = {
            countryName: this.countryname.toUpperCase()
          };
          this.countryAdd(countryData);
        }, 500);
      }
    }
  }
};
</script>
