<template>
  <div>
    <!--container-->
    <v-container>
      <!--tob bar-->
      <bar />
      <!--tob bar end-->

      <v-card class="mx-auto mb-5" outlined>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="2" v-if="userRole != 'sales-engineer'">
              <v-select v-model="region" :items="regions" label="REGION *" class="mt-6" clearable>
              </v-select>
            </v-col>

            <v-col cols="12" md="2">
              <v-autocomplete v-model="segment" :items="segments" item-text="name" item-value="id" label="SEGMENT*"
                class="mt-4" @change="getSalesengineer(segment)" chips multiple clearable></v-autocomplete>
            </v-col>

            <v-col cols="12" md="2" v-if="userRole != 'sales-engineer'">
              <v-select v-model="salesengineer" :items="salesengineers" label="SALES ENGINEER *" item-text="name"
                item-value="id" class="mt-4" chips multiple clearable></v-select>
            </v-col>

            <v-col md="2" cols="12">
              <v-select v-model="productgroup" :items="productgroups" item-text="product_group_name" item-value="id"
                label="PRODUCT GROUP*" class="mt-4" chips multiple clearable></v-select>
            </v-col>

            <v-col cols="12" md="2">
              <v-autocomplete v-model="product" :items="products" item-text="product_name_withtpl" item-value="id"
                label="EQUIPMENT" class="mt-4" chips multiple clearable></v-autocomplete>
            </v-col>

            <v-col cols="12" md="2">
              <v-select class="mt-6" v-model="status" :items="statusList" label="STATUS*" clearable></v-select>
            </v-col>

            <v-col cols="12" md="2">
              <v-select v-model="enquirytype" :items="enquiryTypes" label="ENQUIRY TYPE" class="mt-6"
                clearable></v-select>
            </v-col>

            <v-col cols="12" md="2">
              <v-select v-model="daterange" :items="dateRanges" label="DATE RANGE" class="mt-6" clearable></v-select>
            </v-col>
            <!--<v-col cols="12" md="2">
              <v-select
                v-model="followupstatus"
                :items="followupstatusList"
                label="EXCEPT FOLLOWUP STATUS IN EXPORT"
                class="mt-6"
              ></v-select>
            </v-col>-->
          </v-row>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" dark @click="filterEnquiryReport(1)">
            FIND
          </v-btn>
        </v-card-actions>
      </v-card>

      <!--template-->
      <template>
        <!--card -->

        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-card class="mx-auto" outlined>
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title class="text-h5 mb-1">
                    TOTAL ENQUIRY
                  </v-list-item-title>
                  <v-list-item-subtitle>{{ enquiryDataValues.enquiryValue }} MN</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>

          <v-col cols="12" sm="3" md="3" lg="2">
            <v-card class="mx-auto" outlined>
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title class="text-h5 mb-1">
                    ENQUIRY
                  </v-list-item-title>
                  <v-list-item-subtitle>{{
                    enquiryDataValues.totalEnquirystatusvalue
                  }}
                    MN</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
          <v-col cols="12" sm="3" md="3" lg="2">
            <v-card class="mx-auto" outlined>
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title class="text-h5 mb-1">
                    FOLLOWUP
                  </v-list-item-title>
                  <v-list-item-subtitle>{{
                    enquiryDataValues.enquiryFollowupvalue
                  }}
                    MN</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
          <v-col cols="12" sm="3" md="3" lg="2">
            <v-card class="mx-auto" outlined>
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title class="text-h5 mb-1">
                    NEGOTIATION
                  </v-list-item-title>
                  <v-list-item-subtitle>{{
                    enquiryDataValues.enquiryNegotiationvalue
                  }}
                    MN</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
          <v-col cols="12" sm="3" md="3" lg="2">
            <v-card class="mx-auto" outlined>
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title class="text-h5 mb-1">
                    CONFIRM
                  </v-list-item-title>
                  <v-list-item-subtitle>{{
                    enquiryDataValues.enquiryConfirmvalue
                  }}
                    MN</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
          <v-col cols="12" sm="3" md="3" lg="2">
            <v-card class="mx-auto" outlined>
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title class="text-h5 mb-1">
                    FOLLOWUP CLOSED
                  </v-list-item-title>
                  <v-list-item-subtitle>{{
                    enquiryDataValues.enquiryFoloupclosedvalue
                  }}
                    MN</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
          <v-col cols="12" sm="3" md="3" lg="2">
            <v-card class="mx-auto" outlined>
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title class="text-h5 mb-1">
                    LOST
                  </v-list-item-title>
                  <v-list-item-subtitle>{{ enquiryDataValues.enquiryLostvalue }} MN</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
          <v-col cols="12" sm="3" md="3" lg="2">
            <v-card class="mx-auto" outlined>
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title class="text-h5 mb-1">
                    BILLED
                  </v-list-item-title>
                  <v-list-item-subtitle>{{
                    enquiryDataValues.enquiryBilledvalue
                  }}
                    MN</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
          <v-col cols="12" sm="3" md="3" lg="2">
            <v-card class="mx-auto" outlined>
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title class="text-h5 mb-1">
                    RESCHEDULED
                  </v-list-item-title>
                  <v-list-item-subtitle>{{
                    enquiryDataValues.enquiryReschduledvalue
                  }}
                    MN</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
        </v-row>

        <v-card class="mx-auto my-12" outlined>
          <v-card-title class="text-h5">
            ENQUIRY REPORT
            <v-spacer></v-spacer>

            <!--export Sales Engineer-->
            <v-btn color="primary" dark @click="exportEnquiryReport" v-if="userRole != 'admin'">
              <v-icon right dark class="ma-2">
                mdi-cloud-download
              </v-icon>
              EXPORT
            </v-btn>
            <!--export Sales Engineer End-->

            <!--export Admin-->
            <div v-if="userRole == 'admin'">
              <download-excel :data="json_data" type="csv" name="enquiryReport.csv" v-if="showExport">
                <v-btn color="primary" dark>
                  <v-icon right dark class="ma-2">
                    mdi-cloud-download
                  </v-icon>
                  EXPORT
                </v-btn>
              </download-excel>
            </div>
            <!--export Admin-->


          </v-card-title>
          <v-divider></v-divider>

          <v-card-text>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">REFERENCE NO</th>
                    <th class="text-left">CUSTOMER</th>
                    <th class="text-left">REGION</th>
                    <th class="text-left">SEGMENT</th>
                    <th class="text-left">LOB</th>
                    <th class="text-left">STATE</th>
                    <th class="text-left">CITY</th>
                    <th class="text-left">SALES ENG</th>
                    <th class="text-left">TPL NO</th>
                    <th class="text-left">PRODUCT</th>
                    <th class="text-left">SALES TYPE</th>
                    <th class="text-left">WORKSHOP TYPE</th>
                    <th class="text-left">ENQ TYPE</th>
                    <th class="text-left">LEAD SOURCE</th>
                    <th class="text-left">ENQ DATE</th>
                    <th class="text-left">TSP DATE</th>
                    <th class="text-left">PFM DATE</th>
                    <th class="text-left">ENQ QTY</th>
                    <th class="text-left">EST PRICE</th>
                    <th class="text-left">EST TOT PRICE</th>
                    <th class="text-left">ACT SAL QTY</th>
                    <th class="text-left">TOT SAL PRICE</th>
                    <th class="text-left">FOLLOWUP</th>
                    <th class="text-left">STATUS</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="enquiry in enquiryData.enquiryDetails" :key="enquiry.id">
                    <td>{{ enquiry.referenceNo }}</td>
                    <td>{{ enquiry.customer_details }}</td>
                    <td>{{ enquiry.region }}</td>
                    <td>{{ enquiry.segment }}</td>
                    <td>{{ enquiry.brand }}</td>
                    <td>{{ enquiry.state }}</td>
                    <td>{{ enquiry.city }}</td>
                    <td>{{ enquiry.salesengineer }}</td>
                    <td>{{ enquiry.producttplno }}</td>
                    <td>{{ enquiry.productname }}</td>
                    <td>{{ enquiry.sales_type }}</td>
                    <td>{{ enquiry.workshop_type }}</td>
                    <td>{{ enquiry.enquiryType }}</td>
                    <td>{{ enquiry.leadEnquirySource }}</td>
                    <td>{{ enquiry.enquiry_date }}</td>
                    <td>{{ enquiry.tsp_date }}</td>
                    <td>{{ enquiry.pfm_date }}</td>
                    <td>{{ enquiry.quantity }}</td>
                    <td>{{ enquiry.price }}</td>
                    <td>{{ enquiry.totalPrice }}</td>
                    <td>{{ enquiry.final_sales_qty }}</td>
                    <td>{{ enquiry.final_total_sales_price }}</td>
                    <td>
                      <v-simple-table>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">FOLLOWUP DATE</th>
                              <th class="text-left">STATUS</th>
                              <th class="text-left">REMARKS</th>
                            </tr>
                          </thead>
                <tbody>
                  <tr v-for="enstatus in enquiry.enquiryStatus" :key="enstatus.id">
                    <td>{{ enstatus.followup_date }}</td>
                    <td>{{ enstatus.status }}</td>
                    <td>{{ enstatus.remarks }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            </td>
            <td>{{ enquiry.status }}</td>
            </tr>
            </tbody>
      </template>
      </v-simple-table>

      <!--pagination-->
      <div class="text-center mt-5">
        <v-pagination v-model="page" :length="enquiryReportPagination" :total-visible="7"
          @input="paginationNextPage"></v-pagination>
      </div>
      <!--pagination end-->
      </v-card-text>
      </v-card>
      </template>
      <!--template end-->
    </v-container>
    <!--container end-->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { VUE_APP_BASEURL, VUE_WEB_BASEURL } from "../../utils/api";
import axios from "axios";
import store from "../../store";

export default {
  name: "EnquiryReport",
  data: () => ({
    search: "",
    product: "",
    segment: "",
    salesengineer: "",
    status: "",
    statusList: ["FOLLOWUP", "NEGOTIATION", "CONFIRM", "BILLED", "FOL CLOSED", "LOST"],
    enquirytype: "",
    enquiryTypes: ["DIGITAL LEAD", "NON DIGITAL LEAD"],
    productgroup: "",
    region: "",
    daterange: "THIS FISCAL YEAR",
    followupstatus: "YES",
    followupstatusList: ["YES", "NO"],
    json_data: [],
    page: 1,
    selectedPage: 1,
    showExport: false,
  }),
  computed: {
    ...mapGetters([
      "getEnquiryreport",
      "getEnquiryreportvalues",
      "productList",
      "getSalesengineerlist",
      "segmentList",
      "authRole",
      "getRegions",
      "productGroup",
      "getDateRange",
      "enquiryReportListPaginate"
    ]),
    products: function () {
      return this.productList;
    },
    salesengineers: function () {
      return this.getSalesengineerlist;
    },
    enquiryData: function () {
      return this.getEnquiryreport;
    },
    enquiryDataValues: function () {
      return this.getEnquiryreportvalues;
    },
    segments: function () {
      return this.segmentList;
    },
    userRole: function () {
      return this.authRole;
    },
    regions: function () {
      return this.getRegions;
    },
    productgroups: function () {
      return this.productGroup;
    },
    dateRanges: function () {
      return this.getDateRange;
    },
    enquiryReportPagination: function () {
      return this.enquiryReportListPaginate;
    }
  },
  watch: {},
  created() {
    this.fetchProducts();
    this.fetchDefinedvalues();
    this.fetchSegment();
    this.fetchProductgroup();
    const filterData = {
      page: "",
      report: "enquiry",
      daterange: this.daterange,
    };
    this.enquiryReport(filterData);
    this.enquiryReportValues(filterData);
    this.enquiryReportJson(filterData);
  },

  methods: {
    ...mapActions([
      "fetchProducts",
      "enquiryReport",
      "enquiryReportValues",
      "fetchSalesengineers",
      "fetchSegment",
      "fetchDefinedvalues",
      "fetchProductgroup"
    ]),

    filterEnquiryReport(page) {
      const filterData = {
        page: page,
        report: "enquiry",
        product: this.product,
        segment: this.segment,
        salesengineer: this.salesengineer,
        status: this.status,
        region: this.region,
        productgroup: this.productgroup,
        daterange: this.daterange,
        enquirytype: this.enquirytype
      };
      this.enquiryReport(filterData);
      this.enquiryReportValues(filterData);
      this.enquiryReportJson(filterData);
    },
    //get segment based  salesengineer
    getSalesengineer(segment) {
      this.fetchSalesengineers(segment);
      //  console.log(segment);
    },
    settodate() {
      this.toMindate = new Date(this.fromDate);
    },
    paginationNextPage(page) {
      //this.selectedPage = page;
      this.filterEnquiryReport(page);
    },
    exportEnquiryReport() {
      store.commit("authRequest");

      if (this.userRole == 'admin') {
        this.followupstatus = 'YES';
      }
      else {
        this.followupstatus = 'NO';
      }

      const filterData = {
        report: "enquiry",
        product: this.product,
        segment: this.segment,
        salesengineer: this.salesengineer,
        status: this.status,
        region: this.region,
        productgroup: this.productgroup,
        daterange: this.daterange,
        enquirytype: this.enquirytype,
        followupstatus: this.followupstatus
      };

      axios({
        method: "GET",
        headers: {
          Authorization: "Bearer " + store.getters.UserToken
        },
        url: VUE_APP_BASEURL + "user-token"
      })
        .then(function (response) {
          // console.log(response);

          //console.log(filterData);

          window.open(
            VUE_WEB_BASEURL +
            "enquiry/export?userid=" +
            response.data.id +
            "&report=" +
            filterData.report +
            "&product=" +
            filterData.product +
            "&segment=" +
            filterData.segment +
            "&salesengineer=" +
            filterData.salesengineer +
            "&status=" +
            filterData.status +
            "&region=" +
            filterData.region +
            "&productgroup=" +
            filterData.productgroup +
            "&daterange=" +
            filterData.daterange +
            "&enquirytype=" +
            filterData.enquirytype +
            "&followupstatus=" +
            filterData.followupstatus,
            "_blank"
          );
          store.commit("authSuccess");
          console.log(VUE_WEB_BASEURL);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    enquiryReportJson() {


      if (this.userRole == 'admin') {
        this.followupstatus = 'YES';
      }
      else {
        this.followupstatus = 'NO';
      }

      store.commit("authRequest");
      const filterData = {
        report: "enquiry",
        product: this.product,
        segment: this.segment,
        salesengineer: this.salesengineer,
        status: this.status,
        region: this.region,
        productgroup: this.productgroup,
        daterange: this.daterange,
        enquirytype: this.enquirytype,
        followupstatus: this.followupstatus
      };
      console.log(filterData);
      this.showExport = false;
      const funct = this;
      axios({
        method: "POST",
        headers: {
          Authorization: "Bearer " + store.getters.UserToken
        },
        url: VUE_APP_BASEURL + "export/enquiry-json",
        data: {
          report: filterData.report,
          product: filterData.product,
          segment: filterData.segment,
          salesengineer: filterData.salesengineer,
          status: filterData.status,
          region: filterData.region,
          productgroup: filterData.productgroup,
          daterange: filterData.daterange,
          enquirytype: filterData.enquirytype,
          followupstatus: filterData.followupstatus,
        }
      })
        .then(function (response) {
          //console.log(response);
          funct.exportDataJson(response.data.response.enquiry_followuplist);
          store.commit("authSuccess");
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });

    },
    exportDataJson(data) {
      console.log(data);
      this.json_data = data;
      this.showExport = true;
    }



  }
};
</script>
