<template>
  <div>
    <v-card class="mx-auto ml-5 my-5 mx-5 mr-5 " outlined>
      <v-row align="center" class="mt-2 mr-2">
        <v-col class="d-flex" cols="12" sm="6"></v-col>
        <v-col class="d-flex" cols="12" sm="6">
          <v-select
            v-model="daterange"
            :items="dateRanges"
            label="DATE RANGE"
            dense
            outlined
            @change="filterOrderlosschart()"
          ></v-select>
        </v-col>
      </v-row>
      <highcharts :options="chartOptions" ref="highcharts"></highcharts>
      <!--<button @click="updateCredits">update credits</button>-->
    </v-card>
  </div>
</template>

<script>
// @ is an alias to /src

import { mapGetters, mapActions } from "vuex";

import { VUE_APP_BASEURL } from "../utils/api";
import axios from "axios";
import store from "../store";

export default {
  name: "OrderlossChartDash",
  props: [
    "selectedsegment",
    "selectedsalesengineer",
    "selectedregion",
    "selectedproductgroup"
  ],
  data: () => ({
    daterange: "",
    chartOptions: {
      chart: {
        type: "column"
      },
      title: {
        text: "ORDER LOSS REPORT CHART"
      },
      subtitle: {
        text: ""
      },
      xAxis: {
        categories: [],
        crosshair: true
      },
      yAxis: {
        min: 0,
        title: {
          text: ""
        }
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.1f} </b></td></tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0
        }
      },
      series: [{}],
      credits: {
        enabled: false
      }
    }
  }),
  components: {},
  computed: {
    ...mapGetters(["getDateRange"]),
    dateRanges: function() {
      return this.getDateRange;
    }
  },
  mounted() {},
  created() {
    this.fetchDefinedvalues();
    this.orderlosschartload(this.chartOptions);
  },
  methods: {
    ...mapActions(["fetchDefinedvalues"]),
    orderlosschartload(chartOptions) {
      const filterData = [];

      axios({
        method: "POST",
        headers: {
          Authorization: "Bearer " + store.getters.UserToken
        },
        url: VUE_APP_BASEURL + "dashboard/orderloss-report-chart",
        data: {
          segment: filterData.segment,
          salesengineer: filterData.salesengineer,
          daterange: filterData.daterange,
          region: filterData.region,
          productgroup: filterData.productgroup
        }
      })
        .then(function(response) {
          chartOptions.series = response.data.response.orderlossbarchartData;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    filterOrderlosschart() {
      const filterData = {
        segment: this.selectedsegment,
        salesengineer: this.selectedsalesengineer,
        daterange: this.daterange,
        region: this.selectedregion,
        productgroup: this.selectedproductgroup
      };

      // console.log(filterData);
      const chartOptions = this.chartOptions;
      axios({
        method: "POST",
        headers: {
          Authorization: "Bearer " + store.getters.UserToken
        },
        url: VUE_APP_BASEURL + "dashboard/orderloss-report-chart",
        data: {
          segment: filterData.segment,
          salesengineer: filterData.salesengineer,
          daterange: filterData.daterange,
          region: filterData.region,
          productgroup: filterData.productgroup
        }
      })
        .then(function(response) {
          // console.log(response);
          chartOptions.series = response.data.response.orderlossbarchartData;
        })
        .catch(function(error) {
          console.log(error);
          console.log(error.response);
        });
    }
  }
};
</script>
