<template>
  <div>
    <!--container-->
    <v-container>
      <!--tob bar-->
      <bar />
      <!--tob bar end-->

      <!--template-->
      <template>
        <!--card -->
        <v-card class="form-card mx-auto my-12" outlined>
          <!--alert box-->
          <v-alert text prominent type="error" v-if="errorResponse">
            <div v-for="(error, i) in roleError" :key="i">
              {{ error }}
            </div>
          </v-alert>
          <!--alert box end-->
          <v-list-item class="headline">
            <v-list-item-content>
              <v-list-item-title class="orange--text text--darken-2">
                ROLE ADD
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>

          <!--form-->
          <form>
            <!--input field-->
            <v-card-text>
              <v-row justify="space-between">
                <v-col cols md="12">
                  <v-text-field v-model="rolename" :error-messages="rolenameErrors" label="ROLE NAME*" required
                    @input="$v.rolename.$touch()" @blur="$v.rolename.$touch()"></v-text-field>
                </v-col>

                <v-col cols md="12">
                  <v-select v-model="permission" :items="permissions" :error-messages="permissionErrors"
                    label="PERMISSION *" item-text="name" item-value="name" required @input="$v.permission.$touch()"
                    @blur="$v.permission.$touch()"></v-select>
                </v-col>

                <!--<v-col cols md="12">
                <v-select
                    v-model="menu"
                    :items="menus"
                    :error-messages="menuErrors"
                    label="MENU *"
                    item-text="name"
                    item-value="id"
                    required
                    chips
                    multiple
                    @input="$v.menu.$touch()"
                    @blur="$v.menu.$touch()"
                   ></v-select>
                </v-col>-->
              </v-row>
            </v-card-text>
            <!--input field end-->
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" dark @click="submit"> SUBMIT </v-btn>
              <v-btn color="secondary" dark to="/role"> CANCEL </v-btn>
            </v-card-actions>
          </form>
          <!--form end-->
        </v-card>
        <!--card end-->
      </template>
      <!--template end-->
    </v-container>
    <!--container end-->
  </div>
</template>

<style>
.v-input input {
  text-transform: none !important;
}
</style>

<script>
import { required } from "vuelidate/lib/validators";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "RoleAdd",
  data: () => ({
    rolename: "",
    permission: "",
    menu: ""
  }),
  validations: {
    rolename: { required },
    permission: { required },
    menu: { required }
  },
  computed: {
    ...mapGetters([
      "roleStatus",
      "roleStatusResponse",
      "getPermission",
      "getActivemenus"
    ]),
    rolenameErrors() {
      const errors = [];
      if (!this.$v.rolename.$dirty) return errors;
      !this.$v.rolename.required && errors.push("Role Name is required.");
      return errors;
    },
    permissionErrors() {
      const errors = [];
      if (!this.$v.permission.$dirty) return errors;
      !this.$v.permission.required && errors.push("Permission is required.");
      return errors;
    },
    menuErrors() {
      const errors = [];
      if (!this.$v.menu.$dirty) return errors;
      !this.$v.menu.required && errors.push("Menu is required.");
      return errors;
    },
    errorResponse: function () {
      return this.roleStatus === "error";
    },
    roleError: function () {
      return this.roleStatusResponse;
    },
    menus: function () {
      return this.getActivemenus;
    },
    permissions: function () {
      return this.getPermission;
    }
  },
  created() {
    this.fetchPermissions();
    this.fetchActivemenus();
  },
  methods: {
    ...mapActions(["roleAdd", "fetchPermissions", "fetchActivemenus"]),
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("ERROR");
      } else {
        // do your submit logic here
        setTimeout(() => {
          const roleData = {
            roleName: this.rolename,
            permission: this.permission
          };
          this.roleAdd(roleData);
        }, 500);
      }
    }
  }
};
</script>
