<template>
  <div>
    <!--container-->
    <v-container>
      <!--tob bar-->
      <bar />
      <!--tob bar end-->

      <!--template-->
      <template>
        <!--card -->
        <v-card class="form-card mx-auto my-12">
          <!--alert box-->
          <v-alert text prominent type="error" v-if="errorResponse">
            <div v-for="(error, i) in brandError" :key="i">
              {{ error }}
            </div>
          </v-alert>
          <!--alert box end-->

          <v-list-item class="headline">
            <v-list-item-content>
              <v-list-item-title> LOB ADD </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>

          <!--form-->
          <form>
            <!--input field-->
            <v-card-text>
              <v-row justify="space-between">
                <v-col cols="12" xs="12" sm="12" md="6">
                  <v-text-field
                    v-model="brandname"
                    :error-messages="brandnameErrors"
                    label="LOB NAME*"
                    required
                    @input="$v.brandname.$touch()"
                    @blur="$v.brandname.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <!--input field end-->
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="black" dark @click="submit"> SUBMIT </v-btn>
              <v-btn color="warning" dark to="/brand"> CANCEL </v-btn>
            </v-card-actions>
          </form>
          <!--form end-->
        </v-card>
        <!--card end-->
      </template>
      <!--template end-->
    </v-container>
    <!--container end-->
  </div>
</template>

<script>
//import list
import { required } from "vuelidate/lib/validators";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "BrandAdd",
  data: () => ({
    brandname: ""
  }),
  validations: {
    brandname: { required }
  },
  computed: {
    ...mapGetters(["brandStatus", "brandStatusResponse"]),
    brandnameErrors() {
      const errors = [];
      if (!this.$v.brandname.$dirty) return errors;
      !this.$v.brandname.required && errors.push("LOB Name is required.");
      return errors;
    },
    errorResponse: function() {
      return this.brandStatus === "error";
    },
    brandError: function() {
      return this.brandStatusResponse;
    }
  },
  methods: {
    ...mapActions(["brandAdd"]),
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("ERROR");
      } else {
        //submit logic
        setTimeout(() => {
          const brandData = {
            brandName: this.brandname.toUpperCase()
          };
          this.brandAdd(brandData);
        }, 500);
      }
    }
  }
};
</script>
