<template>
  <div>
    <!--container-->
    <v-container>
      <!--tob bar-->
      <bar />
      <!--tob bar end-->

      <v-card class="mx-auto mb-5" outlined>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="2" v-if="userRole != 'sales-engineer'">
              <v-select v-model="region" :items="regions" label="REGION *" class="mt-6" clearable>
              </v-select>
            </v-col>

            <v-col cols="12" md="2">
              <v-autocomplete v-model="segment" :items="segments" item-text="name" item-value="id" label="SEGMENT*"
                class="mt-4" chips multiple clearable @change="getSalesengineer(segment)"></v-autocomplete>
            </v-col>

            <v-col cols="12" md="2" v-if="userRole != 'sales-engineer'">
              <v-select v-model="salesengineer" :items="salesengineers" label="SALES ENGINEER *" item-text="name"
                item-value="id" class="mt-4" chips multiple clearable></v-select>
            </v-col>

            <v-col cols="12" md="2">
              <v-select v-model="daterange" :items="dateRanges" label="DATE RANGE" class="mt-6" clearable></v-select>
            </v-col>

            <v-col cols="12" md="2">
              <v-text-field v-model="search" label="SEARCH" class="mt-6" clearable></v-text-field>
            </v-col>

          </v-row>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" dark @click="filterLeadReport(1)">
            FIND
          </v-btn>
        </v-card-actions>
      </v-card>

      <!--template-->
      <template>
        <!--card -->

        <v-card class="mx-auto my-12" outlined>
          <v-card-title class="text-h5">
            LEAD REPORT
            <v-spacer></v-spacer>
            <!--<v-btn color="warning" dark @click="exportLeadReport">
              <v-icon right dark class="ma-2">
                mdi-cloud-download
              </v-icon>
              EXPORT
            </v-btn>-->
            <download-excel :data="json_data" type="csv" name="leadReport.csv" v-if="showExport">
              <v-btn color="warning" dark>
                <v-icon right dark class="ma-2">
                  mdi-cloud-download
                </v-icon>
                EXPORT
              </v-btn>
            </download-excel>
          </v-card-title>
          <v-divider></v-divider>

          <v-card-text>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">CUSTOMER CODE</th>
                    <th class="text-left">CUSTOMER</th>
                    <th class="text-left">CONTACT NO</th>
                    <th class="text-left">EMAIL ID</th>
                    <th class="text-left">REGION</th>
                    <th class="text-left">STATE</th>
                    <th class="text-left">CITY</th>
                    <th class="text-left">SEGMENT</th>
                    <th class="text-left">LOB</th>
                    <th class="text-left">SALES ENG</th>
                    <th class="text-left">CATEGORY ENQUIRY SOURCE</th>
                    <th class="text-left">ENQUIRY SOURCE</th>
                    <th class="text-left">ENQUIRY TYPE</th>
                    <th class="text-left">LEAD DATE</th>
                    <th class="text-left">LEAD PRICE</th>
                    <th class="text-left">MESSAGE</th>
                    <th class="text-left">HO REMARKS</th>
                    <th class="text-left">STATUS</th>
                    <th class="text-left">SALESENGINEER STATUS</th>
                    <th class="text-left">REFERENCE NO</th>
                    <th class="text-left">ENQUIRY DATE</th>
                    <th class="text-left">ENQUIRY</th>

                    <th class="text-left">FOLLOWUP</th>
                    <th class="text-left">NEGOTIATION</th>
                    <th class="text-left">CONFIRM</th>
                    <th class="text-left">FOLLOWUP CLOSED</th>
                    <th class="text-left">LOST</th>
                    <th class="text-left">BILLED</th>
                    <th class="text-left">RESCHEDULED</th>

                    <th class="text-left">ENQUIRY BILLED PRICE</th>
                    <th class="text-left">DAYS</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="enquiry in enquiryData.leads" :key="enquiry.sno">
                    <td>{{ enquiry.customer_code }}</td>
                    <td>{{ enquiry.customer_details }}</td>
                    <td>{{ enquiry.contactnumber }}</td>
                    <td>{{ enquiry.emailid }}</td>
                    <td>{{ enquiry.region }}</td>
                    <td>{{ enquiry.state }}</td>
                    <td>{{ enquiry.city }}</td>
                    <td>{{ enquiry.segment }}</td>
                    <td>{{ enquiry.brand }}</td>
                    <td>{{ enquiry.assigned_user }}</td>
                    <td>{{ enquiry.category_enquiry_source }}</td>
                    <td>{{ enquiry.enquiry_source }}</td>
                    <td>{{ enquiry.enquiry_type }}</td>
                    <td>{{ enquiry.enquiry_date }}</td>
                    <td>{{ enquiry.leadtotalPrice }}</td>
                    <td>{{ enquiry.message }}</td>
                    <td>{{ enquiry.ho_remarks }}</td>
                    <td>{{ enquiry.lead_status }}</td>
                    <td>{{ enquiry.salesengineer_status }}</td>
                    <td>{{ enquiry.code }}</td>
                    <td>{{ enquiry.enquiryconvertdate }}</td>
                    <td>{{ enquiry.enquirytotalprice }}</td>

                    <td>{{ enquiry.enquiryFollowupvalue }}</td>
                    <td>{{ enquiry.enquiryNegotiationvalue }}</td>
                    <td>{{ enquiry.enquiryConfirmvalue }}</td>
                    <td>{{ enquiry.enquiryFoloupclosedvalue }}</td>
                    <td>{{ enquiry.enquiryLostvalue }}</td>
                    <td>{{ enquiry.enquiryBilledvalue }}</td>
                    <td>{{ enquiry.enquiryReschduledvalue }}</td>


                    <td>{{ enquiry.enquirybilledprice }}</td>
                    <td>{{ enquiry.days }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>

            <!--pagination-->
            <div class="text-center mt-5">
              <v-pagination v-model="page" :length="leadReportPagination" :total-visible="7"
                @input="paginationNextPage"></v-pagination>
            </div>
            <!--pagination end-->
          </v-card-text>
        </v-card>
      </template>
      <!--template end-->
    </v-container>
    <!--container end-->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { VUE_APP_BASEURL, VUE_WEB_BASEURL } from "../../utils/api";
import axios from "axios";
import store from "../../store";

export default {
  name: "LeadReport",
  data: () => ({
    search: "",
    segment: "",
    salesengineer: "",
    region: "",
    daterange: "",
    product: "",
    productgroup: "",
    status: "",
    page: 1,
    selectedPage: 1,
    showExport: false,
  }),
  computed: {
    ...mapGetters([
      "getLeadreport",
      "getSalesengineerlist",
      "segmentList",
      "authRole",
      "getRegions",
      "getDateRange",
      "leadReportListPaginate"
    ]),
    enquiryData: function () {
      return this.getLeadreport;
    },
    salesengineers: function () {
      return this.getSalesengineerlist;
    },
    segments: function () {
      return this.segmentList;
    },
    userRole: function () {
      return this.authRole;
    },
    regions: function () {
      return this.getRegions;
    },
    dateRanges: function () {
      return this.getDateRange;
    },
    leadReportPagination: function () {
      return this.leadReportListPaginate;
    }
  },
  watch: {},
  created() {
    const filterData = {
      page: ""
    };
    this.leadReport(filterData);
    this.fetchDefinedvalues();
    this.fetchSegment();
    this.leadReportJson(filterData);
  },
  methods: {
    ...mapActions([
      "leadReport",
      "fetchSalesengineers",
      "fetchSegment",
      "fetchDefinedvalues"
    ]),
    filterLeadReport(page) {
      const filterData = {
        page: page,
        segment: this.segment,
        salesengineer: this.salesengineer,
        region: this.region,
        daterange: this.daterange,
        search: this.search
      };

      //console.log(filterData);
      this.leadReport(filterData);
      this.leadReportJson(filterData);
    },
    paginationNextPage(page) {
      //console.log(page);
      //this.selectedPage = page;
      this.filterLeadReport(page);
    },
    //get segment based  salesengineer
    getSalesengineer(segment) {
      this.fetchSalesengineers(segment);
      //  console.log(segment);
    },
    settodate() {
      this.toMindate = new Date(this.fromDate);
    },
    exportLeadReport() {
      store.commit("authRequest");
      const filterData = {
        report: "enquiry",
        product: this.product,
        segment: this.segment,
        salesengineer: this.salesengineer,
        status: this.status,
        region: this.region,
        productgroup: this.productgroup,
        daterange: this.daterange,
        search: this.search
      };

      axios({
        method: "GET",
        headers: {
          Authorization: "Bearer " + store.getters.UserToken
        },
        url: VUE_APP_BASEURL + "user-token"
      })
        .then(function (response) {
          //  console.log(response);
          //  console.log(filterData);

          window.open(
            VUE_WEB_BASEURL +
            "lead/export?userid=" +
            response.data.id +
            "&report=" +
            filterData.report +
            "&product=" +
            filterData.product +
            "&segment=" +
            filterData.segment +
            "&salesengineer=" +
            filterData.salesengineer +
            "&status=" +
            filterData.status +
            "&region=" +
            filterData.region +
            "&productgroup=" +
            filterData.productgroup +
            "&daterange=" +
            filterData.daterange +
            "&search=" +
            filterData.search,
            "_blank"
          );
          store.commit("authSuccess");
          // console.log(VUE_WEB_BASEURL);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    leadReportJson() {
      store.commit("authRequest");
      const filterData = {
        report: "enquiry",
        product: this.product,
        segment: this.segment,
        salesengineer: this.salesengineer,
        status: this.status,
        region: this.region,
        productgroup: this.productgroup,
        daterange: this.daterange,
        search: this.search
      };
      //console.log(filterData);
      this.showExport = false;
      const funct = this;
      axios({
        method: "POST",
        headers: {
          Authorization: "Bearer " + store.getters.UserToken
        },
        url: VUE_APP_BASEURL + "export/lead-json",
        data: {
          report: filterData.report,
          product: filterData.product,
          segment: filterData.segment,
          salesengineer: filterData.salesengineer,
          status: filterData.status,
          region: filterData.region,
          productgroup: filterData.productgroup,
          daterange: filterData.daterange,
          search: filterData.search,
        }
      })
        .then(function (response) {
          //console.log(response);
          funct.exportDataJson(response.data.response.lead_list);
          store.commit("authSuccess");
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });

    },
    exportDataJson(data) {
      console.log(data);
      this.json_data = data;
      this.showExport = true;
    }

  }
};
</script>
