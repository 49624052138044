<template>
  <div>
    <!--container-->
    <v-container>
      <!--tob bar-->
      <bar />
      <!--tob bar end-->

      <!--template-->
      <template>
        <!--alert box-->
        <v-alert
          text
          dense
          color="teal"
          icon="mdi-clock-fast"
          border="left"
          v-if="leadStatusmsg"
        >
          <div v-for="(resp, i) in leadResponse" :key="i">
            {{ resp }}
          </div>
        </v-alert>
        <!--alert box end-->

        <!---datatable -->
        <v-data-table
          :headers="headers"
          :items="leads"
          :search="search"
          sort-by="temporary_no"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>LEAD</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-btn
                color="black"
                dark
                class="mb-2"
                to="/lead/add"
                v-if="userRole != 'sales-engineer'"
              >
                NEW LEAD
              </v-btn>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editLead(item)">
              mdi-pencil
            </v-icon>
          </template>
        </v-data-table>
        <!---datatable end-->
      </template>
      <!--template end-->
    </v-container>
    <!--container end-->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import router from "../../router";

export default {
  name: "Lead",
  data: () => ({
    search: "",
    headers: [
      { text: "Actions", value: "actions", sortable: false },
      { text: "CUSTOMER CODE", value: "customer_code" },
      { text: "CUSTOMER DETAILS", value: "customer_details" },
      { text: "LEAD DATE", value: "enquiry_date" },
      { text: "CONTACT NO", value: "contactnumber" },
      { text: "EMAIL ID", value: "emailid" },
      { text: "REGION", value: "region" },
      { text: "SALES ENGINEER", value: "salesengineer" },
      { text: "SOURCE", value: "enquiry_source" },
      { text: "SALES ENGINEER STATUS", value: "salesengineer_status" },
      { text: "LEAD STATUS", value: "lead_status" },
      
    ]
  }),

  computed: {
    ...mapGetters(["leadList", "leadStatus", "leadStatusResponse", "authRole"]),
    leads: function() {
      return this.leadList;
    },
    leadStatusmsg: function() {
      return this.leadStatus === "success";
    },
    leadResponse: function() {
      return this.leadStatusResponse;
    },
    userRole: function() {
      return this.authRole;
    }
  },

  watch: {},

  created() {
    this.fetchLeads();
  },

  methods: {
    ...mapActions(["fetchLeads"]),

    editLead(item) {
      const id = item.id;
      //  router.push({ name: 'ProductGroupEdit', params: { productgroupId: 123 }})
      router.push({ path: `/lead/edit/${id}` });
    }
  }
};
</script>
