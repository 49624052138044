<template>
  <div>
    <v-app-bar app color="primary">
      <div class="white">
        <img alt="Vue logo" src="../assets/logo.png" height="60" />
      </div>

      <v-app-bar-nav-icon @click="drawer = true" color="white"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>

      <!--<h4 class="white--text font-weight-medium mr-3"> {{ timestamp }}</h4>   
      <v-icon color="white" class="mr-1">mdi-account-circle-outline</v-icon>  -->
      <h4 class="white--text font-weight-medium">{{ username }}</h4>
    </v-app-bar>
    <loading v-if="loading" />

    <!--onclick menu start-->
    <v-navigation-drawer v-model="drawer" fixed temporary>
      <v-list nav dense>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-account-circle-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title> {{ username }} </v-list-item-title>
        </v-list-item>

        <router-link to="/home">
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title> DASHBOARD </v-list-item-title>
          </v-list-item>
        </router-link>

        <div class="superadmin-menulist" v-if="userRole == 'super-admin'">
          <router-link to="/menu">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-chart-timeline</v-icon>
              </v-list-item-icon>
              <v-list-item-title> MENU</v-list-item-title>
            </v-list-item>
          </router-link>

          <router-link to="/role">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-account-cog</v-icon>
              </v-list-item-icon>
              <v-list-item-title> ROLE</v-list-item-title>
            </v-list-item>
          </router-link>
          <v-list-group>
            <template v-slot:activator>
              <v-list-item-icon>
                <v-icon>mdi-view-module</v-icon>
              </v-list-item-icon>
              <v-list-item-title>MASTER</v-list-item-title>
            </template>

            <router-link v-for="([title, url], i) in masterlist" :key="i" link :to="url">
              <v-list-item>
                <v-list-item-title v-text="title"></v-list-item-title>
              </v-list-item>
            </router-link>
          </v-list-group>
          <router-link to="/customer">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-account-group</v-icon>
              </v-list-item-icon>
              <v-list-item-title> CUSTOMER</v-list-item-title>
            </v-list-item>
          </router-link>
          <router-link to="/lead">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-grid</v-icon>
              </v-list-item-icon>
              <v-list-item-title> LEAD</v-list-item-title>
            </v-list-item>
          </router-link>
          <router-link to="/proforma">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-file-sign</v-icon>
              </v-list-item-icon>
              <v-list-item-title> PROFORMA</v-list-item-title>
            </v-list-item>
          </router-link>
          <router-link to="/user">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-account-tie</v-icon>
              </v-list-item-icon>
              <v-list-item-title> USER</v-list-item-title>
            </v-list-item>
          </router-link>
          <v-list-group>
            <template v-slot:activator>
              <v-list-item-icon>
                <v-icon>mdi-archive-plus-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>ENQUIRY</v-list-item-title>
            </template>
            <router-link v-for="([title, url], i) in enquirylist" :key="i" link :to="url">
              <v-list-item>
                <v-list-item-title v-text="title"></v-list-item-title>
              </v-list-item>
            </router-link>
          </v-list-group>

          <v-list-group>
            <template v-slot:activator>
              <v-list-item-icon>
                <v-icon>mdi-poll</v-icon>
              </v-list-item-icon>
              <v-list-item-title>REPORT</v-list-item-title>
            </template>
            <router-link v-for="([title, url], i) in reportlist" :key="i" link :to="url">
              <v-list-item>
                <v-list-item-title v-text="title"></v-list-item-title>
              </v-list-item>
            </router-link>
          </v-list-group>
        </div>

        <!-- <div class="superadmin-menulist" v-else-if="userRole == 'admin'">
          <v-list-group>
            <template v-slot:activator>
              <v-list-item-icon>
                <v-icon>mdi-view-module</v-icon>
              </v-list-item-icon>
              <v-list-item-title>MASTER</v-list-item-title>
            </template>
            <router-link v-for="([title, url], i) in masterlist" :key="i" link :to="url">
              <v-list-item>
                <v-list-item-title v-text="title"></v-list-item-title>
              </v-list-item>
            </router-link>
          </v-list-group>
          <router-link to="/customer">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-account-group</v-icon>
              </v-list-item-icon>
              <v-list-item-title> CUSTOMER</v-list-item-title>
            </v-list-item>
          </router-link>
          <router-link to="/lead">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-grid</v-icon>
              </v-list-item-icon>
              <v-list-item-title> LEAD</v-list-item-title>
            </v-list-item>
          </router-link>
          <router-link to="/proforma">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-file-sign</v-icon>
              </v-list-item-icon>
              <v-list-item-title> PROFORMA</v-list-item-title>
            </v-list-item>
          </router-link>
          <router-link to="/user">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-account-tie</v-icon>
              </v-list-item-icon>
              <v-list-item-title> USER</v-list-item-title>
            </v-list-item>
          </router-link>
          <v-list-group>
            <template v-slot:activator>
              <v-list-item-icon>
                <v-icon>mdi-archive-plus-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>ENQUIRY</v-list-item-title>
            </template>
            <router-link v-for="([title, url], i) in adminenquirylist" :key="i" link :to="url">
              <v-list-item>
                <v-list-item-title v-text="title"></v-list-item-title>
              </v-list-item>
            </router-link>
          </v-list-group>
          <v-list-group>
            <template v-slot:activator>
              <v-list-item-icon>
                <v-icon>mdi-poll</v-icon>
              </v-list-item-icon>
              <v-list-item-title>REPORT</v-list-item-title>
            </template>
            <router-link v-for="([title, url], i) in adminreportlist" :key="i" link :to="url">
              <v-list-item>
                <v-list-item-title v-text="title"></v-list-item-title>
              </v-list-item>
            </router-link>
          </v-list-group>
        </div>-->

        <div v-else>

          <div v-for="(list, j) in sidebarMenus" :key="j">
            <v-list-group v-if="list.url == '#'">
              <template v-slot:activator>
                <v-list-item-icon>
                  <v-icon>{{ list.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ list.name }}</v-list-item-title>
              </template>
              <router-link v-for="(sublist, i) in list.subMenu" :key="i" link :to="sublist.url">
                <v-list-item>
                  <v-list-item-title v-text="sublist.name"></v-list-item-title>
                </v-list-item>
              </router-link>
            </v-list-group>

            <router-link v-if="list.url != '#'" link :to="list.url">
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>{{ list.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ list.name }}</v-list-item-title>
              </v-list-item>
            </router-link>
          </div>


        </div>

        <v-list-item @click="logout">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            <v-btn plain text class="logout-btn">
              Logout
            </v-btn></v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <!--onclick menu end-->
  </div>
</template>

<script>
// @ is an alias to /src

import { mapActions, mapGetters } from "vuex";

export default {
  name: "Bar",
  data: () => ({
    timestamp: "",
    drawer: false,
    group: null,
    masterlist: [
      ["COUNTRY", "/country"],
      ["STATE", "/state"],
      ["CITY", "/city"],
      ["LOB", "/brand"],
      ["PRODUCT GROUP", "/product-group"],
      ["SEGMENT", "/segment"],
      ["PRODUCT", "/product"],
      ["PRODUCT LIBRARY", "/library"]
    ],
    enquirylist: [
      ["NEW ENQUIRY", "/enquiry/customer"],
      ["ENQUIRY LIST", "/enquiry"],
      ["ENQUIRY FOLLOWUP", "/enquiry-followup-list"],
      ["ENQUIRY TSP PLAN", "/enquiry-tsp-plan-list"],
      ["ENQUIRY PARF", "/enquiry-parf-request-list"],
      ["MONTHLY SALES PLAN", "/monthly-sales-plan-list"],
      ["ORDER PLAN", "/tsp-orderplan"]
    ],
    reportlist: [
      ["ENQUIRY REPORT", "/report/enquiry-report"],
      ["LOST REPORT", "/report/lost-report"],
      ["TSP REPORT", "/report/tsp-report"],
      ["EQUIPMENT REPORT", "/report/equipment-report"],
      ["CUSTOMER REPORT", "/report/customer-report"],
      ["BUYBACK REPORT", "/report/buyback-report"],
      ["PARTIAL ORDER REPORT", "/report/partialorder-report"],
      ["LEAD REPORT", "/report/lead-report"],
      ["MONTHLY SALES PLAN REPORT", "/report/monthly-salesplan-report"]
    ],
    adminenquirylist: [
      ["NEW ENQUIRY", "/enquiry/customer"],
      ["ENQUIRY LIST", "/enquiry"],
      // ["ENQUIRY FOLLOWUP", "/enquiry-followup-list"],
      // ["ENQUIRY TSP PLAN", "/enquiry-tsp-plan-list"],
      // ["ENQUIRY PARF", "/enquiry-parf-request-list"],
      // ["MONTHLY SALES PLAN", "/monthly-sales-plan-list"],
      // ["ORDER PLAN", "/tsp-orderplan"]
    ],
    adminreportlist: [
      ["ENQUIRY REPORT", "/report/enquiry-report"],
      ["LOST REPORT", "/report/lost-report"],
      // ["TSP REPORT", "/report/tsp-report"],
      // ["EQUIPMENT REPORT", "/report/equipment-report"],
      // ["CUSTOMER REPORT", "/report/customer-report"],
      // ["BUYBACK REPORT", "/report/buyback-report"],
      // ["PARTIAL ORDER REPORT", "/report/partialorder-report"],
      // ["LEAD REPORT", "/report/lead-report"],
      // ["MONTHLY SALES PLAN REPORT", "/report/monthly-salesplan-report"]
    ]
  }),

  watch: {
    group() {
      this.drawer = false;
    }
  },
  created() {
    this.loginDetails();
    this.menuDetails();
    setInterval(this.getNow, 1000);
  },
  components: {},
  computed: {
    ...mapGetters([
      "authStatus",
      "authStatusError",
      "authUser",
      "authRole",
      "getMenus"
    ]),
    loading: function () {
      return this.authStatus === "loading";
    },
    username: function () {
      return this.authUser;
    },
    userRole: function () {
      //console.log(this.authRole);
      return this.authRole;
    },
    sidebarMenus: function () {
      // console.log(this.getMenus);
      return this.getMenus;
    }
  },
  methods: {
    ...mapActions(["logoutAction", "loginDetails", "menuDetails"]),
    logout() {
      this.logoutAction();
    },
    getNow: function () {
      const today = new Date();
      const date =
        today.getDate() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getFullYear();
      //  const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      const time = today.getHours() + ":" + today.getMinutes();
      const dateTime = date + " " + time;
      this.timestamp = dateTime;
    }
  }
};
</script>

<style scoped>
.logout-btn {
  width: 100%;
  justify-content: left !important;
}

.v-application a {
  text-decoration: none;
}
</style>

<style>
.v-input input {
  text-transform: uppercase !important;
}

.v-input textarea {
  text-transform: uppercase !important;
}

.v-data-table {
  text-transform: uppercase !important;
}

.form-card {
  margin: 10px auto;
  display: block;
}
</style>

<style>
.form-card {
  width: 1200px;
  /*background-color: #a5a59fb0 !important;
 /*background-color: #dee2e6 !important;*/
}

/*
.form-box-response
{
  width:1200px !important;
}

.headline
{
  
  background-color: #f78f24 !important;

}
.headline .v-list-item__title
{
  color:#fff !important;
  font-weight: 600;
}

.v-messages__message
{
   color:#fff !important;
}

.v-card__subtitle, .v-card__text {
    font-size: 1.05rem !important;
}
*/
</style>
