<template>
  <div>
    <!--container-->
    <v-container>
      <!--tob bar-->
      <bar />
      <!--tob bar end-->

      <!--template-->
      <template>
        <!--card -->

        <!--Filter Box-->
        <v-card class="mx-auto mb-5" outlined>
          <v-card-text>
            <v-row>
              <v-col md="3" cols="12">
                <v-select
                  v-model="productgroup"
                  :items="productgroups"
                  item-text="product_group_name"
                  item-value="id"
                  label="PRODUCT GROUP*"
                  class="mt-4"
                  chips
                  multiple
                  clearable
                ></v-select>
              </v-col>

              <v-col cols="12" md="3">
                <v-autocomplete
                  v-model="product"
                  :items="products"
                  item-text="product_name_withtpl"
                  item-value="id"
                  label="EQUIPMENT *"
                  class="mt-6"
                  :error-messages="productErrors"
                  required
                  @input="$v.product.$touch()"
                  @blur="$v.product.$touch()"
                  clearable
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" md="3">
                <v-select
                  class="mt-6"
                  v-model="status"
                  :items="statusList"
                  label="STATUS"
                  clearable
                ></v-select>
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field
                  v-model="year"
                  label="Year"
                  class="mt-6"
                  clearable
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="warning" dark @click="filterEquipmentReport">
              FIND
            </v-btn>
          </v-card-actions>
        </v-card>

        <!--Filter Box-->

        <v-card class="mx-auto my-12" outlined>
          <v-card-title class="text-h5"> EQUIPMENT REPORT </v-card-title>
          <v-divider></v-divider>
          <v-card-title class="text-center">
            <v-spacer></v-spacer> {{ enquiryData.equipment }}
            <v-spacer></v-spacer>
          </v-card-title>
          <v-divider></v-divider>

          <v-card-text>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">LOB</th>
                    <th class="text-left">STATE</th>
                    <th class="text-left">CITY</th>
                    <th class="text-left">WORKSHOP NAME</th>
                    <th class="text-left">QTY</th>
                    <th class="text-left">SUPPLIER</th>
                    <th class="text-left">MANUFACTUR</th>
                    <th class="text-left">MODEL</th>
                    <th class="text-left">YEAR OF PURCHASE</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="enquiry in enquiryData.equipmentReport"
                    :key="enquiry.sno"
                  >
                    <td>{{ enquiry.brand }}</td>
                    <td>{{ enquiry.state }}</td>
                    <td>{{ enquiry.city }}</td>
                    <td>{{ enquiry.workshopName }}</td>
                    <td>{{ enquiry.qty }}</td>
                    <td>{{ enquiry.supplier }}</td>
                    <td>{{ enquiry.manufactur }}</td>
                    <td>{{ enquiry.model }}</td>
                    <td>{{ enquiry.yearofpurchase }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </template>
      <!--template end-->
    </v-container>
    <!--container end-->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  name: "EquipmentReport",
  data: () => ({
    search: "",
    product: "",
    segment: "",
    status: "",
    year: "",
    productgroup: ""
  }),
  validations: {
    product: { required }
  },
  computed: {
    ...mapGetters([
      "getEquipmentreport",
      "getEquipmentcondition",
      "productList",
      "segmentList",
      "authRole",
      "productGroup"
    ]),
    enquiryData: function() {
      return this.getEquipmentreport;
    },
    statusList: function() {
      return this.getEquipmentcondition;
    },
    products: function() {
      return this.productList;
    },
    segments: function() {
      return this.segmentList;
    },
    productgroups: function() {
      return this.productGroup;
    },
    productErrors() {
      const errors = [];
      if (!this.$v.product.$dirty) return errors;
      !this.$v.product.required && errors.push("Equipment is required.");
      return errors;
    }
  },
  watch: {},
  created() {
    const filterData = [];
    this.equipmentReport(filterData);
    this.fetchDefinedvalues();
    this.fetchProducts();
    this.fetchProductgroup();
    this.fetchSegment();
  },

  methods: {
    ...mapActions([
      "equipmentReport",
      "fetchDefinedvalues",
      "fetchProducts",
      "fetchSegment",
      "fetchProductgroup"
    ]),
    filterEquipmentReport() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("ERROR");
      } else {
        const filterData = {
          product: this.product,
          segment: this.segment,
          status: this.status,
          year: this.year,
          productgroup: this.productgroup
        };
        this.equipmentReport(filterData);
      }
    }
  }
};
</script>
