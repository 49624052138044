<template>
  <div>
    <!--container-->
    <v-container>
      <!--tob bar-->
      <bar />
      <!--tob bar end-->

      <!--template-->
      <template>
        <!--card -->
        <v-card class="form-card mx-auto my-12">
          <!--alert box-->
          <v-alert text prominent type="error" v-if="errorResponse">
            <div v-for="(error, i) in cityError" :key="i">
              {{ error }}
            </div>
          </v-alert>
          <!--alert box end-->
          <v-list-item class="headline">
            <v-list-item-content>
              <v-list-item-title> CITY ADD </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>

          <!--form-->
          <form>
            <!--input field-->
            <v-card-text>
              <v-row justify="space-between">
                <v-col cols="12" xs="12" sm="12" md="12">
                  <v-select
                    v-model="countryname"
                    :items="countries"
                    item-text="name"
                    item-value="id"
                    label="COUNTRY"
                    :error-messages="countrynameErrors"
                    required
                    @input="$v.countryname.$touch()"
                    @blur="$v.countryname.$touch()"
                    v-on:change="getState(`${countryname}`)"
                  ></v-select>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="12">
                  <v-select
                    v-model="statename"
                    :items="states"
                    item-text="name"
                    item-value="id"
                    label="STATE"
                    :error-messages="statenameErrors"
                    required
                    @input="$v.statename.$touch()"
                    @blur="$v.statename.$touch()"
                  ></v-select>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="12">
                  <span class="text-right white--text">
                    CITY NAME*
                  </span>
                  <v-text-field
                    v-model="cityname"
                    :error-messages="citynameErrors"
                    label="CITY NAME*"
                    required
                    @input="$v.cityname.$touch()"
                    @blur="$v.cityname.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <!--input field end-->
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="black" dark @click="submit"> SUBMIT </v-btn>
              <v-btn color="warning" dark to="/city"> CANCEL </v-btn>
            </v-card-actions>
          </form>
          <!--form end-->
        </v-card>
        <!--card end-->
      </template>
      <!--template end-->
    </v-container>
    <!--container end-->
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "CityAdd",
  data: () => ({
    cityname: "",
    statename: null,
    countryname: null
  }),
  validations: {
    cityname: { required },
    statename: { required },
    countryname: { required }
  },
  computed: {
    ...mapGetters([
      "countryList",
      "countryBasedstatelist",
      "cityStatus",
      "cityStatusResponse"
    ]),
    citynameErrors() {
      const errors = [];
      if (!this.$v.cityname.$dirty) return errors;
      !this.$v.cityname.required && errors.push("City Name is required.");
      return errors;
    },
    statenameErrors() {
      const errors = [];
      if (!this.$v.statename.$dirty) return errors;
      !this.$v.statename.required && errors.push("State Name is required.");
      return errors;
    },
    countrynameErrors() {
      const errors = [];
      if (!this.$v.countryname.$dirty) return errors;
      !this.$v.countryname.required && errors.push("Country is required.");
      return errors;
    },
    countries: function() {
      return this.countryList;
    },
    states: function() {
      return this.countryBasedstatelist;
    },
    errorResponse: function() {
      return this.cityStatus === "error";
    },
    cityError: function() {
      return this.cityStatusResponse;
    }
  },
  created() {
    this.fetchCountry();
  },
  methods: {
    ...mapActions(["cityAdd", "fetchCountry", "stateGetlist"]),
    getState(countryid) {
      this.stateGetlist(countryid);
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("ERROR");
      } else {
        // do your submit logic here
        setTimeout(() => {
          const cityData = {
            countryName: this.countryname,
            stateName: this.statename,
            cityName: this.cityname.toUpperCase()
          };
          this.cityAdd(cityData);
        }, 500);
      }
    }
  }
};
</script>
