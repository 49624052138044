<template>
  <div>
    <v-card class="mx-auto ml-5 my-5 mx-5 mr-5 " outlined>
      <highcharts :options="chartOptions" ref="highcharts"></highcharts>
      <!--<button @click="updateCredits">update credits</button>-->
    </v-card>
  </div>
</template>

<script>
// @ is an alias to /src

import { VUE_APP_BASEURL } from "../utils/api";
import axios from "axios";
import store from "../store";

export default {
  name: "WorkshopDataUpdationChartDash",
  props: ["selectedsegment", "selectedsalesengineer", "selectedregion"],
  data: () => ({
    chartOptions: {
      chart: {
        type: "column",
        height: 485
      },
      title: {
        text: "WORKSHOP DATA BASE UPDATION"
      },
      subtitle: {
        text: ""
      },
      xAxis: {
        categories: [],
        crosshair: true
      },
      yAxis: {
        min: 0,
        title: {
          text: ""
        }
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.1f} </b></td></tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0
        }
      },
      series: [{}],
      credits: {
        enabled: false
      }
    }
  }),
  components: {},
  computed: {},
  mounted() {},
  created() {
    this.workshopdatabaseupdationchartload(this.chartOptions);
  },
  methods: {
    workshopdatabaseupdationchartload(chartOptions) {
      const filterData = [];

      axios({
        method: "POST",
        headers: {
          Authorization: "Bearer " + store.getters.UserToken
        },
        url: VUE_APP_BASEURL + "dashboard/workshop-updation-chart",
        data: {
          segment: filterData.segment,
          salesengineer: filterData.salesengineer,
          region: filterData.region
        }
      })
        .then(function(response) {
          chartOptions.series = response.data.response.customerbarchartData;
          chartOptions.xAxis.categories =
            response.data.response.customerbarchartnames;
        })
        .catch(function(error) {
          console.log(error);
          console.log(error.response);
        });
    },

    filterworkshopDataUpdationChartDash() {
      const filterData = {
        segment: this.selectedsegment,
        salesengineer: this.selectedsalesengineer,
        region: this.selectedregion
      };
      // console.log(filterData);

      const chartOptions = this.chartOptions;
      axios({
        method: "POST",
        headers: {
          Authorization: "Bearer " + store.getters.UserToken
        },
        url: VUE_APP_BASEURL + "dashboard/workshop-updation-chart",
        data: {
          segment: filterData.segment,
          salesengineer: filterData.salesengineer,
          region: filterData.region
        }
      })
        .then(function(response) {
          //  console.log(response);
          chartOptions.series = response.data.response.customerbarchartData;
          chartOptions.xAxis.categories =
            response.data.response.customerbarchartnames;
        })
        .catch(function(error) {
          console.log(error);
        });
    }
  }
};
</script>
