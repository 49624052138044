import Vue from "vue";

import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

import Vuelidate from "vuelidate";
Vue.use(Vuelidate);

import Highcharts from "highcharts";
import highchartsMore from "highcharts/highcharts-more";
import HighchartsVue from "highcharts-vue";
import loadFunnel from "highcharts/modules/funnel";

import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);

loadFunnel(Highcharts);
//Vue.use(HighchartsVue);
highchartsMore(Highcharts);
Vue.use(HighchartsVue, { Highcharts });

Vue.config.productionTip = false;

//Loading component
import Loading from "./components/Loading";
Vue.component("loading", Loading);
//Bar component
import Bar from "./components/Bar";
Vue.component("bar", Bar);

//Customer Search component
import CustomerSearch from "./components/CustomerSearch";
Vue.component("CustomerSearch", CustomerSearch);

//Equipment Search component
import EquipmentSearch from "./components/EquipmentSearch";
Vue.component("EquipmentSearch", EquipmentSearch);

//Customer Based Equipment Search component
import CustomerBasedEquipmentSearch from "./components/CustomerBasedEquipmentSearch";
Vue.component("CustomerBasedEquipmentSearch", CustomerBasedEquipmentSearch);

//buyback Search component
import BuybackSearch from "./components/BuybackSearch";
Vue.component("BuybackSearch", BuybackSearch);

//chart Components

import YearlyEnquiryBarchart from "./components/YearlyEnquiryBarchart";
Vue.component("YearlyEnquiryBarchart", YearlyEnquiryBarchart);

import MonthlySalesPlanPieChart from "./components/MonthlySalesPlanPieChart";
Vue.component("MonthlySalesPlanPieChart", MonthlySalesPlanPieChart);

import MonthlySalesPlanBarChart from "./components/MonthlySalesPlanBarChart";
Vue.component("MonthlySalesPlanBarChart", MonthlySalesPlanBarChart);

//dashboard reports with chart

//sales Performance Report
import SalesPerformanceReport from "./components/SalesPerformanceReport";
Vue.component("SalesPerformanceReport", SalesPerformanceReport);

//Enquiry Followup Report
import EnquiryFollowupReport from "./components/EnquiryFollowupReport";
Vue.component("EnquiryFollowupReport", EnquiryFollowupReport);

//Workshop database updation Report
import WorkshopDataUpdation from "./components/WorkshopDataUpdation";
Vue.component("WorkshopDataUpdation", WorkshopDataUpdation);

//Order Loss Report Chart
import OrderlossReportChart from "./components/OrderlossReportChart";
Vue.component("OrderlossReportChart", OrderlossReportChart);

/*Dashboard Charts*/
//Sales Performance Dash Chart
import SalesPerformanceDash from "./components/SalesPerformanceDash";
Vue.component("SalesPerformanceDash", SalesPerformanceDash);

//Enquiry Followup Dash Chart
import EnquiryFollowupPieChartDash from "./components/EnquiryFollowupPieChartDash";
Vue.component("EnquiryFollowupPieChartDash", EnquiryFollowupPieChartDash);

//Enquiry Followup Dash Funnel Chart
import EnquiryFollowupFunnelChartDash from "./components/EnquiryFollowupFunnelChartDash";
Vue.component("EnquiryFollowupFunnelChartDash", EnquiryFollowupFunnelChartDash);

//workshop database updation Dash Chart
import WorkshopDataUpdationChartDash from "./components/WorkshopDataUpdationChartDash";
Vue.component("WorkshopDataUpdationChartDash", WorkshopDataUpdationChartDash);

//workshop database updation Dash Chart
import OrderlossChartDash from "./components/OrderlossChartDash";
Vue.component("OrderlossChartDash", OrderlossChartDash);

//Customer Based Enquiry Details and value.
import CustomerEnquiry from "./components/CustomerEnquiry";
Vue.component("CustomerEnquiry", CustomerEnquiry);

import CustomerEnquiryValue from "./components/CustomerEnquiryValue";
Vue.component("CustomerEnquiryValue", CustomerEnquiryValue);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
