<template>
  <div>
    <v-card class="mx-auto ml-5 my-5 mx-5 mr-5" outlined>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr class="orange darken-1">
              <th colspan="2" class="text-center">
                <span class="white--text text-h6"
                  >SALES PERFORMANCE REPORT</span
                >
              </th>
            </tr>
            <tr>
              <th class="text-left">ENGINEER</th>

              <th class="text-left">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left" style="width: 125px;">
                          PARAMETER
                        </th>
                        <th
                          class="text-left"
                          v-for="(pro, k) in salesPerformance.reportDates"
                          :key="k"
                          style="width: 125px;"
                        >
                          {{ pro }}
                        </th>
                      </tr>
                    </thead>
                  </template>
                </v-simple-table>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(record, m) in salesPerformance.records" :key="m">
              <td>{{ record.name }}</td>
              <td>
                <v-simple-table>
                  <template v-slot:default>
                    <tbody>
                      <tr>
                        <td style="width: 125px;">SALES PLAN</td>
                        <td
                          v-for="(salesplan, sp) in record.salesPlan"
                          :key="sp"
                          style="width: 125px;"
                        >
                          {{ salesplan }}
                        </td>
                      </tr>
                      <tr>
                        <td style="width: 125px;">SALES BILLED</td>
                        <td
                          v-for="(salesbilled, sb) in record.salesBilled"
                          :key="sb"
                          style="width: 125px;"
                        >
                          {{ salesbilled }}
                        </td>
                      </tr>
                      <tr>
                        <td style="width: 125px;">SALES LOST</td>
                        <td
                          v-for="(saleslost, sl) in record.salesLost"
                          :key="sl"
                          style="width: 125px;"
                        >
                          {{ saleslost }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </div>
</template>

<script>
// @ is an alias to /src

import { mapGetters, mapActions } from "vuex";

export default {
  name: "SalesPerformanceReport",
  props: [
    "selectedsegment",
    "selectedsalesengineer",
    "selectedregion",
    "selectedproductgroup"
  ],
  data: () => ({}),
  components: {},
  computed: {
    ...mapGetters(["getSalesperformancereportdata"]),
    salesPerformance: function() {
      //  console.log(this.getSalesperformancereportdata);
      return this.getSalesperformancereportdata;
    }
  },
  mounted() {},
  created() {
    const filterData = [];
    this.salesPerformancereport(filterData);
  },
  methods: {
    ...mapActions(["salesPerformancereport"]),

    filtersalesPerformancereport() {
      const filterData = {
        segment: this.selectedsegment,
        salesengineer: this.selectedsalesengineer,
        region: this.selectedregion,
        productgroup: this.selectedproductgroup
      };
      // console.log('PerformanceReport');
      this.salesPerformancereport(filterData);
    }
  }
};
</script>
