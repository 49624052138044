<template>
  <div>
    <!--container-->
    <v-container>
      <!--tob bar-->
      <bar />
      <!--tob bar end-->

      <v-card class="mx-auto mb-5" outlined>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="2" v-if="userRole != 'sales-engineer'">
              <!--<v-select
                v-model="salesengineer"
                :items="salesengineers"
                label="SALES ENGINEER *"
                item-text="name"
                item-value="id"
                class="mt-6"
              ></v-select>-->

              <v-autocomplete
                v-model="salesengineer"
                :items="salesengineers"
                label="SALES ENGINEER *"
                item-text="name"
                item-value="id"
                class="mt-6"
                clearable
              >
              </v-autocomplete>
            </v-col>

            <v-col cols="12" md="2">
              <v-select
                v-model="daterange"
                :items="dateRanges"
                label="DATE RANGE"
                class="mt-6"
                clearable
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" dark @click="filterOrderPlan()">
            FIND
          </v-btn>
        </v-card-actions>
      </v-card>

      <!--template-->
      <template>
        <!--alert box-->
        <v-alert
          text
          dense
          color="teal"
          icon="mdi-clock-fast"
          border="left"
          v-if="parfStatusmsg"
        >
          <div v-for="(resp, i) in parfResponse" :key="i">
            {{ resp }}
          </div>
        </v-alert>
        <!--alert box end-->

        <!---datatable -->
        <v-data-table
          :headers="headers"
          :items="orderplanList.tspOrderPlan"
          :search="search"
          sort-by="temporary_no"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>TSP ORDER PLAN</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
              <v-divider class="mx-4" inset vertical></v-divider>

              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
        </v-data-table>
        <!---datatable end-->
      </template>
      <!--template end-->
    </v-container>
    <!--container end-->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "TspOrderPlan",
  data: () => ({
    search: "",
    daterange: "",
    salesengineer: "",
    headers: [
      {
        text: "PRODUCT NAME",
        align: "start",
        sortable: true,
        value: "productname"
      },
      { text: "TPL NO", value: "producttplno" },
      { text: "QTY", value: "qty" },
      { text: "SALES QTY", value: "salesQty" },
      { text: "TSP QTY", value: "tspQty" },
      { text: "PRICE", value: "price" },
      { text: "TOTAL PRICE", value: "totalPrice" }
    ]
  }),

  computed: {
    ...mapGetters([
      "getOrderplanList",
      "parfStatus",
      "parfStatusResponse",
      "getDateRange",
      "authRole",
      "getSalesengineerlist"
    ]),
    orderplanList: function() {
      return this.getOrderplanList;
    },
    parfStatusmsg: function() {
      return this.parfStatus === "success";
    },
    parfResponse: function() {
      return this.parfStatusResponse;
    },
    userRole: function() {
      return this.authRole;
    },
    dateRanges: function() {
      return this.getDateRange;
    },
    salesengineers: function() {
      return this.getSalesengineerlist;
    }
  },

  watch: {},

  created() {
    this.fetchDefinedvalues();

    const filterData = {
      salesengineer: "",
      daterange: ""
    };

    this.fetchOrderPlanlist(filterData);

    const salesEngineerData = "";

    this.fetchSalesengineers(salesEngineerData);
  },

  methods: {
    ...mapActions([
      "fetchOrderPlanlist",
      "fetchDefinedvalues",
      "fetchSalesengineers"
    ]),

    filterOrderPlan() {
      const filterData = {
        salesengineer: !this.salesengineer ? "" : this.salesengineer,
        daterange: !this.daterange ? "" : this.daterange
      };
      console.log(filterData);
      this.fetchOrderPlanlist(filterData);
    }
  }
};
</script>
