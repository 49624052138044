import { VUE_APP_BASEURL } from "../../utils/api";
import router from "../../router";
import axios from "axios";
import store from "../../store";

const state = {
  enquiries: [],
  enquiryPaginate: 0,
  referenceno: "",
  enquiryResponse: [],
  status: "",
  enquiryId: "",
  customerId: "",
  customerDetails: "",
  EnquiryDate: "",
  isIgst: "",
  freightCharge: "",
  taxAmount: "",
  totalAmount: "",
  totalAmountWithTax: "",
  remarks: "",
  workshopType: "",
  salesType: "",
  enquiryDetails: [],
  workshopName: "",
  address1: "",
  address2: "",
  segment: "",
  brand: "",
  mobileno: "",
  emailid: "",
  terms: []
};

const getters = {
  enquiryList: state => state.enquiries,
  enquiryListPaginate: state => state.enquiryPaginate,
  referencenoDetails: state => state.referenceno,
  enquiryStatusResponse: state => state.enquiryResponse,
  enquiryStatus: state => state.status,
  getenquiryId: state => state.enquiryId,
  enquirycustomerDetails: state => state.customerDetails,
  enquirycustomerId: state => state.customerId,
  enquiryEnquiryDate: state => state.EnquiryDate,
  enquiryIgst: state => state.isIgst,
  enquiryfreightCharge: state => state.freightCharge,
  enquirytaxAmount: state => state.taxAmount,
  enquirytotalAmount: state => state.totalAmount,
  enquirytotalAmountWithTax: state => state.totalAmountWithTax,
  enquiryRemarks: state => state.remarks,
  enquiryworkshopNameDetails: state => state.workshopName,
  enquiryaddress1Details: state => state.address1,
  enquiryaddress2Details: state => state.address2,
  enquiryproductDetails: state => state.enquiryDetails,
  enquiryworkshopType: state => state.workshopType,
  enquirysalesType: state => state.salesType,

  enquirySegment: state => state.segment,
  enquiryBrand: state => state.brand,
  enquiryMobileno: state => state.mobileno,
  enquiryEmailid: state => state.emailid,
  getTerms: state => state.terms
};

const actions = {
  //State fetch
  async fetchEnquiries({ commit }, filterData) {
    commit("authRequest");
    axios({
      method: "GET",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url:
        VUE_APP_BASEURL +
        "enquiry?page=" +
        filterData.page +
        "&workshopName=" +
        filterData.workshopName
    })
      .then(function(response) {
        //   console.log(response);
        if (response.data.result == 1) {
          //     console.log(response.data.response);
          commit("authSuccess");
          commit("setenquiryList", response.data.response);
        }
      })
      .catch(function(error) {
        console.log(error.response);
        if (error.response) {
          // client received an error response (5xx, 4xx)
          console.log(error.response);
          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("enquiryError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  },

  //referenceno fetch
  async fetchReferenceno({ commit }) {
    commit("authRequest");
    axios({
      method: "GET",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "referenceno"
    })
      .then(function(response) {
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("setreferenceNo", response.data.response);
        }
      })
      .catch(function(error) {
        console.log(error);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("enquiryError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  },
  //new Enquiry add action
  async enquiryAdd({ commit }, enquiryData) {
    commit("authRequest");
    //console.log(enquiryData.customerDetails);
    axios({
      method: "POST",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "enquiry",
      data: {
        customerId: enquiryData.customerId,
        customerDetails: enquiryData.customerDetails,
        EnquiryDate: enquiryData.EnquiryDate,
        isIgst: enquiryData.isIgst,
        freightCharge: enquiryData.freightCharge,
        taxAmount: enquiryData.taxAmount,
        totalAmount: enquiryData.totalAmount,
        totalAmountWithTax: enquiryData.totalAmountWithTax,
        remarks: enquiryData.remarks,
        enquiryDetails: enquiryData.enquiryDetails,
        salesType: enquiryData.salesType,
        workshopType: enquiryData.workshopType
      }
    })
      .then(function(response) {
        //  console.log(response);
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("enquiryaddMethod", response.data.response);
          router.push("/enquiry/edit/" + response.data.response.id);
        }
      })
      .catch(function(error) {
        console.log(error.response);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("enquiryError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  },

  //fetch Enquiry details
  async fetchEnquirydetails({ commit }, enquiryId) {
    commit("authRequest");

    axios({
      method: "GET",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "enquiry/" + enquiryId
    })
      .then(function(response) {
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("enquiryfetchDetails", response.data.response);
        }
      })
      .catch(function(error) {
        console.log(error);
        if (error.response) {
          // client received an error response (5xx, 4xx)
          console.log(error.response);
          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("enquiryError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  },

  //new Enquiry Update action
  async enquiryUpdate({ commit }, enquiryData) {
    commit("authRequest");
    //   console.log(enquiryData);
    axios({
      method: "PUT",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "enquiry/" + enquiryData.enquiryId,
      data: {
        customerId: enquiryData.customerId,
        customerDetails: enquiryData.customerDetails,
        EnquiryDate: enquiryData.EnquiryDate,
        isIgst: enquiryData.isIgst,
        freightCharge: enquiryData.freightCharge,
        taxAmount: enquiryData.taxAmount,
        totalAmount: enquiryData.totalAmount,
        totalAmountWithTax: enquiryData.totalAmountWithTax,
        remarks: enquiryData.remarks,
        enquiryDetails: enquiryData.enquiryDetails,
        salesType: enquiryData.salesType,
        workshopType: enquiryData.workshopType,
        terms: enquiryData.terms
      }
    })
      .then(function(response) {
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("enquiryupdateMethod", response.data.response);
          // router.push("/enquiry");
        }
      })
      .catch(function(error) {
        console.log(error);
        if (error.response) {
          // client received an error response (5xx, 4xx)
          console.log(error.response);
          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("enquiryError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          // commit("authLogout");
          //  router.push("/");
          console.log(error.request);
          console.log(error.response);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  },

  //Enquiry details delete action
  async enquirydetailsDelete({ commit, dispatch }, deleteData) {
    commit("authRequest");
    //      console.log(deleteData.deleteId);

    axios({
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "enquiry-details/" + deleteData.deleteId
    })
      .then(function(response) {
        console.log(response);
        if (response.data.result == 1) {
          console.log(deleteData.enquiryId);
          commit("authSuccess");
          commit("enquirydeleteMethod", response.data.response);
          // router.push("/enquiry/edit/"+deleteData.enquiryId);
          dispatch("fetchEnquirydetails", deleteData.enquiryId);
        }
      })
      .catch(function(error) {
        console.log(error);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("enquiryError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  }
};

const mutations = {
  setenquiryList: (state, resp) => {
    state.status = "";
    //console.log(resp);
    state.enquiries = resp.enquiry_followuplist;
    state.enquiryPaginate = resp.enquiryPaginate.last_page;
  },
  setreferenceNo: (state, resp) => {
    state.referenceno = resp;
  },
  enquiryaddMethod: (state, resp) => {
    state.status = "success";
    state.enquiryResponse = resp.message;
  },
  enquirydeleteMethod: (state, resp) => {
    state.status = "success";
    state.enquiryResponse = resp;
  },
  enquiryupdateMethod: (state, resp) => {
    state.status = "success";
    state.enquiryResponse = resp;
  },
  enquiryError: (state, resp) => {
    state.status = "error";
    state.enquiryResponse = resp;
  },
  enquiryfetchDetails: (state, resp) => {
    state.status = "";
    state.enquiryId = resp.id;
    state.referenceno = resp.referenceNo;
    state.customerId = resp.customerId;
    state.customerDetails = resp.customerDetails;
    state.EnquiryDate = resp.EnquiryDate;
    state.enquiryDetails = resp.productList;
    state.isIgst = resp.isIgst;
    state.freightCharge = resp.freightCharge;
    state.taxAmount = resp.taxAmount;
    state.totalAmount = resp.totalAmount;
    state.totalAmountWithTax = resp.totalAmountWithTax;
    state.remarks = resp.remarks;
    state.workshopName = resp.customer.workshop_name;
    state.address1 = resp.customer.address1;
    state.address2 = resp.customer.address2;
    state.salesType = resp.salesType;
    state.workshopType = resp.workshopType;

    state.segment = resp.segment;
    state.brand = resp.brand;
    state.mobileno = resp.customer.contactnumber;
    state.emailid = resp.customer.emailid;
    state.terms = resp.terms;
  },
  updateCustomerdetails: (state, customerDetail) => {
    state.customerDetails = customerDetail;
  },
  updateEnquiryDate: (state, EnquiryDate) => {
    state.EnquiryDate = EnquiryDate;
  },
  updateIgst: (state, Igst) => {
    state.isIgst = Igst;
  },
  updateFreightcharge: (state, freightCharge) => {
    state.freightCharge = freightCharge;
  },
  updateTaxamount: (state, taxAmount) => {
    state.taxAmount = taxAmount;
  },
  updateTotalprice: (state, totalPrice) => {
    state.totalAmount = totalPrice;
  },
  updatetotalAmountWithTax: (state, totalAmountWithTax) => {
    state.totalAmountWithTax = totalAmountWithTax;
  },
  updateRemarks: (state, remarks) => {
    state.remarks = remarks;
  },
  updateworkshopType: (state, workshopType) => {
    state.workshopType = workshopType;
  },
  updatesalesType: (state, salesType) => {
    state.salesType = salesType;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
