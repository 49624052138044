<template>
  <div>
    <!--container-->
    <v-container>
      <!--tob bar-->
      <bar />
      <!--tob bar end-->

      <!--template-->
      <template>
        <!--card -->
        <v-card class="form-card mx-auto my-12" outlined>
          <!--alert box-->
          <v-alert text prominent type="error" v-if="errorResponse">
            <div v-for="(error, i) in menuError" :key="i">
              {{ error }}
            </div>
          </v-alert>
          <!--alert box end-->
          <v-list-item class="headline">
            <v-list-item-content>
              <v-list-item-title class="orange--text text--darken-2">
                MENU ADD
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>

          <!--form-->
          <form>
            <!--input field-->
            <v-card-text>
              <v-row justify="space-between">
                <v-col cols md="4">
                  <v-text-field v-model="menuname" :error-messages="menunameErrors" label="MENU NAME*" required
                    @input="$v.menuname.$touch()" @blur="$v.menuname.$touch()"></v-text-field>
                </v-col>

                <v-col cols md="4">
                  <v-text-field v-model="menuurl" :error-messages="menuurlErrors" label="MENU URL*" required
                    @input="$v.menuurl.$touch()" @blur="$v.menuurl.$touch()"></v-text-field>
                </v-col>

                <v-col cols md="4">
                  <v-text-field v-model="menuicon" label="MENU ICON*"></v-text-field>
                </v-col>

                <v-col cols md="6">
                  <v-text-field v-model="menuorder" :error-messages="menuorderErrors" label="MENU ORDER*" required
                    @input="$v.menuorder.$touch()" @blur="$v.menuorder.$touch()"></v-text-field>
                </v-col>

                <v-col cols md="6">
                  <v-select :items="activeList" v-model="menuactive" :error-messages="menuactiveErrors"
                    label="MENU ACTIVE*" required @input="$v.menuactive.$touch()"
                    @blur="$v.menuactive.$touch()"></v-select>
                </v-col>

                <v-col cols md="6">
                  <v-select :items="ismainmenuList" v-model="ismainmenu" :error-messages="ismainmenuErrors"
                    label="IS MAIN MENU*" required @input="$v.ismainmenu.$touch()"
                    @blur="$v.ismainmenu.$touch()"></v-select>
                </v-col>

                <v-col cols md="6">
                  <v-select :items="mainmenuList" item-text="name" item-value="id" v-model="mainmenuid"
                    label="MAIN MENU ID*"></v-select>
                </v-col>
              </v-row>
            </v-card-text>
            <!--input field end-->
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" dark @click="submit"> SUBMIT </v-btn>
              <v-btn color="secondary" dark to="/menu"> CANCEL </v-btn>
            </v-card-actions>
          </form>
          <!--form end-->
        </v-card>
        <!--card end-->
      </template>
      <!--template end-->
    </v-container>
    <!--container end-->
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "MenuUpdate",
  data: () => ({
    activeList: ["YES", "NO"],
    ismainmenuList: ["YES", "NO"]
  }),
  validations: {
    menuname: { required },
    menuurl: { required },
    menuorder: { required },
    menuactive: { required },
    ismainmenu: { required }
  },
  computed: {
    ...mapGetters([
      "menuStatus",
      "menuStatusResponse",
      "getMainmenus",
      "getMenuname",
      "getMenuurl",
      "getMenuorder",
      "getMenuactive",
      "getIsmainmenu",
      "getMainmenuid",
      "getMenuicon"
    ]),
    mainmenuList: function () {
      return this.getMainmenus;
    },
    menuname: {
      get() {
        return this.getMenuname;
      },
      set(value) {
        this.$store.commit("updateMenuname", value);
      }
    },
    menuurl: {
      get() {
        return this.getMenuurl;
      },
      set(value) {
        this.$store.commit("updateMenuurl", value);
      }
    },
    menuicon: {
      get() {
        return this.getMenuicon;
      },
      set(value) {
        this.$store.commit("updateMenuicon", value);
      }
    },
    menuorder: {
      get() {
        return this.getMenuorder;
      },
      set(value) {
        this.$store.commit("updateMenuorder", value);
      }
    },
    menuactive: {
      get() {
        return this.getMenuactive;
      },
      set(value) {
        this.$store.commit("updateMenuactive", value);
      }
    },
    ismainmenu: {
      get() {
        return this.getIsmainmenu;
      },
      set(value) {
        this.$store.commit("updateIsmainmenu", value);
      }
    },
    mainmenuid: {
      get() {
        return this.getMainmenuid;
      },
      set(value) {
        this.$store.commit("updateMainmenuid", value);
      }
    },
    menunameErrors() {
      const errors = [];
      if (!this.$v.menuname.$dirty) return errors;
      !this.$v.menuname.required && errors.push("Menu Name is required.");
      return errors;
    },
    menuurlErrors() {
      const errors = [];
      if (!this.$v.menuurl.$dirty) return errors;
      !this.$v.menuurl.required && errors.push("Menu Url is required.");
      return errors;
    },
    menuorderErrors() {
      const errors = [];
      if (!this.$v.menuorder.$dirty) return errors;
      !this.$v.menuorder.required && errors.push("Menu Order is required.");
      return errors;
    },
    menuactiveErrors() {
      const errors = [];
      if (!this.$v.menuactive.$dirty) return errors;
      !this.$v.menuactive.required && errors.push("Menu Active is required.");
      return errors;
    },
    ismainmenuErrors() {
      const errors = [];
      if (!this.$v.ismainmenu.$dirty) return errors;
      !this.$v.ismainmenu.required && errors.push("Menu Active is required.");
      return errors;
    },

    errorResponse: function () {
      return this.menuStatus === "error";
    },
    menuError: function () {
      return this.menuStatusResponse;
    }
  },
  created() {
    this.fetchMainmenus();
    this.fetchMenudetails(this.$route.params.id);
  },
  methods: {
    ...mapActions(["menuUpdate", "fetchMainmenus", "fetchMenudetails"]),
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("ERROR");
      } else {
        // do your submit logic here
        setTimeout(() => {
          const menuData = {
            menuId: this.$route.params.id,
            menuName: this.menuname.toUpperCase(),
            menuUrl: this.menuurl,
            menuIcon: this.menuicon,
            menuOrder: this.menuorder,
            menuActive: this.menuactive,
            isMainmenu: this.ismainmenu,
            mainMenuid: this.mainmenuid
          };
          console.log(menuData);
          this.menuUpdate(menuData);
        }, 500);
      }
    }
  }
};
</script>
