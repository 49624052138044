import { VUE_APP_BASEURL } from "../../utils/api";
import router from "../../router";
import axios from "axios";
import store from "../../store";

const state = {
  status: "",
  tspResponse: [],
  tspplanList: [],
  tspplandetailList: [],
  orderplanList: []
};

const getters = {
  getTspplanList: state => state.tspplanList,
  getTspplandetailList: state => state.tspplandetailList,
  getOrderplanList: state => state.orderplanList
};

const actions = {
  //tspplan list fetch
  async fetchTspPlanlist({ commit }) {
    commit("authRequest");
    axios({
      method: "GET",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "enquiry-tsp-plan-list"
    })
      .then(function(response) {
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("settspplanList", response.data.response);
        }
      })
      .catch(function(error) {
        console.log(error);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("tspError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  },

  //tspplan list fetch
  async fetchOrderPlanlist({ commit }, filterData) {
    commit("authRequest");
    axios({
      method: "GET",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url:
        VUE_APP_BASEURL +
        "enquiry-tsp-order-plan?salesengineer=" +
        filterData.salesengineer +
        "&daterange=" +
        filterData.daterange
    })
      .then(function(response) {
        console.log(response);

        if (response.data.result == 1) {
          commit("authSuccess");
          commit("setorderplanList", response.data.response);
        }
      })
      .catch(function(error) {
        console.log(error.response);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            //  commit("authLogout");
            //  router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("tspError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          //   commit("authLogout");
          //   router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          // commit("authLogout");
          // router.push("/");
          console.log(error);
        }
      });
  },

  //tspplan list fetch
  async fetchTspPlandetails({ commit }, enquiryId) {
    commit("authRequest");
    axios({
      method: "GET",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "enquiry-tsp-plan-details/" + enquiryId
    })
      .then(function(response) {
        //  console.log(response);
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("settspplandetailList", response.data.response);
        }
      })
      .catch(function(error) {
        console.log(error);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("tspError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  },

  //new PFM status update action
  async enquiryPfmstatusupdate({ commit }, enquiryData) {
    commit("authRequest");
    axios({
      method: "POST",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "enquiry-tsp-plan-pfm-confirm",
      data: {
        enquiryDetailid: enquiryData.enquiryDetailid,
        enquiryPfmdate: enquiryData.enquiryPfmdate,
        enquiryPfmprice: enquiryData.enquiryPfmprice,
        enquiryPfmqty: enquiryData.enquiryPfmqty,
        enquiryPfmstatus: enquiryData.enquiryPfmstatus,
        enquiryTspstatus: enquiryData.enquiryTspstatus
      }
    })
      .then(function(response) {
        console.log(response);
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("enquiryPfmconvert", response.data.response);
          // router.push("/enquiry-tsp-plan-list");

          location.reload();
        }
      })
      .catch(function(error) {
        console.log(error.response);
        /* if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("followuplistError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }*/
      });
  }
};

const mutations = {
  settspplanList: (state, resp) => {
    state.tspplanList = resp;
  },
  setorderplanList: (state, resp) => {
    state.orderplanList = resp;
  },
  settspplandetailList: (state, resp) => {
    state.tspplandetailList = resp;
  },
  tspError: (state, resp) => {
    state.status = "error";
    state.tspResponse = resp;
  },
  enquiryPfmconvert: (state, resp) => {
    state.status = "success";
    state.tspResponse = resp;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
