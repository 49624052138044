<template>
  <div>
    <!--container-->
    <v-container>
      <!--tob bar-->
      <bar />
      <!--tob bar end-->

      <!--template-->
      <template>
        <!--alert box-->
        <v-alert
          text
          dense
          color="teal"
          icon="mdi-clock-fast"
          border="left"
          v-if="enquiryStatusmsg"
        >
          <div v-for="(resp, i) in enquiryResponse" :key="i">
            {{ resp }}
          </div>
        </v-alert>
        <!--alert box end-->

        <!---datatable -->
        <!--<v-data-table
          :headers="headers"
          :items="enquiries"
          :search="search"
          sort-by="temporary_no"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Enquiry</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
              <v-divider class="mx-4" inset vertical></v-divider>

              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
              <v-icon small class="mr-2" @click="enquiryEdit(item)">
              mdi-pencil
            </v-icon>
          </template>
        </v-data-table>-->
        <!---datatable end-->

        <!--Simple table start-->
        <v-card class="mx-auto" outlined>
          <v-card-title>
            ENQUIRY
            <v-divider vertical class="ml-5"></v-divider>
            <v-text-field
              label="WORKSHOP NAME"
              prepend-inner-icon="mdi-magnify"
              v-model="workshopName"
              @change="filterEnquiry(1)"
              class="ml-5"
            ></v-text-field>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">ACTIONS</th>
                  <th class="text-left">WORKSHOP NAME</th>
                  <th class="text-left">CUSTOMER DETAILS</th>
                  <th class="text-left">REFRERENCE NO</th>
                  <th class="text-left">SEGMENT</th>
                  <th class="text-left">LOB</th>
                  <th class="text-left">ENGINEER NAME</th>
                  <th class="text-left">CITY</th>
                  <th class="text-left">STATE</th>
                  <th class="text-left">ENQUIRY DATE</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="enquiry in enquiries" :key="enquiry.id">
                  <td>
                    <v-icon small class="mr-2" @click="enquiryEdit(enquiry)">
                      mdi-pencil
                    </v-icon>
                  </td>
                  <td>{{ enquiry.workshop_name }}</td>
                  <td>{{ enquiry.customer_details }}</td>
                  <td>{{ enquiry.referenceNo }}</td>
                  <td>{{ enquiry.segment }}</td>
                  <td>{{ enquiry.brand }}</td>
                  <td>{{ enquiry.salesengineer }}</td>
                  <td>{{ enquiry.city }}</td>
                  <td>{{ enquiry.state }}</td>
                  <td>{{ enquiry.enquiry_date }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
        <!--Simple Table end-->
        <!--pagination-->
        <div class="text-center mt-5">
          <v-pagination
            v-model="page"
            :length="enquiryPagination"
            :total-visible="7"
            @input="paginationNextPage"
          ></v-pagination>
        </div>
        <!--pagination end-->
      </template>
      <!--template end-->
    </v-container>
    <!--container end-->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import router from "../../router";

export default {
  name: "Enquiry",
  data: () => ({
    search: "",
    workshopName: "",
    page: 1,
    selectedPage: 1
    /*  headers: [
       { text: "Actions", value: "actions", sortable: false },
      { text: "WORKSHOP NAME", value: "workshop_name" },
      {
        text: "CUSTOMER DETAILS",
        align: "start",
        sortable: true,
        value: "customer_details",
      },
      { text: "REFRERENCE NO", value: "referenceNo" },
       { text: "SEGMENT", value: "segment" },
      { text: "LOB", value: "brand" },
      { text: "ENGINEER NAME", value: "salesengineer" },
      { text: "CITY", value: "city" },
      { text: "STATE", value: "state" },
      { text: "ENQUIRY DATE", value: "enquiry_date" },
     
    ],*/
  }),

  computed: {
    ...mapGetters([
      "enquiryList",
      "enquiryStatus",
      "enquiryStatusResponse",
      "enquiryListPaginate"
    ]),
    enquiries: function() {
      //console.log(this.enquiryList);
      return this.enquiryList;
    },
    enquiryPagination: function() {
      return this.enquiryListPaginate;
    },
    enquiryStatusmsg: function() {
      return this.enquiryStatus === "success";
    },
    enquiryResponse: function() {
      return this.enquiryStatusResponse;
    }
  },

  watch: {},

  created() {
    const filterData = {
      page: "",
      workshopName: ""
    };
    this.fetchEnquiries(filterData);
  },

  methods: {
    ...mapActions(["fetchEnquiries"]),

    enquiryEdit(item) {
      const id = item.id;
      router.push({ path: `/enquiry/edit/${id}` });
    },

    paginationNextPage(page) {
      //this.selectedPage = page;
      this.filterEnquiry(page);
    },
    filterEnquiry(page) {
      const filterData = {
        page: page,
        workshopName: !this.workshopName
          ? this.workshopName
          : this.workshopName.toUpperCase()
      };
      console.log(filterData);
      this.fetchEnquiries(filterData);
    }
  }
};
</script>
