import { VUE_APP_BASEURL } from "../../utils/api";
import router from "../../router";
import axios from "axios";
import store from "../../store";

const state = {
  users: [],
  userResponse: [],
  status: "",
  username: "",
  employeecode: "",
  email: "",
  phonenumber: "",
  address: "",
  region: "",
  active: "",
  role: "",
  segment: [],
  salesengineer: [],
  salesengineerList: []
};

const getters = {
  userList: state => state.users,
  userStatusResponse: state => state.userResponse,
  userStatus: state => state.status,
  getUsername: state => state.username,
  getEmployeecode: state => state.employeecode,
  getEmail: state => state.email,
  getPhonenumber: state => state.phonenumber,
  getAddress: state => state.address,
  getRegion: state => state.region,
  getUseractive: state => state.active,
  getSegment: state => state.segment,
  getSalesengineer: state => state.salesengineer,
  getSalesengineerlist: state => state.salesengineerList,
  getRole: state => state.role
};

const actions = {
  //user fetch
  async fetchUsers({ commit }) {
    commit("authRequest");
    axios({
      method: "GET",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "user"
    })
      .then(function (response) {
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("setuserList", response.data.response);
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("stateError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  },

  //new user add action
  async userAdd({ commit }, userData) {
    commit("authRequest");
    axios({
      method: "POST",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "user",
      data: {
        userName: userData.userName,
        employeeCode: userData.employeeCode,
        password: userData.password,
        email: userData.email,
        phoneNumber: userData.phoneNumber,
        address: userData.address,
        region: userData.region,
        role: userData.role,
        segment: userData.segment,
        salesEngineer: userData.salesEngineer
      }
    })
      .then(function (response) {
        // console.log(response);
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("useraddMethod", response.data.response);
          router.push("/user");
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response) {
          // client received an error response (5xx, 4xx)
          console.log(error.response);
          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("userError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  },
  //new user add action end
  //fetch User details
  async fetchUserdetails({ commit }, userId) {
    commit("authRequest");
    axios({
      method: "GET",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "user/" + userId
    })
      .then(function (response) {
        //   console.log(response);
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("userDetails", response.data.response);
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("userError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  },
  //new user update action
  async userUpdate({ commit }, userData) {
    commit("authRequest");
    axios({
      method: "PUT",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "user/" + userData.userId,
      data: {
        userName: userData.userName,
        employeeCode: userData.employeeCode,
        password: userData.password,
        email: userData.email,
        phoneNumber: userData.phoneNumber,
        address: userData.address,
        region: userData.region,
        role: userData.role,
        segment: userData.segment,
        salesEngineer: userData.salesEngineer,
        active: userData.active
      }
    })
      .then(function (response) {
        console.log(response);
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("userupdateMethod", response.data.response);
          router.push("/user");
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("userError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  },
  //new user update action end

  //sales engineer fetch
  async fetchSalesengineers({ commit }, segment) {
    commit("authRequest");
    //console.log(segment);
    axios({
      method: "POST",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "user/getSegmentbaseduserlist",
      data: {
        segment: segment
      }
    })
      .then(function (response) {
        //    console.log(response);

        if (response.data.result == 1) {
          commit("authSuccess");
          commit("setSalesengineerList", response.data.response);
        }
      })
      .catch(function (error) {
        console.log(error.response);
        if (error.response) {
          // client received an error response (5xx, 4xx)
          console.log(error.response);
          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("userError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  }
};

const mutations = {
  setuserList: (state, resp) => {
    state.users = resp;
  },
  setSalesengineerList: (state, resp) => {
    state.salesengineerList = resp;
  },
  useraddMethod: (state, resp) => {
    state.status = "success";
    state.userResponse = resp;
  },
  userupdateMethod: (state, resp) => {
    state.status = "success";
    state.userResponse = resp;
  },
  userDetails: (state, resp) => {
    state.status = "";
    state.username = resp.userName;
    state.employeecode = resp.employeeCode;
    state.email = resp.email;
    state.phonenumber = resp.phoneNumber;
    state.address = resp.address;
    state.region = resp.region;
    state.role = resp.role;
    state.active = resp.active;
    state.segment = resp.segment;
    state.salesengineer = resp.salesEngineer;
  },
  userError: (state, resp) => {
    state.status = "error";
    state.userResponse = resp;
    console.log(resp);
  },
  updateUsername: (state, username) => {
    state.username = username;
  },
  updateEmployeecode: (state, employeecode) => {
    state.employeecode = employeecode;
  },
  updateEmail: (state, email) => {
    state.email = email;
  },
  updatePhonenumber: (state, phonenumber) => {
    state.phonenumber = phonenumber;
  },
  updateAddress: (state, address) => {
    state.address = address;
  },
  updateRegion: (state, region) => {
    state.region = region;
  },
  updateRole: (state, role) => {
    state.role = role;
  },
  updateSegment: (state, segment) => {
    state.segment = segment;
  },
  updateSalesengineer: (state, salesengineer) => {
    state.salesengineer = salesengineer;
  },
  updateUseractive: (state, active) => {
    state.active = active;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
