<template>
  <div>
    <!--container-->
    <v-container>
      <!--tob bar-->
      <bar />
      <!--tob bar end-->

      <!--template-->
      <template>
        <!--alert box-->
        <v-alert
          text
          dense
          color="teal"
          icon="mdi-clock-fast"
          border="left"
          v-if="monthlysalesStatusmsg"
        >
          <div v-for="(resp, i) in monthlysalesResponse" :key="i">
            {{ resp }}
          </div>
        </v-alert>
        <!--alert box end-->

        <!---datatable -->
        <v-data-table
          :headers="headers"
          :items="monthlySalesplanList"
          :search="search"
          sort-by="pfmdate"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>MONTHLY SALES PLAN</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
              <v-divider class="mx-4" inset vertical></v-divider>

              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-select
              v-if="
                item.status === 'CONFIRM' &&
                  item.tsp_status === 'CONFIRM' &&
                  item.pfmqty === 1
              "
              v-model="item.pfmFinalstatus"
              :items="pfmwithoutPartialbill"
              label="STATUS*"
            >
            </v-select>

            <v-select
              v-else-if="
                item.status === 'CONFIRM' &&
                  item.tsp_status === 'CONFIRM' &&
                  item.pfmqty > 1
              "
              v-model="item.pfmFinalstatus"
              :items="pfmList"
              label="STATUS*"
            >
            </v-select>

            <!--<v-text-field
                v-model="item.status"
                v-else
                readonly
              ></v-text-field>-->

            <div v-else>{{ item.status }}</div>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              color="black"
              dark
              @click="enquiryConfirm(item)"
              v-if="item.status === 'CONFIRM' && item.tsp_status === 'CONFIRM'"
            >
              SUBMIT
            </v-btn>
          </template>
        </v-data-table>
        <!---datatable end-->
      </template>
      <!--template end-->
    </v-container>
    <!--container end-->

    <!--lost dialog-->
    <v-row justify="center">
      <v-dialog v-model="lostDialog" persistent max-width="800px">
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ lostProductname }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="MANUFACTURER NAME"
                    v-model="lostManufacturername"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="SUPPLIER NAME"
                    v-model="lostSuppliername"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="ENQUIRY QTY*"
                    required
                    readonly
                    v-model="lostQuantity"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="PRICE*"
                    v-model="lostPrice"
                    required
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="TOTAL PRICE *"
                    required
                    readonly
                    v-model="lostTotalprice"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="SALES QTY *"
                    required
                    v-model="lostSalesqty"
                    :error-messages="lostSalesqtyErrors"
                    @input="$v.lostSalesqty.$touch()"
                    @blur="$v.lostSalesqty.$touch()"
                    @change="getProductqtyprice()"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="COMPETITOR UNIT PRICE *"
                    v-model="lostCompetitorunitprice"
                    :error-messages="lostCompetitorunitpriceErrors"
                    @input="$v.lostCompetitorunitprice.$touch()"
                    @blur="$v.lostCompetitorunitprice.$touch()"
                    @change="getProductqtyprice()"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="COMPETITOR TOTAL PRICE *"
                    v-model="lostCompetitortotalprice"
                    :error-messages="lostCompetitortotalpriceErrors"
                    @input="$v.lostCompetitortotalprice.$touch()"
                    @blur="$v.lostCompetitortotalprice.$touch()"
                    required
                    readonly
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-select
                    :items="reasons"
                    label="REASON*"
                    v-model="lostReason"
                    :error-messages="lostReasonErrors"
                    @input="$v.lostReason.$touch()"
                    @blur="$v.lostReason.$touch()"
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="REMARKS *"
                    v-model="lostRemarks"
                    required
                    :error-messages="lostRemarksErrors"
                    @input="$v.lostRemarks.$touch()"
                    @blur="$v.lostRemarks.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="warning"
              dark
              @click="
                lostpopupclose();
                lostDialog = false;
              "
            >
              CLOSE
            </v-btn>
            <v-btn
              color="black"
              dark
              @click="lostsubmit"
              v-if="userRole == 'sales-engineer'"
            >
              SUBMIT
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!--lost dialog-->

    <!--pending order dialog-->
    <v-row justify="center">
      <v-dialog v-model="pendingorderDialog" persistent max-width="800px">
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ pendingProductname }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    label="ENQUIRY QTY*"
                    required
                    readonly
                    v-model="pendingQuantity"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    label="PRICE*"
                    v-model="pendingPrice"
                    required
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    label="TOTAL PRICE *"
                    required
                    readonly
                    v-model="pendingTotalprice"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-menu
                    v-model="menu3"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="pendingpfmdate"
                        label="PFM DATE*"
                        :error-messages="pendingpfmdateErrors"
                        @input="$v.pendingpfmdate.$touch()"
                        @blur="$v.pendingpfmdate.$touch()"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="pendingpfmdate"
                      @input="menu3 = false"
                      :min="
                        new Date(
                          pfmMinDate - new Date().getTimezoneOffset() * 60000
                        )
                          .toISOString()
                          .substr(0, 10)
                      "
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="REMARKS *"
                    v-model="pendingRemarks"
                    required
                    :error-messages="pendingRemarksErrors"
                    @input="$v.pendingRemarks.$touch()"
                    @blur="$v.pendingRemarks.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="warning"
              dark
              @click="
                pendingpopupclose();
                pendingorderDialog = false;
              "
            >
              CLOSE
            </v-btn>
            <v-btn
              color="black"
              dark
              @click="pendingsubmit"
              v-if="userRole == 'sales-engineer'"
            >
              SUBMIT
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!--pending order dialog-->

    <!--partial bill dialog-->
    <v-row justify="center">
      <v-dialog v-model="partialorderDialog" persistent max-width="800px">
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ partialProductname }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    label="ENQUIRY QTY*"
                    required
                    readonly
                    v-model="partialQuantity"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    label="PRICE*"
                    v-model="partialPrice"
                    required
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    label="TOTAL PRICE *"
                    required
                    readonly
                    v-model="partialTotalprice"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="CURRENT BILLED QTY*"
                    required
                    v-model="partialBillingQuantity"
                    :error-messages="partialBillingQuantityErrors"
                    @input="$v.partialBillingQuantity.$touch()"
                    @blur="$v.partialBillingQuantity.$touch()"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-menu
                    v-model="menu4"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="partialpfmdate"
                        label="PFM DATE*"
                        :error-messages="partialpfmdateErrors"
                        @input="$v.partialpfmdate.$touch()"
                        @blur="$v.partialpfmdate.$touch()"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="partialpfmdate"
                      @input="menu4 = false"
                      :min="
                        new Date(
                          partialpfmMinDate -
                            new Date().getTimezoneOffset() * 60000
                        )
                          .toISOString()
                          .substr(0, 10)
                      "
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-container>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="warning"
              dark
              @click="
                partialpopupclose();
                partialorderDialog = false;
              "
            >
              CLOSE
            </v-btn>
            <v-btn
              color="black"
              dark
              @click="partialsubmit"
              v-if="userRole == 'sales-engineer'"
            >
              SUBMIT
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!--partial bill dialog-->

    <!--dialog-->
    <v-row justify="center">
      <v-dialog v-model="errorDialog" persistent max-width="290">
        <v-card>
          <v-card-title class="text-h5"> ERROR! </v-card-title>
          <v-card-text>{{ pageError }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="errorDialog = false">
              CLOSE
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!--dialog-->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  name: "MonthlySalesPlan",
  data: () => ({
    search: "",
    pfmList: ["PARTIAL BILL", "PENDING", "BILLED", "LOST", "RESCHEDULE"],
    pfmwithoutPartialbill: ["PENDING", "BILLED", "LOST", "RESCHEDULE"],
    headers: [
      { text: "PFM STATUS", value: "pfmstatus" },
      { text: "STATUS", value: "status", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
      {
        text: "CUSTOMER DETAILS",
        align: "start",
        sortable: true,
        value: "customer"
      },
      { text: "REFRERENCE NO", value: "code" },
      //    { text: "REGION", value: "region" },
      { text: "SEGMENT", value: "segment" },
      { text: "LOB", value: "lob" },
      //   { text: "STATE", value: "state" },
      { text: "CITY", value: "city" },
      { text: "PRODUCT", value: "product" },
      { text: "TPLNO", value: "tplno" },
      //     { text: "SALES TYPE", value: "salestype" },
      //     { text: "WORKSHOP TYPE", value: "workshoptype" },
      { text: "ENQ DATE", value: "enquirydate" },
      { text: "ENQ QTY", value: "enquiryqty" },
      { text: "EST PRICE", value: "enquiryprice" },
      { text: "EST TOT PRICE", value: "enquirytotalprice" },
      { text: "ACT SAL QTY", value: "final_sales_qty" },
      { text: "TOTAL SAL PRICE", value: "final_total_sales_price" },
      { text: "PFM DATE", value: "pfmdate" },
      { text: "SALES ENG", value: "salesengineer" },
      
      
      
    ],
    errorDialog: false,
    pageError: "",
    lostDialog: false,
    lostProductname: "",
    lostManufacturername: "",
    lostSuppliername: "",
    lostQuantity: "",
    lostPrice: "",
    lostTotalprice: "",
    lostSalesqty: "",
    lostCompetitorunitprice: "0.00",
    lostCompetitortotalprice: "",
    lostRemarks: "",
    lostReason: "",
    lostProductDetails: [],
    pendingorderDialog: false,
    modal: false,
    menu3: false,
    pendingpfmdate: "",
    pfmMinDate: Date.now(),
    pendingProductname: "",
    pendingQuantity: "",
    pendingPrice: "",
    pendingTotalprice: "",
    pendingRemarks: "",
    pendingProductDetails: [],
    partialorderDialog: false,
    menu4: false,
    partialpfmdate: "",
    partialpfmMinDate: Date.now(),
    partialBillingQuantity: "",
    partialProductname: "",
    partialQuantity: "",
    partialPrice: "",
    partialTotalprice: "",
    partialProductDetails: []
  }),
  validations: {
    lostSalesqty: { required },
    lostCompetitorunitprice: { required },
    lostCompetitortotalprice: { required },
    lostRemarks: { required },
    lostReason: { required },
    pendingpfmdate: { required },
    pendingRemarks: { required },
    partialpfmdate: { required },
    partialBillingQuantity: { required }
  },
  computed: {
    ...mapGetters([
      "getmonthlySalesplanList",
      "getReasons",
      "getpfmStatus",
      "getpfmStatusResponse",
      "authRole"
    ]),
    monthlySalesplanList: function() {
      return this.getmonthlySalesplanList;
    },
    monthlysalesStatusmsg: function() {
      return this.getpfmStatus === "success";
    },
    monthlysalesResponse: function() {
      return this.getpfmStatusResponse;
    },
    reasons: function() {
      return this.getReasons;
    },
    userRole: function() {
      return this.authRole;
    },
    lostSalesqtyErrors() {
      const errors = [];
      if (!this.$v.lostSalesqty.$dirty) return errors;
      !this.$v.lostSalesqty.required && errors.push("SalesQty is required.");
      return errors;
    },
    lostCompetitorunitpriceErrors() {
      const errors = [];
      if (!this.$v.lostCompetitorunitprice.$dirty) return errors;
      !this.$v.lostCompetitorunitprice.required &&
        errors.push("Competitorunitprice is required.");
      return errors;
    },
    lostCompetitortotalpriceErrors() {
      const errors = [];
      if (!this.$v.lostCompetitortotalprice.$dirty) return errors;
      !this.$v.lostCompetitortotalprice.required &&
        errors.push("Competitortotalprice is required.");
      return errors;
    },
    lostRemarksErrors() {
      const errors = [];
      if (!this.$v.lostRemarks.$dirty) return errors;
      !this.$v.lostRemarks.required && errors.push("Remark is required.");
      return errors;
    },
    lostReasonErrors() {
      const errors = [];
      if (!this.$v.lostReason.$dirty) return errors;
      !this.$v.lostReason.required && errors.push("Reason is required.");
      return errors;
    },
    pendingpfmdateErrors() {
      const errors = [];
      if (!this.$v.pendingpfmdate.$dirty) return errors;
      !this.$v.pendingpfmdate.required && errors.push("PFM date is required.");
      return errors;
    },
    pendingRemarksErrors() {
      const errors = [];
      if (!this.$v.pendingRemarks.$dirty) return errors;
      !this.$v.pendingRemarks.required && errors.push("Remark is required.");
      return errors;
    },
    partialpfmdateErrors() {
      const errors = [];
      if (!this.$v.partialpfmdate.$dirty) return errors;
      !this.$v.partialpfmdate.required && errors.push("PFM date is required.");
      return errors;
    },
    partialBillingQuantityErrors() {
      const errors = [];
      if (!this.$v.partialBillingQuantity.$dirty) return errors;
      !this.$v.partialBillingQuantity.required &&
        errors.push("Partial Billing Qty is required.");
      return errors;
    }
  },

  watch: {},

  created() {
    this.fetchmonthlySalesplanList();
    this.fetchDefinedvalues();
  },

  methods: {
    ...mapActions([
      "fetchmonthlySalesplanList",
      "fetchDefinedvalues",
      "enquiryBilled",
      "enquiryLost",
      "enquiryRescheduled",
      "enquiryPending",
      "enquiryPartialbill"
    ]),
    //qty based product price onchange
    getProductqtyprice() {
      let cost =
        parseFloat(this.lostSalesqty) *
        parseFloat(this.lostCompetitorunitprice);
      this.lostCompetitortotalprice = cost;
    },

    enquiryConfirm(item) {
      if (item.pfmFinalstatus == "BILLED") {
        const enquiryData = {
          enquiryDetailid: item.enquiryDetailid
        };

        this.enquiryBilled(enquiryData);
        //console.log('enter');
      }
      if (item.pfmFinalstatus == "RESCHEDULE") {
        console.log(item.enquiryDetailid);

        const enquiryData = {
          enquiryDetailid: item.enquiryDetailid
        };

        this.enquiryRescheduled(enquiryData);
        //console.log('enter');
      }
      if (item.pfmFinalstatus == "LOST") {
        this.lostDialog = true;
        this.lostProductname = item.product;
        this.lostQuantity = item.final_sales_qty;
        this.lostPrice = item.final_unit_price;
        this.lostTotalprice = item.final_total_sales_price;
        this.lostProductDetails = item;
      }
      if (item.pfmFinalstatus == "PENDING") {
        console.log(item);
        this.pendingorderDialog = true;
        this.pendingProductname = item.product;
        this.pendingQuantity = item.final_sales_qty;
        this.pendingPrice = item.final_unit_price;
        this.pendingTotalprice = item.final_total_sales_price;
        //this.pfmMinDate = new Date(item.pfmdate);
        let pfmdateFormat=this.dbDateFormat(item.pfmdate);
        this.pfmMinDate = new Date(pfmdateFormat);
        this.pendingProductDetails = item;
      }
      if (item.pfmFinalstatus == "PARTIAL BILL") {
        this.partialorderDialog = true;
        this.partialProductname = item.product;
        this.partialQuantity = item.final_sales_qty;
        this.partialPrice = item.final_unit_price;
        this.partialTotalprice = item.final_total_sales_price;
       // this.partialpfmMinDate = new Date(item.pfmdate);
       let pfmdateFormat=this.dbDateFormat(item.pfmdate);
        this.partialpfmMinDate = new Date(pfmdateFormat);
        this.partialProductDetails = item;
        //console.log('enter');
      }
    },

    lostsubmit() {
      this.$v.$touch();
      if (
        this.$v.lostSalesqty.$invalid ||
        this.$v.lostCompetitorunitprice.$invalid ||
        this.$v.lostCompetitortotalprice.$invalid ||
        this.$v.lostRemarks.$invalid ||
        this.$v.lostReason.$invalid
      ) {
        console.log("ERROR");
      } else {
        const enquiryData = {
          enquiryDetailid: this.lostProductDetails.enquiryDetailid,
          lostManufacturername: this.lostManufacturername.toUpperCase(),
          lostSuppliername: this.lostSuppliername.toUpperCase(),
          lostSalesqty: this.lostSalesqty,
          lostCompetitorunitprice: this.lostCompetitorunitprice,
          lostCompetitortotalprice: this.lostCompetitortotalprice,
          lostRemarks: this.lostRemarks.toUpperCase(),
          lostReason: this.lostReason.toUpperCase()
        };
        //   console.log(enquiryData);
        this.enquiryLost(enquiryData);
        this.lostpopupclose();
        this.lostDialog = false;
      }
    },
    lostpopupclose() {
      this.lostProductname = "";
      this.lostManufacturername = "";
      this.lostSuppliername = "";
      this.lostQuantity = "";
      this.lostPrice = "";
      this.lostTotalprice = "";
      this.lostSalesqty = "";
      this.lostCompetitorunitprice = "0.00";
      this.lostCompetitortotalprice = "";
      this.lostRemarks = "";
      this.lostReason = "";
    },

    pendingsubmit() {
      this.$v.$touch();
      if (this.$v.pendingRemarks.$invalid || this.$v.pendingpfmdate.$invalid) {
        console.log("ERROR");
      } else {
        const enquiryData = {
          enquiryDetailid: this.pendingProductDetails.enquiryDetailid,
          pendingRemarks: this.pendingRemarks.toUpperCase(),
          pendingpfmdate: this.pendingpfmdate.toUpperCase()
        };
        console.log(enquiryData);
        this.enquiryPending(enquiryData);
        this.pendingpopupclose();
        this.pendingorderDialog = false;
      }
    },
    pendingpopupclose() {
      this.pendingRemarks = "";
      this.pendingpfmdate = "";
    },
    partialsubmit() {
      this.$v.$touch();
      if (
        this.$v.partialBillingQuantity.$invalid ||
        this.$v.partialpfmdate.$invalid
      ) {
        console.log("ERROR");
      } else {
        if (
          parseFloat(this.partialQuantity) <=
          parseFloat(this.partialBillingQuantity)
        ) {
          console.log("error");
          this.errorDialog = true;
          this.pageError = "PARTIAL QTY MUST BE LESSS THAN ENQUIRY QTY";
        } else {
          const enquiryData = {
            enquiryDetailid: this.partialProductDetails.enquiryDetailid,
            partialBillingQuantity: this.partialBillingQuantity,
            partialpfmdate: this.partialpfmdate
          };
          //   console.log(enquiryData);
          this.enquiryPartialbill(enquiryData);
          this.partialpopupclose();
          this.partialorderDialog = false;
        }
      }
    },
    partialpopupclose() {
      this.partialBillingQuantity = "";
      this.partialpfmdate = "";
    },
    dbDateFormat(date) {
      
      let d = date.split("-");;
      console.log(d);
      return d['2']+'-'+d['1']+'-'+d['0'];
      //console.log(d['2']+'-'+d['1']+'-'+d['0']);

    },
  }
};
</script>
