<template>
  <div>
    <!--container-->
    <v-container>
      <!--tob bar-->
      <bar />
      <!--tob bar end-->
      <!--template-->
      <template>
        <!--card -->
        <v-card class="form-card mx-auto my-12">
          <!--alert box-->
          <v-alert text prominent type="error" v-if="errorResponse">
            <div v-for="(error, i) in leadError" :key="i">
              {{ error }}
            </div>
          </v-alert>
          <!--alert box end-->

          <v-list-item class="headline">
            <v-list-item-content>
              <v-list-item-title>
                LEAD ADD
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <!--form-->
          <form enctype="multipart/form-data">
            <v-card-text>
              <v-row justify="space-between">
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="customerCode"
                    label="CUSTOMER CODE"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="enquiryDate"
                        :error-messages="enquiryDateErrors"
                        @input="$v.enquiryDate.$touch()"
                        @blur="$v.enquiryDate.$touch()"
                        label="ENQUIRY DATE*"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                    v-if="
                  userRole == 'digital-marketing'"
                      v-model="enquiryDate"
                      @input="menu2 = false"
                      :max="
                        new Date(
                          Date.now() - new Date().getTimezoneOffset() * 60000
                        )
                          .toISOString()
                          .substr(0, 10)
                      "
                      :min="new Date(
                          leadMindate - new Date().getTimezoneOffset() * 60000
                        )
                          .toISOString()
                          .substr(0, 10)"
                    ></v-date-picker>
                    <v-date-picker
                    v-if="
                  userRole != 'digital-marketing'"
                      v-model="enquiryDate"
                      @input="menu2 = false"
                      :max="
                        new Date(
                          Date.now() - new Date().getTimezoneOffset() * 60000
                        )
                          .toISOString()
                          .substr(0, 10)
                      "
                      
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="6">
                  <v-select
                    v-model="enquirysource"
                    :items="enquirysourcelist"
                    :error-messages="enquirysourceErrors"
                    label="ENQUIRY SOURCE*"
                    required
                    @input="$v.enquirysource.$touch()"
                    @blur="$v.enquirysource.$touch()"
                  ></v-select>
                </v-col>

                <v-col cols="12" md="6">
                  <v-select
                    v-model="categoryenquirysource"
                    :items="categoryenquirysourcelist"
                    :error-messages="categoryenquirysourceErrors"
                    label="CATEGORY ENQUIRY SOURCE*"
                    required
                    @input="$v.categoryenquirysource.$touch()"
                    @blur="$v.categoryenquirysource.$touch()"
                  ></v-select>
                </v-col>
              </v-row>

              <v-row justify="space-between">
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="customerDetails"
                    :error-messages="customerDetailsErrors"
                    label="NAME OF THE ENQUIRY *"
                    required
                    @input="$v.customerDetails.$touch()"
                    @blur="$v.customerDetails.$touch()"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="contactnumber"
                    label="CONTACT NUMBER*"
                    :error-messages="contactnumberErrors"
                    required
                    @input="$v.contactnumber.$touch()"
                    @blur="$v.contactnumber.$touch()"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="emailid"
                    label="EMAIL ID*"
                    :error-messages="emailidErrors"
                    required
                    @input="$v.emailid.$touch()"
                    @blur="$v.emailid.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row justify="space-between">
                <v-col cols="12" md="4">
                  <v-select
                    v-model="region"
                    :items="regions"
                    :error-messages="regionErrors"
                    label="REGION *"
                    required
                    @input="$v.region.$touch()"
                    @blur="$v.region.$touch()"
                  ></v-select>
                </v-col>

                <v-col cols="12" md="4">
                  <v-autocomplete
                    v-model="state"
                    :items="states"
                    item-text="name"
                    item-value="id"
                    :error-messages="stateErrors"
                    label="STATE *"
                    required
                    @input="$v.state.$touch()"
                    @blur="$v.state.$touch()"
                    v-on:change="getCity(`${state}`)"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" md="4">
                  <v-autocomplete
                    v-model="city"
                    :items="cities"
                    item-text="name"
                    item-value="id"
                    :error-messages="cityErrors"
                    label="CITY *"
                    required
                    @input="$v.city.$touch()"
                    @blur="$v.city.$touch()"
                  ></v-autocomplete>
                </v-col>
              </v-row>

              <v-row justify="space-between">
                <v-col cols="12" md="4">
                  <v-autocomplete
                    v-model="brand"
                    :items="brands"
                    :error-messages="brandErrors"
                    item-text="name"
                    item-value="id"
                    label="LOB*"
                    required
                    dense
                    @input="$v.brand.$touch()"
                    @blur="$v.brand.$touch()"
                    class="mt-6"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" md="4">
                  <v-autocomplete
                    v-model="segment"
                    :error-messages="segmentErrors"
                    :items="segments"
                    item-text="name"
                    item-value="id"
                    label="SEGMENT*"
                    required
                    dense
                    @input="$v.segment.$touch()"
                    @blur="$v.segment.$touch()"
                    class="mt-6"
                    @change="getSalesengineer(segment)"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" md="4">
                  <v-autocomplete
                    v-model="typeenquiry"
                    :error-messages="typeenquiryErrors"
                    :items="typeenquirylist"
                    label="TYPE ENQUIRY*"
                    required
                    dense
                    @input="$v.typeenquiry.$touch()"
                    @blur="$v.typeenquiry.$touch()"
                    class="mt-6"
                  ></v-autocomplete>
                </v-col>
              </v-row>

              <v-row>
                <v-col xs="12" sm="12" md="12">
                  <v-text-field
                    v-model="message"
                    label="MESSAGE"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="12">
                  <v-select
                    v-model="salesengineer"
                    :items="salesengineers"
                    :error-messages="salesengineerErrors"
                    label="SALES ENGINEER *"
                    item-text="name"
                    item-value="id"
                    required
                    @input="$v.salesengineer.$touch()"
                    @blur="$v.salesengineer.$touch()"
                  ></v-select>
                </v-col>
              </v-row>

              <v-row>
                <v-col xs="12" sm="12" md="12">
                  <v-text-field
                    v-model="ho_remarks"
                    label="HO REMARKS"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row
                justify="space-between"
                v-for="(pro, k) in productDetails"
                :key="k"
              >
                <v-col xs="12" sm="12" md="2">
                  <!-- <v-select
                    v-model="pro.equipment"
                    :items="products"
                    item-text="product_name"
                    item-value="id"
                    label="EQUIPMENT"
                    @change="getProductdetails(pro)" ></v-select>-->

                  <v-autocomplete
                    v-model="pro.equipment"
                    :items="products"
                    item-text="product_name_withtpl"
                    item-value="id"
                    label="EQUIPMENT"
                    @change="getProductdetails(pro)"
                  >
                  </v-autocomplete>
                </v-col>

                <v-col xs="12" sm="12" md="1">
                  <v-img
                    max-height="150"
                    max-width="250"
                    :src="pro.productImageshow"
                  ></v-img>
                </v-col>

                <v-col xs="12" sm="12" md="1">
                  <v-text-field
                    v-model="pro.model"
                    label="MODEL"
                    readonly
                  ></v-text-field>
                </v-col>

                <v-col xs="12" sm="12" md="1">
                  <v-text-field
                    v-model="pro.quantity"
                    label="QTY"
                    @change="getProductqtyprice(pro)"
                  ></v-text-field>
                </v-col>

                <v-col xs="12" sm="12" md="2">
                  <v-text-field
                    v-model="pro.price"
                    label="PRICE"
                    readonly
                  ></v-text-field>
                </v-col>

                <v-col xs="12" sm="12" md="2">
                  <v-text-field
                    v-model="pro.special_price"
                    label="SPL PRICE"
                    @change="getProductqtyprice(pro)"
                  ></v-text-field>
                </v-col>

                <v-col xs="12" sm="12" md="2">
                  <v-text-field
                    v-model="pro.totalPrice"
                    label="TOTAL COST"
                    readonly
                  ></v-text-field>
                </v-col>

                <v-col xs="12" sm="12" md="1">
                  <v-btn
                    color="warning"
                    fab
                    dark
                    x-small
                    v-show="productDetails.length > 1"
                    @click="deleteProductdetails(k)"
                  >
                    <v-icon dark> mdi-close </v-icon>
                  </v-btn>
                </v-col>
              </v-row>

              <v-row>
                <v-col xs="12" sm="12" md="11"> </v-col>
                <v-col xs="12" sm="12" md="1">
                  <v-btn depressed color="warning" @click="addProductdetails">
                    +ADD
                  </v-btn>
                </v-col>
              </v-row>

              <v-row>
                <v-col xs="12" sm="12" md="9"> </v-col>
                <v-col xs="12" sm="12" md="3">
                  <v-text-field
                    v-model="taxAmount"
                    label="TAX AMOUNT"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col xs="12" sm="12" md="9"> </v-col>
                <v-col xs="12" sm="12" md="3">
                  <v-text-field
                    v-model="totalPrice"
                    label="TOTAL PRICE"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col xs="12" sm="12" md="9"> </v-col>
                <v-col xs="12" sm="12" md="3">
                  <v-text-field
                    v-model="totalPricewithtax"
                    label="TOTAL PRICE (WITH TAX)"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>

            <!--input field end-->
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="black" dark @click="submit"> SUBMIT </v-btn>
              <v-btn color="warning" dark to="/lead"> CANCEL </v-btn>
            </v-card-actions>
          </form>
        </v-card>
        <!--card end-->
      </template>
      <!--template end-->
    </v-container>
    <!--container end-->
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";

import { mapActions, mapGetters } from "vuex";

import { VUE_APP_BASEURL } from "../../utils/api";
import axios from "axios";
import store from "../../store";

export default {
  name: "LeadAdd",
  data: () => ({
    customerCode: "",
    customerDetails: "",
    modal: false,
    menu2: false,
    enquiryDate: new Date().toISOString().substr(0, 10),
    productDetails: [
      {
        equipment: "",
        productImageshow: "",
        model: "",
        specification: "",
        scopeofsupply: "",
        features: "",
        warrenty: "",
        wty_excel: "",
        quantity: "",
        price: "",
        special_price: "",
        totalPrice: ""
      }
    ],
    state: "",
    city: "",
    brand: "",
    segment: "",
    region: "",
    enquirysource: "",
    categoryenquirysource: "",
    typeenquiry: "",
    message: "",
    ho_remarks: "",
    salesengineer: "",
    contactnumber: "",
    emailid: "",
    taxAmount: "0",
    totalPrice: "0",
    totalPricewithtax: "0",
    leadMindate: ""
  }),
  validations: {
    customerDetails: { required },
    enquiryDate: { required },
    state: { required },
    city: { required },
    brand: { required },
    segment: { required },
    region: { required },
    enquirysource: { required },
    categoryenquirysource: { required },
    typeenquiry: { required },
    contactnumber: { required },
    emailid: { required },
    salesengineer: { required }
  },
  computed: {
    ...mapGetters([
      "leadStatus",
      "leadStatusResponse",
      "stateList",
      "brandList",
      "cityList",
      "segmentList",
      "getRegions",
      "getleadEnquirysourcetype",
      "getleadEnquirycategorysourcetype",
      "gettypeEnquiry",
      "productList",
      "productImageDetails",
      "modelDetailsname",
      "brandPriceDetails",
      "getTax",
      "getSalesengineerlist",
      "authRole",
    ]),
    errorResponse: function() {
      //  console.log(this.enquiryStatus);
      return this.enquiryStatus === "error";
    },
    leadError: function() {
      return this.enquiryStatusResponse;
    },
    userRole: function() {
    
      return this.authRole;
    },
    states: function() {
      return this.stateList;
    },
    cities: function() {
      return this.cityList;
    },
    brands: function() {
      return this.brandList;
    },
    segments: function() {
      return this.segmentList;
    },
    regions: function() {
      return this.getRegions;
    },
    enquirysourcelist: function() {
      return this.getleadEnquirysourcetype;
    },
    categoryenquirysourcelist: function() {
      return this.getleadEnquirycategorysourcetype;
    },
    typeenquirylist: function() {
      return this.gettypeEnquiry;
    },
    products: function() {
      return this.productList;
    },
    salesengineers: function() {
      return this.getSalesengineerlist;
    },
    tax: {
      get() {
        return this.getTax;
      }
    },
    stateErrors() {
      const errors = [];
      if (!this.$v.state.$dirty) return errors;
      !this.$v.state.required && errors.push("State is required.");
      return errors;
    },
    cityErrors() {
      const errors = [];
      if (!this.$v.city.$dirty) return errors;
      !this.$v.city.required && errors.push("City is required.");
      return errors;
    },
    contactnumberErrors() {
      const errors = [];
      if (!this.$v.contactnumber.$dirty) return errors;
      !this.$v.contactnumber.required &&
        errors.push("Contact Number is required.");
      return errors;
    },
    emailidErrors() {
      const errors = [];
      if (!this.$v.emailid.$dirty) return errors;
      !this.$v.emailid.required && errors.push("Email ID is required.");
      return errors;
    },
    customerDetailsErrors() {
      const errors = [];
      if (!this.$v.customerDetails.$dirty) return errors;
      !this.$v.customerDetails.required &&
        errors.push("Customerdetails is required.");
      return errors;
    },
    enquiryDateErrors() {
      const errors = [];
      if (!this.$v.enquiryDate.$dirty) return errors;
      !this.$v.enquiryDate.required && errors.push("Enquirydate is required.");
      return errors;
    },
    brandErrors() {
      const errors = [];
      if (!this.$v.brand.$dirty) return errors;
      !this.$v.brand.required && errors.push("LOB is required.");
      return errors;
    },
    segmentErrors() {
      const errors = [];
      if (!this.$v.segment.$dirty) return errors;
      !this.$v.segment.required && errors.push("Segment is required.");
      return errors;
    },
    regionErrors() {
      const errors = [];
      if (!this.$v.region.$dirty) return errors;
      !this.$v.region.required && errors.push("Region is required.");
      return errors;
    },
    enquirysourceErrors() {
      const errors = [];
      if (!this.$v.enquirysource.$dirty) return errors;
      !this.$v.enquirysource.required &&
        errors.push("Enquiry Source is required.");
      return errors;
    },
    categoryenquirysourceErrors() {
      const errors = [];
      if (!this.$v.categoryenquirysource.$dirty) return errors;
      !this.$v.categoryenquirysource.required &&
        errors.push("Category Enquiry Source is required.");
      return errors;
    },
    typeenquiryErrors() {
      const errors = [];
      if (!this.$v.typeenquiry.$dirty) return errors;
      !this.$v.typeenquiry.required && errors.push("TYpe Enquiry is required.");
      return errors;
    },
    salesengineerErrors() {
      const errors = [];
      if (!this.$v.salesengineer.$dirty) return errors;
      !this.$v.salesengineer.required &&
        errors.push("Salesengineer is required.");
      return errors;
    }
  },
  created() {
    this.fetchStates();
    this.fetchDefinedvalues();
    this.fetchSegment();
    this.fetchBrand();
    this.fetchProducts();
    this.leadMinDateFind();
  },
  methods: {
    ...mapActions([
      "fetchStates",
      "fetchBrand",
      "fetchSegment",
      "cityGetlist",
      "fetchDefinedvalues",
      "fetchProducts",
      "fetchProductdetails",
      "leadAdd",
      "fetchSalesengineers"
    ]),
    getCity(stateid) {
      this.cityGetlist(stateid);
    },
    //add product details
    addProductdetails() {
      this.productDetails.push({
        equipment: "",
        productImageshow: "",
        model: "",
        quantity: "",
        price: "",
        special_price: "",
        totalPrice: ""
      });
    },
    //delete product details
    deleteProductdetails(counter) {
      //   console.log(counter);
      this.productDetails.splice(counter, 1);
      this.calculateTotal();
    },
    //get product details onchange
    getProductdetails(pro) {
      //console.log(this.$route.params.id);

      axios({
        method: "GET",
        headers: {
          Authorization: "Bearer " + store.getters.UserToken
        },
        url: VUE_APP_BASEURL + "product/" + pro.equipment + "?customerId=0"
      })
        .then(function(response) {
          //   console.log(response);

          pro.productImageshow = response.data.response.product_image;
          pro.model = response.data.response.model;
          pro.quantity = 1;
          pro.price = parseFloat(response.data.response.brandPrice);
          pro.special_price = parseFloat(response.data.response.brandPrice);
          let cost = parseFloat(pro.quantity) * parseFloat(pro.special_price);
          pro.totalPrice = cost;
          this.calculateTotal();
        })
        .catch(function(error) {
          console.log(error);
        });

      /*  const productData = {
            customerId: 0,
            productId: pro.equipment,
          };            
      this.fetchProductdetails(productData);
          
      setTimeout(() => {
      pro.productImageshow=this.productImageDetails;
      pro.model=this.modelDetailsname;
      pro.quantity=1;
      pro.price=parseFloat(this.brandPriceDetails);
      pro.special_price=parseFloat(this.brandPriceDetails);
      let cost=parseFloat(pro.quantity)*parseFloat(pro.special_price);
      pro.totalPrice=cost;
      this.calculateTotal();
       }, 500);*/
    },
    //qty based product price onchange
    getProductqtyprice(pro) {
      //console.log(pro);
      let cost = parseFloat(pro.quantity) * parseFloat(pro.special_price);
      pro.totalPrice = cost;
      this.calculateTotal();
    },
    calculateTotal() {
      var gst = this.tax;

      var subtotal;
      subtotal = this.productDetails.reduce(function(sum, product) {
        var lineTotal = parseFloat(product.totalPrice);
        if (!isNaN(lineTotal)) {
          return sum + lineTotal;
        }
      }, 0);

      var billtotal = parseFloat(subtotal);
      var taxamount =
        (parseFloat(billtotal) * parseFloat(gst)) / parseFloat(100);
      var billwithtax = parseFloat(billtotal) + parseFloat(taxamount);

      this.taxAmount = taxamount;
      this.totalPrice = subtotal;
      this.totalPricewithtax = billwithtax.toFixed(2);
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("ERROR");
      } else {
        // do your submit logic here
        setTimeout(() => {
          const leadData = {
            customerCode: this.customerCode,
            EnquiryDate: this.enquiryDate,
            customerDetails: this.customerDetails.toUpperCase(),
            contactNumber: this.contactnumber,
            emailId: this.emailid,
            region: this.region,
            state: this.state,
            city: this.city,
            brand: this.brand,
            segment: this.segment,
            enquirysource: this.enquirysource,
            categoryenquirysource: this.categoryenquirysource,
            typeenquiry: this.typeenquiry,
            message: this.message,
            ho_remarks: this.ho_remarks,
            salesengineer: this.salesengineer,
            taxAmount: this.taxAmount,
            totalAmount: this.totalPrice,
            totalAmountWithTax: this.totalPricewithtax,
            enquiryDetails: this.productDetails
          };

          //console.log(leadData);
          this.leadAdd(leadData);
        }, 500);
      }
    },
    //get segment based  salesengineer
    getSalesengineer(segment) {
      this.fetchSalesengineers(segment);
      //,  console.log(segment);
    },
    leadMinDateFind(){


      //console.log('enter');
      let d = new Date();
      d.setDate(d.getDate() - 3);
      console.log(d);
      this.leadMindate=d;
    }
  }
};
</script>
