import { VUE_APP_BASEURL } from "../../utils/api";
import router from "../../router";
import axios from "axios";
import store from "../../store";

const state = {
  token: localStorage.getItem("user-token") || "",
  status: "",
  responseError: "",
  userName: "",
  userRole: localStorage.getItem("user-role") || "",
  hasLoadedOnce: false,
  workshoptypes: [],
  regions: [],
  customervisitstatuses: [],
  visitstatuses: [],
  sourcelist: [],
  reasons: [],
  equipmentcondition: [],
  statuslist: [],
  salestypes: [],
  enquiryworkshoptypes: [],
  tax: 0,
  userRolemenus: [],

  leadEnquirysourcetype: [],
  leadEnquirycategorysourcetype: [],
  typeEnquiry: [],
  salesEngineerStatuslist: [],
  dateRange: []
};
const getters = {
  isAuthenticated: state => !!state.token,
  UserToken: state => state.token,
  authStatus: state => state.status,
  authUser: state => state.userName,
  authRole: state => state.userRole,
  authStatusError: state => state.responseError,
  getWorkshoptypes: state => state.workshoptypes,
  getRegions: state => state.regions,
  getCustomervisitstatuses: state => state.customervisitstatuses,
  getVisitstatuses: state => state.visitstatuses,
  getSourcelist: state => state.sourcelist,
  getReasons: state => state.reasons,
  getEquipmentcondition: state => state.equipmentcondition,
  getStatuslist: state => state.statuslist,
  getEnquiryWorkshoptypes: state => state.enquiryworkshoptypes,
  getSalestype: state => state.salestypes,
  getTax: state => state.tax,
  getMenus: state => state.userRolemenus,

  getleadEnquirysourcetype: state => state.leadEnquirysourcetype,
  getleadEnquirycategorysourcetype: state =>
    state.leadEnquirycategorysourcetype,
  gettypeEnquiry: state => state.typeEnquiry,
  getsalesEngineerStatuslist: state => state.salesEngineerStatuslist,
  getDateRange: state => state.dateRange
};

const actions = {
  async loginAction({ commit }, loginData) {
    commit("authRequest");
    axios({
      method: "POST",
      url: VUE_APP_BASEURL + "login",
      data: {
        username: loginData.username,
        password: loginData.password
      }
    })
      .then(function(response) {
        //console.log(response);

        if (response.data.result == 1) {
          localStorage.setItem("user-token", response.data.response.apiToken);
          localStorage.setItem("user-role", response.data.response.userRole);

          commit("authLogin", response);
          router.push("/home");
        }
      })
      .catch(function(error) {
        console.log(error.response);
        if (error.response.status == 401) {
          commit("authError", error.response.data.response);
          console.log(error.response.data.response);
        }
      });
  },
  async loginDetails({ commit }) {
    axios({
      method: "GET",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "user-token"
    })
      .then(function(response) {
        // console.log(response);
        commit("authLoginDetails", response);
      })
      .catch(function(error) {
        console.log(error);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("countryError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  },
  async menuDetails({ commit }) {
    axios({
      method: "POST",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "role-menus",
      data: {
        roleName: store.getters.authRole
      }
    })
      .then(function(response) {
        //console.log(response);
        commit("authMenuDetails", response);
      })
      .catch(function(error) {
        console.log(error);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          //console.log(error.response);

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("countryError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  },
  async fetchDefinedvalues({ commit }) {
    axios({
      method: "GET",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "definedValues"
    })
      .then(function(response) {
        commit("definedValues", response);
      })
      .catch(function(error) {
        console.log(error);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("countryError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  },
  async logoutAction({ commit }) {
    commit("authLogout");
    localStorage.removeItem("user-token");
    localStorage.removeItem("user-role");
    router.push("/");
  }
};

const mutations = {
  authRequest: state => {
    state.status = "loading";
  },
  authLogin: (state, resp) => {
    state.status = "success";
    state.token = resp.data.response.apiToken;
    state.userName = resp.data.response.userName;
    state.userRole = resp.data.response.userRole;
    state.hasLoadedOnce = true;
  },
  authLoginDetails: (state, resp) => {
    state.userName = resp.data.name;
    state.userName = resp.data.name;
  },
  authMenuDetails: (state, resp) => {
    state.userRolemenus = resp.data.response;
  },
  authError: (state, resp) => {
    state.status = "error";
    state.hasLoadedOnce = true;
    state.responseError = resp;
  },
  authLogout: state => {
    console.log("logout");
    localStorage.setItem("user-token", "");
    localStorage.setItem("user-role", "");
    state.token = "";
    state.UserToken = "";
    state.isAuthenticated = false;
  },
  authSuccess: state => {
    state.status = "success";
  },
  definedValues: (state, resp) => {
    state.workshoptypes = resp.data.response.workshopTypes;
    state.regions = resp.data.response.regions;
    state.customervisitstatuses = resp.data.response.customerVisitstatuses;
    state.visitstatuses = resp.data.response.visitStatuses;
    state.sourcelist = resp.data.response.sourceList;
    state.reasons = resp.data.response.reasons;
    state.equipmentcondition = resp.data.response.equipmentCondition;
    state.statuslist = resp.data.response.statusList;
    state.enquiryworkshoptypes = resp.data.response.enquiryworkshopTypes;
    state.salestypes = resp.data.response.salesTypes;
    state.tax = resp.data.response.tax;

    state.leadEnquirysourcetype = resp.data.response.leadEnquirysourcetype;
    state.leadEnquirycategorysourcetype =
      resp.data.response.leadEnquirycategorysourcetype;
    state.typeEnquiry = resp.data.response.typeEnquiry;
    state.salesEngineerStatuslist = resp.data.response.salesEngineerStatus;
    state.dateRange = resp.data.response.dateRange;
  },
  updateTax: (state, tax) => {
    state.tax = tax;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
