import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

//modules
import login from "./modules/login";
import country from "./modules/country";
import state from "./modules/state";
import city from "./modules/city";
import brand from "./modules/brand";
import segment from "./modules/segment";
import productgroup from "./modules/productgroup";
import product from "./modules/product";
import customer from "./modules/customer";
import user from "./modules/user";
import enquiry from "./modules/enquiry";
import enquiryfollowup from "./modules/enquiryfollowup";
import parfrequest from "./modules/parfrequest";
import tspplan from "./modules/tspplan";
import monthlysalesplan from "./modules/monthlysalesplan";
import lead from "./modules/lead";
import menu from "./modules/menu";
import role from "./modules/role";
import report from "./modules/report";
import proforma from "./modules/proforma";
export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    login,
    country,
    state,
    city,
    brand,
    segment,
    productgroup,
    product,
    customer,
    user,
    enquiry,
    enquiryfollowup,
    parfrequest,
    tspplan,
    monthlysalesplan,
    lead,
    menu,
    role,
    report,
    proforma
  }
});
