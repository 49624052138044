<template>
  <div>
    <!--container-->
    <v-container>
      <!--tob bar-->
      <bar />
      <!--tob bar end-->

      <!--template-->
      <template>
        <!--alert box-->
        <v-alert
          text
          dense
          color="teal"
          icon="mdi-clock-fast"
          border="left"
          v-if="parfStatusmsg"
        >
          <div v-for="(resp, i) in parfResponse" :key="i">
            {{ resp }}
          </div>
        </v-alert>
        <!--alert box end-->

        <!---datatable -->
        <v-data-table
          :headers="headers"
          :items="tspplanList"
          :search="search"
          sort-by="temporary_no"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>ENQUIRY TSP PLAN</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
              <v-divider class="mx-4" inset vertical></v-divider>

              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <!-- <v-icon small class="mr-2" @click="enquiryEdit(item)">
              mdi-pencil
            </v-icon>-->

            <v-btn
              color="warning"
              class="mr-2"
              outlined
              @click="enquiryEdit(item)"
            >
              MSP
            </v-btn>

            <v-btn color="warning" outlined @click="parfRequestview(item)">
              PARF REQUEST
            </v-btn>
          </template>
        </v-data-table>
        <!---datatable end-->
      </template>
      <!--template end-->
    </v-container>
    <!--container end-->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import router from "../../router";

export default {
  name: "EnquiryTspPlan",
  data: () => ({
    search: "",
    headers: [
      { text: "Actions", value: "actions", sortable: false },
      {
        text: "CUSTOMER DETAILS",
        align: "start",
        sortable: true,
        value: "customer_details"
      },
      { text: "REFERENCE NO", value: "referenceNo" },
      { text: "SEGMENT", value: "segment" },
      { text: "LOB", value: "brand" },
      { text: "WORKSHOP NAME", value: "workshop_name" },
      { text: "ADDRESS", value: "address1" },
      { text: "ENQUIRY DATE", value: "enquiry_date" },
      
    ]
  }),

  computed: {
    ...mapGetters(["getTspplanList", "parfStatus", "parfStatusResponse"]),
    tspplanList: function() {
      console.log(this.getTspplanList);
      return this.getTspplanList;
    },
    parfStatusmsg: function() {
      return this.parfStatus === "success";
    },
    parfResponse: function() {
      return this.parfStatusResponse;
    }
  },

  watch: {},

  created() {
    this.fetchTspPlanlist();
  },

  methods: {
    ...mapActions(["fetchTspPlanlist"]),

    enquiryEdit(item) {
      const id = item.id;
      router.push({ path: `/enquiry-tsp-plan-list/view/${id}` });
    },

    parfRequestview(item) {
      const id = item.id;
      router.push({ path: `/enquiry-parf-rise/view/${id}` });
    }
  }
};
</script>
