<template>
  <div>
    <v-card class="mx-auto mb-5" outlined>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="2" v-if="userRole != 'sales-engineer'">
            <v-select
              v-model="region"
              :items="regions"
              label="REGION *"
              class="mt-6"
              clearable
            >
            </v-select>
          </v-col>

          <v-col cols="12" md="2">
            <v-autocomplete
              v-model="brand"
              :items="brands"
              item-text="name"
              item-value="id"
              label="LOB*"
              class="mt-4"
              chips
              multiple
              clearable
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" md="2">
            <v-select
              v-model="status"
              :items="status_list"
              label="STATUS"
              class="mt-6"
              clearable
            ></v-select>
          </v-col>

          <v-col cols="12" xs="12" sm="12" md="2">
            <v-autocomplete
              v-model="state"
              :items="states"
              item-text="name"
              item-value="id"
              label="STATE "
              class="mt-6"
              v-on:change="getCity(`${state}`)"
              clearable
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" xs="12" sm="12" md="2">
            <v-autocomplete
              v-model="city"
              :items="cities"
              item-text="name"
              item-value="id"
              label="CITY "
              class="mt-6"
              clearable
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="warning" dark @click="filterCustomer"> FIND </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
// @ is an alias to /src

import { mapActions, mapGetters } from "vuex";

export default {
  name: "CustomerBasedEquipmentSearch",
  data: () => ({
    brand: "",
    status: "",
    state: "",
    city: "",
    region: ""
  }),
  components: {},
  computed: {
    ...mapGetters([
      "brandList",
      "getStatuslist",
      "stateList",
      "cityList",
      "getRegions",
      "authRole"
    ]),

    brands: function() {
      return this.brandList;
    },
    status_list: function() {
      return this.getStatuslist;
    },
    states: function() {
      return this.stateList;
    },
    cities: function() {
      return this.cityList;
    },
    regions: function() {
      return this.getRegions;
    },
    userRole: function() {
      return this.authRole;
    }
  },
  created() {
    this.fetchBrand();
    this.fetchDefinedvalues();
    this.fetchStates();
  },
  methods: {
    ...mapActions([
      "fetchBrand",
      "customerBasedequipmentReport",
      "fetchDefinedvalues",
      "fetchStates",
      "cityGetlist"
    ]),
    getCity(stateid) {
      console.log(stateid);
      if (stateid != "null") {
        this.cityGetlist(stateid);
      }
    },
    filterCustomer() {
      const filterData = {
        brand: this.brand,
        status: this.status,
        state: this.state,
        city: this.city,
        region: this.region
      };
      //console.log(filterData);
      this.customerBasedequipmentReport(filterData);
    }
  }
};
</script>
