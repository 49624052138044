<template>
  <div>
    <v-card class="mx-auto mb-5" outlined>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="2">
            <v-autocomplete
              v-model="brand"
              :items="brands"
              item-text="name"
              item-value="id"
              label="LOB*"
              class="mt-6"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="2">
            <v-select
              v-model="status"
              :items="status_list"
              label="STATUS"
              class="mt-6"
            ></v-select>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="warning" dark @click="filterCustomer"> FIND </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
// @ is an alias to /src

import { mapActions, mapGetters } from "vuex";

export default {
  name: "CustomerSearch",
  data: () => ({
    brand: "",
    status: ""
  }),
  components: {},
  computed: {
    ...mapGetters(["brandList", "getStatuslist"]),

    brands: function() {
      return this.brandList;
    },
    status_list: function() {
      return this.getStatuslist;
    }
  },
  created() {
    this.fetchBrand();
    this.fetchDefinedvalues();
  },
  methods: {
    ...mapActions(["fetchBrand", "customerFilter", "fetchDefinedvalues"]),
    filterCustomer() {
      const filterData = {
        page: 1,
        brand: this.brand,
        status: this.status
      };
      //  console.log(filterData);
      this.customerFilter(filterData);
    }
  }
};
</script>
