<template>
  <div class="home">
    <!--loading overlay show component -->
    <bar />
    <!--loading overlay show component -->

    <v-card class="mx-auto ml-5 my-5 mx-5 mr-5" outlined>
      <v-card-text>
        <v-tabs v-model="tab" background-color="primary" dark class="d-none">
          <v-tab> HOME </v-tab>
          <v-tab> SALES PERFORMANCE </v-tab>
          <v-tab> ENQUIRY FOLLOWUP </v-tab>
          <v-tab> MONTHLY SALES PLAN </v-tab>
          <v-tab> WORKSHOP DATABASE UPDATION </v-tab>
          <v-tab> ORDER LOST </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item>
            <!--<v-row class="mb-2 mt-2">
          <v-col cols="12" sm="6" md="6">
            <v-card class="mx-auto" outlined>
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title class="text-h5 mb-1">
                    {{followuplist.todayFollowup}}
                  </v-list-item-title>
                  <v-list-item-subtitle
                    > TODAY FOLLOWUP</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>

          <v-col cols="12" sm="6" md="6">
            <v-card class="mx-auto" outlined>
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title class="text-h5 mb-1">
                     {{followuplist.pendingFollowup}}
                  </v-list-item-title>
                  <v-list-item-subtitle
                    > PENDING FOLLOWUP</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>

          </v-row>-->

            <v-card class="mx-auto ml-5 my-5 mx-5 mr-5 d-none" outlined v-if="userRole != 'sales-engineer'">
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="2">
                    <v-select v-model="region" :items="regions" label="REGION *" class="mt-7" clearable>
                    </v-select>
                  </v-col>

                  <v-col cols="12" md="3">
                    <v-autocomplete v-model="segment" :items="segments" item-text="name" item-value="id" label="SEGMENT*"
                      class="mt-4" chips multiple clearable @change="getSalesengineer(segment)"></v-autocomplete>
                  </v-col>

                  <v-col cols="12" md="3">
                    <v-select v-model="salesengineer" :items="salesengineers" label="SALES ENGINEER *" item-text="name"
                      item-value="id" class="mt-4" chips multiple clearable></v-select>
                  </v-col>

                  <v-col cols="12" md="3">
                    <v-select v-model="productgroup" :items="productgroups" item-text="product_group_name" item-value="id"
                      label="PRODUCT GROUP*" class="mt-4" chips multiple clearable></v-select>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" dark @click="filterEnquiryReport">
                  FIND
                </v-btn>
              </v-card-actions>
            </v-card>

            <v-card flat>
              <v-card-text>
                <v-row justify="space-between">
                  <v-col cols="12" xs="12" sm="12" md="8">
                    <SalesPerformanceDash :selectedsegment="this.segment" :selectedsalesengineer="this.salesengineer"
                      :selectedregion="this.region" :selectedproductgroup="this.productgroup"
                      ref="salesPerformanceDashRef" />
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="4">
                    <!--<EnquiryFollowupPieChartDash
                      :selectedsegment="this.segment"
                      :selectedsalesengineer="this.salesengineer"
                      :selectedregion="this.region"
                      :selectedproductgroup="this.productgroup"
                      ref="enquiryFollowupPieChartDashRef"
                    />-->

                    <EnquiryFollowupFunnelChartDash :selectedsegment="this.segment"
                      :selectedsalesengineer="this.salesengineer" :selectedregion="this.region"
                      :selectedproductgroup="this.productgroup" ref="EnquiryFollowupFunnelChartDashref" />
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="4">
                    <MonthlySalesPlanPieChart :selectedsegment="this.segment" :selectedsalesengineer="this.salesengineer"
                      :selectedregion="this.region" :selectedproductgroup="this.productgroup"
                      ref="monthlySalesPlanPieChartDashRef" />
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="4">
                    <WorkshopDataUpdationChartDash :selectedsegment="this.segment"
                      :selectedsalesengineer="this.salesengineer" :selectedregion="this.region"
                      ref="workshopDataUpdationChartDashRef" />
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="4">
                    <OrderlossChartDash :selectedsegment="this.segment" :selectedsalesengineer="this.salesengineer"
                      :selectedregion="this.region" :selectedproductgroup="this.productgroup"
                      ref="OrderlossChartDashRef" />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card class="mx-auto ml-5 my-5 mx-5 mr-5" outlined v-if="userRole != 'sales-engineer'">
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="2">
                    <v-select v-model="region" :items="regions" label="REGION *" class="mt-7" clearable>
                    </v-select>
                  </v-col>

                  <v-col cols="12" md="2">
                    <v-autocomplete v-model="segment" :items="segments" item-text="name" item-value="id" label="SEGMENT*"
                      class="mt-4" chips multiple clearable @change="getSalesengineer(segment)"></v-autocomplete>
                  </v-col>

                  <v-col cols="12" md="2">
                    <v-select v-model="salesengineer" :items="salesengineers" label="SALES ENGINEER *" item-text="name"
                      item-value="id" class="mt-4" chips multiple clearable></v-select>
                  </v-col>

                  <v-col md="2" cols="12">
                    <v-select v-model="productgroup" :items="productgroups" item-text="product_group_name" item-value="id"
                      label="PRODUCT GROUP*" class="mt-4" chips multiple clearable></v-select>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="warning" dark @click="filterSalesPerformanceReportTab">
                  FIND
                </v-btn>
              </v-card-actions>
            </v-card>

            <v-card flat>
              <v-card-text>
                <v-row justify="space-between">
                  <v-col cols="12" xs="12" sm="12" md="12">
                    <YearlyEnquiryBarchart :selectedsegment="this.segment" :selectedsalesengineer="this.salesengineer"
                      :selectedregion="this.region" :selectedproductgroup="this.productgroup" ref="yearlyBarChartRef" />
                  </v-col>

                  <v-col cols="12" xs="12" sm="12" md="12">
                    <SalesPerformanceReport :selectedsegment="this.segment" :selectedsalesengineer="this.salesengineer"
                      :selectedregion="this.region" :selectedproductgroup="this.productgroup"
                      ref="salesPerformanceReportRef" />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card class="mx-auto ml-5 my-5 mx-5 mr-5" outlined v-if="userRole != 'sales-engineer'">
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="2">
                    <v-select v-model="region" :items="regions" label="REGION *" class="mt-7" clearable>
                    </v-select>
                  </v-col>

                  <v-col cols="12" md="2">
                    <v-autocomplete v-model="segment" :items="segments" item-text="name" item-value="id" label="SEGMENT*"
                      class="mt-4" chips multiple clearable @change="getSalesengineer(segment)"></v-autocomplete>
                  </v-col>

                  <v-col cols="12" md="2">
                    <v-select v-model="salesengineer" :items="salesengineers" label="SALES ENGINEER *" item-text="name"
                      item-value="id" class="mt-4" chips multiple clearable></v-select>
                  </v-col>

                  <v-col md="2" cols="12">
                    <v-select v-model="productgroup" :items="productgroups" item-text="product_group_name" item-value="id"
                      label="PRODUCT GROUP*" class="mt-4" chips multiple clearable></v-select>
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-select v-model="daterange" :items="dateRanges" label="DATE RANGE" class="mt-6"
                      clearable></v-select>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="warning" dark @click="filterEnquiryFollowupReportTab">
                  FIND
                </v-btn>
              </v-card-actions>
            </v-card>

            <v-card flat>
              <v-card-text>
                <v-row justify="space-between">
                  <v-col cols="12" xs="12" sm="12" md="12">
                    <EnquiryFollowupReport :selectedsegment="this.segment" :selectedsalesengineer="this.salesengineer"
                      :selectedregion="this.region" :selectedproductgroup="this.productgroup"
                      :selecteddaterange="this.daterange" ref="enquiryFollowupBarchart" />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card class="mx-auto ml-5 my-5 mx-5 mr-5" outlined v-if="userRole != 'sales-engineer'">
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="2">
                    <v-select v-model="region" :items="regions" label="REGION *" class="mt-7" clearable>
                    </v-select>
                  </v-col>

                  <v-col cols="12" md="2">
                    <v-autocomplete v-model="segment" :items="segments" item-text="name" item-value="id" label="SEGMENT*"
                      class="mt-4" chips multiple clearable @change="getSalesengineer(segment)"></v-autocomplete>
                  </v-col>

                  <v-col cols="12" md="2">
                    <v-select v-model="salesengineer" :items="salesengineers" label="SALES ENGINEER *" item-text="name"
                      item-value="id" class="mt-4" chips multiple clearable></v-select>
                  </v-col>

                  <v-col md="2" cols="12">
                    <v-select v-model="productgroup" :items="productgroups" item-text="product_group_name" item-value="id"
                      label="PRODUCT GROUP*" class="mt-4" chips multiple clearable></v-select>
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-select v-model="daterange" :items="dateRanges" label="DATE RANGE" class="mt-6"
                      clearable></v-select>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="warning" dark @click="filterMonthlySalesPlanTab">
                  FIND
                </v-btn>
              </v-card-actions>
            </v-card>

            <v-card flat>
              <v-card-text>
                <v-row justify="space-between">
                  <v-col cols="12" xs="12" sm="12" md="12">
                    <MonthlySalesPlanBarChart :selectedsegment="this.segment" :selectedsalesengineer="this.salesengineer"
                      :selectedregion="this.region" :selectedproductgroup="this.productgroup"
                      :selecteddaterange="this.daterange" ref="monythlysalesBarchart" />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card class="mx-auto ml-5 my-5 mx-5 mr-5" outlined v-if="userRole != 'sales-engineer'">
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="2">
                    <v-select v-model="region" :items="regions" label="REGION *" class="mt-7" clearable>
                    </v-select>
                  </v-col>

                  <v-col cols="12" md="2">
                    <v-autocomplete v-model="segment" :items="segments" item-text="name" item-value="id" label="SEGMENT*"
                      class="mt-4" chips multiple clearable @change="getSalesengineer(segment)"></v-autocomplete>
                  </v-col>

                  <v-col cols="12" md="2">
                    <v-select v-model="salesengineer" :items="salesengineers" label="SALES ENGINEER *" item-text="name"
                      item-value="id" class="mt-4" chips multiple clearable></v-select>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="warning" dark @click="filterWorkshopUpdationTab">
                  FIND
                </v-btn>
              </v-card-actions>
            </v-card>
            <v-card flat>
              <v-card-text>
                <v-row justify="space-between">
                  <v-col cols="12" xs="12" sm="12" md="12">
                    <WorkshopDataUpdation :selectedsegment="this.segment" :selectedsalesengineer="this.salesengineer"
                      :selectedregion="this.region" ref="workshopUpdationChart" />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card class="mx-auto ml-5 my-5 mx-5 mr-5" outlined v-if="userRole != 'sales-engineer'">
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="2">
                    <v-select v-model="region" :items="regions" label="REGION *" class="mt-7" clearable>
                    </v-select>
                  </v-col>

                  <v-col cols="12" md="2">
                    <v-autocomplete v-model="segment" :items="segments" item-text="name" item-value="id" label="SEGMENT*"
                      class="mt-4" chips multiple clearable @change="getSalesengineer(segment)"></v-autocomplete>
                  </v-col>

                  <v-col cols="12" md="2">
                    <v-select v-model="salesengineer" :items="salesengineers" label="SALES ENGINEER *" item-text="name"
                      item-value="id" class="mt-4" chips multiple clearable></v-select>
                  </v-col>

                  <v-col md="2" cols="12">
                    <v-select v-model="productgroup" :items="productgroups" item-text="product_group_name" item-value="id"
                      label="PRODUCT GROUP*" class="mt-4" chips multiple clearable></v-select>
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-select v-model="daterange" :items="dateRanges" label="DATE RANGE" class="mt-6"
                      clearable></v-select>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="warning" dark @click="filterOrderLossReportchartTab">
                  FIND
                </v-btn>
              </v-card-actions>
            </v-card>

            <v-card flat>
              <v-card-text>
                <v-row justify="space-between">
                  <v-col cols="12" xs="12" sm="12" md="12">
                    <OrderlossReportChart :selectedsegment="this.segment" :selectedsalesengineer="this.salesengineer"
                      :selectedregion="this.region" :selectedproductgroup="this.productgroup"
                      :selecteddaterange="this.daterange" ref="orderLossReportChartref" />
                  </v-col>

                  <!--  <v-col cols="12" xs="12" sm="12" md="6">
        <MonthlySalesPlanPieChart />
      </v-col>-->
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapActions } from "vuex";
import firebaseInitialize from "../firebase";

export default {
  name: "Home",
  data: () => ({
    tab: null,
    productgroup: "",
    region: "",
    segment: "",
    salesengineer: "",
    daterange: ""
  }),
  components: {},
  computed: {
    ...mapGetters([
      "getSalesengineerlist",
      "segmentList",
      "authRole",
      "getRegions",
      "productGroup",
      "getDateRange",
      "followupList"
    ]),
    dateRanges: function () {
      return this.getDateRange;
    },
    salesengineers: function () {
      return this.getSalesengineerlist;
    },
    segments: function () {
      return this.segmentList;
    },
    userRole: function () {
      return this.authRole;
    },
    regions: function () {
      return this.getRegions;
    },
    productgroups: function () {
      return this.productGroup;
    },
    followuplist: function () {
      return this.followupList;
    }
  },
  created() {
    this.fetchSegment();
    this.fetchDefinedvalues();
    this.fetchProductgroup();
    firebaseInitialize();
    // this.fetchFollowuplist();
  },
  methods: {
    ...mapActions([
      "fetchSalesengineers",
      "fetchSegment",
      "salesPerformancereport",
      "fetchProductgroup",
      "fetchDefinedvalues",
      "fetchFollowuplist"
    ]),

    filterEnquiryReport() {
      //salesPerformance filter option
      this.$refs.salesPerformanceDashRef.filterSalesPerformancechartDash();

      //enquiryfollowup pie chart filter option
      //this.$refs.enquiryFollowupPieChartDashRef.filterEnquiryFollowuppiechart();

      //enquiryfollowup Funnel chart filter option
      this.$refs.EnquiryFollowupFunnelChartDashref.filterEnquiryFollowupfunnelchart();

      //enquiryfollowup pie chart filter option
      this.$refs.monthlySalesPlanPieChartDashRef.filterMonthlysalesplanpiechart();

      //Workshop Updation filter option
      this.$refs.workshopDataUpdationChartDashRef.filterworkshopDataUpdationChartDash();

      //Order Loss Chart Dash
      this.$refs.OrderlossChartDashRef.filterOrderlosschart();
    },
    filterSalesPerformanceReportTab() {
      //yearly chart report filter option
      this.$refs.salesPerformanceReportRef.filtersalesPerformancereport();
      this.$refs.yearlyBarChartRef.filterYearlychartReport();
    },
    filterEnquiryFollowupReportTab() {
      this.$refs.enquiryFollowupBarchart.filterEnquiryFollowupReport();
    },
    filterMonthlySalesPlanTab() {
      this.$refs.monythlysalesBarchart.filterMonthlySalesPlanChart();
    },
    filterWorkshopUpdationTab() {
      this.$refs.workshopUpdationChart.filterWorkshopUpdationChart();
    },
    filterOrderLossReportchartTab() {
      this.$refs.orderLossReportChartref.filterOrderLossReport();
    },
    //get segment based  salesengineer
    getSalesengineer(segment) {
      //console.log(segment);
      this.fetchSalesengineers(segment);
    }
  }
};
</script>
