<template>
  <div>
    <v-card class="mx-auto ml-5 my-5 mx-5 mr-5" outlined>
      <highcharts :options="chartOptions" ref="highcharts"></highcharts>
      <!--<button @click="updateCredits">update credits</button>-->
    </v-card>
  </div>
</template>

<script>
// @ is an alias to /src

import { VUE_APP_BASEURL } from "../utils/api";
import axios from "axios";
import store from "../store";

export default {
  name: "SalesPerformanceDash",
  props: [
    "selectedsegment",
    "selectedsalesengineer",
    "selectedregion",
    "selectedproductgroup"
  ],
  data: () => ({
    chartOptions: {
      chart: { height: 485 },
      title: {
        text: "SALES PERFORMANCE REPORT"
      },
      series: [{}],
      xAxis: {
        categories: [],
        labels: {
          useHTML: true,
          align: "center",
          formatter: function() {
            return `<div style="text-align: center">
                        <div style="margin-bottom: 10px">${this.value}</div>
                      </div>`;
          }
        }
      },
      yAxis: [
        {
          labels: {},
          tickAmount: 10,
          title: { enabled: false }
        },
        {
          labels: {},
          tickAmount: 10,
          opposite: true,
          title: { enabled: false }
        }
      ],
      legend: {},
      credits: {
        enabled: false
      }
    }
  }),
  components: {},
  computed: {},
  mounted() {},
  created() {
    this.salesPerformancechart(this.chartOptions);
  },
  methods: {
    salesPerformancechart(chartOptions) {
      const filterData = [];

      axios({
        method: "POST",
        headers: {
          Authorization: "Bearer " + store.getters.UserToken
        },
        url: VUE_APP_BASEURL + "chart/yearly-enquirydata",
        data: {
          segment: filterData.segment,
          salesengineer: filterData.salesengineer,
          region: filterData.region,
          productgroup: filterData.productgroup
        }
      })
        .then(function(response) {
          // console.log(response.data.response.reportDates);
          chartOptions.xAxis.categories = response.data.response.reportDates;
          chartOptions.series = response.data.response.enquiryDashData;
        })
        .catch(function(error) {
          console.log(error.response);
        });
    },

    filterSalesPerformancechartDash() {
      const filterData = {
        segment: this.selectedsegment,
        salesengineer: this.selectedsalesengineer,
        region: this.selectedregion,
        productgroup: this.selectedproductgroup
      };
      console.log(filterData);

      const chartOptions = this.chartOptions;
      axios({
        method: "POST",
        headers: {
          Authorization: "Bearer " + store.getters.UserToken
        },
        url: VUE_APP_BASEURL + "chart/yearly-enquirydata",
        data: {
          segment: filterData.segment,
          salesengineer: filterData.salesengineer,
          region: filterData.region,
          productgroup: filterData.productgroup
        }
      })
        .then(function(response) {
          console.log(response);
          chartOptions.xAxis.categories = response.data.response.reportDates;
          chartOptions.series = response.data.response.enquiryDashData;
        })
        .catch(function(error) {
          console.log(error);
        });
    }
  }
};
</script>
