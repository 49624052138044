import { VUE_APP_BASEURL } from "../../utils/api";
import router from "../../router";
import axios from "axios";
import store from "../../store";

const state = {
  states: [],
  stateResponse: [],
  countryName: "",
  stateName: "",
  status: ""
};

const getters = {
  stateList: state => state.states,
  stateStatusResponse: state => state.stateResponse,
  stateStatus: state => state.status,
  stateDetailsname: state => state.stateName,
  countryidDetailsname: state => state.countryName
};

const actions = {
  //State fetch
  async fetchStates({ commit }) {
    commit("authRequest");
    axios({
      method: "GET",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "state"
    })
      .then(function(response) {
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("setstateList", response.data.response);
        }
      })
      .catch(function(error) {
        console.log(error);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("stateError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  },

  //new State add action
  async stateAdd({ commit }, stateData) {
    commit("authRequest");
    axios({
      method: "POST",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "state",
      data: {
        countryName: stateData.countryName,
        stateName: stateData.stateName
      }
    })
      .then(function(response) {
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("stateaddMethod", response.data.response);
          router.push("/state");
        }
      })
      .catch(function(error) {
        console.log(error);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("stateError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  },
  //new state add action end

  //fetch State details
  async fetchStatedetails({ commit }, stateId) {
    commit("authRequest");

    axios({
      method: "GET",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "state/" + stateId
    })
      .then(function(response) {
        if (response.data.result == 1) {
          commit("authSuccess");
          //    console.log(response.data.response);
          commit("stateDetails", response.data.response);
        }
      })
      .catch(function(error) {
        console.log(error);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("stateError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  },

  //state update action
  async stateUpdate({ commit }, stateData) {
    commit("authRequest");
    axios({
      method: "PUT",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "state/" + stateData.stateId,
      data: {
        countryName: stateData.countryName,
        stateName: stateData.stateName
      }
    })
      .then(function(response) {
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("stateupdateMethod", response.data.response);
          router.push("/state");
        }
      })
      .catch(function(error) {
        console.log(error);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("stateError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  }
  //state update action end
};

const mutations = {
  setstateList: (state, resp) => {
    state.states = resp;
  },
  stateaddMethod: (state, resp) => {
    state.status = "success";
    state.stateResponse = resp;
  },
  stateDetails: (state, resp) => {
    state.status = "";
    state.countryName = resp.country_id;
    state.stateName = resp.name;
    //  console.log(resp);
  },
  stateError: (state, resp) => {
    state.status = "error";
    state.stateResponse = resp;
    // console.log(resp);
  },
  stateupdateMethod: (state, resp) => {
    state.status = "success";
    state.stateResponse = resp;
  },
  updateStatename: (state, statename) => {
    state.stateName = statename;
  },
  updateCountryidname: (state, countryname) => {
    state.countryName = countryname;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
