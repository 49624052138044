var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app-bar',{attrs:{"app":"","color":"primary"}},[_c('div',{staticClass:"white"},[_c('img',{attrs:{"alt":"Vue logo","src":require("../assets/logo.png"),"height":"60"}})]),_c('v-app-bar-nav-icon',{attrs:{"color":"white"},on:{"click":function($event){_vm.drawer = true}}}),_c('v-spacer'),_c('h4',{staticClass:"white--text font-weight-medium"},[_vm._v(_vm._s(_vm.username))])],1),(_vm.loading)?_c('loading'):_vm._e(),_c('v-navigation-drawer',{attrs:{"fixed":"","temporary":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{attrs:{"nav":"","dense":""}},[_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account-circle-outline")])],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.username)+" ")])],1),_c('router-link',{attrs:{"to":"/home"}},[_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-home")])],1),_c('v-list-item-title',[_vm._v(" DASHBOARD ")])],1)],1),(_vm.userRole == 'super-admin')?_c('div',{staticClass:"superadmin-menulist"},[_c('router-link',{attrs:{"to":"/menu"}},[_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-chart-timeline")])],1),_c('v-list-item-title',[_vm._v(" MENU")])],1)],1),_c('router-link',{attrs:{"to":"/role"}},[_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account-cog")])],1),_c('v-list-item-title',[_vm._v(" ROLE")])],1)],1),_c('v-list-group',{scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-view-module")])],1),_c('v-list-item-title',[_vm._v("MASTER")])]},proxy:true}],null,false,2032049474)},_vm._l((_vm.masterlist),function(ref,i){
var title = ref[0];
var url = ref[1];
return _c('router-link',{key:i,attrs:{"link":"","to":url}},[_c('v-list-item',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(title)}})],1)],1)}),1),_c('router-link',{attrs:{"to":"/customer"}},[_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account-group")])],1),_c('v-list-item-title',[_vm._v(" CUSTOMER")])],1)],1),_c('router-link',{attrs:{"to":"/lead"}},[_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-grid")])],1),_c('v-list-item-title',[_vm._v(" LEAD")])],1)],1),_c('router-link',{attrs:{"to":"/proforma"}},[_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-file-sign")])],1),_c('v-list-item-title',[_vm._v(" PROFORMA")])],1)],1),_c('router-link',{attrs:{"to":"/user"}},[_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account-tie")])],1),_c('v-list-item-title',[_vm._v(" USER")])],1)],1),_c('v-list-group',{scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-archive-plus-outline")])],1),_c('v-list-item-title',[_vm._v("ENQUIRY")])]},proxy:true}],null,false,2740407217)},_vm._l((_vm.enquirylist),function(ref,i){
var title = ref[0];
var url = ref[1];
return _c('router-link',{key:i,attrs:{"link":"","to":url}},[_c('v-list-item',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(title)}})],1)],1)}),1),_c('v-list-group',{scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-poll")])],1),_c('v-list-item-title',[_vm._v("REPORT")])]},proxy:true}],null,false,2929656405)},_vm._l((_vm.reportlist),function(ref,i){
var title = ref[0];
var url = ref[1];
return _c('router-link',{key:i,attrs:{"link":"","to":url}},[_c('v-list-item',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(title)}})],1)],1)}),1)],1):_c('div',_vm._l((_vm.sidebarMenus),function(list,j){return _c('div',{key:j},[(list.url == '#')?_c('v-list-group',{scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(list.icon))])],1),_c('v-list-item-title',[_vm._v(_vm._s(list.name))])]},proxy:true}],null,true)},_vm._l((list.subMenu),function(sublist,i){return _c('router-link',{key:i,attrs:{"link":"","to":sublist.url}},[_c('v-list-item',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(sublist.name)}})],1)],1)}),1):_vm._e(),(list.url != '#')?_c('router-link',{attrs:{"link":"","to":list.url}},[_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(list.icon))])],1),_c('v-list-item-title',[_vm._v(_vm._s(list.name))])],1)],1):_vm._e()],1)}),0),_c('v-list-item',{on:{"click":_vm.logout}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-logout")])],1),_c('v-list-item-title',[_c('v-btn',{staticClass:"logout-btn",attrs:{"plain":"","text":""}},[_vm._v(" Logout ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }