import { VUE_APP_BASEURL } from "../../utils/api";
import router from "../../router";
import axios from "axios";
import store from "../../store";

const state = {
  status: "",
  pfmResponse: [],
  monthlySalesplanList: []
};

const getters = {
  getmonthlySalesplanList: state => state.monthlySalesplanList,
  getpfmStatusResponse: state => state.pfmResponse,
  getpfmStatus: state => state.status
};

const actions = {
  //Monthly sales plan list fetch
  async fetchmonthlySalesplanList({ commit }) {
    commit("authRequest");
    axios({
      method: "GET",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "enquiry-monthly-plan-list"
    })
      .then(function(response) {
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("setMonthlysalesplanList", response.data.response);
        }
      })
      .catch(function(error) {
        console.log(error.response);
        if (error.response) {
          console.log(error.response);
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("pfmError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  },

  //new Enquiry add action
  async enquiryBilled({ commit, dispatch }, enquiryData) {
    commit("authRequest");
    // console.log(enquiryData);
    axios({
      method: "PUT",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "enquirybilled/" + enquiryData.enquiryDetailid
    })
      .then(function(response) {
        console.log(response);
        if (response.data.result == 1) {
          commit("authSuccess");
          dispatch("fetchmonthlySalesplanList");
          commit("pfmUpdate", response.data.response);

          //  location.reload();
        }
      })
      .catch(function(error) {
        console.log(error.response);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("pfmError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  },
  //Enquiry Rescheduled action
  async enquiryRescheduled({ commit, dispatch }, enquiryData) {
    commit("authRequest");
    // console.log(enquiryData);
    axios({
      method: "PUT",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "enquiryreschdule/" + enquiryData.enquiryDetailid
    })
      .then(function(response) {
        console.log(response);
        if (response.data.result == 1) {
          commit("authSuccess");
          dispatch("fetchmonthlySalesplanList");
          commit("pfmUpdate", response.data.response);

          //  location.reload();
        }
      })
      .catch(function(error) {
        console.log(error.response);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("pfmError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  },
  //Enquiry Pending action
  async enquiryPending({ commit, dispatch }, enquiryData) {
    commit("authRequest");
    // console.log(enquiryData);
    axios({
      method: "PUT",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "enquirypending/" + enquiryData.enquiryDetailid,
      data: {
        pendingRemarks: enquiryData.pendingRemarks,
        pendingpfmdate: enquiryData.pendingpfmdate
      }
    })
      .then(function(response) {
        console.log(response);
        if (response.data.result == 1) {
          commit("authSuccess");
          dispatch("fetchmonthlySalesplanList");
          commit("pfmUpdate", response.data.response);

          //  location.reload();
        }
      })
      .catch(function(error) {
        console.log(error.response);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("pfmError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  },

  //Enquiry Partial bill action
  async enquiryPartialbill({ commit, dispatch }, enquiryData) {
    commit("authRequest");
    // console.log(enquiryData);
    axios({
      method: "PUT",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url:
        VUE_APP_BASEURL + "enquirypartialbill/" + enquiryData.enquiryDetailid,
      data: {
        partialBillingQuantity: enquiryData.partialBillingQuantity,
        partialpfmdate: enquiryData.partialpfmdate
      }
    })
      .then(function(response) {
        console.log(response);
        if (response.data.result == 1) {
          commit("authSuccess");
          dispatch("fetchmonthlySalesplanList");
          commit("pfmUpdate", response.data.response);

          //  location.reload();
        }
      })
      .catch(function(error) {
        console.log(error.response);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("pfmError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  },

  //Enquiry Lost
  async enquiryLost({ commit, dispatch }, enquiryData) {
    commit("authRequest");
    // console.log(enquiryData);
    axios({
      method: "PUT",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "enquirylost/" + enquiryData.enquiryDetailid,
      data: {
        lostManufacturername: enquiryData.lostManufacturername,
        lostSuppliername: enquiryData.lostSuppliername,
        lostSalesqty: enquiryData.lostSalesqty,
        lostCompetitorunitprice: enquiryData.lostCompetitorunitprice,
        lostCompetitortotalprice: enquiryData.lostCompetitortotalprice,
        lostRemarks: enquiryData.lostRemarks,
        lostReason: enquiryData.lostReason
      }
    })
      .then(function(response) {
        console.log(response);
        if (response.data.result == 1) {
          commit("authSuccess");
          dispatch("fetchmonthlySalesplanList");
          commit("pfmUpdate", response.data.response);

          //  location.reload();
        }
      })
      .catch(function(error) {
        console.log(error.response);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("pfmError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  }
};

const mutations = {
  setMonthlysalesplanList: (state, resp) => {
    state.monthlySalesplanList = resp;
  },
  pfmUpdate: (state, resp) => {
    state.status = "success";
    state.pfmResponse = resp;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
