<template>
  <div>
    <!--container-->
    <v-container>
      <!--tob bar-->
      <bar />
      <!--tob bar end-->

      <!--template-->
      <template>
        <!--card -->
        <v-card class="form-card mx-auto my-12">
          <!--alert box-->
          <v-alert text prominent type="error" v-if="errorResponse">
            <div v-for="(error, i) in customerError" :key="i">
              {{ error }}
            </div>
          </v-alert>
          <!--alert box end-->
          <v-list-item class="headline">
            <v-list-item-content>
              <v-list-item-title>
                CUSTOMER DETAILS
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <!--form-->
          <form enctype="multipart/form-data">
            <!--input field-->
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field v-model="temporary_no" label="TEMPORARY NO *" readonly></v-text-field>
                </v-col>

                <v-col cols="12" sm="12" md="6">
                  <v-text-field v-model="customer_code" label="CUSTOMER CODE" @input="$v.customer_code.$touch()"
                    @blur="$v.customer_code.$touch()"></v-text-field>
                </v-col>

                <v-col cols="12" sm="12" md="6">
                  <v-autocomplete v-model="segment" :error-messages="segmentErrors" :items="segments" item-text="name"
                    item-value="id" label="SEGMENT*" required @input="$v.segment.$touch()"
                    @blur="$v.segment.$touch()"></v-autocomplete>
                </v-col>

                <v-col cols="12" sm="12" md="6">
                  <v-autocomplete v-model="brand" :items="brands" :error-messages="brandErrors" item-text="name"
                    item-value="id" label="LOB*" required @input="$v.brand.$touch()"
                    @blur="$v.brand.$touch()"></v-autocomplete>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" xs="12" sm="12" md="6">
                  <v-text-field v-model="workshop_name" :error-messages="workshopnameErrors" label="WORKSHOP NAME *"
                    required @input="$v.workshop_name.$touch()" @blur="$v.workshop_name.$touch()"></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6">
                  <v-select v-model="workshop_type" :items="workshoptypes" :error-messages="workshoptypeErrors"
                    label="WORKSHOP TYPE *" required @input="$v.workshop_type.$touch()"
                    @blur="$v.workshop_type.$touch()"></v-select>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" xs="12" sm="12" md="6">
                  <v-text-field v-model="address1" :error-messages="address1Errors" label="ADDRESS 1 *" required
                    @input="$v.address1.$touch()" @blur="$v.address1.$touch()"></v-text-field>
                </v-col>

                <v-col xs="12" sm="12" md="6">
                  <v-text-field v-model="address2" label="ADDRESS 2" @input="$v.address2.$touch()"
                    @blur="$v.address2.$touch()"></v-text-field>
                </v-col>
              </v-row>

              <v-row justify="space-between">
                <v-col cols="12" xs="12" sm="12" md="6">
                  <v-autocomplete v-model="state" :items="states" item-text="name" item-value="id"
                    :error-messages="stateErrors" label="STATE *" required @input="$v.state.$touch()"
                    @blur="$v.state.$touch()" v-on:change="getCity(`${state}`)"></v-autocomplete>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6">
                  <v-autocomplete v-model="city" :items="cities" item-text="name" item-value="id"
                    :error-messages="cityErrors" label="DISTRICT *" required @input="$v.city.$touch()"
                    @blur="$v.city.$touch()"></v-autocomplete>
                </v-col>
              </v-row>

              <v-row justify="space-between">
                <v-col cols="12" xs="12" sm="12" md="6">
                  <v-text-field v-model="address3" label="CITY" required @input="$v.address3.$touch()"
                    @blur="$v.address3.$touch()"></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6">
                  <v-text-field v-model="pincode" label="PINCODE" required @input="$v.pincode.$touch()"
                    @blur="$v.pincode.$touch()"></v-text-field>
                </v-col>
              </v-row>

              <v-row justify="space-between">
                <v-col cols="12" xs="12" sm="12" md="4">
                  <v-text-field v-model="owner_name" label="OWNER/ CEO / DIRECTOR"></v-text-field>
                </v-col>

                <v-col xs="12" sm="12" md="4">
                  <v-text-field v-model="owner_contactnumber" label="CONTACT NUMBER"></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="4">
                  <v-text-field v-model="owner_emailid" label="EMAIL ID"></v-text-field>
                </v-col>
              </v-row>

              <v-row justify="space-between">
                <v-col cols="12" xs="12" sm="12" md="4">
                  <v-text-field v-model="gm_name" label="GENERAL MANAGER"></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="4">
                  <v-text-field v-model="gm_contactnumber" label="CONTACT NUMBER"></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="4">
                  <v-text-field v-model="gm_emailid" label="EMAIL ID"></v-text-field>
                </v-col>
              </v-row>

              <v-row justify="space-between">
                <v-col cols="12" xs="12" sm="12" md="4">
                  <v-text-field v-model="sm_name" label="SERVICE MANAGER"></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="4">
                  <v-text-field v-model="sm_contactnumber" label="CONTACT NUMBER"></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="4">
                  <span class="text-right white--text">
                    EMAIL ID
                  </span>
                  <v-text-field v-model="sm_emailid" label="EMAIL ID"></v-text-field>
                </v-col>
              </v-row>

              <v-row justify="space-between">
                <v-col cols="12" xs="12" sm="12" md="4">
                  <v-text-field v-model="contactnumber" label="CONTACT NUMBER*" :error-messages="contactnumberErrors"
                    required @input="$v.contactnumber.$touch()" @blur="$v.contactnumber.$touch()"></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="4">
                  <v-text-field v-model="emailid" label="EMAIL ID*" :error-messages="emailidErrors" required
                    @input="$v.emailid.$touch()" @blur="$v.emailid.$touch()"></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="4">
                  <v-select v-model="region" :items="regions" :error-messages="regionErrors" label="REGION *" required
                    @input="$v.region.$touch()" @blur="$v.region.$touch()"></v-select>
                </v-col>
              </v-row>

              <v-row justify="space-between">
                <v-col cols="12" xs="12" sm="12" md="6">
                  <v-text-field v-model="service_bays" label="SERVICE BAYS"></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6">
                  <v-text-field v-model="bodyshop_bays" label="BODYSHOP BAYS"></v-text-field>
                </v-col>
              </v-row>

              <v-row justify="space-between">
                <v-col cols="12" xs="12" sm="12" md="3">
                  <v-select v-model="customervisit" :items="customervisitstatuses" :error-messages="customervisitErrors"
                    label="CUSTOMER VISIT *" required @input="$v.customervisit.$touch()"
                    @blur="$v.customervisit.$touch()"></v-select>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="3">
                  <v-select v-model="visitstatus" :items="visitstatuses" :error-messages="visitstatusErrors"
                    label="VISIT STATUS*" required @input="$v.visitstatus.$touch()"
                    @blur="$v.visitstatus.$touch()"></v-select>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="3">
                  <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                    offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="visitdate" :error-messages="visitdateErrors" @input="$v.visitdate.$touch()"
                        @blur="$v.visitdate.$touch()" label="VISIT DATE*" prepend-icon="mdi-calendar" readonly
                        v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="visitdate" @input="menu2 = false"></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="3">
                  <v-select v-model="source" :items="sourcelist" :error-messages="sourceErrors" label="SOURCE*" required
                    @input="$v.source.$touch()" @blur="$v.source.$touch()"></v-select>
                </v-col>
              </v-row>

              <v-row justify="space-between" v-for="(pro, k) in customerproductdetails" :key="k">
                <v-col xs="12" sm="12" md="2">
                  <!-- <v-select
                    v-model="pro.equipment"
                    :items="products"
                    
                    item-text="product_name"
                    item-value="id"
                    label="EQUIPMENT"
                  ></v-select>-->

                  <v-autocomplete v-model="pro.equipment" :items="products" item-text="product_name_withtpl"
                    item-value="id" label="EQUIPMENT">
                  </v-autocomplete>
                </v-col>

                <v-col xs="12" sm="12" md="1">
                  <v-text-field v-model="pro.quantity" label="QTY"></v-text-field>
                </v-col>

                <v-col xs="12" sm="12" md="1">
                  <v-text-field v-model="pro.supplier" label="SUPPLIER"></v-text-field>
                </v-col>

                <v-col xs="12" sm="12" md="2">
                  <v-text-field v-model="pro.manufacturer_name" label="MANUFACTURER"></v-text-field>
                </v-col>

                <v-col xs="12" sm="12" md="1">
                  <v-text-field v-model="pro.model" label="MODEL"></v-text-field>
                </v-col>

                <v-col xs="12" sm="12" md="1">
                  <v-text-field v-model="pro.purchase_year" label="PUR YEAR"></v-text-field>
                </v-col>

                <v-col xs="12" sm="12" md="1">
                  <v-select v-model="pro.condition_of_equipment" :items="equipment_conditions"
                    label="EQUIPMENT CONDITION"></v-select>
                </v-col>

                <v-col xs="12" sm="12" md="2">
                  <v-text-field v-model="pro.remarks" label="REMARKS"></v-text-field>
                </v-col>

                <v-col xs="12" sm="12" md="1">
                  <v-btn color="warning" fab dark x-small v-show="customerproductdetails.length > 1"
                    @click="deleteProductdetails(k)">
                    <v-icon dark> mdi-close </v-icon>
                  </v-btn>
                </v-col>
              </v-row>

              <v-row>
                <v-col xs="12" sm="12" md="11"> </v-col>
                <v-col xs="12" sm="12" md="1">
                  <v-btn depressed color="warning" @click="addProductdetails">
                    +ADD
                  </v-btn>
                </v-col>
              </v-row>

              <v-row justify="space-between">
                <v-col xs="12" sm="12" md="4">
                  <v-select v-model="status" :items="status_list" label="STATUS"></v-select>
                </v-col>
              </v-row>
            </v-card-text>
            <!--input field end-->
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" dark @click="submit"> SUBMIT </v-btn>
              <v-btn color="secondary" dark to="/customer"> CANCEL </v-btn>
            </v-card-actions>
          </form>
          <!--form end-->
        </v-card>
        <!--card end-->
      </template>
      <!--template end-->
    </v-container>
    <!--container end-->
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "CustomerEdit",
  data: () => ({
    modal: false,
    menu2: false
  }),
  validations: {
    segment: { required },
    brand: { required },
    workshop_name: { required },
    workshop_type: { required },
    address1: { required },
    state: { required },
    city: { required },
    contactnumber: { required },
    emailid: { required },
    region: { required },
    customervisit: { required },
    visitstatus: { required },
    visitdate: { required },
    source: { required }
  },
  computed: {
    ...mapGetters([
      "segmentList",
      "brandList",
      "stateList",
      "cityList",
      "productList",
      "customerStatus",
      "customerStatusResponse",
      "segmentDetails",
      "brandDetails",
      "workshopNameDetails",
      "workshopTypeDetails",
      "address1Details",
      "address2Details",
      "address3Details",
      "stateDetails",
      "cityDetails",
      "pincodeDetails",
      "contactnumberDetails",
      "emailIdDetails",
      "regionDetails",
      "serviceBaysDetails",
      "bodyshopBaysDetails",
      "customerVisitDetails",
      "visitStatusDetails",
      "visitDateDetails",
      "sourceDetails",
      "ownerNameDetails",
      "ownerContactnumberDetails",
      "ownerEmailidDetails",
      "gmNameDetails",
      "gmContactnumberDetails",
      "gmEmailidDetails",
      "smNameDetails",
      "smContactnumberDetails",
      "smEmailidDetails",
      "getCustometProductDetails",
      "getStatusDetails",
      "getTemporarynoDetails",
      "getCustomercodeDetails",
      "getWorkshoptypes",
      "getRegions",
      "getCustomervisitstatuses",
      "getVisitstatuses",
      "getSourcelist",
      "getEquipmentcondition",
      "getStatuslist"
    ]),
    customerproductdetails: function () {
      return this.getCustometProductDetails;
    },
    segments: function () {
      return this.segmentList;
    },
    brands: function () {
      return this.brandList;
    },
    states: function () {
      return this.stateList;
    },
    cities: function () {
      return this.cityList;
    },
    workshoptypes: function () {
      return this.getWorkshoptypes;
    },
    regions: function () {
      return this.getRegions;
    },
    customervisitstatuses: function () {
      return this.getCustomervisitstatuses;
    },
    visitstatuses: function () {
      return this.getVisitstatuses;
    },
    sourcelist: function () {
      return this.getSourcelist;
    },
    equipment_conditions: function () {
      return this.getEquipmentcondition;
    },
    status_list: function () {
      return this.getStatuslist;
    },
    products: function () {
      return this.productList;
    },
    temporary_no: {
      get() {
        return this.getTemporarynoDetails;
      },
      set(value) {
        this.$store.commit("updateTemporaryno", value);
      }
    },
    customer_code: {
      get() {
        return this.getCustomercodeDetails;
      },
      set(value) {
        this.$store.commit("updateCustomercode", value);
      }
    },

    status: {
      get() {
        return this.getStatusDetails;
      },
      set(value) {
        this.$store.commit("updateStatus", value);
      }
    },
    segment: {
      get() {
        return this.segmentDetails;
      },
      set(value) {
        this.$store.commit("updateSegment", value);
      }
    },
    brand: {
      get() {
        return this.brandDetails;
      },
      set(value) {
        this.$store.commit("updateBrand", value);
      }
    },
    workshop_name: {
      get() {
        return this.workshopNameDetails;
      },
      set(value) {
        this.$store.commit("updateWorkshopName", value);
      }
    },
    workshop_type: {
      get() {
        return this.workshopTypeDetails;
      },
      set(value) {
        this.$store.commit("updateworkshopType", value);
      }
    },
    address1: {
      get() {
        return this.address1Details;
      },
      set(value) {
        this.$store.commit("updateAddress1", value);
      }
    },
    address2: {
      get() {
        return this.address2Details;
      },
      set(value) {
        this.$store.commit("updateAddress2", value);
      }
    },
    address3: {
      get() {
        return this.address3Details;
      },
      set(value) {
        this.$store.commit("updateAddress3", value);
      }
    },
    state: {
      get() {
        return this.stateDetails;
      },
      set(value) {
        this.$store.commit("updateState", value);
      }
    },
    city: {
      get() {
        this.cityGetlist(this.state);

        return this.cityDetails;
      },
      set(value) {
        this.$store.commit("updateCity", value);
      }
    },
    pincode: {
      get() {
        return this.pincodeDetails;
      },
      set(value) {
        this.$store.commit("updatePincode", value);
      }
    },
    contactnumber: {
      get() {
        return this.contactnumberDetails;
      },
      set(value) {
        this.$store.commit("updateContactnumber", value);
      }
    },
    emailid: {
      get() {
        return this.emailIdDetails;
      },
      set(value) {
        this.$store.commit("updateEmailid", value);
      }
    },
    region: {
      get() {
        return this.regionDetails;
      },
      set(value) {
        this.$store.commit("updateRegion", value);
      }
    },
    service_bays: {
      get() {
        return this.serviceBaysDetails;
      },
      set(value) {
        this.$store.commit("updateServiceBay", value);
      }
    },
    bodyshop_bays: {
      get() {
        return this.bodyshopBaysDetails;
      },
      set(value) {
        this.$store.commit("updatebodyshopBay", value);
      }
    },
    customervisit: {
      get() {
        return this.customerVisitDetails;
      },
      set(value) {
        this.$store.commit("updateCustomerVisit", value);
      }
    },
    visitstatus: {
      get() {
        return this.visitStatusDetails;
      },
      set(value) {
        this.$store.commit("updateVisitStatus", value);
      }
    },
    visitdate: {
      get() {
        return this.visitDateDetails;
      },
      set(value) {
        this.$store.commit("updateVisitDate", value);
      }
    },
    source: {
      get() {
        return this.sourceDetails;
      },
      set(value) {
        this.$store.commit("updateSource", value);
      }
    },
    owner_name: {
      get() {
        return this.ownerNameDetails;
      },
      set(value) {
        this.$store.commit("updateOwnerName", value);
      }
    },
    owner_contactnumber: {
      get() {
        return this.ownerContactnumberDetails;
      },
      set(value) {
        this.$store.commit("updateOwnerContactnumber", value);
      }
    },
    owner_emailid: {
      get() {
        return this.ownerEmailidDetails;
      },
      set(value) {
        this.$store.commit("updateOwnerEmailid", value);
      }
    },
    gm_name: {
      get() {
        return this.gmNameDetails;
      },
      set(value) {
        this.$store.commit("updateGmName", value);
      }
    },
    gm_contactnumber: {
      get() {
        return this.gmContactnumberDetails;
      },
      set(value) {
        this.$store.commit("updateGmContactnumber", value);
      }
    },
    gm_emailid: {
      get() {
        return this.gmEmailidDetails;
      },
      set(value) {
        this.$store.commit("updateGmEmailid", value);
      }
    },
    sm_name: {
      get() {
        return this.smNameDetails;
      },
      set(value) {
        this.$store.commit("updateSmName", value);
      }
    },
    sm_contactnumber: {
      get() {
        return this.smContactnumberDetails;
      },
      set(value) {
        this.$store.commit("updateSmContactnumber", value);
      }
    },
    sm_emailid: {
      get() {
        return this.smEmailidDetails;
      },
      set(value) {
        this.$store.commit("updateSmEmailid", value);
      }
    },

    errorResponse: function () {
      return this.customerStatus === "error";
    },
    customerError: function () {
      return this.customerStatusResponse;
    },

    segmentErrors() {
      const errors = [];
      if (!this.$v.segment.$dirty) return errors;
      !this.$v.segment.required && errors.push("Segment is required.");
      return errors;
    },
    brandErrors() {
      const errors = [];
      if (!this.$v.brand.$dirty) return errors;
      !this.$v.brand.required && errors.push("LOB is required.");
      return errors;
    },
    workshopnameErrors() {
      const errors = [];
      if (!this.$v.workshop_name.$dirty) return errors;
      !this.$v.workshop_name.required &&
        errors.push("Workshop Name is required.");
      return errors;
    },
    workshoptypeErrors() {
      const errors = [];
      if (!this.$v.workshop_type.$dirty) return errors;
      !this.$v.workshop_type.required &&
        errors.push("Workshop Type is required.");
      return errors;
    },
    address1Errors() {
      const errors = [];
      if (!this.$v.address1.$dirty) return errors;
      !this.$v.address1.required && errors.push("Address1 is required.");
      return errors;
    },
    stateErrors() {
      const errors = [];
      if (!this.$v.state.$dirty) return errors;
      !this.$v.state.required && errors.push("State is required.");
      return errors;
    },
    cityErrors() {
      const errors = [];
      if (!this.$v.city.$dirty) return errors;
      !this.$v.city.required && errors.push("District is required.");
      return errors;
    },
    contactnumberErrors() {
      const errors = [];
      if (!this.$v.contactnumber.$dirty) return errors;
      !this.$v.contactnumber.required &&
        errors.push("Contact Number is required.");
      return errors;
    },
    emailidErrors() {
      const errors = [];
      if (!this.$v.emailid.$dirty) return errors;
      !this.$v.emailid.required &&
        errors.push("Email ID is required.");
      return errors;
    },
    regionErrors() {
      const errors = [];
      if (!this.$v.region.$dirty) return errors;
      !this.$v.region.required && errors.push("Region is required.");
      return errors;
    },
    customervisitErrors() {
      const errors = [];
      if (!this.$v.customervisit.$dirty) return errors;
      !this.$v.customervisit.required &&
        errors.push("Customer Visit is required.");
      return errors;
    },
    visitstatusErrors() {
      const errors = [];
      if (!this.$v.visitstatus.$dirty) return errors;
      !this.$v.visitstatus.required && errors.push("Visit Status is required.");
      return errors;
    },
    visitdateErrors() {
      const errors = [];
      if (!this.$v.visitdate.$dirty) return errors;
      !this.$v.visitdate.required && errors.push("Visit Date is required.");
      return errors;
    },
    sourceErrors() {
      const errors = [];
      if (!this.$v.source.$dirty) return errors;
      !this.$v.source.required && errors.push("Source is required.");
      return errors;
    }
  },
  created() {
    this.fetchSegment();
    this.fetchBrand();
    this.fetchStates();
    this.fetchProducts();
    this.fetchCustomerdetails(this.$route.params.id);
    this.fetchDefinedvalues();
  },
  methods: {
    ...mapActions([
      "fetchSegment",
      "fetchBrand",
      "fetchStates",
      "cityGetlist",
      "fetchCustomerdetails",
      "fetchProducts",
      "customerUpdate",
      "fetchDefinedvalues"
    ]),
    addProductdetails() {
      this.customerproductdetails.push({
        equipment: "",
        quantity: "",
        supplier: "",
        manufacturer_name: "",
        model: "",
        purchase_year: "",
        condition_of_equipment: "",
        remarks: ""
      });
    },
    deleteProductdetails(counter) {
      this.customerproductdetails.splice(counter, 1);
    },
    getCity(stateid) {
      this.cityGetlist(stateid);
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("ERROR");
      } else {
        // do your submit logic here
        setTimeout(() => {
          const customerData = {
            customerId: this.$route.params.id,
            segment: this.segment,
            customerCode: this.customer_code,
            brand: this.brand,
            workshopName: this.workshop_name.toUpperCase(),
            workshopType: this.workshop_type.toUpperCase(),
            address1: this.address1.toUpperCase(),
            address2: !this.address2
              ? this.address2
              : this.address2.toUpperCase(),
            address3: !this.address3
              ? this.address3
              : this.address3.toUpperCase(),
            state: this.state,
            city: this.city,
            pincode: !this.pincode ? this.pincode : this.pincode.toUpperCase(),
            contactNumber: this.contactnumber,
            emailId: this.emailid,
            region: this.region,
            serviceBays: this.service_bays,
            bodyshopBays: this.bodyshop_bays,
            customerVisit: this.customervisit,
            visitStatus: this.visitstatus,
            visitDate: this.visitdate,
            source: this.source,
            ownerName: !this.owner_name
              ? this.owner_name
              : this.owner_name.toUpperCase(),
            ownerContactnumber: this.owner_contactnumber,
            ownerEmailid: !this.owner_emailid
              ? this.owner_emailid
              : this.owner_emailid.toUpperCase(),
            gmName: !this.gm_name ? this.gm_name : this.gm_name.toUpperCase(),
            gmContactnumber: this.gm_contactnumber,
            gmEmailid: !this.gm_emailid
              ? this.gm_emailid
              : this.gm_emailid.toUpperCase(),
            smName: !this.sm_name ? this.sm_name : this.sm_name.toUpperCase(),
            smContactnumber: this.sm_contactnumber,
            smEmailid: !this.sm_emailid
              ? this.sm_emailid
              : this.sm_emailid.toUpperCase(),
            status: this.status,
            productDetails: this.customerproductdetails
          };

          console.log(customerData);

          this.customerUpdate(customerData);
        }, 500);
      }
    }
  }
};
</script>

<style scoped>
.form-card {
  width: 1400px;
}
</style>
