<template>
  <div>
    <!--container-->
    <v-container>
      <!--tob bar-->
      <bar />
      <!--tob bar end-->

      <!--template-->
      <template>
        <!--alert box-->
        <v-alert
          text
          dense
          color="teal"
          icon="mdi-clock-fast"
          border="left"
          v-if="followupStatusmsg"
        >
          <div v-for="(resp, i) in followupResponse" :key="i">
            {{ resp }}
          </div>
        </v-alert>
        <!--alert box end-->

        <v-row class="mb-2">
          <v-col cols="12" sm="6" md="6">
            <v-card class="mx-auto" outlined>
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title class="text-h5 mb-1">
                    {{ followuplist.todayFollowup }}
                  </v-list-item-title>
                  <v-list-item-subtitle> TODAY FOLLOWUP</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>

          <v-col cols="12" sm="6" md="6">
            <v-card class="mx-auto" outlined>
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title class="text-h5 mb-1">
                    {{ followuplist.pendingFollowup }}
                  </v-list-item-title>
                  <v-list-item-subtitle> PENDING FOLLOWUP</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
        </v-row>

        <!---datatable -->
        <v-data-table
          :headers="headers"
          :items="followuplist.enquiryFollowupList"
          :search="search"
          sort-by="followup_date"
          :sort-desc="[false]"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Enquiry FollowUp</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
              <v-divider class="mx-4" inset vertical></v-divider>

              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="enquiryEdit(item)">
              mdi-pencil
            </v-icon>
          </template>
        </v-data-table>
        <!---datatable end-->
      </template>
      <!--template end-->
    </v-container>
    <!--container end-->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import router from "../../router";

export default {
  name: "EnquiryFollowup",
  data: () => ({
    search: "",
    headers: [
      { text: "Actions", value: "actions", sortable: false },
      { text: "WORKSHOP NAME", value: "workshop_name" },
      {
        text: "CUSTOMER DETAILS",
        align: "start",
        sortable: true,
        value: "customer_details"
      },
      { text: "REFERENCE NO", value: "referenceNo" },
      { text: "SEGMENT", value: "segment" },
      { text: "LOB", value: "brand" },
      { text: "ENGINEER NAME", value: "salesengineer" },
      { text: "CITY", value: "city" },
      { text: "STATE", value: "state" },
      { text: "ENQUIRY DATE", value: "enquiry_date" },
      { text: "TOTAL PRICE", value: "total_price" },
      { text: "FOLLOWUP DATE", value: "followup_date" }
    ]
  }),

  computed: {
    ...mapGetters(["followupList", "followupStatus", "followupStatusResponse"]),
    followuplist: function() {
      return this.followupList;
    },
    followupStatusmsg: function() {
      return this.followupStatus === "success";
    },
    followupResponse: function() {
      return this.followupStatusResponse;
    }
  },

  watch: {},

  created() {
    this.fetchFollowuplist();
  },

  methods: {
    ...mapActions(["fetchFollowuplist"]),

    enquiryEdit(item) {
      const id = item.id;
      router.push({ path: `/enquiry-followup-list/edit/${id}` });
    }
  }
};
</script>
