import { VUE_APP_BASEURL } from "../../utils/api";
import router from "../../router";
import axios from "axios";
import store from "../../store";

const state = {
  customers: [],
  customersPaginate: 0,
  customerResponse: [],
  status: "",
  segment: "",
  brand: "",
  workshopName: "",
  workshopType: "",
  address1: "",
  address2: "",
  address3: "",
  state: "",
  city: "",
  pincode: "",
  contactNumber: "",
  emailId: "",
  region: "",
  serviceBays: "",
  bodyshopBays: "",
  customerVisit: "",
  visitStatus: "",
  visitDate: "",
  source: "",
  ownerName: "",
  ownerContactnumber: "",
  ownerEmailid: "",
  gmName: "",
  gmContactnumber: "",
  gmEmailid: "",
  smName: "",
  smContactnumber: "",
  smEmailid: "",
  customer_status: "",
  customerProductDetails: [],
  temporary_no: "",
  customer_code: "",
  customerStateDetails: []
};

const getters = {
  customerList: state => state.customers,
  customerListPaginate: state => state.customersPaginate,
  customerStatusResponse: state => state.customerResponse,
  customerStatus: state => state.status,
  segmentDetails: state => state.segment,
  brandDetails: state => state.brand,
  workshopNameDetails: state => state.workshopName,
  workshopTypeDetails: state => state.workshopType,
  address1Details: state => state.address1,
  address2Details: state => state.address2,
  address3Details: state => state.address3,
  stateDetails: state => state.state,
  cityDetails: state => state.city,
  pincodeDetails: state => state.pincode,
  contactnumberDetails: state => state.contactNumber,
  emailIdDetails: state => state.emailId,
  regionDetails: state => state.region,
  serviceBaysDetails: state => state.serviceBays,
  bodyshopBaysDetails: state => state.bodyshopBays,
  customerVisitDetails: state => state.customerVisit,
  visitStatusDetails: state => state.visitStatus,
  visitDateDetails: state => state.visitDate,
  sourceDetails: state => state.source,
  ownerNameDetails: state => state.ownerName,
  ownerContactnumberDetails: state => state.ownerContactnumber,
  ownerEmailidDetails: state => state.ownerEmailid,
  gmNameDetails: state => state.gmName,
  gmContactnumberDetails: state => state.gmContactnumber,
  gmEmailidDetails: state => state.gmEmailid,
  smNameDetails: state => state.smName,
  smContactnumberDetails: state => state.smContactnumber,
  smEmailidDetails: state => state.smEmailid,
  getCustometProductDetails: state => state.customerProductDetails,
  getStatusDetails: state => state.customer_status,
  getTemporarynoDetails: state => state.temporary_no,
  getCustomercodeDetails: state => state.customer_code,
  getCustomerDetails: state => state.customerStateDetails
};

const actions = {
  //customer fetch
  async customerFilter({ commit }, filterData) {
    commit("authRequest");
    axios({
      method: "GET",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url:
        VUE_APP_BASEURL +
        "customer?page=" +
        filterData.page +
        "&brand=" +
        filterData.brand +
        "&status=" +
        filterData.status +
        "&workshopName=" +
        filterData.workshopName
    })
      .then(function(response) {
        //console.log(response);
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("setcustomerList", response.data.response);
        }
      })
      .catch(function(error) {
        if (error.response) {
          // client received an error response (5xx, 4xx)

          console.log(error.response);

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("customerError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  },

  //customer fetch
  async fetchCustomers({ commit }) {
    commit("authRequest");
    axios({
      method: "GET",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "customer"
    })
      .then(function(response) {
        console.log(response);
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("setcustomerList", response.data.response);
        }
      })
      .catch(function(error) {
        if (error.response) {
          // client received an error response (5xx, 4xx)

          console.log(error.response);

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("customerError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  },

  //new customer add action
  async customerAdd({ commit }, customerData) {
    commit("authRequest");
    axios({
      method: "POST",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "customer",
      data: {
        segment: customerData.segment,
        brand: customerData.brand,
        workshopName: customerData.workshopName,
        workshopType: customerData.workshopType,
        address1: customerData.address1,
        address2: customerData.address2,
        address3: customerData.address3,
        state: customerData.state,
        city: customerData.city,
        pincode: customerData.pincode,
        contactNumber: customerData.contactNumber,
        emailId: customerData.emailId,
        region: customerData.region,
        serviceBays: customerData.serviceBays,
        bodyshopBays: customerData.bodyshopBays,
        customerVisit: customerData.customerVisit,
        visitStatus: customerData.visitStatus,
        visitDate: customerData.visitDate,
        source: customerData.source,
        ownerName: customerData.ownerName,
        ownerContactnumber: customerData.ownerContactnumber,
        ownerEmailid: customerData.ownerEmailid,
        gmName: customerData.gmName,
        gmContactnumber: customerData.gmContactnumber,
        gmEmailid: customerData.gmEmailid,
        smName: customerData.smName,
        smContactnumber: customerData.smContactnumber,
        smEmailid: customerData.smEmailid
        //customerContactdetails: customerData.customerContactdetails,
      }
    })
      .then(function(response) {
        // console.log(response);
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("customeraddMethod", response.data.response);
          router.push("/customer");
        }
      })
      .catch(function(error) {
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("customerError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  },
  //new customer add action end

  //fetch customer details
  async fetchCustomerdetails({ commit }, customerId) {
    commit("authRequest");

    axios({
      method: "GET",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "customer/" + customerId
    })
      .then(function(response) {
        //  console.log(response);
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("customerDetails", response.data.response);
        }
      })
      .catch(function(error) {
        //  console.log(error);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("customerError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  },

  //Product update action
  async customerUpdate({ commit }, customerData) {
    // console.log(customerData);
    //  console.log(store.getters.UserToken);
    commit("authRequest");
    axios({
      method: "PUT",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "customer/" + customerData.customerId,
      data: {
        segment: customerData.segment,
        brand: customerData.brand,
        customerCode: customerData.customerCode,
        workshopName: customerData.workshopName,
        workshopType: customerData.workshopType,
        address1: customerData.address1,
        address2: customerData.address2,
        address3: customerData.address3,
        state: customerData.state,
        city: customerData.city,
        pincode: customerData.pincode,
        contactNumber: customerData.contactNumber,
        emailId: customerData.emailId,
        region: customerData.region,
        serviceBays: customerData.serviceBays,
        bodyshopBays: customerData.bodyshopBays,
        customerVisit: customerData.customerVisit,
        visitStatus: customerData.visitStatus,
        visitDate: customerData.visitDate,
        source: customerData.source,
        status: customerData.status,
        ownerName: customerData.ownerName,
        ownerContactnumber: customerData.ownerContactnumber,
        ownerEmailid: customerData.ownerEmailid,
        gmName: customerData.gmName,
        gmContactnumber: customerData.gmContactnumber,
        gmEmailid: customerData.gmEmailid,
        smName: customerData.smName,
        smContactnumber: customerData.smContactnumber,
        smEmailid: customerData.smEmailid,
        // customerContactdetails: customerData.customerContactdetails,
        productDetails: customerData.productDetails
      }
    })
      .then(function(response) {
        //  console.log(response);
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("customerupdateMethod", response.data.response);
          router.push("/customer");
        }
      })
      .catch(function(error) {
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("customerError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  },
  //city update action end

  //fetch customer details based on customer code
  async fetchCustomerdetailsbasedoncustomercode({ commit }, customercode) {
    commit("authRequest");

    axios({
      method: "POST",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "customer/customercodebasedetails",
      data: {
        customer_code: customercode
      }
    })
      .then(function(response) {
        console.log(response);
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("customercodeDetails", response.data.response);
        }
      })
      .catch(function(error) {
        //  console.log(error);
        if (error.response) {
          // client received an error response (5xx, 4xx)
          console.log(error.response);
          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("customerError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  }
};

const mutations = {
  setcustomerList: (state, resp) => {
    console.log(resp.customerData);
    state.customers = resp.customerData;
    state.customersPaginate = resp.customerPaginate.last_page;
  },
  customeraddMethod: (state, resp) => {
    state.status = "success";
    state.customerResponse = resp;
  },
  customerupdateMethod: (state, resp) => {
    state.status = "success";
    state.customerResponse = resp;
  },
  customerError: (state, resp) => {
    state.status = "error";
    state.customerResponse = resp;
    console.log(resp);
  },
  customerDetails: (state, resp) => {
    state.status = "";
    state.segment = resp.segment;
    state.brand = resp.brand;
    state.workshopName = resp.workshopName;
    state.workshopType = resp.workshopType;
    state.address1 = resp.address1;
    state.address2 = resp.address2;
    state.address3 = resp.address3;
    state.state = resp.state;
    state.city = resp.city;
    state.pincode = resp.pincode;
    state.emailId = resp.emailId;
    state.contactNumber = resp.contactNumber;
    state.region = resp.region;
    state.serviceBays = resp.serviceBays;
    state.bodyshopBays = resp.bodyshopBays;
    state.customerVisit = resp.customerVisit;
    state.visitStatus = resp.visitStatus;
    state.visitDate = resp.visitDate;
    state.source = resp.source;

    state.ownerName = resp.ownerName;
    state.ownerContactnumber = resp.ownerContactnumber;
    state.ownerEmailid = resp.ownerEmailid;
    state.gmName = resp.gmName;
    state.gmContactnumber = resp.gmContactnumber;
    state.gmEmailid = resp.gmEmailid;
    state.smName = resp.smName;
    state.smContactnumber = resp.smContactnumber;
    state.smEmailid = resp.smEmailid;
    state.customerProductDetails = resp.productList;
    state.customer_status = resp.status;

    state.temporary_no = resp.temporaryNo;
    state.customer_code = resp.customerCode;

    state.customerStateDetails = resp;
  },
  customercodeDetails: (state, resp) => {
    state.status = "";
    state.workshopName = resp.workshopName;
    state.address1 = resp.address1;
    state.address2 = resp.address2;
  },
  updateTemporaryno: (state, temporary_no) => {
    state.temporary_no = temporary_no;
  },
  updateCustomercode: (state, customer_code) => {
    state.customer_code = customer_code;
  },
  updateStatus: (state, customer_status) => {
    state.customer_status = customer_status;
  },
  updateSegment: (state, segment) => {
    state.segment = segment;
  },
  updateBrand: (state, brand) => {
    state.brand = brand;
  },
  updateWorkshopName: (state, workshopName) => {
    state.workshopName = workshopName;
  },
  updateworkshopType: (state, workshopType) => {
    state.workshopType = workshopType;
  },
  updateAddress1: (state, address1) => {
    state.address1 = address1;
  },
  updateAddress2: (state, address2) => {
    state.address2 = address2;
  },
  updateAddress3: (state, address3) => {
    state.address3 = address3;
  },
  updateState: (state, stateid) => {
    state.state = stateid;
  },
  updateCity: (state, city) => {
    state.city = city;
  },
  updatePincode: (state, pincode) => {
    state.pincode = pincode;
  },
  updateContactnumber: (state, contactnumber) => {
    state.contactNumber = contactnumber;
  },
  updateEmailid: (state, emailid) => {
    state.emailId = emailid;
  },
  updateRegion: (state, region) => {
    state.region = region;
  },
  updateServiceBays: (state, servicebays) => {
    state.serviceBays = servicebays;
  },
  updateBodyshopBays: (state, bodyshopbays) => {
    state.bodyshopBays = bodyshopbays;
  },
  updateCustomerVisit: (state, customervisit) => {
    state.customerVisit = customervisit;
  },
  updateVisitStatus: (state, visitstatus) => {
    state.visitStatus = visitstatus;
  },
  updateVisitDate: (state, visitdate) => {
    state.visitDate = visitdate;
  },
  updateSource: (state, source) => {
    state.source = source;
  },

  updateOwnerName: (state, ownerName) => {
    state.ownerName = ownerName;
  },
  updateOwnerContactnumber: (state, ownerContactnumber) => {
    state.ownerContactnumber = ownerContactnumber;
  },
  updateOwnerEmailid: (state, ownerEmailid) => {
    state.ownerEmailid = ownerEmailid;
  },
  updateGmName: (state, gmName) => {
    state.gmName = gmName;
  },
  updateGmContactnumber: (state, gmContactnumber) => {
    state.gmContactnumber = gmContactnumber;
  },
  updateGmEmailid: (state, gmEmailid) => {
    state.gmEmailid = gmEmailid;
  },
  updateSmName: (state, smName) => {
    state.smName = smName;
  },
  updateSmContactnumber: (state, smContactnumber) => {
    state.smContactnumber = smContactnumber;
  },
  updateSmEmailid: (state, smEmailid) => {
    state.smEmailid = smEmailid;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
