<template>
  <div>
    <!--container-->
    <v-container>
      <!--tob bar-->
      <bar />
      <!--tob bar end-->

      <!--template-->
      <template>
        <!--card -->
        <v-card class="form-card mx-auto my-12">
          <!--alert box-->
          <v-alert text prominent type="error" v-if="errorResponse">
            <div v-for="(error, i) in productError" :key="i">
              {{ error }}
            </div>
          </v-alert>
          <!--alert box end-->
          <v-list-item class="headline">
            <v-list-item-content>
              <v-list-item-title> PRODUCT EDIT </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <!--form-->
          <form enctype="multipart/form-data">
            <!--input field-->
            <v-card-text>
              <v-row justify="space-between">
                <v-col cols="12" xs="12" sm="12" md="6">
                  <v-select
                    v-model="productgroup"
                    :error-messages="productgroupErrors"
                    :items="productgroups"
                    item-text="product_group_name"
                    item-value="id"
                    label="PRODUCT GROUP*"
                    required
                    @input="$v.productgroup.$touch()"
                    @blur="$v.productgroup.$touch()"
                  ></v-select>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6">
                  <span class="text-right white--text"> TPL NO* </span>
                  <v-text-field
                    v-model="tplno"
                    :error-messages="tplnoErrors"
                    label="TPL NO*"
                    required
                    @input="$v.tplno.$touch()"
                    @blur="$v.tplno.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row justify="space-between">
                <v-col cols="12" xs="12" sm="12" md="6">
                  <v-text-field
                    v-model="equipmentname"
                    :error-messages="equipmentnameErrors"
                    label="EQUIPMENT NAME *"
                    required
                    @input="$v.equipmentname.$touch()"
                    @blur="$v.equipmentname.$touch()"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6">
                  <v-img
                    max-height="150"
                    max-width="250"
                    v-bind:src="productImageshow"
                    class="mb-5"
                  ></v-img>
                  <v-file-input
                    v-model="productimage"
                    accept="image/*"
                    label="PRODUCT IMAGE*"
                    @change="onFileSelected"
                  ></v-file-input>
                </v-col>
              </v-row>

              <v-row justify="space-between">
                <v-col cols="12" xs="12" sm="12" md="6">
                  <v-text-field
                    v-model="warranty_in_years"
                    label="WARRANTY IN YEARS *"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6">
                  <v-text-field
                    v-model="warranty_exclusions"
                    :error-messages="warranty_exclusionsErrors"
                    label="WARRANTY EXCLUSIONS *"
                    required
                    @input="$v.warranty_exclusions.$touch()"
                    @blur="$v.warranty_exclusions.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row justify="space-between">
                <v-col cols="12" xs="12" sm="12" md="6">
                  <v-text-field
                    v-model="model"
                    :error-messages="modelErrors"
                    label="MODEL *"
                    required
                    @input="$v.model.$touch()"
                    @blur="$v.model.$touch()"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6">
                  <v-text-field
                    v-model="scope_of_supply"
                    :error-messages="scope_of_supplyErrors"
                    label="SCOPE OF SUPPLY *"
                    required
                    @input="$v.scope_of_supply.$touch()"
                    @blur="$v.scope_of_supply.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row justify="space-between">
                <v-col cols="12" xs="12" sm="12" md="6">
                  <v-text-field
                    v-model="product_specification"
                    :error-messages="product_specificationErrors"
                    label="PRODUCT SPECIFICATION *"
                    required
                    @input="$v.product_specification.$touch()"
                    @blur="$v.product_specification.$touch()"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6">
                  <v-text-field
                    v-model="special_features"
                    :error-messages="special_featuresErrors"
                    label="SPECIAL FEATURES *"
                    required
                    @input="$v.special_features.$touch()"
                    @blur="$v.special_features.$touch()"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6">
                  <v-select
                    v-model="productactive"
                    label="ACTIVE STATUS"
                    :items="productactiveStatus"
                  ></v-select>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6"> </v-col>

                <v-col cols="12" xs="12" sm="12" md="6">
                  <a :href="urlAttachment1" target="_blank"
                    >{{ urlAttachment1filename }}
                  </a>

                  <v-file-input
                    v-model="attachment1"
                    label="PRODUCT CATALOGUE"
                    @change="attachment1Selected"
                  ></v-file-input>

                  <!--<input type="file"  @change="attachment1Selected">-->
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6">
                  <a :href="urlAttachment2" target="_blank">{{
                    urlAttachment2filename
                  }}</a>

                  <v-file-input
                    v-model="attachment2"
                    label="PRODUCT PPT"
                    @change="attachment2Selected"
                  ></v-file-input>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6">
                  <a :href="urlAttachment3" target="_blank"
                    >{{ urlAttachment3filename }}
                  </a>

                  <v-file-input
                    v-model="attachment3"
                    label="PRODUCT BPV"
                    @change="attachment3Selected"
                  ></v-file-input>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6">
                  <a :href="urlAttachment4" target="_blank">{{
                    urlAttachment4filename
                  }}</a>

                  <v-file-input
                    v-model="attachment4"
                    label="PRODUCT VIDEO"
                    @change="attachment4Selected"
                  ></v-file-input>
                </v-col>
              </v-row>

              <v-row
                justify="space-between"
                v-for="(brand, k) in brands"
                :key="k"
                :set="(v = $v.brands.$each[k])"
              >
                <v-col xs="12" sm="12" md="4">
                  <v-text-field
                    v-model="brand.name"
                    label="BRAND *"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col xs="12" sm="12" md="4">
                  <v-text-field
                    v-model="brand.price"
                    label="PRICE *"
                    :error="v.price.$error"
                  ></v-text-field>
                </v-col>
                <v-col xs="12" sm="12" md="4">
                  <v-text-field
                    v-model="brand.warranty"
                    label="WARRANTY *"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <!--input field end-->
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="black" dark @click="submit"> SUBMIT </v-btn>
              <v-btn color="warning" dark to="/product"> CANCEL </v-btn>
            </v-card-actions>
          </form>
          <!--form end-->
        </v-card>
        <!--card end-->
      </template>
      <!--template end-->
    </v-container>
    <!--container end-->
  </div>
</template>

<script>
import { required, decimal } from "vuelidate/lib/validators";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "ProductEdit",
  data: () => ({
    selectedFile: null,
    productimage: [],
    attachment1: [],
    attachment1File: null,
    attachment1name: "",
    attachment2: [],
    attachment2File: null,
    attachment2name: "",
    attachment3: [],
    attachment3File: null,
    attachment3name: "",
    attachment4: [],
    attachment4File: null,
    attachment4name: "",
    productactiveStatus: ["YES", "NO"]
  }),
  validations: {
    productgroup: { required },
    tplno: { required },
    brands: {
      $each: {
        price: {
          decimal
        }
      }
    },
    equipmentname: { required },
    warranty_exclusions: { required },
    model: { required },
    scope_of_supply: { required },
    product_specification: { required },
    special_features: { required },
    warranty_in_years: { required }
  },
  computed: {
    ...mapGetters([
      "productImageDetails",
      "brandList",
      "productGroup",
      "productStatus",
      "productStatusResponse",
      "productgroupDetailsname",
      "tplnoDetailsname",
      "equipmentnameDetailsname",
      "warrantyinyearsDetailsname",
      "warrantyexclusionsDetailsname",
      "modelDetailsname",
      "scopeofsupplyDetailsname",
      "productspecificationDetailsname",
      "specialfeaturesDetailsname",
      "productBrandPriceDetails",
      "getAttachment1",
      "getAttachment2",
      "getAttachment3",
      "getAttachment4",
      "getAttachment1filename",
      "getAttachment2filename",
      "getAttachment3filename",
      "getAttachment4filename",
      "getProductActive"
    ]),
    productImageshow: function() {
      return this.productImageDetails;
    },
    brands: function() {
      // console.log(this.productBrandPriceDetails);
      return this.productBrandPriceDetails;
    },
    urlAttachment1filename: function() {
      return this.getAttachment1filename;
    },
    urlAttachment2filename: function() {
      return this.getAttachment2filename;
    },
    urlAttachment3filename: function() {
      return this.getAttachment3filename;
    },
    urlAttachment4filename: function() {
      return this.getAttachment4filename;
    },
    urlAttachment1: function() {
      return this.getAttachment1;
    },
    urlAttachment2: function() {
      return this.getAttachment2;
    },
    urlAttachment3: function() {
      return this.getAttachment3;
    },
    urlAttachment4: function() {
      return this.getAttachment4;
    },
    productgroups: function() {
      return this.productGroup;
    },
    errorResponse: function() {
      return this.productStatus === "error";
    },
    productError: function() {
      return this.productStatusResponse;
    },

    productgroupErrors() {
      const errors = [];
      if (!this.$v.productgroup.$dirty) return errors;
      !this.$v.productgroup.required &&
        errors.push("Product Group is required.");
      return errors;
    },
    tplnoErrors() {
      const errors = [];
      if (!this.$v.tplno.$dirty) return errors;
      !this.$v.tplno.required && errors.push("TPL NO is required");
      return errors;
    },
    equipmentnameErrors() {
      const errors = [];
      if (!this.$v.equipmentname.$dirty) return errors;
      !this.$v.equipmentname.required &&
        errors.push("Equipment Name is required");
      return errors;
    },
    warranty_exclusionsErrors() {
      const errors = [];
      if (!this.$v.warranty_exclusions.$dirty) return errors;
      !this.$v.warranty_exclusions.required &&
        errors.push("Warrenty Exclusion is required.");
      return errors;
    },
    modelErrors() {
      const errors = [];
      if (!this.$v.model.$dirty) return errors;
      !this.$v.model.required && errors.push("Model is required");
      return errors;
    },
    scope_of_supplyErrors() {
      const errors = [];
      if (!this.$v.scope_of_supply.$dirty) return errors;
      !this.$v.scope_of_supply.required &&
        errors.push("Scope Of Supply is required.");
      return errors;
    },
    product_specificationErrors() {
      const errors = [];
      if (!this.$v.product_specification.$dirty) return errors;
      !this.$v.product_specification.required &&
        errors.push("Product Specification is required");
      return errors;
    },
    special_featuresErrors() {
      const errors = [];
      if (!this.$v.special_features.$dirty) return errors;
      !this.$v.special_features.required &&
        errors.push("Special Features is required");
      return errors;
    },

    productactive: {
      get() {
        return this.getProductActive;
      },
      set(value) {
        this.$store.commit("updateproductActive", value);
      }
    },
    productgroup: {
      get() {
        return this.productgroupDetailsname;
      },
      set(value) {
        this.$store.commit("updateproductGroup", value);
      }
    },
    tplno: {
      get() {
        return this.tplnoDetailsname;
      },
      set(value) {
        this.$store.commit("updatetplno", value);
      }
    },
    equipmentname: {
      get() {
        return this.equipmentnameDetailsname;
      },
      set(value) {
        this.$store.commit("updateequipmentname", value);
      }
    },
    warranty_in_years: {
      get() {
        return this.warrantyinyearsDetailsname;
      },
      set(value) {
        this.$store.commit("updatewarrantyinyears", value);
      }
    },
    warranty_exclusions: {
      get() {
        return this.warrantyexclusionsDetailsname;
      },
      set(value) {
        this.$store.commit("updatewarrantyexclusions", value);
      }
    },
    model: {
      get() {
        return this.modelDetailsname;
      },
      set(value) {
        this.$store.commit("updatemodel", value);
      }
    },
    scope_of_supply: {
      get() {
        return this.scopeofsupplyDetailsname;
      },
      set(value) {
        this.$store.commit("updatescopeofsupply", value);
      }
    },
    product_specification: {
      get() {
        return this.productspecificationDetailsname;
      },
      set(value) {
        this.$store.commit("updateproductspecification", value);
      }
    },
    special_features: {
      get() {
        return this.specialfeaturesDetailsname;
      },
      set(value) {
        this.$store.commit("updatespecialfeatures", value);
      }
    }
  },
  created() {
    this.fetchProductgroup();
    const productData = {
      customerId: 0,
      productId: this.$route.params.id
    };

    this.fetchProductdetails(productData);
  },
  methods: {
    ...mapActions([
      "fetchProductgroup",
      "fetchProductdetails",
      "productUpdate"
    ]),
    onFileSelected(event) {
      let image = event;
      let reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = event => {
        this.selectedFile = event.target.result;
      };
    },
    attachment1Selected(event) {
      // console.log(event.name);
      this.attachment1name = event.name;
      let attach1 = event;
      let reader = new FileReader();
      reader.readAsDataURL(attach1);
      reader.onload = event => {
        this.attachment1File = event.target.result;
      };

      // this.attachment1File = event;

      // this.attachment1File = event.target.files[0];
    },
    attachment2Selected(event) {
      let attach2 = event;
      this.attachment2name = event.name;
      let reader = new FileReader();
      reader.readAsDataURL(attach2);
      reader.onload = event => {
        this.attachment2File = event.target.result;
      };

      //this.attachment2File = event;
    },
    attachment3Selected(event) {
      let attach3 = event;
      this.attachment3name = event.name;
      let reader = new FileReader();
      reader.readAsDataURL(attach3);
      reader.onload = event => {
        this.attachment3File = event.target.result;
      };
    },
    attachment4Selected(event) {
      let attach4 = event;
      this.attachment4name = event.name;
      let reader = new FileReader();
      reader.readAsDataURL(attach4);
      reader.onload = event => {
        this.attachment4File = event.target.result;
      };
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("ERROR");
      } else {
        // do your submit logic here
        setTimeout(() => {
          const productData = {
            productId: this.$route.params.id,
            productGroup: this.productgroup,
            tplNo: this.tplno.toUpperCase(),
            equipmentName: this.equipmentname.toUpperCase(),
            productImage: this.selectedFile,
            warrantyinYears: this.warranty_in_years.toUpperCase(),
            warrantyExclusions: this.warranty_exclusions.toUpperCase(),
            model: this.model.toUpperCase(),
            scopeofSupply: this.scope_of_supply.toUpperCase(),
            productSpecification: this.product_specification.toUpperCase(),
            specialFeatures: this.special_features.toUpperCase(),
            brandPrice: this.brands,
            attachment1name: this.attachment1name,
            attachment1: this.attachment1File,
            attachment2name: this.attachment2name,
            attachment2: this.attachment2File,
            attachment3name: this.attachment3name,
            attachment3: this.attachment3File,
            attachment4name: this.attachment4name,
            attachment4: this.attachment4File,
            productactive: this.productactive
          };

          // console.log(productData);
          this.productUpdate(productData);
        }, 500);
      }
    }
  }
};
</script>
