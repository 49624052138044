<template>
  <div>
    <v-card class="mx-auto ml-5 my-5 mx-5 mr-5" outlined>
      <v-row align="center" class="mt-2 mr-2">
        <v-col class="d-flex" cols="12" sm="6"></v-col>
        <v-col class="d-flex" cols="12" sm="6">
          <v-select
            v-model="daterange"
            :items="dateRanges"
            label="DATE RANGE"
            dense
            outlined
            @change="filterEnquiryFollowuppiechart()"
          ></v-select>
        </v-col>
      </v-row>
      <highcharts :options="chartOptions" ref="highcharts"></highcharts>
      <!--<button @click="updateCredits">update credits</button>-->
    </v-card>
  </div>
</template>

<script>
// @ is an alias to /src

import { mapActions, mapGetters } from "vuex";
import { VUE_APP_BASEURL } from "../utils/api";
import axios from "axios";
import store from "../store";

export default {
  name: "EnquiryFollowupPieChartDash",
  props: [
    "selectedsegment",
    "selectedsalesengineer",
    "selectedregion",
    "selectedproductgroup"
  ],
  data: () => ({
    daterange: "",
    chartOptions: {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: "pie",
        height: 400
      },
      title: {
        text: "ENQUIRY FOLLOWUP"
      },
      tooltip: {
        pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>"
      },
      accessibility: {
        point: {
          valueSuffix: "%"
        }
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: false,
            format: "<b>{point.name}</b>: {point.percentage:.1f} %"
          },
          showInLegend: true
        }
      },
      series: [
        {
          name: "Brands",
          colorByPoint: true,
          data: [{}]
        }
      ],
      credits: {
        enabled: false
      }
    }
  }),
  components: {},
  computed: {
    ...mapGetters(["getDateRange"]),
    dateRanges: function() {
      return this.getDateRange;
    }
  },
  mounted() {},
  created() {
    this.fetchDefinedvalues();
    this.enquiryFollowuppiechart(this.chartOptions);
  },
  methods: {
    ...mapActions(["fetchDefinedvalues"]),
    enquiryFollowuppiechart(chartOptions) {
      const filterData = [];
      axios({
        method: "POST",
        headers: {
          Authorization: "Bearer " + store.getters.UserToken
        },
        url: VUE_APP_BASEURL + "dashboard/enquiryfollowup-report-chart",
        data: {
          segment: filterData.segment,
          salesengineer: filterData.salesengineer,
          daterange: filterData.daterange,
          region: filterData.region,
          productgroup: filterData.productgroup
        }
      })
        .then(function(response) {
          chartOptions.series[0].data =
            response.data.response.enquiryFollowuppiechartData;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    filterEnquiryFollowuppiechart() {
      const filterData = {
        segment: this.selectedsegment,
        salesengineer: this.selectedsalesengineer,
        daterange: this.daterange,
        region: this.selectedregion,
        productgroup: this.selectedproductgroup
      };
      const chartOptions = this.chartOptions;
      axios({
        method: "POST",
        headers: {
          Authorization: "Bearer " + store.getters.UserToken
        },
        url: VUE_APP_BASEURL + "dashboard/enquiryfollowup-report-chart",
        data: {
          segment: filterData.segment,
          salesengineer: filterData.salesengineer,
          daterange: filterData.daterange,
          region: filterData.region,
          productgroup: filterData.productgroup
        }
      })
        .then(function(response) {
          //  console.log(response);
          chartOptions.series[0].data =
            response.data.response.enquiryFollowuppiechartData;
        })
        .catch(function(error) {
          console.log(error);
          console.log(error.response);
        });
    }
  }
};
</script>
