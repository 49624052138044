import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import NotFound from "../views/NotFound.vue";

/**productgroup Components */
import ProductGroup from "../views/productgroup/ProductGroup.vue";
import ProductGroupAdd from "../views/productgroup/Add.vue";
import ProductGroupEdit from "../views/productgroup/Edit.vue";
/**productgroup Components End */

/**Country Components */
import Country from "../views/country/Country.vue";
import CountryAdd from "../views/country/Add.vue";
import CountryEdit from "../views/country/Edit.vue";
/**Country Components End*/

/**Brand Components */
import Brand from "../views/brand/Brand.vue";
import BrandAdd from "../views/brand/Add.vue";
import BrandEdit from "../views/brand/Edit.vue";
/**Brand Components End*/

/**State Components */
import State from "../views/state/State.vue";
import StateAdd from "../views/state/Add.vue";
import StateEdit from "../views/state/Edit.vue";
/**State Components End*/

/**City Components */
import City from "../views/city/City.vue";
import CityAdd from "../views/city/Add.vue";
import CityEdit from "../views/city/Edit.vue";
/**City Components End*/

/**Segment Components */
import Segment from "../views/segment/Segment.vue";
import SegmentAdd from "../views/segment/Add.vue";
import SegmentEdit from "../views/segment/Edit.vue";
/**Brand Components End*/

/**product Components */
import Product from "../views/product/Product.vue";
import Library from "../views/product/Library.vue";
import ProductAdd from "../views/product/Add.vue";
import ProductEdit from "../views/product/Edit.vue";
/**product Components End*/

/**customer Components */
import Customer from "../views/customer/Customer.vue";
import CustomerAdd from "../views/customer/Add.vue";
import CustomerEdit from "../views/customer/Edit.vue";
/**customer Components End*/

/**User Components */
import User from "../views/user/User.vue";
import UserAdd from "../views/user/Add.vue";
import UserEdit from "../views/user/Edit.vue";
/**User Components End*/

/**Enquiry Components */
import EnquiryCustomer from "../views/enquiry/Customer.vue";
import EnquiryAdd from "../views/enquiry/Add.vue";
import Enquiry from "../views/enquiry/Enquiry.vue";
import EnquiryEdit from "../views/enquiry/Edit.vue";

/**Enquiry Followup  Components */
import EnquiryFollowup from "../views/enquiryfollowup/EnquiryFollowup.vue";
import EnquiryFollowupUpdate from "../views/enquiryfollowup/EnquiryFollowupUpdate.vue";

import EnquiryTspPlan from "../views/enquirytspplan/EnquiryTspPlan.vue";
import EnquiryTspPlanForm from "../views/enquirytspplan/EnquiryTspPlanForm.vue";
import TspOrderPlan from "../views/enquirytspplan/TspOrderPlan.vue";

import EnquiryParfRequest from "../views/enquiryparf/EnquiryParfRequest.vue";
import EnquiryParfView from "../views/enquiryparf/EnquiryParfView.vue";
import EnquiryParfRise from "../views/enquiryparf/EnquiryParfRise.vue";

import EnquiryParfSalesView from "../views/enquiryparf/EnquiryParfSalesView.vue";

import MonthlySalesPlan from "../views/enquiryfollowup/MonthlySalesPlan.vue";

/**Lead  Components */
import Lead from "../views/lead/Lead.vue";
import LeadAdd from "../views/lead/Add.vue";
import LeadEdit from "../views/lead/Edit.vue";
import LeadEnquiryConvert from "../views/lead/LeadEnquiryConvert.vue";

/**Menu  Components */
import Menu from "../views/menu/Menu.vue";
import MenuAdd from "../views/menu/Add.vue";
import MenuEdit from "../views/menu/Edit.vue";

/**Role  Components */
import Role from "../views/role/Role.vue";
import RoleAdd from "../views/role/Add.vue";
import RoleEdit from "../views/role/Edit.vue";

/*Report Components*/
import EnquiryReport from "../views/report/EnquiryReport.vue";
import LostReport from "../views/report/LostReport.vue";
import TspReport from "../views/report/TspReport.vue";
import EquipmentReport from "../views/report/EquipmentReport.vue";
import CustomerReport from "../views/report/CustomerReport.vue";
import BuybackReport from "../views/report/BuybackReport.vue";
import PartialorderReport from "../views/report/PartialorderReport.vue";
import LeadReport from "../views/report/LeadReport.vue";
import MonthlySalesPlanReport from "../views/report/MonthlySalesPlanReport.vue";

/**Proforma  Components */
import Proforma from "../views/proforma/proforma.vue";
import ProformaAdd from "../views/proforma/Add.vue";
import ProformaEdit from "../views/proforma/Edit.vue";


import store from "../store";
Vue.use(VueRouter);

//validation if not authenticated and redirect
const ifnotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next("/");
    return;
  }
  next();
};

//validation if authenticated and redirect
const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated && from.path == "/") {
    next("/home");
    return;
  }
  if (!store.getters.isAuthenticated && from.path != "/") {
    next();
    return;
  }
  if (!store.getters.isAuthenticated && from.path == "/") {
    next();
    return;
  }
  next("/");
};

//routes list
const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
    beforeEnter: ifAuthenticated
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
    beforeEnter: ifnotAuthenticated
  },
  {
    path: "/country",
    name: "Country",
    component: Country,
    beforeEnter: ifnotAuthenticated
  },
  {
    path: "/country/add",
    name: "CountryAdd",
    component: CountryAdd,
    beforeEnter: ifnotAuthenticated
  },
  {
    path: "/country/edit/:id",
    name: "CountryEdit",
    component: CountryEdit,
    beforeEnter: ifnotAuthenticated
  },
  {
    path: "/state",
    name: "State",
    component: State,
    beforeEnter: ifnotAuthenticated
  },
  {
    path: "/state/add",
    name: "StateAdd",
    component: StateAdd,
    beforeEnter: ifnotAuthenticated
  },
  {
    path: "/state/edit/:id",
    name: "StateEdit",
    component: StateEdit,
    beforeEnter: ifnotAuthenticated
  },
  {
    path: "/city",
    name: "City",
    component: City,
    beforeEnter: ifnotAuthenticated
  },
  {
    path: "/city/add",
    name: "CityAdd",
    component: CityAdd,
    beforeEnter: ifnotAuthenticated
  },
  {
    path: "/city/edit/:id",
    name: "CityEdit",
    component: CityEdit,
    beforeEnter: ifnotAuthenticated
  },
  {
    path: "/product-group",
    name: "ProductGroup",
    component: ProductGroup,
    beforeEnter: ifnotAuthenticated
  },
  {
    path: "/product-group/add",
    name: "ProductGroupAdd",
    component: ProductGroupAdd,
    beforeEnter: ifnotAuthenticated
  },
  {
    path: "/product-group/edit/:id",
    name: "ProductGroupEdit",
    component: ProductGroupEdit,
    beforeEnter: ifnotAuthenticated
  },
  {
    path: "/brand",
    name: "Brand",
    component: Brand,
    beforeEnter: ifnotAuthenticated
  },
  {
    path: "/brand/add",
    name: "BrandAdd",
    component: BrandAdd,
    beforeEnter: ifnotAuthenticated
  },
  {
    path: "/brand/edit/:id",
    name: "BrandEdit",
    component: BrandEdit,
    beforeEnter: ifnotAuthenticated
  },
  {
    path: "/segment",
    name: "Segment",
    component: Segment,
    beforeEnter: ifnotAuthenticated
  },
  {
    path: "/segment/add",
    name: "SegmentAdd",
    component: SegmentAdd,
    beforeEnter: ifnotAuthenticated
  },
  {
    path: "/segment/edit/:id",
    name: "SegmentEdit",
    component: SegmentEdit,
    beforeEnter: ifnotAuthenticated
  },
  {
    path: "/product",
    name: "Product",
    component: Product,
    beforeEnter: ifnotAuthenticated
  },
  {
    path: "/library",
    name: "Product Library",
    component: Library,
    beforeEnter: ifnotAuthenticated
  },
  {
    path: "/product/add",
    name: "Product Add",
    component: ProductAdd,
    beforeEnter: ifnotAuthenticated
  },
  {
    path: "/product/edit/:id",
    name: "Product Edit",
    component: ProductEdit,
    beforeEnter: ifnotAuthenticated
  },
  {
    path: "/customer",
    name: "Customer",
    component: Customer,
    beforeEnter: ifnotAuthenticated
  },
  {
    path: "/customer/add",
    name: "Customer Add",
    component: CustomerAdd,
    beforeEnter: ifnotAuthenticated
  },
  {
    path: "/customer/edit/:id",
    name: "Customer Edit",
    component: CustomerEdit,
    beforeEnter: ifnotAuthenticated
  },
  {
    path: "/user",
    name: "User",
    component: User,
    beforeEnter: ifnotAuthenticated
  },
  {
    path: "/user/add",
    name: "User Add",
    component: UserAdd,
    beforeEnter: ifnotAuthenticated
  },
  {
    path: "/user/edit/:id",
    name: "User Edit",
    component: UserEdit,
    beforeEnter: ifnotAuthenticated
  },
  {
    path: "/enquiry/add/:id",
    name: "Enquiry Add",
    component: EnquiryAdd,
    beforeEnter: ifnotAuthenticated
  },
  {
    path: "/enquiry/customer",
    name: "Enquiry Customer",
    component: EnquiryCustomer,
    beforeEnter: ifnotAuthenticated
  },
  {
    path: "/enquiry",
    name: "Enquiry",
    component: Enquiry,
    beforeEnter: ifnotAuthenticated
  },
  {
    path: "/enquiry/edit/:id",
    name: "Enquiry Edit",
    component: EnquiryEdit,
    beforeEnter: ifnotAuthenticated
  },
  {
    path: "/enquiry-followup-list",
    name: "EnquiryFollowup",
    component: EnquiryFollowup,
    beforeEnter: ifnotAuthenticated
  },
  {
    path: "/enquiry-followup-list/edit/:id",
    name: "EnquiryFollowupUpdate",
    component: EnquiryFollowupUpdate,
    beforeEnter: ifnotAuthenticated
  },
  {
    path: "/enquiry-tsp-plan-list",
    name: "EnquiryTspPlan",
    component: EnquiryTspPlan,
    beforeEnter: ifnotAuthenticated
  },
  {
    path: "/tsp-orderplan",
    name: "TspOrderPlan",
    component: TspOrderPlan,
    beforeEnter: ifnotAuthenticated
  },
  {
    path: "/enquiry-tsp-plan-list/view/:id",
    name: "EnquiryTspPlanForm",
    component: EnquiryTspPlanForm,
    beforeEnter: ifnotAuthenticated
  },
  {
    path: "/enquiry-parf-request-list",
    name: "EnquiryParfPlan",
    component: EnquiryParfRequest,
    beforeEnter: ifnotAuthenticated
  },
  {
    path: "/enquiry-parf/view/:id",
    name: "EnquiryParfView",
    component: EnquiryParfView,
    beforeEnter: ifnotAuthenticated
  },
  {
    path: "/enquiry-parf-sales/view/:id",
    name: "EnquiryParfSalesView",
    component: EnquiryParfSalesView,
    beforeEnter: ifnotAuthenticated
  },
  {
    path: "/enquiry-parf-rise/view/:id",
    name: "EnquiryParfRise",
    component: EnquiryParfRise,
    beforeEnter: ifnotAuthenticated
  },
  {
    path: "/monthly-sales-plan-list",
    name: "MonthlySalesPlan",
    component: MonthlySalesPlan,
    beforeEnter: ifnotAuthenticated
  },
  {
    path: "/lead",
    name: "Lead",
    component: Lead,
    beforeEnter: ifnotAuthenticated
  },
  {
    path: "/lead/add",
    name: "Lead Add",
    component: LeadAdd,
    beforeEnter: ifnotAuthenticated
  },
  {
    path: "/lead/edit/:id",
    name: "Lead Edit",
    component: LeadEdit,
    beforeEnter: ifnotAuthenticated
  },

  {
    path: "/lead/enquiry/:id",
    name: "Lead Enquiry Convert",
    component: LeadEnquiryConvert,
    beforeEnter: ifnotAuthenticated
  },
  {
    path: "/menu",
    name: "Menu",
    component: Menu,
    beforeEnter: ifnotAuthenticated
  },
  {
    path: "/menu/add",
    name: "Menu Add",
    component: MenuAdd,
    beforeEnter: ifnotAuthenticated
  },
  {
    path: "/menu/edit/:id",
    name: "Menu Edit",
    component: MenuEdit,
    beforeEnter: ifnotAuthenticated
  },
  {
    path: "/role",
    name: "Role",
    component: Role,
    beforeEnter: ifnotAuthenticated
  },
  {
    path: "/role/add",
    name: "Role Add",
    component: RoleAdd,
    beforeEnter: ifnotAuthenticated
  },
  {
    path: "/role/edit/:id",
    name: "Role Edit",
    component: RoleEdit,
    beforeEnter: ifnotAuthenticated
  },
  {
    path: "/report/enquiry-report",
    name: "EnquiryReport",
    component: EnquiryReport,
    beforeEnter: ifnotAuthenticated
  },
  {
    path: "/report/lost-report",
    name: "LostReport",
    component: LostReport,
    beforeEnter: ifnotAuthenticated
  },
  {
    path: "/report/tsp-report",
    name: "TspReport",
    component: TspReport,
    beforeEnter: ifnotAuthenticated
  },
  {
    path: "/report/equipment-report",
    name: "EquipmentReport",
    component: EquipmentReport,
    beforeEnter: ifnotAuthenticated
  },
  {
    path: "/report/customer-report",
    name: "CustomerReport",
    component: CustomerReport,
    beforeEnter: ifnotAuthenticated
  },
  {
    path: "/report/buyback-report",
    name: "BuybackReport",
    component: BuybackReport,
    beforeEnter: ifnotAuthenticated
  },
  {
    path: "/report/partialorder-report",
    name: "PartialorderReport",
    component: PartialorderReport,
    beforeEnter: ifnotAuthenticated
  },
  {
    path: "/report/lead-report",
    name: "LeadReport",
    component: LeadReport,
    beforeEnter: ifnotAuthenticated
  },
  {
    path: "/report/monthly-salesplan-report",
    name: "MonthlySalesPlanReport",
    component: MonthlySalesPlanReport,
    beforeEnter: ifnotAuthenticated
  },
  {
    path: "/proforma",
    name: "Proforma",
    component: Proforma,
    beforeEnter: ifnotAuthenticated
  },

  {
    path: "/proforma/add/:id",
    name: "Proforma Add",
    component: ProformaAdd,
    beforeEnter: ifnotAuthenticated
  },
  {
    path: "/proforma/edit/:id",
    name: "Proforma Edit",
    component: ProformaEdit,
    beforeEnter: ifnotAuthenticated
  },

  {
    path: "*",
    name: "Not Found",
    component: NotFound,
    beforeEnter: ifnotAuthenticated
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
