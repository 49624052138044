import { VUE_APP_BASEURL } from "../../utils/api";
import router from "../../router";
import axios from "axios";
import store from "../../store";

const state = {
  status: "",
  parfResponse: [],
  parfplanList: [],
  parfRequestdetails: [],
  parfupdateStatus: "",
  parfupdateResponse: []
};

const getters = {
  getParfplanList: state => state.parfplanList,
  getparfRequestdetails: state => state.parfRequestdetails,
  parfStatus: state => state.status,
  parfStatusResponse: state => state.parfResponse,
  getparfUpdatestatus: state => state.parfupdateStatus,
  getparfUpdateResponse: state => state.parfupdateResponse
};

const actions = {
  //new PFM status update action
  async enquiryParfrequestrise({ commit }, enquiryData) {
    commit("authRequest");
    axios({
      method: "POST",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "enquiry-parf-request-rise",
      data: {
        enquiryId: enquiryData.enquiryId,
        parfRequestdetails: enquiryData.parfRequestdetails,
        baaf: enquiryData.baaf,
        freight: enquiryData.freight,
        justification: enquiryData.justification,
        payment_terms: enquiryData.payment_terms,
        taxes: enquiryData.taxes
      }
    })
      .then(function(response) {
        console.log(response);
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("enquiryParfrise", response.data.response);
          router.push("/enquiry-tsp-plan-list");
        }
      })
      .catch(function(error) {
        console.log(error.response);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("followuplistError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  },

  //parf list fetch
  async fetchParfplanList({ commit }) {
    commit("authRequest");
    axios({
      method: "GET",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "enquiry-parf-request-list"
    })
      .then(function(response) {
        // console.log(response);
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("setparfplanList", response.data.response);
        }
      })
      .catch(function(error) {
        console.log(error);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("followuplistError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  },

  //parf request details fetch
  async fetchRequestparfdetails({ commit }, enquiryId) {
    // console.log(enquiryId);
    commit("authRequest");
    axios({
      method: "GET",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "enquiry-parf-request-details/" + enquiryId
    })
      .then(function(response) {
        //   console.log(response);
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("setparfRequestdetails", response.data.response);
        }
      })
      .catch(function(error) {
        console.log(error);
        if (error.response) {
          // client received an error response (5xx, 4xx)
          console.log(error.response);
          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("followuplistError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  },

  //PARF send request
  async enquiryParfrequestupdate({ commit }, parfData) {
    commit("authRequest");
    axios({
      method: "PUT",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "enquiryparfrequest/" + parfData.parfId,
      data: {
        parfRequestdetails: parfData.parfRequestdetails,
        parfStatus: parfData.parfStatus
      }
    })
      .then(function(response) {
        console.log(response);
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("parfStatusupdate", response.data.response);
          router.push("/enquiry-parf-request-list");
        }
      })
      .catch(function(error) {
        console.log(error.response);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("followuplistError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  }
};

const mutations = {
  parfStatusupdate: (state, resp) => {
    state.parfupdateStatus = "success";
    state.parfupdateResponse = resp;

    //   console.log(state.parfupdateResponse);
  },
  enquiryParfrise: (state, resp) => {
    state.status = "success";
    state.parfResponse = resp;
  },
  setparfplanList: (state, resp) => {
    state.parfplanList = resp;
  },
  setparfRequestdetails: (state, resp) => {
    state.parfRequestdetails = resp;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
