<template>
  <div>
    <!--container-->
    <v-container>
      <!--tob bar-->
      <bar />
      <!--tob bar end-->

      <!--template-->
      <template>
        <!--alert box-->
        <v-alert
          text
          dense
          color="teal"
          icon="mdi-clock-fast"
          border="left"
          v-if="proformaStatusmsg"
        >
          <div v-for="(resp, i) in proformaResponse" :key="i">
            {{ resp }}
          </div>
        </v-alert>
        <!--alert box end-->

         <!--Simple table start-->
         <v-card class="mx-auto" outlined>
          <v-card-title>
            PROFORMA
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <!--<v-btn color="black" dark class="mb-2" to="/proforma/add">
              NEW PROFORMA
            </v-btn>-->
          </v-card-title>
          <v-divider horizontal></v-divider>
        <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">ACTIONS</th>
                  <th class="text-left">PO REFRERENCE</th>
                  <th class="text-left">INVOICE NO</th>
                  <th class="text-left">INVOICE DATE</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="proforma in proformas" :key="proforma.id">
                  <td>
                    <v-icon small class="mr-2" @click="editProforma(proforma)">
                      mdi-pencil
                    </v-icon>
                  </td>
                  <td>{{ proforma.po_ref }}</td>
                  <td>ATS/PRO/{{ new Date(proforma.proforma_invoicedate).getFullYear() }}- {{ new Date(proforma.proforma_invoicedate).getFullYear()+1 }}/{{ idFormat(proforma.id) }}</td>
                  <td>{{ proforma.proforma_invoicedate }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
        <!--Simple Table end-->
        <!--pagination-->
        <div class="text-center mt-5">
          <v-pagination
            v-model="page"
            :length="proformaPagination"
            :total-visible="7"
            @input="paginationNextPage"
          ></v-pagination>
        </div>
        <!--pagination end-->

      
      </template>
      <!--template end-->
    </v-container>
    <!--container end-->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import router from "../../router";


export default {
  name: "Lead",
  data: () => ({
    search: "",
    page: 1,
    selectedPage: 1,
  }),

  computed: {
    ...mapGetters(["proformaList","proformaListPaginate", "proformaStatus", "proformaStatusResponse", "authRole"]),
    proformas: function() {
      return this.proformaList;
    },
    proformaPagination: function() {
      return this.proformaListPaginate;
    },
    proformaStatusmsg: function() {
      return this.proformaStatus === "success";
    },
    proformaResponse: function() {
      return this.proformaStatusResponse;
    },
    userRole: function() {
      return this.authRole;
    }
  },

  watch: {},

  created() {
    const filterData = {
      page: "",
    };
    this.fetchProformas(filterData);
  },

  methods: {
    ...mapActions(["fetchProformas"]),

    paginationNextPage(page) {
      //this.selectedPage = page;
      this.filterProforma(page);
    },
    filterProforma(page) {
      const filterData = {
        page: page,
      };
      console.log(filterData);
      this.fetchProformas(filterData);
    },

    editProforma(item) {
      const id = item.id;
      //  router.push({ name: 'ProductGroupEdit', params: { productgroupId: 123 }})
      router.push({ path: `/proforma/edit/${id}` });
    }
    ,
    idFormat(id)
    {
      return id.toLocaleString().padStart(3, '0');;
    }
  }
};
</script>
