import { VUE_APP_BASEURL } from "../../utils/api";
import router from "../../router";
import axios from "axios";
import store from "../../store";

const state = {
  menus: [],
  menuResponse: [],
  status: "",
  menuName: "",
  menuUrl: "",
  menuIcon: "",
  menuOrder: "",
  menuActive: "",
  menuIsmainmenu: "",
  menuMainId: "",
  mainMenus: [],
  activeMenus: []
};

const getters = {
  menuList: state => state.menus,
  menuStatusResponse: state => state.menuResponse,
  menuStatus: state => state.status,
  getMenuname: state => state.menuName,
  getMenuurl: state => state.menuUrl,
  getMenuicon: state => state.menuIcon,
  getMenuorder: state => state.menuOrder,
  getMenuactive: state => state.menuActive,
  getIsmainmenu: state => state.menuIsmainmenu,
  getMainmenuid: state => state.menuMainId,
  getMainmenus: state => state.mainMenus,
  getActivemenus: state => state.activeMenus
};

const actions = {
  //State fetch
  async fetchMenus({ commit }) {
    commit("authRequest");
    axios({
      method: "GET",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "menu"
    })
      .then(function(response) {
        console.log(response);
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("setmenuList", response.data.response);
        }
      })
      .catch(function(error) {
        console.log(error);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("menuError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  },

  //fetch main menus
  async fetchMainmenus({ commit }) {
    commit("authRequest");
    axios({
      method: "GET",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "main-menu-list"
    })
      .then(function(response) {
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("setMainmenuList", response.data.response);
        }
      })
      .catch(function(error) {
        console.log(error);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("menuError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  },

  //new menu add action
  async menuAdd({ commit }, menuData) {
    commit("authRequest");
    axios({
      method: "POST",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "menu",
      data: {
        menuName: menuData.menuName,
        menuUrl: menuData.menuUrl,
        menuIcon: menuData.menuIcon,
        menuOrder: menuData.menuOrder,
        menuActive: menuData.menuActive,
        is_mainmenu: menuData.isMainmenu,
        mainmenu_id: menuData.mainMenuid
      }
    })
      .then(function(response) {
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("menuaddMethod", response.data.response);
          router.push("/menu");
        }
      })
      .catch(function(error) {
        console.log(error);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("menuError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  },
  //new menu add action end

  //fetch State details
  async fetchMenudetails({ commit }, menuId) {
    commit("authRequest");

    axios({
      method: "GET",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "menu/" + menuId
    })
      .then(function(response) {
        if (response.data.result == 1) {
          commit("authSuccess");
          
          commit("menuDetails", response.data.response);
        }
      })
      .catch(function(error) {
        console.log(error);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("menuError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  },

  //menu update action
  async menuUpdate({ commit }, menuData) {
    commit("authRequest");
    axios({
      method: "PUT",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "menu/" + menuData.menuId,
      data: {
        menuName: menuData.menuName,
        menuUrl: menuData.menuUrl,
        menuIcon: menuData.menuIcon,
        menuOrder: menuData.menuOrder,
        menuActive: menuData.menuActive,
        is_mainmenu: menuData.isMainmenu,
        mainmenu_id: menuData.mainMenuid
      }
    })
      .then(function(response) {
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("menuupdateMethod", response.data.response);
          router.push("/menu");
        }
      })
      .catch(function(error) {
        console.log(error);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("menuError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  },
  //menu update action end

  //fetch main menus
  async fetchActivemenus({ commit }) {
    commit("authRequest");
    axios({
      method: "GET",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "active-menus"
    })
      .then(function(response) {
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("setActivemenuList", response.data.response);
        }
      })
      .catch(function(error) {
        console.log(error);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("menuError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  }
};

const mutations = {
  setmenuList: (state, resp) => {
    state.menus = resp;
  },
  setMainmenuList: (state, resp) => {
    state.mainMenus = resp;
  },
  setActivemenuList: (state, resp) => {
    state.activeMenus = resp;
  },
  menuaddMethod: (state, resp) => {
    state.status = "success";
    state.menuResponse = resp;
  },
  menuError: (state, resp) => {
    state.status = "error";
    state.menuResponse = resp;
  },
  menuDetails: (state, resp) => {
    state.status = "";
    state.menuName = resp.name;
    state.menuUrl = resp.url;
    state.menuIcon = resp.icon;
    state.menuOrder = resp.order;
    state.menuActive = resp.active;
    state.menuIsmainmenu = resp.is_mainmenu;
    state.menuMainId = resp.mainmenu_id;
  },
  menuupdateMethod: (state, resp) => {
    state.status = "success";
    state.menuResponse = resp;
  },
  updateMenuname: (state, menuname) => {
    state.menuName = menuname;
  },
  updateMenuurl: (state, menuurl) => {
    state.menuUrl = menuurl;
  },
  updateMenuorder: (state, menuorder) => {
    state.menuOrder = menuorder;
  },
  updateMenuactive: (state, menuactive) => {
    state.menuActive = menuactive;
  },
  updateIsmainmenu: (state, ismainmenu) => {
    state.menuIsmainmenu = ismainmenu;
  },
  updateMainmenuid: (state, mainmenuid) => {
    state.menuMainId = mainmenuid;
  },
  updateMenuicon: (state, icon) => {
    state.menuIcon = icon;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
