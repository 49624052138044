<template>
  <div>
    <!--container-->
    <v-container>
      <!--tob bar-->
      <bar />
      <!--tob bar end-->

      <!--template-->
      <template>
        <!--alert box-->
        <v-alert
          text
          dense
          color="teal"
          icon="mdi-clock-fast"
          border="left"
          v-if="productGroupstatusmsg"
        >
          <div v-for="(resp, i) in productGroupresponse" :key="i">
            {{ resp }}
          </div>
        </v-alert>
        <!--alert box end-->

        <!---datatable -->
        <v-data-table
          :headers="headers"
          :items="productgroups"
          :search="search"
          sort-by="product_group_name"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>PRODUCT GROUP</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-btn color="black" dark class="mb-2" to="/product-group/add">
                New Product Group
              </v-btn>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editProductgroup(item)">
              mdi-pencil
            </v-icon>
          </template>
        </v-data-table>
        <!---datatable end-->
      </template>
      <!--template end-->
    </v-container>
    <!--container end-->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import router from "../../router";
export default {
  name: "ProductGroup",
  data: () => ({
    search: "",
    headers: [
      { text: "Actions", align: "start", value: "actions", sortable: false },
      {
        text: "Name",
        align: "start",
        sortable: true,
        value: "product_group_name"
      },
      
    ],
    desserts: []
  }),
  computed: {
    ...mapGetters([
      "productGroupStatus",
      "productGroupStatusResponse",
      "productGroup"
    ]),
    productgroups: function() {
      return this.productGroup;
    },
    productGroupstatusmsg: function() {
      return this.productGroupStatus === "success";
    },
    productGroupresponse: function() {
      return this.productGroupStatusResponse;
    }
  },

  watch: {},

  created() {
    this.fetchProductgroup();
  },

  methods: {
    ...mapActions(["fetchProductgroup"]),
    editProductgroup(item) {
      const id = item.id;
      //  router.push({ name: 'ProductGroupEdit', params: { productgroupId: 123 }})
      router.push({ path: `/product-group/edit/${id}` });
    }
  }
};
</script>
