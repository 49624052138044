<template>
  <div>
    <!--container-->
    <v-container>
      <!--tob bar-->
      <bar />
      <!--tob bar end-->
      <!--template-->
      <template>
        <!--card -->
        <v-card class="form-card mx-auto my-12">
          <!--alert box-->
          <v-alert text prominent type="error" v-if="errorResponse">
            <div v-for="(error, i) in enquiryError" :key="i">
              {{ error }}
            </div>
          </v-alert>
          <!--alert box end-->

          <v-list-item class="headline">
            <v-list-item-content>
              <v-list-item-title>
                REQUEST QUOTE ADD
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <!--form-->
          <form enctype="multipart/form-data">
            <v-card-text>
              <v-row justify="space-between">
                <v-col sm="12" md="6">
                  <v-text-field
                    v-model="referenceNo"
                    :error-messages="referenceNoErrors"
                    label="REFRERENCE NO: *"
                    required
                    readonly
                    @input="$v.referenceNo.$touch()"
                    @blur="$v.referenceNo.$touch()"
                  ></v-text-field>
                </v-col>

                <v-col sm="12" md="6">
                  <v-text-field
                    v-model="customerDetails"
                    :error-messages="customerDetailsErrors"
                    label="CUSTOMER DETAILS: *"
                    required
                    @input="$v.customerDetails.$touch()"
                    @blur="$v.customerDetails.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row justify="space-between">
                <v-col xs="12" sm="12" md="4">
                  <v-text-field
                    v-model="workshopName"
                    label="WORK SHOPNAME"
                    required
                    :error-messages="workshopNameErrors"
                    @input="$v.workshopName.$touch()"
                    @blur="$v.workshopName.$touch()"
                  ></v-text-field>
                </v-col>

                <v-col xs="12" sm="12" md="4">
                  <v-text-field
                    v-model="address1"
                    label="ADDRESS 1"
                    required
                    :error-messages="address1Errors"
                    @input="$v.address1.$touch()"
                    @blur="$v.address1.$touch()"
                  ></v-text-field>
                </v-col>

                <v-col xs="12" sm="12" md="4">
                  <v-text-field
                    v-model="address2"
                    label="ADDRESS 2"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row justify="space-between">
                <v-col xs="12" sm="12" md="4">
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="enquiryDate"
                        :error-messages="enquiryDateErrors"
                        @input="$v.enquiryDate.$touch()"
                        @blur="$v.enquiryDate.$touch()"
                        label="ENQUIRY DATE*"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="enquiryDate"
                      @input="menu2 = false"
                      :max="
                        new Date(
                          Date.now() - new Date().getTimezoneOffset() * 60000
                        )
                          .toISOString()
                          .substr(0, 10)
                      "
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col xs="12" sm="12" md="4">
                  <v-select
                    v-model="salesType"
                    :items="salestypes"
                    :error-messages="salesTypeErrors"
                    label="SALES TYPE *"
                    required
                    @input="$v.salesType.$touch()"
                    @blur="$v.salesType.$touch()"
                  ></v-select>
                </v-col>

                <v-col xs="12" sm="12" md="4">
                  <v-select
                    v-model="workshopType"
                    :items="workshoptypes"
                    label="WORKSHOP TYPE *"
                    required
                    :error-messages="workshopTypeErrors"
                    @input="$v.workshopType.$touch()"
                    @blur="$v.workshopType.$touch()"
                  ></v-select>
                </v-col>
              </v-row>

              <v-row
                justify="space-between"
                v-for="(pro, k) in productDetails"
                :key="k"
              >
                <v-col xs="12" sm="12" md="2">
                  <!-- <v-select
                    v-model="pro.equipment"
                    :items="products"
                    item-text="product_name"
                    item-value="id"
                    label="EQUIPMENT"
                    @change="getProductdetails(pro)" ></v-select>-->

                  <v-autocomplete
                    v-model="pro.equipment"
                    :items="products"
                    item-text="product_name_withtpl"
                    item-value="id"
                    label="EQUIPMENT"
                    @change="getProductdetails(pro)"
                  >
                  </v-autocomplete>
                </v-col>

                <v-col xs="12" sm="12" md="1">
                  <v-img
                    max-height="150"
                    max-width="250"
                    :src="pro.productImageshow"
                  ></v-img>
                </v-col>

                <v-col xs="12" sm="12" md="1">
                  <v-text-field
                    v-model="pro.model"
                    label="MODEL"
                    readonly
                  ></v-text-field>
                </v-col>

                <v-col xs="12" sm="12" md="1">
                  <v-text-field
                    v-model="pro.quantity"
                    label="QTY"
                    @change="getProductqtyprice(pro)"
                  ></v-text-field>
                </v-col>

                <v-col xs="12" sm="12" md="2">
                  <v-text-field
                    v-model="pro.price"
                    label="PRICE"
                    readonly
                  ></v-text-field>
                </v-col>

                <v-col xs="12" sm="12" md="2">
                  <v-text-field
                    v-model="pro.special_price"
                    label="SPL PRICE"
                    @change="getProductqtyprice(pro)"
                  ></v-text-field>
                </v-col>

                <v-col xs="12" sm="12" md="2">
                  <v-text-field
                    v-model="pro.totalPrice"
                    label="TOTAL COST"
                    readonly
                  ></v-text-field>
                </v-col>

                <v-col xs="12" sm="12" md="1">
                  <v-btn
                    color="warning"
                    fab
                    dark
                    x-small
                    v-show="productDetails.length > 1"
                    @click="deleteProductdetails(k)"
                  >
                    <v-icon dark> mdi-close </v-icon>
                  </v-btn>
                </v-col>
              </v-row>

              <v-row>
                <v-col xs="12" sm="12" md="11"> </v-col>
                <v-col xs="12" sm="12" md="1">
                  <v-btn depressed color="warning" @click="addProductdetails">
                    +ADD
                  </v-btn>
                </v-col>
              </v-row>

              <v-row>
                <v-col xs="12" sm="12" md="9"> </v-col>
                <v-col xs="12" sm="12" md="3">
                  <v-checkbox
                    v-model="igst"
                    label="IGST"
                    color="orange"
                    value="1"
                    hide-details
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row>
                <v-col xs="12" sm="12" md="9"> </v-col>
                <v-col xs="12" sm="12" md="3">
                  <v-text-field
                    v-model="freightCharge"
                    label="FREIGHT CHARGE"
                    @change="calculateTotal"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col xs="12" sm="12" md="9"> </v-col>
                <v-col xs="12" sm="12" md="3">
                  <v-text-field
                    v-model="taxAmount"
                    label="TAX AMOUNT"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col xs="12" sm="12" md="9"> </v-col>
                <v-col xs="12" sm="12" md="3">
                  <v-text-field
                    v-model="totalPrice"
                    label="TOTAL PRICE"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col xs="12" sm="12" md="9"> </v-col>
                <v-col xs="12" sm="12" md="3">
                  <v-text-field
                    v-model="totalPricewithtax"
                    label="TOTAL PRICE (WITH TAX)"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col xs="12" sm="12" md="6">
                  <v-text-field
                    v-model="remarks"
                    label="REMARKS*"
                    required
                    :error-messages="remarksErrors"
                    @input="$v.remarks.$touch()"
                    @blur="$v.remarks.$touch()"
                  ></v-text-field>
                </v-col>
                <v-col xs="12" sm="12" md="3"> </v-col>
                <v-col xs="12" sm="12" md="3"> </v-col>
              </v-row>
            </v-card-text>

            <!--input field end-->
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="black" dark @click="submit"> SUBMIT </v-btn>
              <v-btn color="warning" dark to="/enquiry/customer">
                CANCEL
              </v-btn>
            </v-card-actions>
          </form>
        </v-card>
        <!--card end-->
      </template>
      <!--template end-->
    </v-container>
    <!--container end-->
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "LeadEnquiryConvert",
  data: () => ({
    workshopType: "",
    salesType: "",
    modal: false,
    menu2: false,
    enquiryDate: new Date().toISOString().substr(0, 10),
    igst: "",
    freightCharge: "0",
    remarks: ""
  }),
  validations: {
    referenceNo: { required },
    customerDetails: { required },
    workshopType: { required },
    salesType: { required },
    enquiryDate: { required },
    remarks: { required },
    workshopName: { required },
    address1: { required }
  },
  computed: {
    ...mapGetters([
      "referencenoDetails",
      "workshopNameDetails",
      "address1Details",
      "address2Details",
      "productList",
      "productImageDetails",
      "modelDetailsname",
      "productspecificationDetailsname",
      "scopeofsupplyDetailsname",
      "specialfeaturesDetailsname",
      "warrantyexclusionsDetailsname",
      "enquiryStatus",
      "enquiryStatusResponse",
      "warrantyinyearsDetailsname",
      "brandPriceDetails",
      "getEnquiryWorkshoptypes",
      "getSalestype",
      "getTax",
      "getleadcustomerDetails",
      "getleadtaxAmount",
      "getleadtotalAmount",
      "getleadtotalAmountWithTax",
      "getleadproductDetails",
      "getleadCustomercodeDetails",
      "workshopNameDetails",
      "address1Details",
      "address2Details"
    ]),
    errorResponse: function() {
      return this.enquiryStatus === "error";
    },
    enquiryError: function() {
      return this.enquiryStatusResponse;
    },
    workshoptypes: function() {
      return this.getEnquiryWorkshoptypes;
    },
    salestypes: function() {
      return this.getSalestype;
    },
    products: function() {
      return this.productList;
    },

    customerDetails: {
      get() {
        return this.getleadcustomerDetails;
      },
      set(value) {
        this.$store.commit("updateleadCustomerdetails", value);
      }
    },
    tax: {
      get() {
        return this.getTax;
      }
    },
    referenceNo: {
      get() {
        return this.referencenoDetails;
      }
    },
    taxAmount: {
      get() {
        return this.getleadtaxAmount;
      },
      set(value) {
        this.$store.commit("updateleadTaxamount", value);
      }
    },
    totalPrice: {
      get() {
        return this.getleadtotalAmount;
      },
      set(value) {
        this.$store.commit("updateleadTotalprice", value);
      }
    },
    totalPricewithtax: {
      get() {
        return this.getleadtotalAmountWithTax;
      },
      set(value) {
        this.$store.commit("updateleadtotalAmountWithTax", value);
      }
    },
    workshopName: {
      get() {
        return this.workshopNameDetails;
      },
      set(value) {
        this.$store.commit("updateWorkshopName", value);
      }
    },
    address1: {
      get() {
        return this.address1Details;
      },
      set(value) {
        this.$store.commit("updateAddress1", value);
      }
    },
    address2: {
      get() {
        return this.address2Details;
      },
      set(value) {
        this.$store.commit("updateAddress2", value);
      }
    },
    productDetails: function() {
      return this.getleadproductDetails;
    },
    referenceNoErrors() {
      const errors = [];
      if (!this.$v.referenceNo.$dirty) return errors;
      !this.$v.referenceNo.required && errors.push("Referenceno is required.");
      return errors;
    },
    customerDetailsErrors() {
      const errors = [];
      if (!this.$v.customerDetails.$dirty) return errors;
      !this.$v.customerDetails.required &&
        errors.push("Customerdetails is required.");
      return errors;
    },
    workshopTypeErrors() {
      const errors = [];
      if (!this.$v.workshopType.$dirty) return errors;
      !this.$v.workshopType.required &&
        errors.push("Workshoptype is required.");
      return errors;
    },
    salesTypeErrors() {
      const errors = [];
      if (!this.$v.salesType.$dirty) return errors;
      !this.$v.salesType.required && errors.push("Salestype is required.");
      return errors;
    },
    enquiryDateErrors() {
      const errors = [];
      if (!this.$v.enquiryDate.$dirty) return errors;
      !this.$v.enquiryDate.required && errors.push("Enquirydate is required.");
      return errors;
    },
    remarksErrors() {
      const errors = [];
      if (!this.$v.remarks.$dirty) return errors;
      !this.$v.remarks.required && errors.push("Remarks is required.");
      return errors;
    },
    workshopNameErrors() {
      const errors = [];
      if (!this.$v.workshopName.$dirty) return errors;
      !this.$v.workshopName.required &&
        errors.push("Workshop Name is required.");
      return errors;
    },
    address1Errors() {
      const errors = [];
      if (!this.$v.address1.$dirty) return errors;
      !this.$v.address1.required && errors.push("Address1 is required.");
      return errors;
    }
  },
  created() {
    this.fetchProducts();
    this.fetchReferenceno();
    this.fetchDefinedvalues();
    this.fetchLeaddetails(this.$route.params.id);
    this.fetchCustomerdetailsbasedoncustomercode(
      this.getleadCustomercodeDetails
    );
  },
  methods: {
    ...mapActions([
      "fetchProducts",
      "fetchLeaddetails",
      "fetchProductdetails",
      "fetchReferenceno",
      "leadConvert",
      "fetchDefinedvalues",
      "fetchCustomerdetailsbasedoncustomercode"
    ]),
    //add product details
    addProductdetails() {
      this.productDetails.push({
        equipment: "",
        productImageshow: "",
        model: "",
        quantity: "",
        price: "",
        special_price: "",
        totalPrice: ""
      });
    },
    //delete product details
    deleteProductdetails(counter) {
      //   console.log(counter);
      this.productDetails.splice(counter, 1);
      this.calculateTotal();
    },
    //get product details onchange
    getProductdetails(pro) {
      //console.log(this.$route.params.id);

      const productData = {
        customerId: this.$route.params.id,
        productId: pro.equipment
      };
      this.fetchProductdetails(productData);

      setTimeout(() => {
        pro.productImageshow = this.productImageDetails;
        pro.model = this.modelDetailsname;
        pro.quantity = 1;
        pro.price = parseFloat(this.brandPriceDetails);
        pro.special_price = parseFloat(this.brandPriceDetails);
        let cost = parseFloat(pro.quantity) * parseFloat(pro.special_price);
        pro.totalPrice = cost;
        this.calculateTotal();
      }, 500);
    },
    //qty based product price onchange
    getProductqtyprice(pro) {
      //console.log(pro);
      let cost = parseFloat(pro.quantity) * parseFloat(pro.special_price);
      pro.totalPrice = cost;
      this.calculateTotal();
    },
    calculateTotal() {
      var gst = this.tax;

      var subtotal;
      subtotal = this.productDetails.reduce(function(sum, product) {
        var lineTotal = parseFloat(product.totalPrice);
        if (!isNaN(lineTotal)) {
          return sum + lineTotal;
        }
      }, 0);

      var billtotal = parseFloat(subtotal) + parseFloat(this.freightCharge);
      var taxamount =
        (parseFloat(billtotal) * parseFloat(gst)) / parseFloat(100);
      var billwithtax = parseFloat(billtotal) + parseFloat(taxamount);

      this.taxAmount = taxamount;
      this.totalPrice = subtotal;
      this.totalPricewithtax = billwithtax.toFixed(2);
    },

    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("ERROR");
      } else {
        // do your submit logic here
        setTimeout(() => {
          const enquiryData = {
            leadId: this.$route.params.id,
            customerDetails: !this.customerDetails
              ? this.customerDetails
              : this.customerDetails.toUpperCase(),
            EnquiryDate: this.enquiryDate,
            isIgst: this.igst,
            freightCharge: this.freightCharge,
            taxAmount: this.taxAmount,
            totalAmount: this.totalPrice,
            totalAmountWithTax: this.totalPricewithtax,
            remarks: !this.remarks ? this.remarks : this.remarks.toUpperCase(),
            enquiryDetails: this.productDetails,
            salesType: this.salesType,
            workshopType: this.workshopType,
            workshopName: !this.workshopName
              ? this.workshopName
              : this.workshopName.toUpperCase(),
            address1: !this.address1
              ? this.address1
              : this.address1.toUpperCase(),
            address2: !this.address2
              ? this.address2
              : this.address2.toUpperCase()
          };

          // console.log(enquiryData);
          this.leadConvert(enquiryData);
        }, 500);
      }
    }
  }
};
</script>
