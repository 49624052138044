<template>
  <div>
    <!--container-->
    <v-container>
      <!--tob bar-->
      <bar />
      <!--tob bar end-->

      <!--template-->
      <template v-if="errorResponse">
        <div class="form-box-response mx-auto my-12">
          <!--alert box-->
          <v-alert text prominent type="error">
            <div v-for="(error, i) in followupError" :key="i">
              {{ error }}
            </div>
          </v-alert>
          <!--alert box end-->
        </div>
      </template>
      <!--template end-->

      <!--template-->
      <template>
        <!--card -->
        <v-card class="form-card mx-auto my-12" outlined>
          <!--form-->
          <form enctype="multipart/form-data">
            <v-card-text>
              <v-row justify="space-between" class="my-2 ml-2 mr-2">
                <v-col cols="12" md="2">
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="followupDate"
                        label="FOLLOWUP DATE*"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="followupDate"
                      @input="menu2 = false"
                      v-on:change="setNextfollowupmindate()"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" md="3">
                  <v-select
                    v-model="followupstatus"
                    :items="followupstatusList"
                    label="STATUS*"
                  ></v-select>
                </v-col>

                <v-col cols="12" md="3">
                  <v-menu
                    v-model="menu3"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="nextfollowupDate"
                        label="NEXT FOLLOWUP DATE*"
                        :error-messages="nextfollowupDateErrors"
                        @input="$v.nextfollowupDate.$touch()"
                        @blur="$v.nextfollowupDate.$touch()"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>

                    <v-date-picker
                      v-model="nextfollowupDate"
                      @input="menu3 = false"
                      :min="
                        new Date(
                          nextfollowupMinDate -
                            new Date().getTimezoneOffset() * 60000
                        )
                          .toISOString()
                          .substr(0, 10)
                      "
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="remarks"
                    label="REMARKS"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="1">
                  <v-btn
                    color="black"
                    dark
                    @click="statusAddforall"
                    v-if="userRole == 'sales-engineer'"
                  >
                    ADD
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </form>
          <!--form-->
        </v-card>
        <!--card -->
      </template>

      <!--template end-->

      <!--template-->
      <template>
        <!--card -->
        <v-card class="form-card mx-auto my-12">
          <v-list-item class="headline">
            <v-list-item-content>
              <v-list-item-title> ENQUIRY FOLLOWUP </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>

          <!--form-->
          <form enctype="multipart/form-data">
            <v-card-text>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left grey lighten-4">CUSTOMER DETAILS</th>
                      <th class="text-left grey lighten-4">REFRERENCE NO</th>
                      <th class="text-left grey lighten-4">SEGMENT</th>
                      <th class="text-left grey lighten-4">LOB</th>
                      <th class="text-left grey lighten-4">WORKSHOP NAME</th>
                      <th class="text-left grey lighten-4">MOBILE NO</th>
                      <th class="text-left grey lighten-4">EMAIL</th>
                      <th class="text-left grey lighten-4">ADDRESS</th>
                      <th class="text-left grey lighten-4">ENQUIRY DATE</th>
                      <th class="text-left grey lighten-4">TOTAL PRICE</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{{ customerDetails }}</td>
                      <td>{{ followupReferenceno }}</td>
                      <td>{{ segment }}</td>
                      <td>{{ brand }}</td>
                      <td>{{ workshopName }}</td>
                      <td>{{ mobileno }}</td>
                      <td>{{ emailid }}</td>
                      <td>{{ address1 }}</td>
                      <td>{{ enquiryDate }}</td>
                      <td>{{ totalAmountWithTax }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-divider></v-divider>

              <v-card
                v-for="(pro, k) in productDetails"
                :key="k"
                class="mx-auto my-6"
                outlined
              >
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left grey lighten-4">
                          <label class="orange--text text--darken-2"
                            >PRODUCT</label
                          >
                        </th>
                        <th class="text-left grey lighten-4">
                          <label class="orange--text text--darken-2"
                            >MODEL</label
                          >
                        </th>
                        <th class="text-left grey lighten-4">
                          <label class="orange--text text--darken-2">QTY</label>
                        </th>
                        <th class="text-left grey lighten-4">
                          <label class="orange--text text--darken-2"
                            >TOTAL COST</label
                          >
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{{ pro.equipment.product_name }}</td>
                        <td>{{ pro.model }}</td>
                        <td>{{ pro.quantity }}</td>
                        <td>{{ pro.totalPrice }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <v-divider></v-divider>

                <v-row justify="space-between" class="my-2 ml-2 mr-2">
                  <v-col md="6">
                    <div class="text-overline">FOLLOWUP</div>

                    <div v-for="(foloup, i) in pro.enquiryStatus" :key="i">
                      <v-row justify="space-between">
                        <v-col cols="12" md="4">
                          <!-- <v-text-field
                            label="DATE"
                            readonly
                            v-model="foloup.followup_date"
                          ></v-text-field>-->

                          <v-menu
                            v-model="foloup.menu4"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="foloup.followup_date"
                                label="DATE*"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="foloup.followup_date"
                              @input="foloup.menu4 = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-text-field
                            label="STATUS"
                            readonly
                            v-model="foloup.status"
                            v-if="foloup.status == 'FOLLOWUP'"
                          >
                          </v-text-field>

                          <v-text-field
                            cols="12"
                            md="4"
                            label="STATUS"
                            readonly
                            v-model="foloup.status"
                            v-else-if="foloup.status == 'NEGOTIATION'"
                          >
                          </v-text-field>

                          <v-text-field
                            cols="12"
                            md="4"
                            label="STATUS"
                            readonly
                            v-model="foloup.status"
                            v-else-if="foloup.status == 'PFM CONFIRM'"
                          >
                          </v-text-field>

                          <v-text-field
                            cols="12"
                            md="4"
                            label="STATUS"
                            readonly
                            v-model="foloup.status"
                            v-else-if="foloup.status == 'RESCHEDULE'"
                          >
                          </v-text-field>

                          <v-select
                            cols="12"
                            md="4"
                            v-model="foloup.status"
                            :items="followupconfirmationstatusList"
                            v-else
                            label="STATUS*"
                          ></v-select>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-text-field
                            label="REMARKS"
                            v-model="foloup.remarks"
                            :readonly="foloup.status == ''"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>

                  <v-col md="6">
                    <div class="text-overline">TSP</div>
                    <div
                      v-for="(tentative, i) in pro.enquiryTentative"
                      :key="i"
                    >
                      <v-row justify="space-between">
                        <v-col cols="12" md="6">
                          <v-menu
                            v-model="tentative.tspmenu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="tentative.tentative_sales_date"
                                label="TENTATIVE DATE*"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="tentative.tentative_sales_date"
                              @input="tentative.tspmenu = false"
                              :min="
                                new Date(
                                  Date.now() -
                                    new Date().getTimezoneOffset() * 60000
                                )
                                  .toISOString()
                                  .substr(0, 10)
                              "
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="tentative.tentative_sales_qty"
                            label="QTY"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </div>
                    <v-row>
                      <v-col md="10"> </v-col>
                      <v-col md="1">
                        <v-btn
                          depressed
                          color="warning"
                          @click="addTentativedetails(pro)"
                          v-show="!pro.addTentative"
                        >
                          +ADD
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-divider></v-divider>
                <v-row justify="space-between" class="my-2 ml-2 mr-2">
                  <v-col md="6"> </v-col>
                  <v-col md="4"> </v-col>
                  <v-col md="2">
                    <v-btn
                      color="black"
                      dark
                      @click="updateProductstatus(pro)"
                      v-if="userRole == 'sales-engineer'"
                    >
                      SAVE
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-card-text>

            <!--input field end-->
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <!--  <v-btn color="black" dark @click="submit"> SUBMIT </v-btn>-->
              <v-btn color="warning" dark to="/enquiry-followup-list">
                BACK
              </v-btn>
            </v-card-actions>
          </form>
        </v-card>

        <!--card end-->
      </template>
      <!--template end-->
    </v-container>
    <!--container end-->

    <!--dialog-->
    <v-row justify="center">
      <v-dialog v-model="errorDialog" persistent max-width="290">
        <v-card>
          <v-card-title class="text-h5"> ERROR! </v-card-title>
          <v-card-text>{{ pageError }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="errorDialog = false">
              CLOSE
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!--dialog-->
    <!--dialog-->

    <v-row justify="center">
      <v-dialog v-model="lostDialog" persistent max-width="800px">
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ lostProductname }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="MANUFACTURER NAME"
                    v-model="lostManufacturername"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="SUPPLIER NAME"
                    v-model="lostSuppliername"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="ENQUIRY QTY*"
                    required
                    readonly
                    v-model="lostQuantity"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="PRICE*"
                    v-model="lostPrice"
                    required
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="TOTAL PRICE *"
                    required
                    readonly
                    v-model="lostTotalprice"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="SALES QTY *"
                    required
                    v-model="lostSalesqty"
                    :error-messages="lostSalesqtyErrors"
                    @input="$v.lostSalesqty.$touch()"
                    @blur="$v.lostSalesqty.$touch()"
                    @change="getProductqtyprice()"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="COMPETITOR UNIT PRICE *"
                    v-model="lostCompetitorunitprice"
                    :error-messages="lostCompetitorunitpriceErrors"
                    @input="$v.lostCompetitorunitprice.$touch()"
                    @blur="$v.lostCompetitorunitprice.$touch()"
                    @change="getProductqtyprice()"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="COMPETITOR TOTAL PRICE *"
                    v-model="lostCompetitortotalprice"
                    :error-messages="lostCompetitortotalpriceErrors"
                    @input="$v.lostCompetitortotalprice.$touch()"
                    @blur="$v.lostCompetitortotalprice.$touch()"
                    required
                    readonly
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-select
                    :items="reasons"
                    label="REASON*"
                    v-model="lostReason"
                    :error-messages="lostReasonErrors"
                    @input="$v.lostReason.$touch()"
                    @blur="$v.lostReason.$touch()"
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="REMARKS *"
                    v-model="lostRemarks"
                    required
                    :error-messages="lostRemarksErrors"
                    @input="$v.lostRemarks.$touch()"
                    @blur="$v.lostRemarks.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="warning"
              dark
              @click="
                close();
                lostDialog = false;
              "
            >
              CLOSE
            </v-btn>
            <v-btn
              color="black"
              dark
              @click="submit"
              v-if="userRole == 'sales-engineer'"
            >
              SUBMIT
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <!--dialog-->
  </div>
</template>

<script>
import { required, requiredIf } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "EnquiryFollowupUpdate",
  data: () => ({
    followupstatus: "",
    followupstatusList: ["FOLLOWUP", "NEGOTIATION"],
    followupconfirmationstatusList: ["CONFIRM", "FOL CLOSED", "LOST"],
    modal: false,
    menu2: false,
    followupDate: new Date().toISOString().substr(0, 10),
    menu3: false,
    nextfollowupDate: "",
    nextfollowupMinDate: Date.now(),
    menu4: false,
    remarks: "",
    errorDialog: false,
    lostDialog: false,
    pageError: "",
    lostProductname: "",
    lostManufacturername: "",
    lostSuppliername: "",
    lostQuantity: "",
    lostPrice: "",
    lostTotalprice: "",
    lostSalesqty: "",
    lostCompetitorunitprice: "0.00",
    lostCompetitortotalprice: "",
    lostRemarks: "",
    lostReason: "",
    lostProductDetails: []
  }),
  validations: {
    nextfollowupDate: {
      required: requiredIf(function() {
        return this.followupstatus;
      })
    },
    lostSalesqty: { required },
    lostCompetitorunitprice: { required },
    lostCompetitortotalprice: { required },
    lostRemarks: { required },
    lostReason: { required }
  },
  computed: {
    ...mapGetters([
      "followupStatus",
      "followupStatusResponse",
      "followupcustomerDetails",
      "followupEnquiryDate",
      "followuptotalAmountWithTax",
      "followupworkshopNameDetails",
      "followupaddress1Details",
      "followupSegment",
      "followupBrand",
      "followupMobileno",
      "followupEmailid",
      "followupproductDetails",
      "followupReferenceno",
      "getReasons",
      "authRole"
    ]),
    nextfollowupDateErrors() {
      const errors = [];
      if (!this.$v.nextfollowupDate.$dirty) return errors;
      !this.$v.nextfollowupDate.required &&
        errors.push("nextfollowupDate is required.");
      return errors;
    },
    lostSalesqtyErrors() {
      const errors = [];
      if (!this.$v.lostSalesqty.$dirty) return errors;
      !this.$v.lostSalesqty.required && errors.push("SalesQty is required.");
      return errors;
    },
    lostCompetitorunitpriceErrors() {
      const errors = [];
      if (!this.$v.lostCompetitorunitprice.$dirty) return errors;
      !this.$v.lostCompetitorunitprice.required &&
        errors.push("Competitorunitprice is required.");
      return errors;
    },
    lostCompetitortotalpriceErrors() {
      const errors = [];
      if (!this.$v.lostCompetitortotalprice.$dirty) return errors;
      !this.$v.lostCompetitortotalprice.required &&
        errors.push("Competitortotalprice is required.");
      return errors;
    },
    lostRemarksErrors() {
      const errors = [];
      if (!this.$v.lostRemarks.$dirty) return errors;
      !this.$v.lostRemarks.required && errors.push("Remark is required.");
      return errors;
    },
    lostReasonErrors() {
      const errors = [];
      if (!this.$v.lostReason.$dirty) return errors;
      !this.$v.lostReason.required && errors.push("Reason is required.");
      return errors;
    },
    errorResponse: function() {
      return this.followupStatus === "error";
    },
    followupError: function() {
      return this.followupStatusResponse;
    },
    reasons: function() {
      return this.getReasons;
    },
    userRole: function() {
      return this.authRole;
    },
    customerDetails: {
      get() {
        return this.followupcustomerDetails;
      }
    },
    segment: {
      get() {
        return this.followupSegment;
      }
    },
    brand: {
      get() {
        return this.followupBrand;
      }
    },
    mobileno: {
      get() {
        return this.followupMobileno;
      }
    },
    emailid: {
      get() {
        return this.followupEmailid;
      }
    },
    workshopName: {
      get() {
        return this.followupworkshopNameDetails;
      }
    },
    address1: {
      get() {
        return this.followupaddress1Details;
      }
    },
    enquiryDate: {
      get() {
        return this.followupEnquiryDate;
      }
    },
    totalAmountWithTax: {
      get() {
        return this.followuptotalAmountWithTax;
      }
    },
    productDetails: function() {
      return this.followupproductDetails;
    }
  },
  created() {
    this.fetchEnquiryStatusdetails(this.$route.params.id);
    this.fetchDefinedvalues();
  },
  methods: {
    ...mapActions([
      "fetchEnquiryStatusdetails",
      "enquiryStatusaddforall",
      "enquiryStatusupdate",
      "fetchDefinedvalues"
    ]),
    //qty based product price onchange
    getProductqtyprice() {
      let cost =
        parseFloat(this.lostSalesqty) *
        parseFloat(this.lostCompetitorunitprice);
      this.lostCompetitortotalprice = cost;
    },
    //add product details
    addTentativedetails(tentative) {
      tentative.enquiryTentative.push({
        id: "",
        tspmenu: "",
        tentative_sales_qty: "",
        tentative_sales_date: ""
      });

      tentative.addTentative = true;
    },
    //add Status details
    statusAddforall() {
      this.$v.nextfollowupDate.$touch();

      if (this.$v.nextfollowupDate.$invalid) {
        console.log("ERROR");
      } else {
        // do your submit logic here
        setTimeout(() => {
          const enquiryStatus = {
            enquiryId: this.$route.params.id,
            followupDate: this.followupDate,
            followupStatus: this.followupstatus,
            nextfollowupDate: this.nextfollowupDate,
            remarks: this.remarks
          };

          //console.log(enquiryStatus);
          this.enquiryStatusaddforall(enquiryStatus);
        }, 500);
      }
    },

    updateProductstatus(enquiryProductstatus) {
      const lastStatus =
        enquiryProductstatus.enquiryStatus[
          enquiryProductstatus.enquiryStatus.length - 1
        ];

      const lastTentative =
        enquiryProductstatus.enquiryTentative[
          enquiryProductstatus.enquiryTentative.length - 1
        ];

      // console.log(lastTentative);

      if (
        lastTentative.tentative_sales_date != "" &&
        lastTentative.tentative_sales_qty != ""
      ) {
        const lastFollowupDate = new Date(lastStatus.followup_date);
        const lastTentativeDate = new Date(lastTentative.tentative_sales_date);

        if (lastTentativeDate >= lastFollowupDate) {
          if (
            lastStatus.status == null ||
            lastStatus.status == "FOLLOWUP" ||
            lastStatus.status == "NEGOTIATION" ||
            lastStatus.status == "CONFIRM" ||
            lastStatus.status == "FOL CLOSED"
          ) {
            console.log(enquiryProductstatus);
            const enquiryData = {
              enquiryDetailid: enquiryProductstatus.enquiryDetailid,
              enquiryStatus: enquiryProductstatus.enquiryStatus,
              enquiryTentative: enquiryProductstatus.enquiryTentative
            };
            this.enquiryStatusupdate(enquiryData);
          }

          /* if (lastStatus.status == "FOL CLOSED") {

            const enquiryData = {
            enquiryDetailid: enquiryProductstatus.enquiryDetailid,
            enquiryStatus: enquiryProductstatus.enquiryStatus,
            enquiryTentative: enquiryProductstatus.enquiryTentative,
            };
         
            this.enquiryStatusupdate(enquiryData);

          }*/
          if (lastStatus.status == "LOST") {
            this.lostDialog = true;
            this.lostProductname = enquiryProductstatus.equipment.product_name;
            this.lostQuantity = enquiryProductstatus.quantity;
            this.lostPrice = enquiryProductstatus.price;
            this.lostTotalprice = enquiryProductstatus.totalPrice;
            this.lostProductDetails = enquiryProductstatus;
          }
        } else {
          //  console.log('tentative date cant be less than followup date.');
          this.errorDialog = true;
          this.pageError = "TENTATIVE DATE CANT BE LESS THAN FOLLOWUP DATE";
        }
      } else {
        if (lastStatus.status == "CONFIRM") {
          this.errorDialog = true;
          this.pageError = "TENTATIVE SALES DATE & QTY CANNOT BE EMPTY";
        }
        if (lastStatus.status == "FOL CLOSED") {
          const enquiryData = {
            enquiryDetailid: enquiryProductstatus.enquiryDetailid,
            enquiryStatus: enquiryProductstatus.enquiryStatus,
            enquiryTentative: enquiryProductstatus.enquiryTentative
          };

          this.enquiryStatusupdate(enquiryData);
        }
        if (lastStatus.status == "LOST") {
          this.lostDialog = true;
          this.lostProductname = enquiryProductstatus.equipment.product_name;
          this.lostQuantity = enquiryProductstatus.quantity;
          this.lostPrice = enquiryProductstatus.price;
          this.lostTotalprice = enquiryProductstatus.totalPrice;
          this.lostProductDetails = enquiryProductstatus;
        }
      }
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("ERROR");
      } else {
        const enquiryData = {
          enquiryDetailid: this.lostProductDetails.enquiryDetailid,
          enquiryStatus: this.lostProductDetails.enquiryStatus,
          enquiryTentative: this.lostProductDetails.enquiryTentative,
          lostManufacturername: this.lostManufacturername.toUpperCase(),
          lostSuppliername: this.lostSuppliername.toUpperCase(),
          lostSalesqty: this.lostSalesqty,
          lostCompetitorunitprice: this.lostCompetitorunitprice,
          lostCompetitortotalprice: this.lostCompetitortotalprice,
          lostRemarks: this.lostRemarks.toUpperCase(),
          lostReason: this.lostReason.toUpperCase()
        };

        this.enquiryStatusupdate(enquiryData);
      }
    },
    close() {
      this.lostProductname = "";
      this.lostManufacturername = "";
      this.lostSuppliername = "";
      this.lostQuantity = "";
      this.lostPrice = "";
      this.lostTotalprice = "";
      this.lostSalesqty = "";
      this.lostCompetitorunitprice = "0.00";
      this.lostCompetitortotalprice = "";
      this.lostRemarks = "";
      this.lostReason = "";
    },
    setNextfollowupmindate() {
      console.log(this.followupDate);
      this.nextfollowupMinDate = new Date(this.followupDate);
    }
  }
};
</script>
