import { VUE_APP_BASEURL } from "../../utils/api";
import router from "../../router";
import axios from "axios";
import store from "../../store";

const state = {
  products: [],
  productResponse: [],
  status: "",
  productGroup: "",
  tplNo: "",
  equipmentName: "",
  productImage: "",
  warrantyExclusions: "",
  model: "",
  scopeofSupply: "",
  productSpecification: "",
  productBrandPrice: [],
  specialFeatures: "",
  brandPrice: "",
  warrantyinYears: "",
  attachment1: "",
  attachment2: "",
  attachment3: "",
  attachment4: "",
  attachment1filename: "",
  attachment2filename: "",
  attachment3filename: "",
  attachment4filename: "",
  productActive: "",
  allProducts: []
};

const getters = {
  productList: state => state.products,
  productStatusResponse: state => state.productResponse,
  productStatus: state => state.status,
  productgroupDetailsname: state => state.productGroup,
  productImageDetails: state => state.productImage,
  tplnoDetailsname: state => state.tplNo,
  equipmentnameDetailsname: state => state.equipmentName,
  warrantyexclusionsDetailsname: state => state.warrantyExclusions,
  modelDetailsname: state => state.model,
  scopeofsupplyDetailsname: state => state.scopeofSupply,
  productspecificationDetailsname: state => state.productSpecification,
  specialfeaturesDetailsname: state => state.specialFeatures,
  productBrandPriceDetails: state => state.productBrandPrice,
  warrantyinyearsDetailsname: state => state.warrantyinYears,
  brandPriceDetails: state => state.brandPrice,
  getProductActive: state => state.productActive,
  getAttachment1: state => state.attachment1,
  getAttachment2: state => state.attachment2,
  getAttachment3: state => state.attachment3,
  getAttachment4: state => state.attachment4,
  getAttachment1filename: state => state.attachment1filename,
  getAttachment2filename: state => state.attachment2filename,
  getAttachment3filename: state => state.attachment3filename,
  getAttachment4filename: state => state.attachment4filename,
  allproductList: state => state.allProducts
};

const actions = {
  //State fetch
  async fetchAllProducts({ commit }) {
    commit("authRequest");
    axios({
      method: "GET",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "all-product-list"
    })
      .then(function(response) {
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("setallProductsList", response.data.response);
        }
      })
      .catch(function(error) {
        console.log(error);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("productError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  },

  //State fetch
  async fetchProducts({ commit }) {
    commit("authRequest");
    axios({
      method: "GET",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "product"
    })
      .then(function(response) {
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("setproductList", response.data.response);
        }
      })
      .catch(function(error) {
        console.log(error);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("productError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  },

  //new Product add action
  async productAdd({ commit }, productData) {
    commit("authRequest");
    console.log(productData);
    axios({
      method: "POST",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "product",
      data: {
        productGroup: productData.productGroup,
        tplNo: productData.tplNo,
        equipmentName: productData.equipmentName,
        productImage: productData.productImage,
        warrantyinYears: productData.warrantyinYears,
        warrantyExclusions: productData.warrantyExclusions,
        model: productData.model,
        scopeofSupply: productData.scopeofSupply,
        productSpecification: productData.productSpecification,
        specialFeatures: productData.specialFeatures,
        attachment1: productData.attachment1,
        attachment2: productData.attachment2,
        attachment3: productData.attachment3,
        attachment4: productData.attachment4,
        attachment1name: productData.attachment1name,
        attachment2name: productData.attachment2name,
        attachment3name: productData.attachment3name,
        attachment4name: productData.attachment4name
      }
    })
      .then(function(response) {
        console.log(response);
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("productaddMethod", response.data.response);
          router.push("/product");
        }
      })
      .catch(function(error) {
        console.log(error);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          console.log(error.response);

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("productError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  },
  //new City add action end

  //fetch Product details
  async fetchProductdetails({ commit }, productData) {
    commit("authRequest");

    axios({
      method: "GET",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url:
        VUE_APP_BASEURL +
        "product/" +
        productData.productId +
        "?customerId=" +
        productData.customerId
    })
      .then(function(response) {
        // console.log(response);
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("productDetails", response.data.response);
        }
      })
      .catch(function(error) {
        console.log(error);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("productError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  },

  //Product update action
  async productUpdate({ commit }, productData) {
    console.log(productData);
    //console.log(store.getters.UserToken);
    commit("authRequest");
    axios({
      method: "PUT",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "product/" + productData.productId,
      data: {
        productGroup: productData.productGroup,
        tplNo: productData.tplNo,
        equipmentName: productData.equipmentName,
        productImage: productData.productImage,
        warrantyinYears: productData.warrantyinYears,
        warrantyExclusions: productData.warrantyExclusions,
        model: productData.model,
        scopeofSupply: productData.scopeofSupply,
        productSpecification: productData.productSpecification,
        specialFeatures: productData.specialFeatures,
        brandPrice: productData.brandPrice,
        attachment1: productData.attachment1,
        attachment2: productData.attachment2,
        attachment3: productData.attachment3,
        attachment4: productData.attachment4,
        attachment1name: productData.attachment1name,
        attachment2name: productData.attachment2name,
        attachment3name: productData.attachment3name,
        attachment4name: productData.attachment4name,
        productActive: productData.productactive
      }
    })
      .then(function(response) {
        console.log(response);
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("productupdateMethod", response.data.response);
         // router.push("/product");
        }
      })
      .catch(function(error) {
        console.log(error.response);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("productError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  }
  //city update action end
};

const mutations = {
  setproductList: (state, resp) => {
    state.products = resp;
  },
  setallProductsList: (state, resp) => {
    state.allProducts = resp;
  },
  productaddMethod: (state, resp) => {
    state.status = "success";
    state.productResponse = resp;
  },
  productupdateMethod: (state, resp) => {
    state.status = "success";
    state.productResponse = resp;
  },
  productError: (state, resp) => {
    state.status = "error";
    state.productResponse = resp;
  },
  productDetails: (state, resp) => {
    state.status = "";
    state.productGroup = resp.product_group;
    state.equipmentName = resp.product_name;
    state.tplNo = resp.tpl_no;
    state.productImage = resp.product_image;
    state.warrantyinYears = resp.warranty_in_years;
    state.warrantyExclusions = resp.warranty_exclusions;
    state.model = resp.model;
    state.scopeofSupply = resp.scope_of_supply;
    state.productSpecification = resp.product_specification;
    state.specialFeatures = resp.special_features;
    state.productBrandPrice = resp.brands;
    state.brandPrice = resp.brandPrice;

    state.productActive = resp.active;

    state.attachment1 = resp.attachment1;
    state.attachment2 = resp.attachment2;
    state.attachment3 = resp.attachment3;
    state.attachment4 = resp.attachment4;

    state.attachment1filename = resp.attachment1filename;
    state.attachment2filename = resp.attachment2filename;
    state.attachment3filename = resp.attachment3filename;
    state.attachment4filename = resp.attachment4filename;
  },
  updateproductGroup: (state, productgroupname) => {
    state.productGroup = productgroupname;
  },
  updatetplno: (state, tplno) => {
    state.tplNo = tplno;
  },
  updateequipmentname: (state, equipmentname) => {
    state.equipmentName = equipmentname;
  },
  updatewarrantyinyears: (state, warrantyinYears) => {
    state.warrantyinYears = warrantyinYears;
  },
  updatewarrantyexclusions: (state, warrantyexclusions) => {
    state.warrantyExclusions = warrantyexclusions;
  },
  updatemodel: (state, model) => {
    state.model = model;
  },
  updatescopeofsupply: (state, scopeofsupply) => {
    state.scopeofSupply = scopeofsupply;
  },
  updateproductspecification: (state, productspecification) => {
    state.productSpecification = productspecification;
  },
  updatespecialfeatures: (state, specialfeatures) => {
    state.specialFeatures = specialfeatures;
  },
  updateproductActive: (state, active) => {
    state.productActive = active;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
