<template>
  <div>
    <v-card class="mx-auto ml-5 my-5 mx-5 mr-5" outlined>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr class="orange darken-1">
              <th colspan="10" class="text-center">
                <span class="white--text text-h6">ORDER LOSS REPORT</span>
              </th>
            </tr>
            <tr>
              <th class="text-left">STATUS</th>
              <th class="text-left">AMOUNT</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(record, m) in chartData.orderlossbarchartData" :key="m">
              <td>{{ record.name }}</td>
              <td>{{ record.data[0] }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>

    <v-card class="mx-auto ml-5 my-5 mx-5 mr-5 " outlined>
      <highcharts :options="chartOptions" ref="highcharts"></highcharts>
      <!--<button @click="updateCredits">update credits</button>-->
    </v-card>
  </div>
</template>

<script>
// @ is an alias to /src

import { mapGetters, mapActions } from "vuex";
import { VUE_APP_BASEURL } from "../utils/api";
import axios from "axios";
import store from "../store";

export default {
  name: "OrderlossReportChart",
  props: [
    "selectedsegment",
    "selectedsalesengineer",
    "selectedregion",
    "selectedproductgroup",
    "selecteddaterange"
  ],
  data: () => ({
    chartOptions: {
      chart: {
        type: "column"
      },
      title: {
        text: "ORDER LOSS REPORT CHART"
      },
      subtitle: {
        text: ""
      },
      xAxis: {
        categories: [],
        crosshair: true
      },
      yAxis: {
        min: 0,
        title: {
          text: ""
        }
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.1f} </b></td></tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0
        }
      },
      series: [{}],
      credits: {
        enabled: false
      }
    }
  }),
  components: {},
  computed: {
    ...mapGetters(["getOrderlosschartdata"]),
    chartData: function() {
      return this.getOrderlosschartdata;
    }
  },
  mounted() {
    /*setTimeout(() => {
        this.chartOptions.series=this.chartData.orderlossbarchartData;
        this.chartOptions.xAxis.categories=this.chartData.monthlysalesplanDates;
        //console.log('updated');
        }, 4000)*/
  },
  created() {
    const filterData = [];
    this.orderlossreportchart(filterData);
    this.orderlosschartload(this.chartOptions);
  },
  methods: {
    ...mapActions(["orderlossreportchart"]),

    orderlosschartload(chartOptions) {
      const filterData = [];

      axios({
        method: "POST",
        headers: {
          Authorization: "Bearer " + store.getters.UserToken
        },
        url: VUE_APP_BASEURL + "dashboard/orderloss-report-chart",
        data: {
          segment: filterData.segment,
          salesengineer: filterData.salesengineer,
          fromdate: filterData.fromDate,
          todate: filterData.toDate
        }
      })
        .then(function(response) {
          chartOptions.series = response.data.response.orderlossbarchartData;
        })
        .catch(function(error) {
          console.log(error);
        });
    },

    filterOrderLossReport() {
      const filterData = {
        segment: this.selectedsegment,
        salesengineer: this.selectedsalesengineer,
        daterange: this.selecteddaterange,
        region: this.selectedregion,
        productgroup: this.selectedproductgroup
      };
      const chartOptions = this.chartOptions;
      axios({
        method: "POST",
        headers: {
          Authorization: "Bearer " + store.getters.UserToken
        },
        url: VUE_APP_BASEURL + "dashboard/orderloss-report-chart",
        data: {
          segment: filterData.segment,
          salesengineer: filterData.salesengineer,
          daterange: filterData.daterange,
          region: filterData.region,
          productgroup: filterData.productgroup
        }
      })
        .then(function(response) {
          console.log(response);
          chartOptions.series = response.data.response.orderlossbarchartData;
        })
        .catch(function(error) {
          console.log(error);
          console.log(error.response);
        });

      this.orderlossreportchart(filterData);
    }
  }
};
</script>
