var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('bar'),[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.products,"search":_vm.search,"sort-by":"calories"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("PRODUCT")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer')],1)]},proxy:true},{key:"item.product_image",fn:function(ref){
var item = ref.item;
return [_c('img',{staticStyle:{"width":"100px"},attrs:{"src":item.product_image}})]}},{key:"item.attachment1",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":item.attachment1,"target":"_blank"}},[_vm._v(" "+_vm._s(item.attachment1filename)+" ")])]}},{key:"item.attachment2",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":item.attachment2,"target":"_blank"}},[_vm._v(" "+_vm._s(item.attachment2filename)+" ")])]}},{key:"item.attachment3",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":item.attachment3,"target":"_blank"}},[_vm._v(" "+_vm._s(item.attachment3filename)+" ")])]}},{key:"item.attachment4",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":item.attachment4,"target":"_blank"}},[_vm._v(" "+_vm._s(item.attachment4filename)+" ")])]}}],null,true)})]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }