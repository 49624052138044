import { VUE_APP_BASEURL } from "../../utils/api";
import router from "../../router";
import axios from "axios";
import store from "../../store";

const state = {
    proformas:[],
    proformaPaginate: 0,
    proformaResponse: [],
    performaDetails: "",
    status: "",
  };

  const getters = {
    proformaList: state => state.proformas,
    proformaListPaginate: state => state.proformaPaginate,
    proformaStatusResponse: state => state.proformaResponse,
    proformaStatus: state => state.status,
    getProformaDetails: state => state.performaDetails,
  };

const actions = {  

    //State fetch
    async fetchProformas({ commit }, filterData) {
      commit("authRequest");
      axios({
        method: "GET",
        headers: {
          Authorization: "Bearer " + store.getters.UserToken
        },
        url:
          VUE_APP_BASEURL +
          "proforma?page=" +
          filterData.page 
      })
        .then(function(response) {
          //   console.log(response);
          if (response.data.result == 1) {
            //     console.log(response.data.response);
            commit("authSuccess");
            commit("setProformaList", response.data.response);
          }
        })
        .catch(function(error) {
          console.log(error.response);
          if (error.response) {
            // client received an error response (5xx, 4xx)
            console.log(error.response);
            if (error.response.status == 404) {
              commit("authError", "AuthenticationError");
              commit("authLogout");
              router.push("/");
            }
            if (error.response.status == 422) {
              commit("authError", "AuthenticationError");
              commit("proformaError", error.response.data.response);
            }
          } else if (error.request) {
            // client never received a response, or request never left
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
            console.log(error.request);
          } else {
            // anything else
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
            console.log(error);
          }
        });
    },

      //new Enquiry add action
  async proformaAdd({ commit }, proformaData) {
    commit("authRequest");
    console.log(proformaData);
    axios({
      method: "POST",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "proforma",
      data: {
            gstin: proformaData.gstin,
            pan: proformaData.pan,
            cin: proformaData.cin,
            iecode: proformaData.iecode,
            reverseCharge: proformaData.reverseCharge,
            poRef: proformaData.poRef,
            placeofSupply: proformaData.placeofSupply,
            proformaInvoiceNo: proformaData.proformaInvoiceNo,
            billTo: proformaData.billTo,
            shipTo: proformaData.shipTo,
            proformaInvoiceDate: proformaData.proformaInvoiceDate,
            isIgst: proformaData.isIgst,
            tax: proformaData.tax,
            taxAmount: proformaData.taxAmount,
            totalAmount: proformaData.totalAmount,
            totalAmountWithTax: proformaData.totalAmountWithTax,
            proformaDetails: proformaData.proformaDetails,
            deliveryterms: proformaData.deliveryterms,
            paymentterms: proformaData.paymentterms,
            ewaybill: proformaData.ewaybill,
            transportname: proformaData.transportname,
            lrnumber: proformaData.lrnumber,
            transportdate: proformaData.transportdate,
            vehiclenumber: proformaData.vehiclenumber,
            dispatchmode: proformaData.dispatchmode,
            customerId:  proformaData.customerId,
            freightCharges: proformaData.freightCharges,
      }
    })
      .then(function(response) {
        //  console.log(response);
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("proformaaddMethod", response.data.response);
          router.push("/proforma/edit/" + response.data.response.id);
        }
      })
      .catch(function(error) {
        console.log(error.response);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("proformaError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  },
  //fetch Enquiry details
  async fetchProformaDetails({ commit }, proformaId) {
    commit("authRequest");

    axios({
      method: "GET",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "proforma/" + proformaId
    })
      .then(function(response) {
   //     console.log(response);
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("proformaFetchDetails", response.data.response);
        }
      })
      .catch(function(error) {
        console.log(error);
        if (error.response) {
          // client received an error response (5xx, 4xx)
          console.log(error.response);
          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("proformaError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  },

  //new Enquiry add action
  async proformaUpdate({ commit }, proformaData) {
    commit("authRequest");
    console.log(proformaData);
    axios({
      method: "PUT",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "proforma/" + proformaData.proformaId,
      data: {
            gstin: proformaData.gstin,
            pan: proformaData.pan,
            cin: proformaData.cin,
            iecode: proformaData.iecode,
            reverseCharge: proformaData.reverseCharge,
            poRef: proformaData.poRef,
            placeofSupply: proformaData.placeofSupply,
            proformaInvoiceNo: proformaData.proformaInvoiceNo,
            billTo: proformaData.billTo,
            shipTo: proformaData.shipTo,
            proformaInvoiceDate: proformaData.proformaInvoiceDate,
            isIgst: proformaData.isIgst,
            tax: proformaData.tax,
            taxAmount: proformaData.taxAmount,
            totalAmount: proformaData.totalAmount,
            totalAmountWithTax: proformaData.totalAmountWithTax,
            proformaDetails: proformaData.proformaDetails,
            deliveryterms: proformaData.deliveryterms,
            paymentterms: proformaData.paymentterms,
            ewaybill: proformaData.ewaybill,
            transportname: proformaData.transportname,
            lrnumber: proformaData.lrnumber,
            transportdate: proformaData.transportdate,
            vehiclenumber: proformaData.vehiclenumber,
            dispatchmode: proformaData.dispatchmode,
            terms: proformaData.terms,
            freightCharges: proformaData.freightCharges,
      }
    })
      .then(function(response) {
        //  console.log(response);
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("proformaUpdateMethod", response.data.response);
        }
      })
      .catch(function(error) {
        console.log(error.response);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("proformaError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  },
  //Enquiry details delete action
  async proformadetailsDelete({ commit, dispatch }, deleteData) {
    commit("authRequest");
    //      console.log(deleteData.deleteId);

    axios({
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "proforma-details/" + deleteData.deleteId
    })
      .then(function(response) {
        console.log(response);
        if (response.data.result == 1) {
          console.log(deleteData.proformaId);
          commit("authSuccess");
          commit("proformadeleteMethod", response.data.response);
          dispatch("fetchProformaDetails", deleteData.proformaId);
        }
      })
      .catch(function(error) {
        console.log(error);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("enquiryError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  }
  

  };

  const mutations = {
    setProformaList: (state, resp) => {
      state.status = "";
      state.proformas = resp.proformaPaginate.data;
      state.proformaPaginate = resp.proformaPaginate.last_page;
    },
    proformaaddMethod: (state, resp) => {
      state.status = "success";
      state.proformaResponse = resp.message;
    },
    proformaUpdateMethod: (state, resp) => {
      state.status = "success";
      state.proformaResponse = resp.message;
    },
    proformadeleteMethod: (state, resp) => {
      state.status = "success";
      state.proformaResponse = resp.message;
    },
    proformaError: (state, resp) => {
      state.status = "error";
      state.proformaResponse = resp;
    },
    proformaFetchDetails: (state, resp) => {
      console.log(resp);
      state.performaDetails = resp;
    },
    updateGstin: (state, gstin) => {
      state.performaDetails.gstin = gstin;
    },
    updatePan: (state, pan) => {
      state.performaDetails.pan = pan;
    },
    updateCin: (state, cin) => {
      state.performaDetails.cin = cin;
    },
    updateIecode: (state, iecode) => {
      state.performaDetails.iecode = iecode;
    },
    updateReverseCharge: (state, reverseCharge) => {
      state.performaDetails.reverseCharge = reverseCharge;
    },
    updatePoRef: (state, poRef) => {
      state.performaDetails.poRef = poRef;
    },
    updatePlaceOfSupply: (state, placeofsupply) => {
      state.performaDetails.placeofsupply = placeofsupply;
    },
    updateBillTo: (state, billTo) => {
      state.performaDetails.billTo = billTo;
    },
    updateShipTo: (state, shipTo) => {
      state.performaDetails.shipTo = shipTo;
    },
    updateProformaInvoiceNo: (state, proformaInvoiceNo) => {
      state.performaDetails.proformaInvoiceNo = proformaInvoiceNo;
    },
    updateProformaInvoiceDate: (state, proformaInvoiceDate) => {
      state.performaDetails.proformaInvoiceDate = proformaInvoiceDate;
    },
    updatePerformaTax: (state, tax) => {
      state.performaDetails.tax = tax;
    },
    updatePerformaIgst: (state, isIgst) => {
      state.performaDetails.isIgst = isIgst;
    },
    updatePerformaTaxAmount: (state, taxAmount) => {
      state.performaDetails.taxAmount = taxAmount;
    },
    updatePerformaTotalPrice: (state, totalAmount) => {
      state.performaDetails.totalAmount = totalAmount;
    },
    updateFreightCharges: (state, freightCharges) => {
      state.performaDetails.freightCharges = freightCharges;
    },
    updatePerformaTotalPriceWithTax: (state, totalAmountWithTax) => {
      state.performaDetails.totalAmountWithTax = totalAmountWithTax;
    },
    updatePerformaEwaybill: (state, ewaybill) => {
      state.performaDetails.ewaybill = ewaybill;
    },
    updatePerformaTransportname: (state, transportname) => {
      state.performaDetails.transportname = transportname;
    },
    updatePerformaLrnumber: (state, lrnumber) => {
      state.performaDetails.lrnumber = lrnumber;
    },
    updatePerformaTransportdate: (state, transportdate) => {
      state.performaDetails.transportdate = transportdate;
    },
    updatePerformaVehiclenumber: (state, vehiclenumber) => {
      state.performaDetails.vehiclenumber = vehiclenumber;
    },
    updatePerformaDispatchmode: (state, dispatchmode) => {
      state.performaDetails.dispatchmode = dispatchmode;
    },
    updatePerformaDeliveryterms: (state, deliveryterms) => {
      state.performaDetails.deliveryterms = deliveryterms;
    },
    updatePerformaPaymenttermss: (state, paymentterms) => {
      state.performaDetails.paymentterms = paymentterms;
    },


    

  };
  

  export default {
    state,
    getters,
    actions,
    mutations
  };