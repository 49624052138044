<template>
  <div>
    <!---datatable -->
    <v-data-table
      :headers="headers"
      :items="customerEnquiryDetails.enquiries"
      :search="search"
      sort-by="name"
      class="elevation-1 form-card mx-auto my-12"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>ENQUIRY</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>

          <v-spacer></v-spacer>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="enquiryEdit(item)">
          mdi-pencil
        </v-icon>
      </template>
    </v-data-table>
    <!---datatable end-->
  </div>
</template>

<script>
// @ is an alias to /src

import { mapActions, mapGetters } from "vuex";
import router from "../router";
export default {
  name: "CustomerEnquiry",
  props: ["customerId"],
  data: () => ({
    search: "",
    headers: [
      { text: "Actions", value: "actions", sortable: false },
      { text: "WORKSHOP NAME", value: "workshop_name" },
      {
        text: "CUSTOMER DETAILS",
        align: "start",
        sortable: true,
        value: "customer_details"
      },
      { text: "REFRERENCE NO", value: "referenceNo" },
      { text: "SEGMENT", value: "segment" },
      { text: "LOB", value: "brand" },
      { text: "ENGINEER NAME", value: "salesengineer" },
      { text: "CITY", value: "city" },
      { text: "STATE", value: "state" },
      { text: "ENQUIRY DATE", value: "enquiry_date" }
    ]
  }),
  components: {},
  computed: {
    ...mapGetters(["getCustomerDetails"]),
    customerEnquiryDetails: function() {
      return this.getCustomerDetails;
    }
  },
  mounted() {},
  created() {
    setTimeout(() => {
      console.log(this.customerId);
      this.fetchCustomerdetails(this.customerId);
    }, 2000);
  },
  methods: {
    ...mapActions(["fetchCustomerdetails"]),
    enquiryEdit(item) {
      const id = item.id;
      router.push({ path: `/enquiry/edit/${id}` });
      location.reload();
    }
  }
};
</script>
