import { VUE_APP_BASEURL } from "../../utils/api";
import router from "../../router";
import axios from "axios";
import store from "../../store";

const state = {
  cities: [],
  states: [],
  cityResponse: [],
  countryName: "",
  stateName: "",
  cityName: "",
  status: ""
};

const getters = {
  cityList: state => state.cities,
  cityStatusResponse: state => state.cityResponse,
  cityStatus: state => state.status,
  countryBasedstatelist: state => state.states,
  cityDetailsname: state => state.cityName,
  stateidDetailsname: state => state.stateName,
  citycountryidDetailsname: state => state.countryName
};

const actions = {
  //State fetch
  async fetchCities({ commit }) {
    commit("authRequest");
    axios({
      method: "GET",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "city"
    })
      .then(function(response) {
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("setcityList", response.data.response);
        }
      })
      .catch(function(error) {
        console.log(error);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("stateError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  },

  //Get state Based on Country
  async stateGetlist({ commit }, countryId) {
    commit("authRequest");
    axios({
      method: "POST",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "state/getStatelist",
      data: {
        countryName: countryId
      }
    })
      .then(function(response) {
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("statelistMethod", response.data.response);
        }
      })
      .catch(function(error) {
        console.log(error);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("cityError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  },
  //Get state Based on Country

  //Get city Based on State
  async cityGetlist({ commit }, stateId) {
    commit("authRequest");
    axios({
      method: "POST",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "city/getCitylist",
      data: {
        stateName: stateId
      }
    })
      .then(function(response) {
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("citylistMethod", response.data.response);
        }
      })
      .catch(function(error) {
        console.log(error);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("cityError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  },
  //Get state Based on Country

  //new City add action
  async cityAdd({ commit }, stateData) {
    commit("authRequest");
    axios({
      method: "POST",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "city",
      data: {
        countryName: stateData.countryName,
        stateName: stateData.stateName,
        cityName: stateData.cityName
      }
    })
      .then(function(response) {
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("cityaddMethod", response.data.response);
          router.push("/city");
        }
      })
      .catch(function(error) {
        console.log(error);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("cityError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  },
  //new City add action end

  //fetch City details
  async fetchCitydetails({ commit, dispatch }, cityId) {
    commit("authRequest");

    axios({
      method: "GET",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "city/" + cityId
    })
      .then(function(response) {
        if (response.data.result == 1) {
          commit("authSuccess");
          dispatch("stateGetlist", response.data.response.country_id);
          commit("cityDetails", response.data.response);
        }
      })
      .catch(function(error) {
        console.log(error);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("countryError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  },

  //city update action
  async cityUpdate({ commit }, cityData) {
    commit("authRequest");
    axios({
      method: "PUT",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "city/" + cityData.cityId,
      data: {
        countryName: cityData.countryName,
        stateName: cityData.stateName,
        cityName: cityData.cityName
      }
    })
      .then(function(response) {
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("cityupdateMethod", response.data.response);
          router.push("/city");
        }
      })
      .catch(function(error) {
        console.log(error);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("countryError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  }
  //city update action end
};

const mutations = {
  setcityList: (state, resp) => {
    state.cities = resp;
  },
  cityaddMethod: (state, resp) => {
    state.status = "success";
    state.cityResponse = resp;
  },
  statelistMethod: (state, resp) => {
    state.status = "";
    state.states = resp;
  },
  citylistMethod: (state, resp) => {
    state.status = "";
    state.cities = resp;
  },
  cityDetails: (state, resp) => {
    state.status = "";
    state.countryName = resp.country_id;
    state.stateName = resp.state_id;
    state.cityName = resp.name;
  },
  cityupdateMethod: (state, resp) => {
    state.status = "success";
    state.cityResponse = resp;
  },
  cityError: (state, resp) => {
    state.status = "error";
    state.cityResponse = resp;
  },
  updateCityname: (state, cityname) => {
    state.cityName = cityname;
  },
  updateStateidname: (state, statename) => {
    state.stateName = statename;
  },
  updateCountryidname: (state, countryname) => {
    state.countryName = countryname;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
