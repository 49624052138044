<template>
  <div>
    <v-card class="mx-auto mb-5" outlined>
      <v-card-text>
        <v-row>
          <v-col md="2" cols="12">
            <v-select
              v-model="productgroup"
              :items="productgroups"
              item-text="product_group_name"
              item-value="id"
              label="PRODUCT GROUP*"
              class="mt-4"
              chips
              multiple
              clearable
            ></v-select>
          </v-col>

          <v-col cols="12" md="2">
            <v-autocomplete
              v-model="product"
              :items="products"
              item-text="product_name_withtpl"
              item-value="id"
              label="EQUIPMENT *"
              class="mt-6"
              :error-messages="productErrors"
              required
              @input="$v.product.$touch()"
              @blur="$v.product.$touch()"
              clearable
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" md="2">
            <v-text-field
              v-model="buybackyear"
              label="BUYBACK YEAR"
              class="mt-6"
            ></v-text-field>
          </v-col>

          <v-col cols="12" xs="12" sm="12" md="2">
            <v-autocomplete
              v-model="state"
              :items="states"
              item-text="name"
              item-value="id"
              label="STATE "
              class="mt-6"
              v-on:change="getCity(`${state}`)"
              clearable
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" xs="12" sm="12" md="2">
            <v-autocomplete
              v-model="city"
              :items="cities"
              item-text="name"
              item-value="id"
              label="CITY "
              class="mt-6"
              clearable
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="warning" dark @click="filterEquipmentReport">
          FIND
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
// @ is an alias to /src

import { mapActions, mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
export default {
  name: "BuybackSearch",
  data: () => ({
    product: "",
    buybackyear: "",
    state: "",
    city: "",
    productgroup: ""
  }),
  validations: {
    product: { required }
  },
  components: {},
  computed: {
    ...mapGetters(["productList", "stateList", "cityList", "productGroup"]),

    products: function() {
      return this.productList;
    },
    states: function() {
      return this.stateList;
    },
    cities: function() {
      return this.cityList;
    },
    productgroups: function() {
      return this.productGroup;
    },
    productErrors() {
      const errors = [];
      if (!this.$v.product.$dirty) return errors;
      !this.$v.product.required && errors.push("Equipment is required.");
      return errors;
    }
  },
  created() {
    this.fetchProducts();
    this.fetchDefinedvalues();
    this.fetchStates();
    this.fetchProductgroup();
  },
  methods: {
    ...mapActions([
      "fetchProducts",
      "equipmentReport",
      "fetchDefinedvalues",
      "fetchStates",
      "cityGetlist",
      "fetchProductgroup"
    ]),
    getCity(stateid) {
      if (stateid != "null") {
        this.cityGetlist(stateid);
      }
    },
    filterEquipmentReport() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("ERROR");
      } else {
        const filterData = {
          product: this.product,
          buybackyear: this.buybackyear,
          state: this.state,
          city: this.city,
          productgroup: this.productgroup
        };

        this.equipmentReport(filterData);
      }
    }
  }
};
</script>
