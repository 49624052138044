import { VUE_APP_BASEURL } from "../../utils/api";
import router from "../../router";
import axios from "axios";
import store from "../../store";

const state = {
  leads: [],
  leadResponse: [],
  status: "",
  customerDetails: "",
  EnquiryDate: "",
  contactNumber: "",
  emailId: "",
  segment: "",
  state: "",
  brand: "",
  city: "",
  region: "",
  taxAmount: "",
  totalAmount: "",
  totalAmountWithTax: "",
  customer_code: "",
  enquiryDetails: [],
  leadEnquiryid: "",
  enquirySource: "",
  categoryEnquirysource: "",
  message: "",
  hoRemarks: "",
  enquiryType: "",
  assignedUser: "",
  salesengineerStatus: "",
  leadConvertstatus: ""
};

const getters = {
  leadList: state => state.leads,
  leadStatusResponse: state => state.leadResponse,
  leadStatus: state => state.status,
  getleadcustomerDetails: state => state.customerDetails,
  getleadEnquiryDate: state => state.EnquiryDate,
  getleadtaxAmount: state => state.taxAmount,
  getleadtotalAmount: state => state.totalAmount,
  getleadtotalAmountWithTax: state => state.totalAmountWithTax,
  getleadbrand: state => state.brand,
  getleadSegment: state => state.segment,
  getleadMobileno: state => state.contactNumber,
  getleadEmailid: state => state.emailId,
  getleadstateDetails: state => state.state,
  getleadcityDetails: state => state.city,
  getleadregionDetails: state => state.region,
  getleadsourceDetails: state => state.source,
  getleadCustomercodeDetails: state => state.customer_code,
  getleadproductDetails: state => state.enquiryDetails,

  getleadEnquiryid: state => state.leadEnquiryid,

  getleadenquirySource: state => state.enquirySource,
  getleadcategoryEnquirysource: state => state.categoryEnquirysource,
  getleadmessage: state => state.message,
  getleadhoRemarks: state => state.hoRemarks,
  getleadenquiryType: state => state.enquiryType,
  getleadassignedUser: state => state.assignedUser,
  getsalesEngineerStatus: state => state.salesengineerStatus,
  getleadConvertstatus: state => state.leadConvertstatus
};

const actions = {
  //lead fetch
  async fetchLeads({ commit }) {
    commit("authRequest");
    axios({
      method: "GET",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "lead"
    })
      .then(function(response) {
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("setleadList", response.data.response);
        }
      })
      .catch(function(error) {
        console.log(error.response);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("leadError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  },

  //new lead add action
  async leadAdd({ commit }, leadData) {
    commit("authRequest");
    axios({
      method: "POST",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "lead",
      data: {
        customerCode: leadData.customerCode,
        customerDetails: leadData.customerDetails,
        EnquiryDate: leadData.EnquiryDate,
        contactNumber: leadData.contactNumber,
        emailId: leadData.emailId,
        state: leadData.state,
        city: leadData.city,
        brand: leadData.brand,
        segment: leadData.segment,
        region: leadData.region,
        source: leadData.source,
        taxAmount: leadData.taxAmount,
        totalAmount: leadData.totalAmount,
        totalAmountWithTax: leadData.totalAmountWithTax,
        enquiryDetails: leadData.enquiryDetails,
        enquirysource: leadData.enquirysource,
        categoryenquirysource: leadData.categoryenquirysource,
        typeenquiry: leadData.typeenquiry,
        message: leadData.message,
        ho_remarks: leadData.ho_remarks,
        salesengineer: leadData.salesengineer
      }
    })
      .then(function(response) {
         console.log(response);
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("leadaddMethod", response.data.response);
          router.push("/lead");
        }
      })
      .catch(function(error) {
        console.log(error.response);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("leadError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  },
  //new Lead add action end

  //fetch customer details
  async fetchLeaddetails({ commit }, leadId) {
    commit("authRequest");
    axios({
      method: "GET",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "lead/" + leadId
    })
      .then(function(response) {
        //  console.log(response);

        if (response.data.result == 1) {
          commit("authSuccess");
          commit("leadDetails", response.data.response);
        }
      })
      .catch(function(error) {
        console.log(error);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("leadError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  },

  //new lead add action
  async leadUpdate({ commit }, leadData) {
    console.log(leadData);
    commit("authRequest");
    axios({
      method: "PUT",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "lead/" + leadData.leadId,
      data: {
        customerCode: leadData.customerCode,
        customerDetails: leadData.customerDetails,
        EnquiryDate: leadData.EnquiryDate,
        contactNumber: leadData.contactNumber,
        emailId: leadData.emailId,
        state: leadData.state,
        city: leadData.city,
        brand: leadData.brand,
        segment: leadData.segment,
        region: leadData.region,
        source: leadData.source,
        taxAmount: leadData.taxAmount,
        totalAmount: leadData.totalAmount,
        totalAmountWithTax: leadData.totalAmountWithTax,
        enquiryDetails: leadData.enquiryDetails,
        enquirysource: leadData.enquirysource,
        categoryenquirysource: leadData.categoryenquirysource,
        typeenquiry: leadData.typeenquiry,
        message: leadData.message,
        ho_remarks: leadData.ho_remarks,
        salesengineer: leadData.salesengineer,
        salesengineerStatus: leadData.salesengineerStatus,
        leadconvertstatus: leadData.leadconvertstatus
      }
    })
      .then(function(response) {
        // console.log(response);
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("leadupdateMethod", response.data.response);
          router.push("/lead");
        }
      })
      .catch(function(error) {
        console.log(error);
        if (error.response) {
          // client received an error response (5xx, 4xx)
          console.log(error.response);
          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("leadError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  },
  //new Lead add action end

  //new lead add action
  async leadstatusReject({ commit }, leadData) {
    commit("authRequest");
    axios({
      method: "POST",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "lead/reject",
      data: {
        leadId: leadData.leadId,
        salesengineerStatus: leadData.salesengineerStatus
      }
    })
      .then(function(response) {
        // console.log(response);
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("leadrejectMethod", response.data.response);
          router.push("/lead");
        }
      })
      .catch(function(error) {
        console.log(error);
        if (error.response) {
          // client received an error response (5xx, 4xx)
          console.log(error.response);
          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("leadError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  },
  //Lead Status Reject

  //new lead add action
  async leadConvert({ commit }, leadData) {
    commit("authRequest");
    axios({
      method: "POST",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "lead/confirm",
      data: {
        leadId: leadData.leadId,
        customerDetails: leadData.customerDetails,
        workshopName: leadData.workshopName,
        address1: leadData.address1,
        address2: leadData.address2,
        EnquiryDate: leadData.EnquiryDate,
        isIgst: leadData.isIgst,
        freightCharge: leadData.freightCharge,
        taxAmount: leadData.taxAmount,
        totalAmount: leadData.totalAmount,
        totalAmountWithTax: leadData.totalAmountWithTax,
        remarks: leadData.remarks,
        enquiryDetails: leadData.enquiryDetails,
        salesType: leadData.salesType,
        workshopType: leadData.workshopType
      }
    })
      .then(function(response) {
        // console.log(response);
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("leadConfirm", response.data.response);
          router.push("/lead");
        }
      })
      .catch(function(error) {
        console.log(error);
        if (error.response) {
          // client received an error response (5xx, 4xx)
          console.log(error.response);
          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("leadError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  }
  //Lead Status Reject
};

const mutations = {
  setleadList: (state, resp) => {
    state.leads = resp;
  },
  leadaddMethod: (state, resp) => {
    state.status = "success";
    state.leadResponse = resp;
  },
  leadupdateMethod: (state, resp) => {
    state.status = "success";
    state.leadResponse = resp;
  },
  leadrejectMethod: (state, resp) => {
    state.status = "success";
    state.leadResponse = resp;
  },
  leadError: (state, resp) => {
    state.status = "error";
    state.leadResponse = resp;
  },
  leadDetails: (state, resp) => {
    state.status = "";
    state.customer_code = resp.customerCode;
    state.customerDetails = resp.customerDetails;
    state.EnquiryDate = resp.EnquiryDate;
    state.brand = resp.brand;
    state.segment = resp.segment;
    state.state = resp.state;
    state.city = resp.city;
    state.emailId = resp.emailId;
    state.contactNumber = resp.contactNumber;
    state.region = resp.region;

    state.enquirySource = resp.enquiry_source;
    state.categoryEnquirysource = resp.category_enquiry_source;
    state.message = resp.message;
    state.hoRemarks = resp.ho_remarks;
    state.enquiryType = resp.enquiry_type;
    state.assignedUser = resp.assigned_user;

    state.salesengineerStatus = resp.salesengineer_status;
    state.leadConvertstatus = resp.lead_status;

    state.leadEnquiryid = resp.leadEnquiryid;

    state.taxAmount = resp.taxAmount;
    state.totalAmount = resp.totalAmount;
    state.totalAmountWithTax = resp.totalAmountWithTax;
    state.enquiryDetails = resp.productList;
  },
  updateleadCustomercode: (state, customer_code) => {
    state.customer_code = customer_code;
  },
  updateleadCustomerdetails: (state, customerDetail) => {
    state.customerDetails = customerDetail;
  },
  updateleadEnquiryDate: (state, EnquiryDate) => {
    state.EnquiryDate = EnquiryDate;
  },
  updateleadContactnumber: (state, contactnumber) => {
    state.contactNumber = contactnumber;
  },
  updateleadEmailid: (state, emailid) => {
    state.emailId = emailid;
  },
  updateleadState: (state, stateid) => {
    state.state = stateid;
  },
  updateleadCity: (state, city) => {
    state.city = city;
  },
  updateleadBrand: (state, brand) => {
    state.brand = brand;
  },
  updateleadSegment: (state, segment) => {
    state.segment = segment;
  },
  updateleadRegion: (state, region) => {
    state.region = region;
  },
  updateleadSource: (state, source) => {
    state.source = source;
  },
  updateleadTaxamount: (state, taxAmount) => {
    state.taxAmount = taxAmount;
  },
  updateleadTotalprice: (state, totalPrice) => {
    state.totalAmount = totalPrice;
  },
  updateleadtotalAmountWithTax: (state, totalAmountWithTax) => {
    state.totalAmountWithTax = totalAmountWithTax;
  },
  updateleadenquirySource: (state, enquirySource) => {
    state.enquirySource = enquirySource;
  },
  updateleadcategoryEnquirysource: (state, categoryEnquirysource) => {
    state.categoryEnquirysource = categoryEnquirysource;
  },
  updateleadenquiryType: (state, enquiryType) => {
    state.enquiryType = enquiryType;
  },
  updateleadmessage: (state, message) => {
    state.message = message;
  },
  updateleadhoRemarks: (state, hoRemarks) => {
    state.hoRemarks = hoRemarks;
  },
  updateleadassignedUser: (state, assignedUser) => {
    state.assignedUser = assignedUser;
  },
  updatesalesengineerStatus: (state, salesengineerStatus) => {
    state.salesengineerStatus = salesengineerStatus;
  },
  updateleadConvertstatus: (state, leadConvertstatus) => {
    state.leadConvertstatus = leadConvertstatus;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
