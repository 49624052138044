<template>
  <div>
    <!--container-->
    <v-container>
      <!--tob bar-->
      <bar />
      <!--tob bar end-->

      <!--template-->
      <template>
        <!--alert box-->
        <v-alert
          text
          dense
          color="teal"
          icon="mdi-clock-fast"
          border="left"
          v-if="cityStatusmsg"
        >
          <div v-for="(resp, i) in cityResponse" :key="i">
            {{ resp }}
          </div>
        </v-alert>
        <!--alert box end-->

        <!---datatable -->
        <v-data-table
          :headers="headers"
          :items="cities"
          :search="search"
          sort-by="name"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>CITY</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-btn color="black" dark class="mb-2" to="/city/add">
                NEW CITY
              </v-btn>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editCity(item)">
              mdi-pencil
            </v-icon>
          </template>
        </v-data-table>
        <!---datatable end-->
      </template>
      <!--template end-->
    </v-container>
    <!--container end-->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import router from "../../router";
export default {
  name: "City",
  data: () => ({
    search: "",
    headers: [
      { text: "Actions", value: "actions", sortable: false },
      {
        text: "CITY",
        align: "start",
        sortable: true,
        value: "name"
      },
      {
        text: "STATE",
        align: "start",
        sortable: true,
        value: "state_name"
      },
      {
        text: "COUNTRY",
        align: "start",
        sortable: true,
        value: "country_name"
      },
      
    ]
  }),

  computed: {
    ...mapGetters(["cityList", "cityStatus", "cityStatusResponse"]),
    cities: function() {
      return this.cityList;
    },
    cityStatusmsg: function() {
      return this.cityStatus === "success";
    },
    cityResponse: function() {
      return this.cityStatusResponse;
    }
  },

  watch: {},

  created() {
    this.fetchCities();
  },

  methods: {
    ...mapActions(["fetchCities"]),

    editCity(item) {
      const id = item.id;
      //  router.push({ name: 'ProductGroupEdit', params: { productgroupId: 123 }})
      router.push({ path: `/city/edit/${id}` });
    }
  }
};
</script>
