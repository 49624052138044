<template>
  <div>
    <!--container-->
    <v-container>
      <!--tob bar-->
      <bar />
      <!--tob bar end-->

      <!--template-->
      <template>
        <!--card -->

        <v-card class="mx-auto mb-5" outlined>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="3" v-if="userRole != 'sales-engineer'">
                <v-select
                  v-model="region"
                  :items="regions"
                  label="REGION *"
                  class="mt-6"
                  clearable
                >
                </v-select>
              </v-col>

              <v-col cols="12" md="3">
                <v-autocomplete
                  v-model="segment"
                  :items="segments"
                  item-text="name"
                  item-value="id"
                  label="SEGMENT*"
                  class="mt-4"
                  @change="getSalesengineer(segment)"
                  chips
                  multiple
                  clearable
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" md="3" v-if="userRole != 'sales-engineer'">
                <v-select
                  v-model="salesengineer"
                  :items="salesengineers"
                  label="SALES ENGINEER *"
                  item-text="name"
                  item-value="id"
                  class="mt-4"
                  chips
                  multiple
                  clearable
                ></v-select>
              </v-col>

              <v-col cols="12" md="3">
                <v-select
                  v-model="daterange"
                  :items="dateRanges"
                  label="DATE RANGE"
                  class="mt-6"
                  clearable
                ></v-select>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="warning" dark @click="filterTspReport(1)">
              FIND
            </v-btn>
          </v-card-actions>
        </v-card>

        <v-card class="mx-auto my-12" outlined>
          <v-card-title class="text-h5">
            TSP REPORT
            <v-spacer></v-spacer>
            <v-btn color="warning" dark @click="exportTsp">
              <v-icon right dark class="ma-2">
                mdi-cloud-download
              </v-icon>
              EXPORT
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>

          <v-card-text>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">REFERENCE NO</th>
                    <th class="text-left">ENQ DATE</th>
                    <th class="text-left">CUSTOMER</th>
                    <th class="text-left">REGION</th>
                    <th class="text-left">TPL NO</th>
                    <th class="text-left">PRODUCT</th>
                    <th class="text-left">SALES TYPE</th>
                    <th class="text-left">WORKSHOP TYPE</th>
                    <th class="text-left">TSP STATUS</th>
                    <th class="text-left">TSP SALES PRICE</th>
                    <th class="text-left">BILLED QTY</th>
                    <th class="text-left">TOTAL SALES PRICE</th>
                    <th class="text-left">FOLLOWUP STATUS</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="enquiry in tspData.tspReport" :key="enquiry.id">
                    <td>{{ enquiry.referenceNo }}</td>
                    <td>{{ enquiry.enquiry_date }}</td>
                    <td>{{ enquiry.customer_details }}</td>
                    <td>{{ enquiry.region }}</td>
                    <td>{{ enquiry.producttplno }}</td>
                    <td>{{ enquiry.productname }}</td>
                    <td>{{ enquiry.sales_type }}</td>
                    <td>{{ enquiry.workshop_type }}</td>
                    <td>
                      <v-simple-table>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">TSP DATE</th>
                              <th class="text-left">QTY</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="tsstatus in enquiry.enquiryTentativelist"
                              :key="tsstatus.id"
                            >
                              <td>{{ dateFormat(tsstatus.tentative_sales_date) }}</td>
                              <td>{{ tsstatus.tentative_sales_qty }}</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </td>
                    <td>
                      {{ enquiry.tsp_sales_price }}
                    </td>
                    <td>
                      {{ enquiry.final_sales_qty }}
                    </td>
                    <td>
                      {{ enquiry.final_sales_price }}
                    </td>
                    <td>
                      <v-simple-table>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">FOLLOWUP DATE</th>
                              <th class="text-left">STATUS</th>
                              <th class="text-left">REMARKS</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="enstatus in enquiry.enquiryStatuslist"
                              :key="enstatus.id"
                            >
                              <td>{{ dateFormat(enstatus.followup_date) }}</td>
                              <td>{{ enstatus.status }}</td>
                              <td>{{ enstatus.remarks }}</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>

            <!--pagination-->
            <div class="text-center mt-5">
              <v-pagination
                v-model="page"
                :length="tspReportPagination"
                :total-visible="7"
                @input="paginationNextPage"
              ></v-pagination>
            </div>
            <!--pagination end-->
          </v-card-text>
        </v-card>
      </template>
      <!--template end-->
    </v-container>
    <!--container end-->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { VUE_APP_BASEURL, VUE_WEB_BASEURL } from "../../utils/api";
import axios from "axios";
import store from "../../store";

export default {
  name: "TspReport",
  data: () => ({
    search: "",
    segment: "",
    salesengineer: "",
    region: "",
    daterange: "",
    page: 1,
    selectedPage: 1
  }),
  computed: {
    ...mapGetters([
      "getTspreport",
      "getSalesengineerlist",
      "segmentList",
      "authRole",
      "getRegions",
      "getDateRange",
      "tspReportListPaginate"
    ]),
    segments: function() {
      return this.segmentList;
    },
    salesengineers: function() {
      return this.getSalesengineerlist;
    },
    tspData: function() {
      // console.log(this.getTspreport);
      return this.getTspreport;
    },
    userRole: function() {
      return this.authRole;
    },
    regions: function() {
      return this.getRegions;
    },
    dateRanges: function() {
      return this.getDateRange;
    },
    tspReportPagination: function() {
      return this.tspReportListPaginate;
    }
  },
  watch: {},
  created() {
    this.fetchSegment();
    this.fetchDefinedvalues();
    const filterData = {
      segment: "",
      salesengineer: "",
      region: "",
      daterange: "",
      page: ""
    };
    this.tspReport(filterData);
  },

  methods: {
    ...mapActions([
      "tspReport",
      "fetchSalesengineers",
      "fetchSegment",
      "fetchDefinedvalues"
    ]),

    filterTspReport(page) {
      const filterData = {
        page: page,
        segment: this.segment,
        salesengineer: this.salesengineer,
        region: this.region,
        daterange: this.daterange
      };
      //console.log(filterData);
      this.tspReport(filterData);
    },

    exportTsp() {
      store.commit("authRequest");
      const filterData = {
        segment: this.segment,
        salesengineer: this.salesengineer,
        region: this.region,
        daterange: this.daterange
      };

      axios({
        method: "GET",
        headers: {
          Authorization: "Bearer " + store.getters.UserToken
        },
        url: VUE_APP_BASEURL + "user-token"
      })
        .then(function(response) {
          // console.log(response);

          //console.log(filterData);
          window.open(
            VUE_WEB_BASEURL +
              "tspsalesplan/export?userid=" +
              response.data.id +
              "&segment=" +
              filterData.segment +
              "&salesengineer=" +
              filterData.salesengineer +
              "&region=" +
              filterData.region +
              "&daterange=" +
              filterData.daterange,
            "_blank"
          );
          store.commit("authSuccess");
          console.log(VUE_WEB_BASEURL);
        })
        .catch(function(error) {
          console.log(error);
        });
    },

    //get segment based  salesengineer
    getSalesengineer(segment) {
      this.fetchSalesengineers(segment);
      //  console.log(segment);
    },
    settodate() {
      this.toMindate = new Date(this.fromDate);
    },
    paginationNextPage(page) {
      //this.selectedPage = page;
      this.filterTspReport(page);
    },
    dateFormat(date) {
      //console.log(date);
      let d = new Date(date);
      let day =d.getDate();
      let month =d.getMonth();
      let year =d.getFullYear();

     // console.log(day);

      return ('0' + day).slice(-2)+'-'+('0' + month).slice(-2)+'-'+year;

     // console.log(day+'-'+month+'-'+year);

    },
  }
};
</script>
