<template>
  <div class="notfound">
    <!--loading overlay show component -->
    <bar />
    <!--loading overlay show component -->
    <v-container>
      <v-row align="center">
        <v-col align="center">
          <h1 class="display-2 primary--text">Whoops, 404</h1>
          <p>The page you were looking for does not exist</p>
          <p>
            <a href="/">Go home?</a>
          </p>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "NotFound",
  components: {}
};
</script>
