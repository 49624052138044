<template>
  <div>
    <!--container-->
    <v-container>
      <!--tob bar-->
      <bar />
      <!--tob bar end-->

      <!-- customer Enquiry Details-->

      <!--<CustomerEnquiryValue :customerId="this.$route.params.id" />-->

      <!-- customer Enquiry Details end-->

      <!--template-->
      <template>
        <!--card -->
        <v-card class="form-card mx-auto my-12">
          <!--alert box-->
          <v-alert text prominent type="error" v-if="errorResponse">
            <div v-for="(error, i) in enquiryError" :key="i">
              {{ error }}
            </div>
          </v-alert>
          <!--alert box end-->

          <!--alert box-->
          <v-alert text dense color="teal" icon="mdi-clock-fast" border="left" v-if="enquiryStatusmsg">
            <div v-for="(resp, i) in enquiryResponse" :key="i">
              {{ resp }}
            </div>
          </v-alert>
          <!--alert box end-->

          <v-list-item class="headline">
            <v-list-item-content>
              <v-list-item-title> REQUEST QUOTE EDIT </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>

          <!--form-->
          <form enctype="multipart/form-data" autocomplete="off">
            <v-card-text>
              <v-row justify="space-between">
                <v-col xs="12" sm="12" md="6">
                  <v-text-field v-model="referenceNo" :error-messages="referenceNoErrors" label="REFRERENCE NO: *"
                    required readonly @input="$v.referenceNo.$touch()" @blur="$v.referenceNo.$touch()"></v-text-field>
                </v-col>

                <v-col xs="12" sm="12" md="6">
                  <v-text-field v-model="customerDetails" :error-messages="customerDetailsErrors"
                    label="CUSTOMER DETAILS: *" required @input="$v.customerDetails.$touch()"
                    @blur="$v.customerDetails.$touch()"></v-text-field>
                </v-col>
              </v-row>

              <v-row justify="space-between">
                <v-col xs="12" sm="12" md="4">
                  <v-text-field v-model="workshopName" label="WORK SHOPNAME" readonly></v-text-field>
                </v-col>

                <v-col xs="12" sm="12" md="4">
                  <v-text-field v-model="address1" label="ADDRESS 1" readonly></v-text-field>
                </v-col>

                <v-col xs="12" sm="12" md="4">
                  <v-text-field v-model="address2" label="ADDRESS 2" readonly></v-text-field>
                </v-col>
              </v-row>

              <v-row justify="space-between">
                <v-col xs="12" sm="12" md="4">
                  <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                    offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="enquiryDate" :error-messages="enquiryDateErrors"
                        @input="$v.enquiryDate.$touch()" @blur="$v.enquiryDate.$touch()" label="ENQUIRY DATE*"
                        prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="enquiryDate" @input="menu2 = false"></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col xs="12" sm="12" md="4">
                  <v-select v-model="salesType" :items="salestypes" :error-messages="salesTypeErrors"
                    label="SERVICE TYPE *" required @input="$v.salesType.$touch()"
                    @blur="$v.salesType.$touch()"></v-select>
                </v-col>

                <v-col xs="12" sm="12" md="4">
                  <v-select v-model="workshopType" :items="workshoptypes" label="WORKSHOP TYPE *" required
                    :error-messages="workshopTypeErrors" @input="$v.workshopType.$touch()"
                    @blur="$v.workshopType.$touch()"></v-select>
                </v-col>
              </v-row>

              <v-row justify="space-between" v-for="(pro, k) in productDetails" :key="k">
                <v-col xs="12" sm="12" md="2">
                  <!--<v-select
                    v-model="pro.equipment"
                    :items="products"
                    item-text="product_name"
                    item-value="id"
                    label="EQUIPMENT"
                    @change="getProductdetails(pro)"
                  ></v-select>-->

                  <v-autocomplete v-model="pro.equipment" :items="products" item-text="product_name_withtpl"
                    item-value="id" label="EQUIPMENT" @change="getProductdetails(pro)">
                  </v-autocomplete>
                </v-col>

                <v-col xs="12" sm="12" md="1">
                  <v-img max-height="150" max-width="250" :src="pro.productImageshow"></v-img>
                </v-col>

                <v-col xs="12" sm="12" md="1">
                  <v-text-field v-model="pro.model" label="MODEL" readonly></v-text-field>
                </v-col>

                <v-col xs="12" sm="12" md="1">
                  <v-text-field v-model="pro.warranty" label="WARRANTY"></v-text-field>
                </v-col>

                <v-col xs="12" sm="12" md="1">
                  <v-text-field v-model="pro.quantity" label="QTY" @change="getProductqtyprice(pro)">
                  </v-text-field>
                </v-col>

                <v-col xs="12" sm="12" md="1">
                  <v-text-field v-model="pro.price" label="PRICE" readonly></v-text-field>
                </v-col>

                <v-col xs="12" sm="12" md="2">
                  <v-text-field v-model="pro.special_price" label="SPL PRICE"
                    @change="getProductqtyprice(pro)"></v-text-field>
                </v-col>

                <v-col xs="12" sm="12" md="2">
                  <v-text-field v-model="pro.totalPrice" label="TOTAL COST" readonly></v-text-field>
                </v-col>

                <v-col xs="12" sm="12" md="1">
                  <v-btn color="warning" fab dark x-small @click="deleteProductdetails(k)">
                    <v-icon dark> mdi-close </v-icon>
                  </v-btn>
                </v-col>
              </v-row>

              <v-row>
                <v-col xs="12" sm="12" md="11"> </v-col>
                <v-col xs="12" sm="12" md="1">
                  <v-btn depressed color="warning" @click="addProductdetails">
                    +ADD
                  </v-btn>
                </v-col>
              </v-row>

              <v-row>
                <v-col xs="12" sm="12" md="9"> </v-col>
                <v-col xs="12" sm="12" md="3">
                  <v-checkbox v-model="igst" label="IGST" color="orange" value="1"></v-checkbox>
                </v-col>
              </v-row>

              <v-row>
                <v-col xs="12" sm="12" md="9"> </v-col>
                <v-col xs="12" sm="12" md="3">
                  <span class="text-right white--text"> FREIGHT CHARGE </span>
                  <v-text-field v-model="freightCharge" label="FREIGHT CHARGE" @change="calculateTotal"></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col xs="12" sm="12" md="9"> </v-col>
                <v-col xs="12" sm="12" md="3">
                  <span class="text-right white--text"> TAX AMOUNT </span>
                  <v-text-field v-model="taxAmount" label="TAX AMOUNT"></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col xs="12" sm="12" md="9"> </v-col>
                <v-col xs="12" sm="12" md="3">
                  <span class="text-right white--text"> TOTAL PRICE </span>
                  <v-text-field v-model="totalPrice" label="TOTAL PRICE"></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col xs="12" sm="12" md="9"> </v-col>
                <v-col xs="12" sm="12" md="3">
                  <span class="text-right white--text">
                    TOTAL PRICE (WITH TAX)
                  </span>
                  <v-text-field v-model="totalPricewithtax" label="TOTAL PRICE (WITH TAX)"></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col xs="12" sm="12" md="3"> </v-col>
                <v-col xs="12" sm="12" md="6">
                  <span class="text-right white--text"> REMARKS </span>
                  <v-text-field v-model="remarks" label="REMARKS*" required :error-messages="remarksErrors"
                    @input="$v.remarks.$touch()" @blur="$v.remarks.$touch()"></v-text-field>
                </v-col>
                <v-col xs="12" sm="12" md="3"> </v-col>
              </v-row>
              <!--input field end-->
              <v-divider></v-divider>
              <h3 class="mt-5 mb-5 orange--text text--darken-2">
                TERMS & CONDITIONS
              </h3>
              <!--input field end-->
              <v-divider></v-divider>

              <div v-for="(ter, t) in terms" :key="'A' + t">
                <v-text-field v-model="terms[t]"></v-text-field>
              </div>
            </v-card-text>

            <!--input field end-->
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="black" dark @click="submit" v-if="userRole == 'sales-engineer'">
                SUBMIT
              </v-btn>
              <v-btn color="warning" dark to="/enquiry"> CANCEL </v-btn>

              <v-btn color="blue-grey darken-3" dark @click="invoiceOpen">
                PDF
              </v-btn>
            </v-card-actions>
          </form>
        </v-card>
        <!--card end-->

        <!-- customer Enquiry Details-->

        <!--<CustomerEnquiry :customerId="this.enquirycustomerId" ref="customerEnquirylist" />-->

        <!-- customer Enquiry Details end-->
      </template>
      <!--template end-->
    </v-container>
    <!--container end-->

    <v-row justify="center">
      <v-dialog v-model="deleteDialog" persistent max-width="290">
        <v-card>
          <v-card-title class="text-h5">
            Are You sure Want to delete!
          </v-card-title>
          <v-card-text>Current Data is in database.If it's deleted can't be
            retrive.</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="deleteDialog = false">
              No
            </v-btn>
            <v-btn color="red darken-1" text @click="deleteEnquirydetails(deleteId, enquiryId)">
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";

import { mapActions, mapGetters } from "vuex";

import { VUE_WEB_BASEURL } from "../../utils/api";
import { VUE_APP_BASEURL } from "../../utils/api";
import axios from "axios";
import store from "../../store";

export default {
  name: "EnquiryEdit",
  data: () => ({
    modal: false,
    menu2: false,
    deleteDialog: false
  }),
  validations: {
    referenceNo: { required },
    customerDetails: { required },
    workshopType: { required },
    salesType: { required },
    enquiryDate: { required },
    remarks: { required }
  },
  computed: {
    ...mapGetters([
      "referencenoDetails",
      "getenquiryId",
      "enquirycustomerDetails",
      "enquirycustomerId",
      "enquiryEnquiryDate",
      "enquiryIgst",
      "enquiryfreightCharge",
      "enquirytaxAmount",
      "enquirytotalAmount",
      "enquirytotalAmountWithTax",
      "enquiryRemarks",
      "enquiryworkshopNameDetails",
      "enquiryaddress1Details",
      "enquiryaddress2Details",
      "productList",
      "productImageDetails",
      "modelDetailsname",
      "enquiryproductDetails",
      "enquiryworkshopType",
      "enquirysalesType",
      "enquiryStatus",
      "enquiryStatusResponse",
      "brandPriceDetails",
      "getTerms",
      "enquiryStatusResponse",
      "getEnquiryWorkshoptypes",
      "getSalestype",
      "authRole",
      "getTax",
    ]),
    enquiryStatusmsg: function () {
      return this.enquiryStatus === "success";
    },
    enquiryResponse: function () {
      return this.enquiryStatusResponse;
    },
    errorResponse: function () {
      return this.enquiryStatus === "error";
    },
    enquiryError: function () {
      return this.enquiryStatusResponse;
    },
    workshoptypes: function () {
      return this.getEnquiryWorkshoptypes;
    },
    salestypes: function () {
      return this.getSalestype;
    },
    products: function () {
      return this.productList;
    },
    userRole: function () {
      return this.authRole;
    },
    /*  terms: function () {

      console.log(this.getTerms);
      return this.getTerms;
    },*/

    terms: {
      get() {
        return this.getTerms;
      },
      set(value) {
        console.log(value);
      }
    },
    enquiryId: {
      get() {
        return this.getenquiryId;
      }
    },
    referenceNo: {
      get() {
        return this.referencenoDetails;
      }
    },
    customerDetails: {
      get() {
        return this.enquirycustomerDetails;
      },
      set(value) {
        this.$store.commit("updateCustomerdetails", value);
      }
    },
    enquiryDate: {
      get() {
        return this.enquiryEnquiryDate;
      },
      set(value) {
        this.$store.commit("updateEnquiryDate", value);
      }
    },
    igst: {
      get() {
        return this.enquiryIgst;
      },
      set(value) {
        this.$store.commit("updateIgst", value);
      }
    },
    freightCharge: {
      get() {
        return this.enquiryfreightCharge;
      },
      set(value) {
        this.$store.commit("updateFreightcharge", value);
      }
    },
    tax: {
      get() {
        return this.getTax;
      }
    },
    taxAmount: {
      get() {
        return this.enquirytaxAmount;
      },
      set(value) {
        this.$store.commit("updateTaxamount", value);
      }
    },
    totalPrice: {
      get() {
        return this.enquirytotalAmount;
      },
      set(value) {
        this.$store.commit("updateTotalprice", value);
      }
    },
    totalPricewithtax: {
      get() {
        return this.enquirytotalAmountWithTax;
      },
      set(value) {
        this.$store.commit("updatetotalAmountWithTax", value);
      }
    },
    remarks: {
      get() {
        return this.enquiryRemarks;
      },
      set(value) {
        this.$store.commit("updateRemarks", value);
      }
    },
    workshopType: {
      get() {
        return this.enquiryworkshopType;
      },
      set(value) {
        this.$store.commit("updateworkshopType", value);
      }
    },
    salesType: {
      get() {
        return this.enquirysalesType;
      },
      set(value) {
        this.$store.commit("updatesalesType", value);
      }
    },
    workshopName: {
      get() {
        return this.enquiryworkshopNameDetails;
      }
    },
    address1: {
      get() {
        return this.enquiryaddress1Details;
      }
    },
    address2: {
      get() {
        return this.enquiryaddress2Details;
      }
    },
    productDetails: function () {
      console.log(this.enquiryproductDetails);
      return this.enquiryproductDetails;
    },
    referenceNoErrors() {
      const errors = [];
      if (!this.$v.referenceNo.$dirty) return errors;
      !this.$v.referenceNo.required && errors.push("Referenceno is required.");
      return errors;
    },
    customerDetailsErrors() {
      const errors = [];
      if (!this.$v.customerDetails.$dirty) return errors;
      !this.$v.customerDetails.required &&
        errors.push("Customerdetails is required.");
      return errors;
    },
    workshopTypeErrors() {
      const errors = [];
      if (!this.$v.workshopType.$dirty) return errors;
      !this.$v.workshopType.required &&
        errors.push("Workshoptype is required.");
      return errors;
    },
    salesTypeErrors() {
      const errors = [];
      if (!this.$v.salesType.$dirty) return errors;
      !this.$v.salesType.required && errors.push("Salestype is required.");
      return errors;
    },
    enquiryDateErrors() {
      const errors = [];
      if (!this.$v.enquiryDate.$dirty) return errors;
      !this.$v.enquiryDate.required && errors.push("Enquirydate is required.");
      return errors;
    },
    remarksErrors() {
      const errors = [];
      if (!this.$v.remarks.$dirty) return errors;
      !this.$v.remarks.required && errors.push("Remarks is required.");
      return errors;
    }
  },
  created() {
    this.fetchEnquirydetails(this.$route.params.id);
    this.fetchProducts();
    this.fetchDefinedvalues();
  },
  methods: {
    ...mapActions([
      "fetchEnquirydetails",
      "fetchProducts",
      "fetchProductdetails",
      "enquiryUpdate",
      "enquirydetailsDelete",
      "fetchDefinedvalues"
    ]),
    //add product details
    addProductdetails() {
      this.productDetails.push({
        equipment: "",
        productImageshow: "",
        model: "",
        warranty: "",
        quantity: "",
        price: "",
        special_price: "",
        totalPrice: "",
        enquiryDetailid: ""
      });
    },
    //delete product details
    deleteProductdetails(counter) {
      //  console.log(counter);
      //  console.log(this.productdetails[counter]);

      let enquirydetail = this.productDetails[counter];
      //console.log(enquirydetail.enquiryDetailid);
      //check database existing else delete the record option.
      if (enquirydetail.enquiryDetailid != "") {
        this.deleteId = enquirydetail.enquiryDetailid;
        this.deleteDialog = true;
      } else {
        //remove record
        this.productDetails.splice(counter, 1);
      }

      this.calculateTotal();
    },
    //delete enquiry details
    deleteEnquirydetails(deleteId, enquiryID) {
      //  console.log(enquiryID);
      const deleteData = {
        enquiryId: enquiryID,
        deleteId: deleteId
      };
      this.enquirydetailsDelete(deleteData);
      this.deleteDialog = false;
    },
    //get product details onchange
    getProductdetails(pro) {
      // console.log(pro.equipment);

      axios({
        method: "GET",
        headers: {
          Authorization: "Bearer " + store.getters.UserToken
        },
        url:
          VUE_APP_BASEURL +
          "product/" +
          pro.equipment +
          "?customerId=" +
          this.$route.params.id
      })
        .then(function (response) {
          //   console.log(response);

          pro.productImageshow = response.data.response.product_image;
          pro.model = response.data.response.model;
          pro.warranty = response.data.response.warranty_in_years;
          pro.quantity = 1;
          pro.price = parseFloat(response.data.response.brandPrice);
          pro.special_price = parseFloat(response.data.response.brandPrice);
          let cost = parseFloat(pro.quantity) * parseFloat(pro.special_price);
          pro.totalPrice = cost;
          this.calculateTotal();
        })
        .catch(function (error) {
          console.log(error);
        });

      /*const productData = {
            customerId: this.enquirycustomerId,
            productId: pro.equipment,
          };  
      
      this.fetchProductdetails(productData);
     
      setTimeout(() => {
      console.log(this.brandPriceDetails);
      pro.productImageshow=this.productImageDetails;
      pro.model=this.modelDetailsname;
      pro.quantity=1;
      pro.price=parseFloat(this.brandPriceDetails);
      pro.special_price=parseFloat(this.brandPriceDetails);
      let cost=parseFloat(pro.quantity)*parseFloat(pro.special_price);
      pro.totalPrice=cost;
      this.calculateTotal();
       }, 500);*/
    },
    //qty based product price onchange
    getProductqtyprice(pro) {
      console.log(pro);
      let cost = parseFloat(pro.quantity) * parseFloat(pro.special_price);
      pro.totalPrice = cost;
      this.calculateTotal();
    },
    calculateTotal() {
      // var gst = 18;
      var gst = this.tax;

      var subtotal;
      subtotal = this.productDetails.reduce(function (sum, product) {
        var lineTotal = parseFloat(product.totalPrice);
        if (!isNaN(lineTotal)) {
          return sum + lineTotal;
        }
      }, 0);

      var billtotal = parseFloat(subtotal) + parseFloat(this.freightCharge);
      var taxamount =
        (parseFloat(billtotal) * parseFloat(gst)) / parseFloat(100);
      var billwithtax = parseFloat(billtotal) + parseFloat(taxamount);

      this.taxAmount = taxamount;
      this.totalPrice = subtotal.toFixed(2);
      this.totalPricewithtax = billwithtax.toFixed(2);
    },

    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("ERROR");
      } else {
        // do your submit logic here
        setTimeout(() => {
          const enquiryData = {
            enquiryId: this.$route.params.id,
            customerId: this.enquirycustomerId,
            customerDetails: this.customerDetails.toUpperCase(),
            EnquiryDate: this.enquiryDate,
            isIgst: this.igst,
            freightCharge: this.freightCharge,
            taxAmount: this.taxAmount,
            totalAmount: this.totalPrice,
            totalAmountWithTax: this.totalPricewithtax,
            remarks: this.remarks.toUpperCase(),
            enquiryDetails: this.productDetails,
            salesType: this.salesType,
            workshopType: this.workshopType,
            terms: this.terms
          };

          //  console.log(enquiryData);

          this.enquiryUpdate(enquiryData);
        }, 500);
      }
    },
    invoiceOpen() {
      window.open(
        VUE_WEB_BASEURL + "enquiry/invoice/" + this.$route.params.id,
        "_blank"
      );
      //console.log(VUE_WEB_BASEURL);
    }
  }
};
</script>
