<template>
  <div>
    <!--container-->
    <v-container>
      <!--tob bar-->
      <bar />
      <!--tob bar end-->

      <!--template-->
      <template>
        <!--card -->

        <!--Filter Box-->
        <BuybackSearch />
        <!--Filter Box-->

        <v-card class="mx-auto my-12" outlined>
          <v-card-title class="text-h5"> BUY BACK REPORT</v-card-title>
          <v-divider></v-divider>
          <v-card-title class="text-center">
            <v-spacer></v-spacer> {{ enquiryData.equipment }}
            <v-spacer></v-spacer>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">BRAND</th>
                    <th class="text-left">STATE</th>
                    <th class="text-left">CITY</th>
                    <th class="text-left">WORKSHOP NAME</th>
                    <th class="text-left">QTY</th>
                    <th class="text-left">SUPPLIER</th>
                    <th class="text-left">MANUFACTURES</th>
                    <th class="text-left">MODEL</th>
                    <th class="text-left">YEAR OF PURCHASE</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="enquiry in enquiryData.equipmentReport"
                    :key="enquiry.sno"
                  >
                    <td>{{ enquiry.brand }}</td>
                    <td>{{ enquiry.state }}</td>
                    <td>{{ enquiry.city }}</td>
                    <td>{{ enquiry.workshopName }}</td>
                    <td>{{ enquiry.qty }}</td>
                    <td>{{ enquiry.supplier }}</td>
                    <td>{{ enquiry.manufactur }}</td>
                    <td>{{ enquiry.model }}</td>
                    <td>{{ enquiry.yearofpurchase }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </template>
      <!--template end-->
    </v-container>
    <!--container end-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "BuybackReport",
  data: () => ({
    search: ""
  }),
  computed: {
    ...mapGetters(["getEquipmentreport"]),
    enquiryData: function() {
      //console.log(this.getEquipmentreport);
      return this.getEquipmentreport;
    }
  },
  watch: {},
  created() {}
};
</script>
