import { VUE_APP_BASEURL } from "../../utils/api";
import router from "../../router";
import axios from "axios";
import store from "../../store";

const state = {
  brands: [],
  brandResponse: [],
  brandName: "",
  status: ""
};

const getters = {
  brandList: state => state.brands,
  brandStatusResponse: state => state.brandResponse,
  brandStatus: state => state.status,
  brandDetailsname: state => state.brandName
};

const actions = {
  //brand fetch
  async fetchBrand({ commit }) {
    commit("authRequest");
    axios({
      method: "GET",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "brand"
    })
      .then(function(response) {
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("setbrandList", response.data.response);
        }
      })
      .catch(function(error) {
        console.log(error);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("brandError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  },

  //new Brand add action
  async brandAdd({ commit }, brandData) {
    commit("authRequest");
    axios({
      method: "POST",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "brand",
      data: {
        brandName: brandData.brandName
      }
    })
      .then(function(response) {
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("brandaddMethod", response.data.response);
          router.push("/brand");
        }
      })
      .catch(function(error) {
        console.log(error);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("brandError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  },
  //new country add action end

  //fetch brand details
  async fetchBranddetails({ commit }, brandId) {
    commit("authRequest");

    axios({
      method: "GET",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "brand/" + brandId
    })
      .then(function(response) {
        if (response.data.result == 1) {
          commit("authSuccess");

          commit("brandDetails", response.data.response.name);
        }
      })
      .catch(function(error) {
        console.log(error);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("brandError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  },

  //new brand update action
  async brandUpdate({ commit }, brandData) {
    commit("authRequest");
    axios({
      method: "PUT",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "brand/" + brandData.brandId,
      data: {
        brandName: brandData.brandName
      }
    })
      .then(function(response) {
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("brandupdateMethod", response.data.response);
          router.push("/brand");
        }
      })
      .catch(function(error) {
        console.log(error);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("brandError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  }
  //new country update action end
};

const mutations = {
  setbrandList: (state, resp) => {
    state.brands = resp;
  },
  brandaddMethod: (state, resp) => {
    state.status = "success";
    state.brandResponse = resp;
  },
  brandError: (state, resp) => {
    state.status = "error";
    state.brandResponse = resp;
  },
  brandDetails: (state, resp) => {
    state.status = "";
    state.brandName = resp;
  },
  brandupdateMethod: (state, resp) => {
    state.status = "success";
    state.brandResponse = resp;
  },
  updateBrandname: (state, brandname) => {
    state.brandName = brandname;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
