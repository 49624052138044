<template>
  <div>
    <!--container-->
    <v-container>
      <!--tob bar-->
      <bar />
      <!--tob bar end-->

      <!--template-->
      <template>
        <!--alert box-->
        <v-alert
          text
          dense
          color="teal"
          icon="mdi-clock-fast"
          border="left"
          v-if="parfStatusmsg"
        >
          <div v-for="(resp, i) in parfResponse" :key="i">
            {{ resp }}
          </div>
        </v-alert>
        <!--alert box end-->

        <!---datatable -->
        <v-data-table
          :headers="headers"
          :items="parfList"
          :search="search"
          sort-by="temporary_no"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>ENQUIRY PARF PLAN</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
              <v-divider class="mx-4" inset vertical></v-divider>

              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="enquiryEdit(item)"
              v-if="userRole != 'sales-engineer'"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              small
              class="mr-2"
              @click="enquirySalesEdit(item)"
              v-if="userRole == 'sales-engineer'"
            >
              mdi-pencil
            </v-icon>
          </template>
        </v-data-table>
        <!---datatable end-->
      </template>
      <!--template end-->
    </v-container>
    <!--container end-->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import router from "../../router";

export default {
  name: "EnquiryParfRequest",
  data: () => ({
    search: "",
    headers: [
      { text: "Actions", value: "actions", sortable: false },
      {
        text: "CUSTOMER DETAILS",
        align: "start",
        sortable: true,
        value: "customer_details"
      },
      { text: "REFERENCE NO", value: "referenceNo" },
      { text: "SEGMENT", value: "segment" },
      { text: "LOB", value: "brand" },
      { text: "WORKSHOP NAME", value: "workshop_name" },
      { text: "ADDRESS", value: "address1" },
      { text: "ENQUIRY DATE", value: "enquiry_date" },
      { text: "STATUS", value: "status" },
      
    ]
  }),

  computed: {
    ...mapGetters([
      "getParfplanList",
      "followupStatus",
      "followupStatusResponse",
      "authRole"
    ]),
    parfList: function() {
      return this.getParfplanList;
    },
    parfStatusmsg: function() {
      return this.followupStatus === "success";
    },
    parfResponse: function() {
      return this.followupStatusResponse;
    },
    userRole: function() {
      return this.authRole;
    }
  },

  watch: {},

  created() {
    this.fetchParfplanList();
  },

  methods: {
    ...mapActions(["fetchParfplanList"]),

    enquiryEdit(item) {
      const id = item.id;
      router.push({ path: `/enquiry-parf/view/${id}` });
    },

    enquirySalesEdit(item) {
      const id = item.id;
      router.push({ path: `/enquiry-parf-sales/view/${id}` });
    }
  }
};
</script>
