import { VUE_APP_BASEURL } from "../../utils/api";
import router from "../../router";
import axios from "axios";
import store from "../../store";

const state = {
  countries: [],
  countryResponse: [],
  countryName: "",
  status: ""
};

const getters = {
  countryList: state => state.countries,
  countryStatusResponse: state => state.countryResponse,
  countryStatus: state => state.status,
  countryDetailsname: state => state.countryName
};

const actions = {
  //Country fetch
  async fetchCountry({ commit }) {
    commit("authRequest");
    axios({
      method: "GET",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "country"
    })
      .then(function(response) {
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("setcountryList", response.data.response);
        }
      })
      .catch(function(error) {
        console.log(error);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("countryError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  },

  //new country add action
  async countryAdd({ commit }, countryData) {
    commit("authRequest");
    axios({
      method: "POST",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "country",
      data: {
        countryName: countryData.countryName
      }
    })
      .then(function(response) {
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("countryaddMethod", response.data.response);
          router.push("/country");
        }
      })
      .catch(function(error) {
        console.log(error);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("countryError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  },
  //new country add action end

  //fetch Country details
  async fetchCountrydetails({ commit }, countryId) {
    commit("authRequest");

    axios({
      method: "GET",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "country/" + countryId
    })
      .then(function(response) {
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("countryDetails", response.data.response.name);
        }
      })
      .catch(function(error) {
        console.log(error);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("countryError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  },

  //new country update action
  async countryUpdate({ commit }, countryData) {
    commit("authRequest");
    axios({
      method: "PUT",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "country/" + countryData.countryId,
      data: {
        countryName: countryData.countryName
      }
    })
      .then(function(response) {
        //  console.log(response);
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("countryupdateMethod", response.data.response);
          router.push("/country");
        }
      })
      .catch(function(error) {
        console.log(error);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("countryError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  }
  //new country update action end
};

const mutations = {
  setcountryList: (state, resp) => {
    //  console.log(resp);
    state.countries = resp;
  },
  countryaddMethod: (state, resp) => {
    state.status = "success";
    state.countryResponse = resp;
  },
  countryError: (state, resp) => {
    state.status = "error";
    state.countryResponse = resp;
    console.log(resp);
  },
  countryDetails: (state, resp) => {
    state.status = "";
    state.countryName = resp;
  },
  countryupdateMethod: (state, resp) => {
    state.status = "success";
    state.countryResponse = resp;
  },
  updateCountryname: (state, countryname) => {
    state.countryName = countryname;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
