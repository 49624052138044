<template>
  <div>
    <!--container-->
    <v-container>
      <!--tob bar-->
      <bar />
      <!--tob bar end-->

      <!--template-->
      <template>
        <!--card -->
        <v-card class="form-card mx-auto my-12">
          <!--alert box-->
          <v-alert text prominent type="error" v-if="errorResponse">
            <div v-for="(error, i) in userError" :key="i">
              {{ error }}
            </div>
          </v-alert>
          <!--alert box end-->
          <v-list-item class="headline">
            <v-list-item-content>
              <v-list-item-title> USER EDIT </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>

          <!--form-->
          <form>
            <!--input field-->
            <v-card-text>
              <v-row justify="space-between">
                <v-col cols="12" md="12">
                  <v-text-field v-model="userName" :error-messages="userNameErrors" label="USER NAME*" required
                    @input="$v.userName.$touch()" @blur="$v.userName.$touch()"></v-text-field>
                </v-col>

                <v-col cols="12" md="12">
                  <v-text-field v-model="employeeCode" :error-messages="employeeCodeErrors" label="EMPLOYEE CODE*"
                    required @input="$v.employeeCode.$touch()" @blur="$v.employeeCode.$touch()"></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                  <v-text-field v-model="password" label="PASSWORD*" required></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                  <v-text-field v-model="email" :error-messages="emailErrors" label="EMAIL*" required
                    @input="$v.email.$touch()" @blur="$v.email.$touch()"></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                  <v-text-field v-model="phoneNumber" :error-messages="phoneNumberErrors" label="PHONE NUMBER*" required
                    @input="$v.phoneNumber.$touch()" @blur="$v.phoneNumber.$touch()"></v-text-field>
                </v-col>

                <v-col cols="12" md="12">
                  <v-text-field v-model="address" label="ADDRESS"></v-text-field>
                </v-col>

                <v-col cols="12" md="12">
                  <v-select v-model="region" :items="regions" :error-messages="regionErrors" label="REGION *" required
                    @input="$v.region.$touch()" @blur="$v.region.$touch()"></v-select>
                </v-col>

                <v-col cols="12" md="12">
                  <v-select v-model="role" :items="roles" :error-messages="roleErrors" label="ROLE *" item-text="name"
                    item-value="name" required @input="$v.role.$touch()" @blur="$v.role.$touch()"
                    @change="getRoletype(role)"></v-select>
                </v-col>

                <v-col cols="12" md="12">
                  <v-select v-model="segment" :items="segments" :error-messages="segmentErrors" label="SEGMENT *"
                    item-text="name" item-value="id" required chips multiple @input="$v.segment.$touch()"
                    @blur="$v.segment.$touch()" @change="onchangegetSalesengineer(segment)"></v-select>
                </v-col>

                <v-col cols="12" md="12" v-if="roleRegionalmanager">
                  <v-select v-model="salesengineer" :items="salesengineers" :error-messages="salesengineerErrors"
                    label="SALES ENGINEER *" item-text="name" item-value="id" required chips multiple
                    @input="$v.salesengineer.$touch()" @blur="$v.salesengineer.$touch()"></v-select>
                </v-col>

                <v-col cols md="12">
                  <v-select :items="activeList" v-model="active" label="ACTIVE*"></v-select>
                </v-col>
              </v-row>
            </v-card-text>
            <!--input field end-->
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" dark @click="submit"> SUBMIT </v-btn>
              <v-btn color="secondary" dark to="/user"> CANCEL </v-btn>
            </v-card-actions>
          </form>
          <!--form end-->
        </v-card>
        <!--card end-->
      </template>
      <!--template end-->
    </v-container>
    <!--container end-->
  </div>
</template>

<script>
import { required, requiredIf } from "vuelidate/lib/validators";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "UserEdit",
  data: () => ({
    password: "",
    activeList: ["YES", "NO"],
    roleRegionalmanager: false
  }),
  validations: {
    userName: { required },
    employeeCode: { required },
    email: { required },
    phoneNumber: { required },
    region: { required },
    role: { required },
    segment: { required },
    salesengineer: {
      required: requiredIf(function () {
        if (this.role == "regional-manager") return true;
      })
    }
  },
  computed: {
    ...mapGetters([
      "getRegions",
      "roleList",
      "segmentList",
      "userList",
      "getUsername",
      "getEmployeecode",
      "getEmail",
      "getPhonenumber",
      "getAddress",
      "getRegion",
      "getRole",
      "getSegment",
      "getSalesengineer",
      "userStatus",
      "userStatusResponse",
      "getSalesengineerlist",
      "getUseractive"
    ]),
    errorResponse: function () {
      return this.userStatus === "error";
    },
    userError: function () {
      return this.userStatusResponse;
    },
    regions: function () {
      return this.getRegions;
    },
    roles: function () {
      return this.roleList;
    },
    segments: function () {
      return this.segmentList;
    },
    salesengineers: function () {
      return this.getSalesengineerlist;
    },

    userName: {
      get() {
        return this.getUsername;
      },
      set(value) {
        this.$store.commit("updateUsername", value);
      }
    },
    employeeCode: {
      get() {
        return this.getEmployeecode;
      },
      set(value) {
        this.$store.commit("updateEmployeecode", value);
      }
    },
    email: {
      get() {
        return this.getEmail;
      },
      set(value) {
        this.$store.commit("updateEmail", value);
      }
    },
    phoneNumber: {
      get() {
        return this.getPhonenumber;
      },
      set(value) {
        this.$store.commit("updatePhonenumber", value);
      }
    },
    address: {
      get() {
        return this.getAddress;
      },
      set(value) {
        this.$store.commit("updateAddress", value);
      }
    },
    region: {
      get() {
        return this.getRegion;
      },
      set(value) {
        this.$store.commit("updateRegion", value);
      }
    },
    role: {
      get() {
        this.getRoletype(this.getRole);
        return this.getRole;
      },
      set(value) {
        this.$store.commit("updateRole", value);
      }
    },
    segment: {
      get() {
        this.fetchSalesengineers(this.getSegment);
        return this.getSegment;
      },
      set(value) {
        this.$store.commit("updateSegment", value);
      }
    },
    salesengineer: {
      get() {
        return this.getSalesengineer;
      },
      set(value) {
        this.$store.commit("updateSalesengineer", value);
      }
    },
    active: {
      get() {
        return this.getUseractive;
      },
      set(value) {
        this.$store.commit("updateUseractive", value);
      }
    },

    userNameErrors() {
      const errors = [];
      if (!this.$v.userName.$dirty) return errors;
      !this.$v.userName.required && errors.push("username is required.");
      return errors;
    },
    employeeCodeErrors() {
      const errors = [];
      if (!this.$v.employeeCode.$dirty) return errors;
      !this.$v.employeeCode.required &&
        errors.push("employeecode is required.");
      return errors;
    },

    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.required && errors.push("Email is required.");
      return errors;
    },
    phoneNumberErrors() {
      const errors = [];
      if (!this.$v.phoneNumber.$dirty) return errors;
      !this.$v.phoneNumber.required && errors.push("Phone number is required.");
      return errors;
    },
    regionErrors() {
      const errors = [];
      if (!this.$v.region.$dirty) return errors;
      !this.$v.region.required && errors.push("Region is required.");
      return errors;
    },
    roleErrors() {
      const errors = [];
      if (!this.$v.role.$dirty) return errors;
      !this.$v.role.required && errors.push("Role is required.");
      return errors;
    },
    segmentErrors() {
      const errors = [];
      if (!this.$v.segment.$dirty) return errors;
      !this.$v.segment.required && errors.push("Segment is required.");
      return errors;
    },
    salesengineerErrors() {
      const errors = [];
      if (!this.$v.salesengineer.$dirty) return errors;
      !this.$v.salesengineer.required &&
        errors.push("Salesengineer is required.");
      return errors;
    }
  },
  created() {
    this.fetchDefinedvalues();
    this.fetchRoles();
    this.fetchSegment();
    this.fetchUsers();
    this.fetchUserdetails(this.$route.params.id);
  },
  methods: {
    ...mapActions([
      "fetchDefinedvalues",
      "fetchRoles",
      "fetchSegment",
      "fetchUsers",
      "fetchUserdetails",
      "userUpdate",
      "fetchSalesengineers"
    ]),
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("ERROR");
      } else {
        // do your submit logic here
        setTimeout(() => {
          //customer datas
          const userData = {
            userId: this.$route.params.id,
            userName: !this.userName
              ? this.userName
              : this.userName.toUpperCase(),
            employeeCode: !this.employeeCode
              ? this.employeeCode
              : this.employeeCode.toUpperCase(),
            password: !this.password
              ? this.password
              : this.password.toUpperCase(),
            email: !this.email ? this.email : this.email.toUpperCase(),
            phoneNumber: !this.phoneNumber
              ? this.phoneNumber
              : this.phoneNumber.toUpperCase(),
            address: !this.address ? this.address : this.address.toUpperCase(),
            region: this.region,
            role: this.role,
            segment: this.segment,
            salesEngineer: this.salesengineer,
            active: this.active
          };

          console.log(userData);
          this.userUpdate(userData);
        }, 500);
      }
    },
    getRoletype(role) {
      if (role == "regional-manager") {
        this.roleRegionalmanager = true;
      } else if (role == "salesco-ordinator") {
        this.roleRegionalmanager = true;
      } else {
        this.roleRegionalmanager = false;
      }
    },
    //get segment based  salesengineer
    onchangegetSalesengineer(segment) {
      this.fetchSalesengineers(segment);
      //  console.log(segment);
    }
  }
};
</script>
