import { VUE_APP_BASEURL } from "../../utils/api";
import router from "../../router";
import axios from "axios";
import store from "../../store";

const state = {
  followupList: [],
  status: "",
  followupResponse: [],
  enquiries: [],
  referenceno: "",
  enquiryResponse: [],
  enquiryId: "",
  customerId: "",
  customerDetails: "",
  EnquiryDate: "",
  isIgst: "",
  freightCharge: "",
  taxAmount: "",
  totalAmount: "",
  totalAmountWithTax: "",
  remarks: "",
  workshopType: "",
  salesType: "",
  enquiryDetails: [],
  workshopName: "",
  address1: "",
  address2: "",
  segment: "",
  brand: "",
  mobileno: "",
  emailid: ""
};

const getters = {
  followupList: state => state.followupList,
  followupStatusResponse: state => state.followupResponse,
  followupStatus: state => state.status,
  followupcustomerDetails: state => state.customerDetails,
  followupcustomerId: state => state.customerId,
  followupEnquiryDate: state => state.EnquiryDate,
  followupIgst: state => state.isIgst,
  followupfreightCharge: state => state.freightCharge,
  followuptaxAmount: state => state.taxAmount,
  followuptotalAmount: state => state.totalAmount,
  followuptotalAmountWithTax: state => state.totalAmountWithTax,
  followupRemarks: state => state.remarks,
  followupworkshopNameDetails: state => state.workshopName,
  followupaddress1Details: state => state.address1,
  followupaddress2Details: state => state.address2,
  followupproductDetails: state => state.enquiryDetails,
  followupworkshopType: state => state.workshopType,
  followupsalesType: state => state.salesType,
  followupSegment: state => state.segment,
  followupBrand: state => state.brand,
  followupMobileno: state => state.mobileno,
  followupEmailid: state => state.emailid,
  followupReferenceno: state => state.referenceno
};

const actions = {
  //followuplist fetch
  async fetchFollowuplist({ commit }) {
    commit("authRequest");
    axios({
      method: "GET",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "enquiry-followup-list"
    })
      .then(function(response) {
        // console.log(response);

        if (response.data.result == 1) {
          commit("authSuccess");
          commit("setfollowupList", response.data.response);
        }
      })
      .catch(function(error) {
        console.log(error.response);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("followuplistError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  },

  //fetch Enquiry details
  async fetchEnquiryStatusdetails({ commit }, enquiryId) {
    commit("authRequest");

    axios({
      method: "GET",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "followupEnquirydetails/" + enquiryId
    })
      .then(function(response) {
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("followupfetchDetails", response.data.response);
        }
      })
      .catch(function(error) {
        console.log(error);
        if (error.response) {
          // client received an error response (5xx, 4xx)
          console.log(error.response);
          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("followuplistError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  },

  //new Enquiry add action
  async enquiryStatusaddforall({ commit }, enquiryStatus) {
    commit("authRequest");

    axios({
      method: "POST",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "enquirystatus",
      data: {
        enquiryId: enquiryStatus.enquiryId,
        followupDate: enquiryStatus.followupDate,
        followupStatus: enquiryStatus.followupStatus,
        nextfollowupDate: enquiryStatus.nextfollowupDate,
        remarks: enquiryStatus.remarks
      }
    })
      .then(function(response) {
        console.log(response);
        if (response.data.result == 1) {
          commit("authSuccess");
          //  commit("enquiryaddMethod", response.data.response);
          // router.push("/enquiry-followup-list/edit/"+response.data.response).catch(()=>{});

          location.reload();
        }
      })
      .catch(function(error) {
        console.log(error.response);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("followuplistError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  },

  //new Enquiry add action
  async enquiryStatusupdate({ commit }, enquiryData) {
    commit("authRequest");
    // console.log(enquiryData);
    axios({
      method: "PUT",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url:
        VUE_APP_BASEURL + "enquiryProductstatus/" + enquiryData.enquiryDetailid,
      data: {
        enquiryStatus: enquiryData.enquiryStatus,
        enquiryTentative: enquiryData.enquiryTentative,
        lostManufacturername: enquiryData.lostManufacturername,
        lostSuppliername: enquiryData.lostSuppliername,
        lostSalesqty: enquiryData.lostSalesqty,
        lostCompetitorunitprice: enquiryData.lostCompetitorunitprice,
        lostCompetitortotalprice: enquiryData.lostCompetitortotalprice,
        lostRemarks: enquiryData.lostRemarks,
        lostReason: enquiryData.lostReason
      }
    })
      .then(function(response) {
        console.log(response);
        if (response.data.result == 1) {
          commit("authSuccess");
          //  commit("enquiryaddMethod", response.data.response);

          location.reload();
        }
      })
      .catch(function(error) {
        console.log(error.response);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("followuplistError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  }
};

const mutations = {
  setfollowupList: (state, resp) => {
    state.followupList = resp;
  },
  followuplistError: (state, resp) => {
    state.status = "error";
    state.followupResponse = resp;
  },
  followupfetchDetails: (state, resp) => {
    state.status = "";
    (state.enquiryId = resp.id),
      (state.referenceno = resp.referenceNo),
      (state.customerId = resp.customerId),
      (state.customerDetails = resp.customerDetails);
    state.EnquiryDate = resp.EnquiryDate;
    state.enquiryDetails = resp.productList;
    state.isIgst = resp.isIgst;
    state.freightCharge = resp.freightCharge;
    state.taxAmount = resp.taxAmount;
    state.totalAmount = resp.totalAmount;
    state.totalAmountWithTax = resp.totalAmountWithTax;
    state.remarks = resp.remarks;
    state.workshopName = resp.customer.workshop_name;
    state.address1 = resp.customer.address1;
    state.address2 = resp.customer.address2;
    state.salesType = resp.salesType;
    state.workshopType = resp.workshopType;

    state.segment = resp.segment;
    state.brand = resp.brand;
    state.mobileno = resp.customer.contactnumber;
    state.emailid = resp.customer.emailid;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
