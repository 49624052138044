import { VUE_APP_BASEURL } from "../../utils/api";
import router from "../../router";
import axios from "axios";
import store from "../../store";

const state = {
  productgroups: [],
  productgroupResponse: [],
  productgroupName: "",
  status: ""
};

const getters = {
  productGroup: state => state.productgroups,
  productGroupStatusResponse: state => state.productgroupResponse,
  productGroupStatus: state => state.status,
  productGroupDetailsname: state => state.productgroupName
};

const actions = {
  //product group fetch
  async fetchProductgroup({ commit }) {
    commit("authRequest");
    axios({
      method: "GET",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "product-group"
    })
      .then(function(response) {
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("setProductgroups", response.data.response);
        }
      })
      .catch(function(error) {
        console.log(error);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("productGroupError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  },

  //new product group add action
  async productgroupAdd({ commit }, productgroupData) {
    commit("authRequest");
    axios({
      method: "POST",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "product-group",
      data: {
        productgroupName: productgroupData.productgroupName
      }
    })
      .then(function(response) {
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("productGroupadd", response.data.response);
          router.push("/product-group");
        }
      })
      .catch(function(error) {
        console.log(error);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("productGroupError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  },
  //new product group add action end

  //fetch product Group details
  async fetchProductgroupdetails({ commit }, productgroupId) {
    commit("authRequest");

    axios({
      method: "GET",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "product-group/" + productgroupId
    })
      .then(function(response) {
        if (response.data.result == 1) {
          commit("authSuccess");
          console.log(response.data.response.product_group_name);
          commit(
            "productGroupdetails",
            response.data.response.product_group_name
          );
        }
      })
      .catch(function(error) {
        console.log(error);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("productGroupError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  },

  //new product group update action
  async productgroupUpdate({ commit }, productgroupData) {
    commit("authRequest");
    axios({
      method: "PUT",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "product-group/" + productgroupData.productgroupId,
      data: {
        productgroupName: productgroupData.productgroupName
      }
    })
      .then(function(response) {
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("productGroupupdate", response.data.response);
          router.push("/product-group");
        }
      })
      .catch(function(error) {
        console.log(error);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            commit("productGroupError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error.request);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          commit("authLogout");
          router.push("/");
          console.log(error);
        }
      });
  }
  //new product group update action end
};

const mutations = {
  setProductgroups: (state, resp) => {
    state.productgroups = resp;
  },
  productGroupadd: (state, resp) => {
    state.status = "success";
    state.productgroupResponse = resp;
  },
  productGroupdetails: (state, resp) => {
    state.status = "";
    state.productgroupName = resp;
  },
  productGroupError: (state, resp) => {
    state.status = "error";
    state.productgroupResponse = resp;
  },
  productGroupupdate: (state, resp) => {
    state.status = "success";
    state.productgroupResponse = resp;
  },
  updateproductGroupname: (state, groupname) => {
    state.productgroupName = groupname;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
