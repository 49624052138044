<template>
  <div>
    <!--container-->
    <v-container>
      <!--tob bar-->
      <bar />
      <!--tob bar end-->

      <!--template-->
      <template>
        <!--alert box-->
        <v-alert
          text
          dense
          color="teal"
          icon="mdi-clock-fast"
          border="left"
          v-if="parfStatusmsg"
        >
          <div v-for="(resp, i) in parfResponse" :key="i">
            {{ resp }}
          </div>
        </v-alert>
        <!--alert box end-->

        <v-card outlined>
          <v-list-item class="headline">
            <v-list-item-content>
              <v-list-item-title>
                PRICE/PAYMENT TERMS APPROVAL REQUEST
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td class="text-left">
                    ENGINEER : {{ parfRequestdetails.engineerName }}
                  </td>
                  <td class="text-left" rowspan="2">
                    <v-simple-table>
                      <template v-slot:default>
                        <tbody>
                          <tr>
                            <td>
                              CUSTOMER :{{ parfRequestdetails.customerDetails }}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              LINE OF BUSINESS :{{ parfRequestdetails.lob }}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              REQUEST NO :{{ parfRequestdetails.referenceNo }}
                            </td>
                          </tr>
                          <tr>
                            <td>DATE :{{ parfRequestdetails.EnquiryDate }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </td>
                </tr>
                <tr>
                  <td class="text-left">
                    BRANCH : {{ parfRequestdetails.region }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>

        <!--card -->
        <v-card class="mx-auto my-12" outlined>
          <!--form-->
          <form>
            <!--input field-->
            <v-card-text>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        ITEM CODE
                      </th>
                      <th class="text-left">
                        DESCRIPTION
                      </th>
                      <th class="text-left">
                        WARRENTY TERMS
                      </th>
                      <th class="text-left">
                        QTY
                      </th>
                      <th class="text-left">
                        UNIT PRICE
                      </th>
                      <th class="text-left">
                        SPECIAL PRICE
                      </th>
                      <th class="text-left">
                        REQUIRED PRICE
                      </th>
                      <th class="text-left">
                        SUGGESTED PRICE
                      </th>
                      <th class="text-left">
                        TOTAL PRICE
                      </th>
                      <th class="text-left">
                        DEALER TPC
                      </th>
                      <th class="text-left">
                        LAST SUPPLIED PRICE
                      </th>
                      <th class="text-left">
                        DEALER VALUE
                      </th>
                      <th class="text-left">
                        COS
                      </th>
                      <th class="text-left">
                        MARGIN
                      </th>
                      <th class="text-left">
                        IN%
                      </th>
                      <th class="text-left">
                        ASP
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in parfRequestdetails.productList"
                      :key="item.SNo"
                    >
                      <td>{{ item.equipment.tpl_no }}</td>
                      <td>{{ item.equipment.product_name }}</td>
                      <td>{{ item.equipment.warranty_in_years }}</td>
                      <td>{{ item.quantity }}</td>
                      <td>{{ item.price }}</td>
                      <td>{{ item.special_unit_price }}</td>
                      <td>{{ item.request_unit_price }}</td>
                      <td>
                        <v-text-field
                          v-model="item.final_unit_price"
                          @change="getProductqtyprice(item)"
                        ></v-text-field>
                      </td>
                      <td>{{ item.final_total_sales_price }}</td>
                      <td>
                        <v-text-field
                          v-model="item.dealer_tpc"
                          @change="getDealertpc(item)"
                        ></v-text-field>
                      </td>
                      <td>
                        <v-text-field
                          v-model="item.last_supplied_price"
                        ></v-text-field>
                      </td>
                      <td>
                        {{ item.dealer_value }}
                      </td>
                      <td>
                        <v-text-field
                          v-model="item.cos_value"
                          @change="getCosvalue(item)"
                        ></v-text-field>
                      </td>
                      <td>
                        {{ item.margin }}
                      </td>
                      <td>
                        {{ item.margin_in_per }}
                      </td>
                      <td>
                        <v-text-field
                          v-model="item.asp_value"
                          @change="getAspvalue(item)"
                        ></v-text-field>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="7"></td>
                      <td>
                        TOTAL
                      </td>
                      <td>
                        {{ parfRequestdetails.suggested_total_amount }}
                      </td>
                      <td colspan="2" class="text-right">DEALER VALUE</td>
                      <td>{{ parfRequestdetails.total_dealer_value }}</td>
                      <td class="text-left">
                        {{ parfRequestdetails.cos_total_amount }}
                      </td>
                      <td class="text-left">
                        {{ parfRequestdetails.margin_total_amount }}
                      </td>
                      <td class="text-left">
                        {{ parfRequestdetails.margin_total_inper }}
                      </td>
                      <td class="text-left"></td>
                    </tr>
                    <tr>
                      <td colspan="7"></td>
                      <td>
                        BAAF
                      </td>
                      <td>
                        <v-text-field
                          v-model="parfRequestdetails.baaf"
                          @change="calculateTotal"
                        ></v-text-field>
                      </td>
                      <td colspan="7"></td>
                    </tr>
                    <tr>
                      <td colspan="7"></td>
                      <td>
                        FREIGHT
                      </td>
                      <td>
                        <v-text-field
                          v-model="parfRequestdetails.freight"
                          @change="calculateTotal"
                        ></v-text-field>
                      </td>
                      <td colspan="7"></td>
                    </tr>

                    <!--payment terms box-->
                    <tr>
                      <td colspan="4"></td>
                      <td colspan="8">
                        <v-simple-table>
                          <template v-slot:default>
                            <tbody>
                              <tr>
                                <td>PAYMENT TERMS :</td>
                                <td>{{ parfRequestdetails.payment_terms }}</td>
                              </tr>
                              <tr>
                                <td>TAX :</td>
                                <td>{{ parfRequestdetails.taxes }}</td>
                              </tr>
                              <tr>
                                <td>FREIGHT (TO PAY / PAID) :</td>
                                <td>{{ parfRequestdetails.freight }}</td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </td>
                      <td colspan="4"></td>
                    </tr>
                    <!--payment terms box end-->

                    <!--margin box-->
                    <tr>
                      <td colspan="4"></td>
                      <td colspan="8">
                        <v-simple-table>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-left">
                                  MARGIN
                                </th>
                                <th class="text-left">
                                  VALUE
                                </th>
                                <th class="text-left">
                                  PERCENTAGE
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>ATS ELGI</td>
                                <td>
                                  {{ parfRequestdetails.ats_dealervalue }}
                                </td>
                                <td>
                                  {{ parfRequestdetails.ats_dealervalue_inper }}
                                </td>
                              </tr>
                              <tr>
                                <td>DEALER</td>
                                <td>
                                  {{ parfRequestdetails.total_dealer_value }}
                                </td>
                                <td>
                                  {{
                                    parfRequestdetails.total_dealer_value_inper
                                  }}
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </td>
                      <td colspan="4"></td>
                    </tr>
                    <!--margin box-->
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
            <!--input field end-->
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="black"
                dark
                @click="submit"
                v-if="
                  parfRequestdetails.status != 'REJECT' &&
                    parfRequestdetails.status != 'CONFIRM'
                "
              >
                SUBMIT
              </v-btn>
              <v-btn
                color="error"
                @click="reject"
                v-if="
                  parfRequestdetails.status != 'REJECT' &&
                    parfRequestdetails.status != 'CONFIRM'
                "
              >
                REJECT
              </v-btn>
              <v-btn color="warning" dark to="/enquiry-parf-request-list">
                CANCEL
              </v-btn>
            </v-card-actions>
          </form>
          <!--form end-->
        </v-card>
        <!--card end-->
      </template>
      <!--template end-->
    </v-container>
    <!--container end-->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "EnquiryParfView",
  data: () => ({
    search: "",
    headers: [
      {
        text: "EQUIPMENT",
        align: "start",
        sortable: true,
        value: "equipment"
      },
      { text: "QTY", value: "quantity" },
      { text: "PRICE", value: "price" },
      { text: "TOTAL PRICE", value: "totalPrice" },
      { text: "TSP QTY", value: "tspqty" },
      { text: "TSP TOTAL PRICE", value: "tsptotalprice" },
      { text: "TSPDATE", value: "tspdate" },
      { text: "STATUS", value: "status" },
      {
        text: "SUGGESTED PRICE",
        value: "suggested_unit_price",
        sortable: false
      }
    ]
  }),

  computed: {
    ...mapGetters([
      "getparfRequestdetails",
      "getparfUpdatestatus",
      "getparfUpdateResponse",
      "authRole"
    ]),
    parfRequestdetails: function() {
      return this.getparfRequestdetails;
    },
    parfStatusmsg: function() {
      return this.getparfUpdatestatus === "success";
    },
    parfResponse: function() {
      return this.getparfUpdateResponse;
    },
    userRole: function() {
      return this.authRole;
    }
  },

  watch: {},

  created() {
    this.fetchRequestparfdetails(this.$route.params.id);
  },

  methods: {
    ...mapActions(["fetchRequestparfdetails", "enquiryParfrequestupdate"]),
    getProductqtyprice(item) {
      console.log(item);
      let cost = parseFloat(item.quantity) * parseFloat(item.final_unit_price);
      item.final_total_sales_price = cost;
      this.getDealertpc(item);
    },
    getDealertpc(item) {
      let tpcValue =
        (parseFloat(item.final_total_sales_price) *
          parseFloat(item.dealer_tpc)) /
        100;
      item.dealer_value = tpcValue;
      this.getMarginvalue(item);
      this.calculateTotal();
    },
    getCosvalue(item) {
      let itemCoscost = parseFloat(item.quantity) * parseFloat(item.cos_value);

      console.log(item);

      item.total_item_coscost_value = itemCoscost;
      this.getMarginvalue(item);
    },
    getAspvalue(item) {
      let itemAspcost = parseFloat(item.quantity) * parseFloat(item.asp_value);
      item.total_item_asp_value = itemAspcost;
      this.getMarginvalue(item);
    },
    getMarginvalue(item) {
      let margin_val =
        parseFloat(item.final_total_sales_price) -
        parseFloat(item.dealer_value) -
        parseFloat(item.total_item_coscost_value);
      let margin_per =
        (parseFloat(margin_val) / parseFloat(item.final_total_sales_price)) *
        100;
      item.margin = margin_val.toFixed(2);
      item.margin_in_per = margin_per.toFixed(2);
      this.calculateTotal();
    },
    calculateTotal() {
      //total value
      var subtotal;
      subtotal = this.parfRequestdetails.productList.reduce(function(
        sum,
        product
      ) {
        var lineTotal = parseFloat(product.final_total_sales_price);
        if (!isNaN(lineTotal)) {
          return sum + lineTotal;
        }
      },
      0);
      //total dealer value
      var subdealervalue;
      subdealervalue = this.parfRequestdetails.productList.reduce(function(
        sum,
        product
      ) {
        var dealer_valuelineTotal = parseFloat(product.dealer_value);
        if (!isNaN(dealer_valuelineTotal)) {
          return sum + dealer_valuelineTotal;
        }
      },
      0);
      //total cos value
      var subcosvalue;
      subcosvalue = this.parfRequestdetails.productList.reduce(function(
        sum,
        product
      ) {
        var cos_valuelineTotal = parseFloat(product.total_item_coscost_value);
        if (!isNaN(cos_valuelineTotal)) {
          return sum + cos_valuelineTotal;
        }
      },
      0);

      //total amount value
      this.parfRequestdetails.suggested_total_amount = subtotal.toFixed(2);
      this.parfRequestdetails.cos_total_amount = subcosvalue.toFixed(2);

      //dealervalue
      this.parfRequestdetails.total_dealer_value = subdealervalue.toFixed(2);
      let total_dealer_value_inper =
        (parseFloat(subdealervalue) / parseFloat(subtotal)) * 100;
      this.parfRequestdetails.total_dealer_value_inper = total_dealer_value_inper.toFixed(
        2
      );

      //margin values
      let submarginvalue =
        parseFloat(subtotal) -
        parseFloat(subdealervalue) -
        parseFloat(subcosvalue);
      this.parfRequestdetails.margin_total_amount = submarginvalue.toFixed(2);

      //marginvalue in per
      let submarginvalueinper =
        (parseFloat(submarginvalue) / parseFloat(subtotal)) * 100;
      this.parfRequestdetails.margin_total_inper = submarginvalueinper.toFixed(
        2
      );

      //ats elgi dealer value
      let ats_dealervalue =
        parseFloat(submarginvalue) -
        parseFloat(this.parfRequestdetails.baaf) -
        parseFloat(this.parfRequestdetails.freight);
      this.parfRequestdetails.ats_dealervalue = ats_dealervalue.toFixed(2);

      let ats_dealervalue_inper =
        (parseFloat(this.parfRequestdetails.ats_dealervalue) /
          parseFloat(subtotal)) *
        100;

      this.parfRequestdetails.ats_dealervalue_inper = ats_dealervalue_inper.toFixed(
        2
      );
    },
    submit() {
      setTimeout(() => {
        const parfData = {
          parfId: this.$route.params.id,
          parfRequestdetails: this.parfRequestdetails,
          parfStatus: "CONFIRM"
        };
        this.enquiryParfrequestupdate(parfData);
      }, 500);
    },
    reject() {
      console.log("reject");

      this.parfRequestdetails.productList.filter(item => {
        item.final_unit_price = item.special_unit_price;
        this.getProductqtyprice(item);
      });

      setTimeout(() => {
        const parfData = {
          parfId: this.$route.params.id,
          parfRequestdetails: this.parfRequestdetails,
          parfStatus: "REJECT"
        };

        console.log(parfData);
        this.enquiryParfrequestupdate(parfData);
      }, 600);
    }
  }
};
</script>
