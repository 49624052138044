<template>
  <div>
    <!--container-->
    <v-container>
      <!--tob bar-->
      <bar />
      <!--tob bar end-->

      <!--template-->
      <template>
        <!--alert box-->
        <v-alert
          text
          dense
          color="teal"
          icon="mdi-clock-fast"
          border="left"
          v-if="tspplanStatusmsg"
        >
          <div v-for="(resp, i) in tspplanResponse" :key="i">
            {{ resp }}
          </div>
        </v-alert>
        <!--alert box end-->

        <v-card outlined>
          <v-list-item class="headline">
            <v-list-item-content>
              <v-list-item-title>
                PRICE/PAYMENT TERMS APPROVAL REQUEST
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td class="text-left">
                    ENGINEER : {{ parfRequestdetails.engineerName }}
                  </td>
                  <td class="text-left" rowspan="2">
                    <v-simple-table>
                      <template v-slot:default>
                        <tbody>
                          <tr>
                            <td>
                              CUSTOMER :{{ parfRequestdetails.customerDetails }}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              LINE OF BUSINESS :{{ parfRequestdetails.lob }}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              REQUEST NO :{{ parfRequestdetails.referenceNo }}
                            </td>
                          </tr>
                          <tr>
                            <td>DATE :{{ parfRequestdetails.EnquiryDate }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </td>
                </tr>
                <tr>
                  <td class="text-left">
                    BRANCH : {{ parfRequestdetails.region }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>

        <!--card -->
        <v-card class="mx-auto my-12" outlined>
          <!--form-->
          <form>
            <!--input field-->
            <v-card-text>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        ITEM CODE
                      </th>
                      <th class="text-left">
                        DESCRIPTION
                      </th>
                      <th class="text-left">
                        WARRENTY TERMS
                      </th>
                      <th class="text-left">
                        QTY
                      </th>
                      <th class="text-left">
                        UNIT PRICE
                      </th>
                      <th class="text-left">
                        SPECIAL PRICE
                      </th>
                      <th class="text-left">
                        REQUIRED PRICE
                      </th>
                      <th class="text-left">
                        DEALER TPC
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in parfRequestdetails.productList"
                      :key="item.SNo"
                    >
                      <td>{{ item.equipment.tpl_no }}</td>
                      <td>{{ item.equipment.product_name }}</td>
                      <td>{{ item.equipment.warranty_in_years }}</td>
                      <td>{{ item.quantity }}</td>
                      <td>{{ item.price }}</td>
                      <td>{{ item.special_unit_price }}</td>
                      <td>
                        <v-text-field
                          v-model="item.request_unit_price"
                        ></v-text-field>
                      </td>
                      <td>
                        <v-text-field v-model="item.dealer_tpc"></v-text-field>
                      </td>
                    </tr>

                    <tr>
                      <td colspan="6"></td>
                      <td>
                        BAAF
                      </td>
                      <td>
                        <v-text-field v-model="baaf"></v-text-field>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="6"></td>
                      <td>
                        FREIGHT
                      </td>
                      <td>
                        <v-text-field v-model="freight"></v-text-field>
                      </td>
                    </tr>

                    <tr>
                      <td colspan="3"></td>
                      <td>
                        JUSTIFICATION*
                      </td>
                      <td colspan="4">
                        <v-text-field v-model="justification"></v-text-field>
                      </td>
                    </tr>

                    <tr>
                      <td colspan="3"></td>
                      <td>
                        PAYMENT TERMS *
                      </td>
                      <td colspan="4">
                        <v-text-field v-model="payment_terms"></v-text-field>
                      </td>
                    </tr>

                    <tr>
                      <td colspan="3"></td>
                      <td>
                        TAXES *
                      </td>
                      <td colspan="4">
                        <v-text-field v-model="taxes"></v-text-field>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
            <!--input field end-->
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="black" dark @click="submit"> SUBMIT </v-btn>
              <v-btn color="warning" dark to="/enquiry-tsp-plan-list">
                CANCEL
              </v-btn>
            </v-card-actions>
          </form>
          <!--form end-->
        </v-card>
        <!--card end-->
      </template>
      <!--template end-->
    </v-container>
    <!--container end-->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "EnquiryParfRise",
  data: () => ({
    search: "",
    baaf: 0,
    freight: 0,
    justification: "",
    payment_terms: "",
    taxes: "",
    headers: [
      {
        text: "EQUIPMENT",
        align: "start",
        sortable: true,
        value: "equipment"
      },
      { text: "QTY", value: "quantity" },
      { text: "PRICE", value: "price" },
      { text: "TOTAL PRICE", value: "totalPrice" },
      { text: "TSP QTY", value: "tspqty" },
      { text: "TSP TOTAL PRICE", value: "tsptotalprice" },
      { text: "TSPDATE", value: "tspdate" },
      { text: "STATUS", value: "status" },
      {
        text: "SUGGESTED PRICE",
        value: "suggested_unit_price",
        sortable: false
      }
    ]
  }),

  computed: {
    ...mapGetters([
      "getTspplandetailList",
      "followupStatus",
      "followupStatusResponse"
    ]),
    parfRequestdetails: function() {
      return this.getTspplandetailList;
    },
    tspplanStatusmsg: function() {
      return this.followupStatus === "success";
    },
    tspplanResponse: function() {
      return this.followupStatusResponse;
    }
  },

  watch: {},

  created() {
    this.fetchTspPlandetails(this.$route.params.id);
  },

  methods: {
    ...mapActions(["fetchTspPlandetails", "enquiryParfrequestrise"]),
    submit() {
      const enquiryData = {
        enquiryId: this.$route.params.id,
        parfRequestdetails: this.parfRequestdetails.productList,
        baaf: this.baaf,
        freight: this.freight,
        justification: this.justification,
        payment_terms: this.payment_terms,
        taxes: this.taxes
      };
      console.log(enquiryData);
      this.enquiryParfrequestrise(enquiryData);
    }
  }
};
</script>
