import { VUE_APP_BASEURL } from "../../utils/api";
import router from "../../router";
import axios from "axios";
import store from "../../store";

const state = {
  enquiryReportdata: [],
  enquiryReportValuesdata: [],
  tspReportdata: [],
  equipmentReportdata: [],
  customerReportdata: [],
  partialorderReportdata: [],
  leadorderReportdata: [],

  //chart
  yearlyenquiryChartdata: [],
  monthlysalesplanChartdata: [],

  //dashboard
  salesPerformancereportdata: [],
  enquiryFollowupreportdata: [],
  workshopupdationreportchartdata: [],
  orderlosschartdata: [],
  enquiryReportPaginate: 0,
  tspReportPaginate: 0,
  mspReportPaginate: 0,
  leadReportPaginate: 0
};

const getters = {
  getEnquiryreport: state => state.enquiryReportdata,
  getEnquiryreportvalues: state => state.enquiryReportValuesdata,

  getTspreport: state => state.tspReportdata,
  getEquipmentreport: state => state.equipmentReportdata,
  getCustomerreportdata: state => state.customerReportdata,
  getPartialorderreport: state => state.partialorderReportdata,
  getLeadreport: state => state.leadorderReportdata,

  //chartdata
  getyearlyenquiryChart: state => state.yearlyenquiryChartdata,
  getmonthlysalesplanChart: state => state.monthlysalesplanChartdata,

  //dashboard data
  getSalesperformancereportdata: state => state.salesPerformancereportdata,
  getEnquiryfollowupreportdata: state => state.enquiryFollowupreportdata,
  getWorkshopupdationreportchartdata: state =>
    state.workshopupdationreportchartdata,
  getOrderlosschartdata: state => state.orderlosschartdata,
  enquiryReportListPaginate: state => state.enquiryReportPaginate,
  tspReportListPaginate: state => state.tspReportPaginate,
  mspReportListPaginate: state => state.mspReportPaginate,
  leadReportListPaginate: state => state.leadReportPaginate
};

const actions = {
  //Enquiry Report
  async enquiryReport({ commit }, filterData) {
    //console.log(filterData);

    commit("authRequest");
    axios({
      method: "POST",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "report/enquiry-report?page=" + filterData.page,
      data: {
        report: filterData.report,
        product: filterData.product,
        segment: filterData.segment,
        salesengineer: filterData.salesengineer,
        status: filterData.status,
        region: filterData.region,
        productgroup: filterData.productgroup,
        daterange: filterData.daterange,
        enquirytype: filterData.enquirytype
      }
    })
      .then(function(response) {
          console.log(response);
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("setEnquiryreport", response.data.response);
        }
      })
      .catch(function(error) {
        console.log(error);
        if (error.response) {
          // client received an error response (5xx, 4xx)
          console.log(error.response);
          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            // commit("brandError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          //  commit("authLogout");
          //  router.push("/");
          console.log(error);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          //  commit("authLogout");
          //  router.push("/");
          console.log(error);
        }
      });
  },
  //Enquiry Report


    //Enquiry Report Values
    async enquiryReportValues({ commit }, filterData) {
    //  console.log(filterData);
  
      commit("authRequest");
      axios({
        method: "POST",
        headers: {
          Authorization: "Bearer " + store.getters.UserToken
        },
        url: VUE_APP_BASEURL + "report/enquiry-report-values",
        data: {
          report: filterData.report,
          product: filterData.product,
          segment: filterData.segment,
          salesengineer: filterData.salesengineer,
          status: filterData.status,
          region: filterData.region,
          productgroup: filterData.productgroup,
          daterange: filterData.daterange,
          enquirytype: filterData.enquirytype
        }
      })
        .then(function(response) {
          //  console.log(response);
          if (response.data.result == 1) {
            commit("authSuccess");
            commit("setEnquiryreportValues", response.data.response);
          }
        })
        .catch(function(error) {
          console.log(error);
          if (error.response) {
            // client received an error response (5xx, 4xx)
            console.log(error.response);
            if (error.response.status == 404) {
              commit("authError", "AuthenticationError");
              commit("authLogout");
              router.push("/");
            }
            if (error.response.status == 422) {
              commit("authError", "AuthenticationError");
              // commit("brandError", error.response.data.response);
            }
          } else if (error.request) {
            // client never received a response, or request never left
            commit("authError", "AuthenticationError");
            //  commit("authLogout");
            //  router.push("/");
            console.log(error);
          } else {
            // anything else
            commit("authError", "AuthenticationError");
            //  commit("authLogout");
            //  router.push("/");
            console.log(error);
          }
        });
    },
    //Enquiry Report Values

  //TSP Report
  async tspReport({ commit }, filterData) {
    //  console.log('eneter');

    commit("authRequest");
    axios({
      method: "POST",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "report/tsp-report?page=" + filterData.page,
      data: {
        segment: filterData.segment,
        salesengineer: filterData.salesengineer,
        region: filterData.region,
        daterange: filterData.daterange
      }
    })
      .then(function(response) {
        console.log(response);
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("setTspreport", response.data.response);
        }
      })
      .catch(function(error) {
        console.log(error.response);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            // commit("brandError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          //  commit("authLogout");
          //  router.push("/");
          console.log(error);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          //  commit("authLogout");
          //  router.push("/");
          console.log(error);
        }
      });
  },
  //TSP Report

  //Equipment Report
  async equipmentReport({ commit }, filterData) {
    //console.log('eneter');

    commit("authRequest");
    axios({
      method: "POST",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "report/equipment-report",
      data: {
        product: filterData.product,
        segment: filterData.segment,
        status: filterData.status,
        year: filterData.year,
        buybackyear: filterData.buybackyear,
        state: filterData.state,
        city: filterData.city,
        productgroup: filterData.productgroup
      }
    })
      .then(function(response) {
        console.log(response);
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("setEquipmentreport", response.data.response);
        }
      })
      .catch(function(error) {
        console.log(error.response);
        if (error.response) {
          // client received an error response (5xx, 4xx)
          console.log(error.response);
          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            // commit("brandError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          //  commit("authLogout");
          //  router.push("/");
          console.log(error);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          //  commit("authLogout");
          //  router.push("/");
          console.log(error);
        }
      });
  },
  //TSP Report

  //customer based equipment report

  //customer fetch
  async customerBasedequipmentReport({ commit }, filterData) {
    commit("authRequest");
    // console.log(filterData.status);
    axios({
      method: "POST",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "report/customer-report",
      data: {
        brand: filterData.brand,
        status: filterData.status,
        state: filterData.state,
        city: filterData.city,
        region: filterData.region
      }
    })
      .then(function(response) {
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("setCustomerreport", response.data.response);
        }
      })
      .catch(function(error) {
        console.log(error.response);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            // commit("authLogout");
            // router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          //  commit("authLogout");
          //  router.push("/");
          console.log(error);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          //  commit("authLogout");
          //  router.push("/");
          console.log(error);
        }
      });
  },

  //Partial Order Report
  async partialorderReport({ commit }, filterData) {
    console.log("eneter");

    commit("authRequest");
    axios({
      method: "POST",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "report/partial-order-report",
      data: {
        product: filterData.product,
        segment: filterData.segment,
        salesengineer: filterData.salesengineer,
        status: filterData.status,
        region: filterData.region,
        productgroup: filterData.productgroup,
        daterange: filterData.daterange
      }
    })
      .then(function(response) {
        console.log(response);
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("setPartialorderreport", response.data.response);
        }
      })
      .catch(function(error) {
        console.log(error.response);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
          //  commit("authLogout");
          //  router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            // commit("brandError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          //  commit("authLogout");
          //  router.push("/");
          console.log(error);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          //  commit("authLogout");
          //  router.push("/");
          console.log(error);
        }
      });
  },
  //Partial Order Report

  //Lead Report
  async leadReport({ commit }, filterData) {
    //  console.log('eneter');

    commit("authRequest");
    axios({
      method: "POST",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "report/lead-report?page=" + filterData.page,
      data: {
        segment: filterData.segment,
        salesengineer: filterData.salesengineer,
        region: filterData.region,
        daterange: filterData.daterange,
        search: filterData.search
      }
    })
      .then(function(response) {
       // console.log(response);
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("setLeadreport", response.data.response);
        }
      })
      .catch(function(error) {
        console.log(error.response);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            //commit("authLogout");
           // router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            // commit("brandError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          //  commit("authLogout");
          //  router.push("/");
          console.log(error);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          //  commit("authLogout");
          //  router.push("/");
          console.log(error);
        }
      });
  },
  //Lead Report

  //Yearly Enquiry Chart
  async yearlyEnquirychart({ commit }, filterData) {
    //console.log('eneter');

    commit("authRequest");
    axios({
      method: "POST",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "chart/yearly-enquirydata",
      data: {
        segment: filterData.segment,
        salesengineer: filterData.salesengineer
      }
    })
      .then(function(response) {
        //  console.log(response);
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("setYearlyenquirydata", response.data.response);
        }
      })
      .catch(function(error) {
        console.log(error.response);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            //commit("authLogout");
           // router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            // commit("brandError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          //  commit("authLogout");
          //  router.push("/");
          console.log(error);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          //  commit("authLogout");
          //  router.push("/");
          console.log(error);
        }
      });
  },
  //Yearly Enquiry Chart

  //Yearly Enquiry Chart
  async monthlySalesplanchart({ commit }, filterData) {
    //console.log(filterData);

    commit("authRequest");
    axios({
      method: "POST",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url:
        VUE_APP_BASEURL +
        "chart/monthlysalesplan-chart-data?page=" +
        filterData.page,
      data: {
        product: filterData.product,
        segment: filterData.segment,
        salesengineer: filterData.salesengineer,
        status: filterData.status,
        daterange: filterData.daterange,
        region: filterData.region,
        productgroup: filterData.productgroup
      }
    })
      .then(function(response) {
        //  console.log(response);
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("setMonthlysalesplandata", response.data.response);
        }
      })
      .catch(function(error) {
        console.log(error.response);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            // commit("brandError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          //  commit("authLogout");
          //  router.push("/");
          console.log(error);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          //  commit("authLogout");
          //  router.push("/");
          console.log(error);
        }
      });
  },
  //Yearly Enquiry Chart

  //Sales Performance Report
  async salesPerformancereport({ commit }, filterData) {
    // console.log('eneter');

    commit("authRequest");
    axios({
      method: "POST",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "dashboard/salesperformance-report-chart",
      data: {
        segment: filterData.segment,
        salesengineer: filterData.salesengineer,
        region: filterData.region,
        productgroup: filterData.productgroup
      }
    })
      .then(function(response) {
        //console.log(response);
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("setSalesperformancereportdata", response.data.response);
        }
      })
      .catch(function(error) {
        console.log(error.response);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            // commit("brandError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          // commit("authLogout");
          //  router.push("/");
          console.log(error);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          //  commit("authLogout");
          // router.push("/");
          console.log(error);
        }
      });
  },
  //Sales Performance Report

  //Enquiry Followup Report chart
  async enquiryFollowupreport({ commit }, filterData) {
    // console.log('eneter');

    commit("authRequest");
    axios({
      method: "POST",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "dashboard/enquiryfollowup-report-chart",
      data: {
        segment: filterData.segment,
        salesengineer: filterData.salesengineer,
        daterange: filterData.daterange,
        region: filterData.region,
        productgroup: filterData.productgroup
      }
    })
      .then(function(response) {
        // console.log(response);
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("setEnquiryfollowupreportdata", response.data.response);
        }
      })
      .catch(function(error) {
        console.log(error.response);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            // commit("brandError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          // commit("authLogout");
          // router.push("/");
          console.log(error);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          //  commit("authLogout");
          //  router.push("/");
          console.log(error);
        }
      });
  },
  //Enquiry Followup Report chart

  //workshop database updation report chart
  async workshopdatabaseupdationreportchart({ commit }, filterData) {
    // console.log('eneter');

    commit("authRequest");
    axios({
      method: "POST",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "dashboard/workshop-updation-chart",
      data: {
        segment: filterData.segment,
        salesengineer: filterData.salesengineer,
        region: filterData.region
      }
    })
      .then(function(response) {
        // console.log(response);
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("setWorkshopupdationreportchartdata", response.data.response);
        }
      })
      .catch(function(error) {
        console.log(error.response);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            // commit("brandError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          // commit("authLogout");
          // router.push("/");
          console.log(error);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          //  commit("authLogout");
          //  router.push("/");
          console.log(error);
        }
      });
  },
  //workshop database updation report chart

  //order loss report chart
  async orderlossreportchart({ commit }, filterData) {
    // console.log('eneter');

    commit("authRequest");
    axios({
      method: "POST",
      headers: {
        Authorization: "Bearer " + store.getters.UserToken
      },
      url: VUE_APP_BASEURL + "dashboard/orderloss-report-chart",
      data: {
        segment: filterData.segment,
        salesengineer: filterData.salesengineer,
        daterange: filterData.daterange,
        region: filterData.region,
        productgroup: filterData.productgroup
      }
    })
      .then(function(response) {
        // console.log(response);
        if (response.data.result == 1) {
          commit("authSuccess");
          commit("setOrderlosschartdata", response.data.response);
        }
      })
      .catch(function(error) {
        console.log(error.response);
        if (error.response) {
          // client received an error response (5xx, 4xx)

          if (error.response.status == 404) {
            commit("authError", "AuthenticationError");
            commit("authLogout");
            router.push("/");
          }
          if (error.response.status == 422) {
            commit("authError", "AuthenticationError");
            // commit("brandError", error.response.data.response);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          commit("authError", "AuthenticationError");
          // commit("authLogout");
          // router.push("/");
          console.log(error);
        } else {
          // anything else
          commit("authError", "AuthenticationError");
          //  commit("authLogout");
          //  router.push("/");
          console.log(error);
        }
      });
  }
  //order loss report chart
};

const mutations = {
  setEnquiryreport: (state, resp) => {
    state.enquiryReportdata = resp;
    state.enquiryReportPaginate = resp.enquiriesPaginate.last_page;
  },
  setEnquiryreportValues: (state, resp) => {
    state.enquiryReportValuesdata = resp;
  },
  setTspreport: (state, resp) => {
    state.tspReportdata = resp;
    state.tspReportPaginate = resp.tspReportPaginate.last_page;
  },
  setEquipmentreport: (state, resp) => {
    state.equipmentReportdata = resp;
  },
  setCustomerreport: (state, resp) => {
    state.customerReportdata = resp;
  },
  setPartialorderreport: (state, resp) => {
    state.partialorderReportdata = resp;
  },
  setLeadreport: (state, resp) => {
    state.leadorderReportdata = resp;
    state.leadReportPaginate = resp.leadsPaginate.last_page;
  },
  setYearlyenquirydata: (state, resp) => {
    state.yearlyenquiryChartdata = resp;
  },
  setMonthlysalesplandata: (state, resp) => {
    state.monthlysalesplanChartdata = resp;
    state.mspReportPaginate = resp.enquiriesPaginate.last_page;
  },
  setSalesperformancereportdata: (state, resp) => {
    state.salesPerformancereportdata = resp;
  },
  setEnquiryfollowupreportdata: (state, resp) => {
    state.enquiryFollowupreportdata = resp;
  },
  setWorkshopupdationreportchartdata: (state, resp) => {
    state.workshopupdationreportchartdata = resp;
  },
  setOrderlosschartdata: (state, resp) => {
    state.orderlosschartdata = resp;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
