<template>
  <div>
    <!--container-->
    <v-container>
      <!--tob bar-->
      <bar />
      <!--tob bar end-->

      <!--template-->
      <template>
        <!--alert box-->
        <v-alert text dense color="teal" icon="mdi-clock-fast" border="left" v-if="productStatusmsg">
          <div v-for="(resp, i) in productResponse" :key="i">
            {{ resp }}
          </div>
        </v-alert>
        <!--alert box end-->

        <!---datatable -->
        <v-data-table :headers="headers" :items="products" :search="search" sort-by="calories" class="elevation-1">
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>PRODUCT</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line
                hide-details></v-text-field>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-btn color="black" dark class="mb-2" to="/product/add">
                New Product
              </v-btn>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <template v-slot:[`item.product_image`]="{ item }">
            <img :src="item.product_image" style="width:100px;" />
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editProduct(item)">
              mdi-pencil
            </v-icon>
          </template>
        </v-data-table>
        <!---datatable end-->
      </template>
      <!--template end-->
    </v-container>
    <!--container end-->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import router from "../../router";

export default {
  name: "Product",
  data: () => ({
    search: "",
    headers: [
      { text: "Actions", value: "actions", sortable: false },
      {
        text: "PRODUCT GROUP",
        align: "start",
        sortable: true,
        value: "product_group"
      },
      { text: "TPL NO", value: "tpl_no" },
      { text: "IMAGE", value: "product_image" },
      { text: "PRODUCT NAME", value: "product_name" },
      { text: "ACTIVE", value: "active" },

    ]
  }),

  computed: {
    ...mapGetters(["allproductList", "productStatus", "productStatusResponse"]),
    products: function () {
      return this.allproductList;
    },
    productStatusmsg: function () {
      return this.productStatus === "success";
    },
    productResponse: function () {
      return this.productStatusResponse;
    }
  },

  watch: {},

  created() {
    this.fetchAllProducts();
  },

  methods: {
    ...mapActions(["fetchAllProducts"]),

    editProduct(item) {
      const id = item.id;
      //  router.push({ name: 'ProductGroupEdit', params: { productgroupId: 123 }})
      router.push({ path: `/product/edit/${id}` });
    }
  }
};
</script>
