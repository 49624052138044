<template>
  <div>
    <v-card class="mx-auto mb-5" outlined>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="2">
            <v-autocomplete
              v-model="product"
              :items="products"
              item-text="product_name_withtpl"
              item-value="id"
              label="EQUIPMENT"
              class="mt-6"
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="warning" dark @click="filterEquipmentReport">
          FIND
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
// @ is an alias to /src

import { mapActions, mapGetters } from "vuex";

export default {
  name: "CustomerSearch",
  data: () => ({
    product: ""
  }),
  components: {},
  computed: {
    ...mapGetters(["productList"]),

    products: function() {
      return this.productList;
    }
  },
  created() {
    this.fetchProducts();
    this.fetchDefinedvalues();
  },
  methods: {
    ...mapActions(["fetchProducts", "equipmentReport", "fetchDefinedvalues"]),
    filterEquipmentReport() {
      const filterData = {
        product: this.product
      };
      //  console.log(filterData);
      this.equipmentReport(filterData);
    }
  }
};
</script>
