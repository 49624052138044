<template>
  <div>
    <!--container-->
    <v-container>
      <!--tob bar-->
      <bar />
      <!--tob bar end-->

      <!--template-->
      <template>
        <!---datatable -->
        <v-data-table
          :headers="headers"
          :items="products"
          :search="search"
          sort-by="calories"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>PRODUCT</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <template v-slot:[`item.product_image`]="{ item }">
            <img :src="item.product_image" style="width:100px;" />
          </template>
          <template v-slot:[`item.attachment1`]="{ item }">
            <a :href="item.attachment1" target="_blank">
              {{ item.attachment1filename }}
            </a>
          </template>
          <template v-slot:[`item.attachment2`]="{ item }">
            <a :href="item.attachment2" target="_blank">
              {{ item.attachment2filename }}
            </a>
          </template>
          <template v-slot:[`item.attachment3`]="{ item }">
            <a :href="item.attachment3" target="_blank">
              {{ item.attachment3filename }}
            </a>
          </template>
          <template v-slot:[`item.attachment4`]="{ item }">
            <a :href="item.attachment4" target="_blank">
              {{ item.attachment4filename }}
            </a>
          </template>
        </v-data-table>
        <!---datatable end-->
      </template>
      <!--template end-->
    </v-container>
    <!--container end-->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Library",
  data: () => ({
    search: "",
    headers: [
      {
        text: "PRODUCT GROUP",
        align: "start",
        sortable: true,
        value: "product_group"
      },
      { text: "TPL NO", value: "tpl_no" },
      { text: "IMAGE", value: "product_image" },
      { text: "PRODUCT NAME", value: "product_name" },
      { text: "PRODUCT CATALOGUE", value: "attachment1" },
      { text: "PRODUCT PPT", value: "attachment2" },
      { text: "PRODUCT BPV", value: "attachment3" },
      { text: "PRODUCT VIDEO", value: "attachment4" }
    ]
  }),

  computed: {
    ...mapGetters(["allproductList"]),
    products: function() {
      return this.allproductList;
    }
  },

  watch: {},

  created() {
    this.fetchAllProducts();
  },

  methods: {
    ...mapActions(["fetchAllProducts"])
  }
};
</script>
