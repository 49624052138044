<template>
  <v-app>
    <v-banner v-if="deferredPrompt" color="primary" dark class="text-left">
      Get our free app. It won't take up space on your phone!

      <template v-slot:actions>
        <v-btn text color="black" @click="dismiss">Dismiss</v-btn>
        <v-btn text color="white" @click="install">Install</v-btn>
      </template>
    </v-banner>
    <v-content>
      <div id="app">
        <router-view />
      </div>
    </v-content>
  </v-app>
</template>
<script>
export default {
  name: "App",
  data() {
    return {
      deferredPrompt: null
    };
  },
  created() {
    window.addEventListener("beforeinstallprompt", e => {
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
    });
    window.addEventListener("appinstalled", () => {
      this.deferredPrompt = null;
    });
  },
  methods: {
    async dismiss() {
      this.deferredPrompt = null;
    },
    async install() {
      this.deferredPrompt.prompt();
    }
  }
};
</script>
