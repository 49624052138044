<template>
  <div>
    <!--container-->
    <v-container>
      <!--tob bar-->
      <bar />
      <!--tob bar end-->

      <!--template-->
      <template>
        <!--card -->
        <v-card class="form-card mx-auto my-12">
          <!--alert box-->
          <v-alert text prominent type="error" v-if="errorResponse">
            <div v-for="(error, i) in stateError" :key="i">
              {{ error }}
            </div>
          </v-alert>
          <!--alert box end-->
          <v-list-item class="headline">
            <v-list-item-content>
              <v-list-item-title>
                STATE EDIT
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <!--form-->
          <form>
            <!--input field-->
            <v-card-text>
              <v-row justify="space-between">
                <v-col cols="12" xs="12" sm="12" md="12">
                  <v-select
                    v-model="countryname"
                    :items="countries"
                    item-text="name"
                    item-value="id"
                    label="COUNTRY"
                    :error-messages="countrynameErrors"
                    required
                    @input="$v.countryname.$touch()"
                    @blur="$v.countryname.$touch()"
                  ></v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12">
                  <v-text-field
                    v-model="statename"
                    :value="statename"
                    :error-messages="statenameErrors"
                    label="STATE NAME*"
                    required
                    @input="$v.statename.$touch()"
                    @blur="$v.statename.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <!--input field end-->
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="black" dark @click="submit"> SUBMIT </v-btn>
              <v-btn color="warning" dark to="/state"> CANCEL </v-btn>
            </v-card-actions>
          </form>
          <!--form end-->
        </v-card>
        <!--card end-->
      </template>
      <!--template end-->
    </v-container>
    <!--container end-->
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "StateEdit",
  data: () => ({}),
  validations: {
    statename: { required },
    countryname: { required }
  },
  computed: {
    ...mapGetters([
      "countryList",
      "stateStatus",
      "stateStatusResponse",
      "stateDetailsname",
      "countryidDetailsname"
    ]),
    countrynameErrors() {
      const errors = [];
      if (!this.$v.countryname.$dirty) return errors;
      !this.$v.countryname.required && errors.push("Country is required.");
      return errors;
    },
    statenameErrors() {
      const errors = [];
      if (!this.$v.statename.$dirty) return errors;
      !this.$v.statename.required && errors.push("State Name is required.");
      return errors;
    },
    errorResponse: function() {
      return this.stateStatus === "error";
    },
    stateError: function() {
      return this.stateStatusResponse;
    },
    countries: function() {
      return this.countryList;
    },
    statename: {
      get() {
        return this.stateDetailsname;
      },
      set(value) {
        this.$store.commit("updateStatename", value);
      }
    },
    countryname: {
      get() {
        return this.countryidDetailsname;
      },
      set(value) {
        this.$store.commit("updateCountryidname", value);
      }
    }
  },
  created() {
    this.fetchCountry();
    this.fetchStatedetails(this.$route.params.id);
  },

  methods: {
    ...mapActions(["stateUpdate", "fetchStatedetails", "fetchCountry"]),
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("ERROR");
      } else {
        // do your submit logic here
        setTimeout(() => {
          const stateData = {
            stateId: this.$route.params.id,
            stateName: this.statename.toUpperCase(),
            countryName: this.countryname
          };
          console.log(stateData);
          this.stateUpdate(stateData);
          //console.log(productgroupData);
        }, 500);
      }
    }
  }
};
</script>

<style scoped>
.form-card {
  margin: 10px auto;
  display: block;
}
</style>
