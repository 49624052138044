import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";


// import { getMessaging, getToken } from "firebase/messaging";
// import { VUE_APP_BASEURL } from "./utils/api";
// import axios from "axios";
// import store from "./store";

export default async function firebaseInitialize() {
  // TODO: Add SDKs for Firebase products that you want to use
  // https://firebase.google.com/docs/web/setup#available-libraries

  // Your web app's Firebase configuration
  const firebaseConfig = {
    apiKey: "AIzaSyBJkBD3jC1YwMSAY5aQQpJEiDoy1OSSFao",
    authDomain: "cdma-pwa-push-notification.firebaseapp.com",
    projectId: "cdma-pwa-push-notification",
    storageBucket: "cdma-pwa-push-notification.appspot.com",
    messagingSenderId: "412849403616",
    appId: "1:412849403616:web:ff858a5918d6c648b88285"
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);

  // Initialize Firebase Cloud Messaging and get a reference to the service
  const messaging = getMessaging(app);

  console.log(messaging);

  console.log("Requesting permission...");
  /*
  Notification.requestPermission().then(permission => {
    if (permission === "granted") {
      console.log("Notification permission granted.");
    }
  });

  getToken(messaging, {
    vapidKey:
      "BJFA5c57PkImv0Vt2oWnZk16Dk-ZsByWUPFW-jv_n871HHWv46G1JH3tTroi2bJL9GxP98QGW0Rl0SjAoukXn1U"
  })
    .then(currentToken => {
      if (currentToken) {
       // console.log(currentToken);
        // Send the token to your server and update the UI if necessary
        axios({
          method: "POST",
          headers: {
            Authorization: "Bearer " + store.getters.UserToken
          },
          url: VUE_APP_BASEURL + "pwa-user",
          data: {
            usertoken: currentToken,
          }
        })
          .then(function(response) {
             console.log(response);
          })
          .catch(function(error) {
            console.log(error);
          });
        // ...
      } else {
        // Show permission request UI
        console.log(
          "No registration token available. Request permission to generate one."
        );
        // ...
      }
    })
    .catch(err => {
      console.log("An error occurred while retrieving token. ", err);
    });*/
}
